import React, { FunctionComponent } from 'react';
import { Route, RouteComponentProps } from 'react-router-dom';

import { CustomerExportProps } from '../../pages/customerExport/CustomerExport';
import PageContainer from '../../pages/pageContainer/PageContainer';

interface PrivateRouteProps {
    path: string;
    component: React.FunctionComponent<RouteComponentProps & CustomerExportProps>;
    mainClassName?: string;
}

const PrivateRoute: FunctionComponent<PrivateRouteProps> = ({
    // eslint-disable-next-line @typescript-eslint/naming-convention
    component: Component,
    mainClassName,
    ...rest
}) => (
    <Route
        {...rest}
        render={(props): JSX.Element => (
            <PageContainer mainClassName={mainClassName}>
                <Component {...props}/>
            </PageContainer>
        )
        }
    />
);

export default PrivateRoute;
