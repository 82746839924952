import { Dispatch, SetStateAction, useState } from 'react';

import { Vehicle } from '../../components/VehicleInformationCards';

const useCarInformation = (): {
    selectedVehicle: Vehicle | null;
    setSelectedVehicle: Dispatch<SetStateAction<Vehicle | null>>;
} => {
    const [selectedVehicle, setSelectedVehicle] = useState<Vehicle | null>(null);

    return {
        selectedVehicle,
        setSelectedVehicle,
    };
};

export default useCarInformation;
