import 'core-js/stable';
import 'regenerator-runtime/runtime';
import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import './index.css';
import './i18n';
import { ApolloProvider } from '@apollo/react-hooks';
import { ThemeProvider } from '@mui/material/styles';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterMoment } from '@mui/x-date-pickers-pro/AdapterMoment';
import * as Sentry from '@sentry/browser';
import React from 'react';
import ReactDOM from 'react-dom';

import { client } from './ApolloClient';
import App from './App';
import config from './config';
import currentEnvironment from './environment/currentEnvironment';
import Environment from './environment/Environment';
import { initializeZendeskStyles } from './helpers/prefillZendeskConfig';
import ErrorBoundary from './pages/errorPages/ErrorBoundary';
import theme from './styles/theme';

if (currentEnvironment === Environment.Production) {
    Sentry.init({
        dsn: config.sentryDsn,
        environment: 'production',
        release: config.appVersion,
    });
}

if (currentEnvironment !== Environment.Production) {
    const favicon = document.getElementById('newFavicon') as HTMLLinkElement;

    favicon.href = `${config.appUrl}/logo_favicon_pink.ico`;
}

initializeZendeskStyles();

ReactDOM.render(
    <ThemeProvider theme={theme}>
        <ErrorBoundary message="logIn">
            <ApolloProvider client={client}>
                <LocalizationProvider dateAdapter={AdapterMoment}>
                    <App />
                </LocalizationProvider>
            </ApolloProvider>
        </ErrorBoundary>
    </ThemeProvider>,
    document.getElementById('root'),
);

/*
 * If you want your app to work offline and load faster, you can change
 * unregister() to register() below. Note this comes with some pitfalls.
 * Learn more about service workers: https://bit.ly/CRA-PWA
 */
// ServiceWorker.unregister();
