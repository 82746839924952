interface MopinionMetadata {
    name: string | null;
    email: string | null;
    accountType: string | null;
}

declare global {
    interface Window {
        metadata: MopinionMetadata;
    }
}

// eslint-disable-next-line import/no-anonymous-default-export
export default (metadata: MopinionMetadata): void => {
    window.metadata = metadata;
};
