import { Visibility, VisibilityOff } from '@mui/icons-material';
import { IconButton, InputAdornment } from '@mui/material';
import React, { FunctionComponent, useState } from 'react';
import { useTranslation } from 'react-i18next';

interface ShowPasswordButtonProps {
    onShowPassword: (isVisible: boolean) => void;
}

const ShowPasswordButton: FunctionComponent<ShowPasswordButtonProps> = ({ onShowPassword }) => {
    const [showingPassword, setShowingPassword] = useState(false);
    const { t } = useTranslation();

    const handleClick = () => {
        const isPasswordVisible = !showingPassword;

        setShowingPassword(isPasswordVisible);
        onShowPassword(isPasswordVisible);
    };

    return (
        <InputAdornment position="end">
            <IconButton
                aria-label={showingPassword ? t('hidePassword') : t('showPassword')}
                onClick={handleClick}
                onMouseDown={event => event.preventDefault()}
                style={{ padding: 0 }}
            >
                {showingPassword ? <Visibility /> : <VisibilityOff />}
            </IconButton>
        </InputAdornment>
    );
};

export default ShowPasswordButton;
