/* eslint-disable @typescript-eslint/no-explicit-any */
import { Modal, PrimaryButton, SecondaryButton, TertiaryButton, TextField } from '@get-e/react-components';
import { Box, Grid, Typography } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { COLORS } from '../../../../constants/colors';
import { BUTTON_WIDTH } from '../../../../constants/layout';
import { RideStatusOption, getRideStatusOptionKey } from '../../../../helpers/rideStatusUtil';
import CellText from '../../components/CellText';
import FlightStatusBadge from '../../components/FlightStatusBadge';
import StatusOptionBadge from '../../components/StatusOptionBadge';

const useStyles = makeStyles({
    heading: {
        marginBottom: '1.875rem',
        color: COLORS.BLUE,
    },
    remarksButton: { paddingBottom: '2rem' },
    referenceField: {
        marginBottom: '0.75rem',
        width: '100%',
    },
    remarksField: {
        marginBottom: 0,
        width: '100%',
    },
    buttonFixedWidth: {
        width: `${BUTTON_WIDTH} !important`,
        height: '44px',
    },
    cancelButton: {
        width: '150px',
        marginLeft: '1rem',
        color: COLORS.BLUE,
        border: `2px solid ${COLORS.BLUE}`,
        outline: '2px',
        '&:hover': { border: `2px solid ${COLORS.BLUE}` },
    },
});

export interface ConfirmRideData {
    referenceNumber: string;
    remarks: string;
}

interface ConfirmRideModalProps {
    isModalOpen: boolean;
    onClose: () => void;
    onConfirm: (data: ConfirmRideData) => void;
    data: any;
    isSaving?: boolean;
}

const ConfirmRideModal = ({ isModalOpen, onClose, onConfirm, data, isSaving }: ConfirmRideModalProps) => {
    const classes = useStyles();
    const { t } = useTranslation();
    const [referenceNumber, setReferenceNumber] = useState('');
    const [remarks, setRemarks] = useState<string>('');
    const [isRemarksVisible, setIsRemarksVisible] = useState(false);

    const handleConfirm = () => {
        onConfirm({
            referenceNumber,
            remarks,
        });
        onClose();
    };

    return (
        <Modal open={isModalOpen} onClose={onClose} maxWidth="md">
            <Grid container maxWidth="320px">
                <Grid item>
                    <Typography variant="h2" component="h2" className={classes.heading}>
                        {t('pages.rides.confirmRide')}
                    </Typography>
                </Grid>
                <Grid item display="flex" width="100%" alignItems="center" justifyContent="space-between">
                    <Typography color={COLORS.BLUE_DARK} fontWeight={700}>
                        {data.pickup}
                    </Typography>
                </Grid>
                <Grid item>
                    <CellText marginBottom="0.5rem" marginTop="1rem" fontSize="1rem">
                        <strong>From: </strong>
                        {data.route.from}
                    </CellText>
                    <CellText fontSize="1rem">
                        <strong>To: </strong>
                        {data.route.to}
                    </CellText>
                    <Box display="flex" alignItems="center" justifyContent="flex-start" paddingTop="0.5rem">
                        <StatusOptionBadge statusOptions={[getRideStatusOptionKey(RideStatusOption.NO_FLIGHT_INFORMATION)]} />
                        <Typography
                            sx={{
                                fontSize: '1rem',
                                color: COLORS.SLATE_GREY,
                                marginRight: '0.5rem !important',
                            }}
                        >
                            <strong>{t('flight')}: </strong>
                            {data.route.flight}
                        </Typography>
                        <FlightStatusBadge status={data.flightStatus} showTooltip />
                    </Box>
                    <CellText marginBottom="0.5rem" marginTop="1rem" fontSize="1rem">
                        <strong>{t('pages.rides.mainPassenger')}: </strong>
                        {data.passengers[0]}
                    </CellText>
                    <Box display="flex">
                        <CellText fontSize="1rem">
                            <strong>{t('travellers')}: </strong>
                        </CellText>
                        <CellText tooltip={data.passengers as string[]} marginLeft="0.1rem" fontSize="1rem">
                            {data.passengersCount}
                        </CellText>
                    </Box>
                    <CellText fontSize="1rem">
                        <strong>{t('luggages')}: </strong>
                        {data.luggages}
                    </CellText>
                    <CellText fontSize="1rem">
                        <strong>{t('carType')}: </strong>
                        {data.vehicleType}
                    </CellText>
                    <CellText marginTop="1rem" fontSize="1rem">
                        <strong>{t('pages.rides.rideNumber')}</strong>: {data.rideNumber}
                    </CellText>
                    <CellText marginTop="1rem" fontSize="1rem">
                        <strong>{t('pages.rides.specialRequest')}</strong>: {data.reference ?? '-'}
                    </CellText>
                </Grid>
                <Grid item width="100%" paddingTop="2rem">
                    <Typography color={COLORS.BLUE_DARK} fontWeight={700} marginBottom="1rem !important">
                        {t('pages.rides.referenceNumber')}
                    </Typography>
                    <TextField
                        className={classes.referenceField}
                        label={t('pages.rides.reference')}
                        name="reference"
                        onChange={event => setReferenceNumber(event.target.value)}
                        autoComplete="off"
                        value={referenceNumber}
                    />
                    <Grid item width="100%" display="flex" alignItems="flex-end" flexDirection="column">
                        {!isRemarksVisible && (
                            <TertiaryButton
                                className={classes.remarksButton}
                                onClick={() => setIsRemarksVisible(!isRemarksVisible)}
                            >
                                {t('pages.rides.remarks')}
                            </TertiaryButton>
                        )}
                        {isRemarksVisible && (
                            <TextField
                                type="text"
                                className={classes.remarksField}
                                label={t('pages.rides.remarks')}
                                name="remarks"
                                onChange={event => setRemarks(event.target.value)}
                                autoComplete="off"
                                value={remarks}
                                InputProps={{
                                    minRows: '3',
                                    multiline: true,
                                }}
                            />
                        )}
                    </Grid>
                </Grid>
            </Grid>
            <Box display="flex" justifyContent="space-between" marginTop="1.25rem">
                <PrimaryButton
                    className={classes.buttonFixedWidth}
                    onClick={handleConfirm}
                    loading={isSaving}
                    disabled={isSaving}
                >
                    {t('buttonName.confirm')}
                </PrimaryButton>
                <SecondaryButton onClick={onClose} className={classes.cancelButton}>
                    {t('buttonName.back')}
                </SecondaryButton>
            </Box>
        </Modal>
    );
};

export default ConfirmRideModal;
