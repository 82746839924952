import DoneIcon from '@material-ui/icons/Done';
import FileCopyIcon from '@material-ui/icons/FileCopy';
import {
    Button,
    Grid,
    Link,
    Typography,
    useMediaQuery,
    useTheme,
} from '@mui/material';
import copy from 'copy-to-clipboard';
import React, { FunctionComponent, useState } from 'react';
import { useTranslation } from 'react-i18next';
import useButtonStyles from '../../../styles/Button';
import typographyStyles from '../../../styles/Typography';

interface ContactInformationProps {
    contactDetails: {
        name: string;
        phone: string;
        description?: string;
    };
}

const ContactInformation: FunctionComponent<ContactInformationProps> = (
    { contactDetails }
) => {
    const { iconButton } = useButtonStyles();
    const classes = typographyStyles();
    const { t } = useTranslation();
    const theme = useTheme();
    const mobileView = useMediaQuery(theme.breakpoints.down('xs'));
    const [showCopyIcon, setShowCopyIcon] = useState(false);
    const [copied, setCopied] = useState(false);

    const handleClick = (text: string): void => {
        setCopied(true);
        copy(text);
    };

    const handleMouseLeave = (): void => {
        setShowCopyIcon(false);
        setCopied(false);
    };

    return (
        <>
            <Grid item xs={12} sm={6}>
                <Typography component="p" className={classes.mobileTitle}>
                    {t(`contact.${contactDetails.name}`)} {contactDetails.description
                        ? (
                            <Typography
                                component="span"
                            >
                                {t(`contact.${contactDetails.name}Description`)}
                            </Typography>
                        )
                        : null
                    }
                </Typography>
            </Grid>
            <Grid
                item
                xs={12}
                sm={6}
                style={{ textAlign: mobileView ? 'left' : 'right' }}
            >
                <div
                    style={{ height: '19px' }}
                    onMouseOver={() => setShowCopyIcon(true)}
                    onMouseLeave={() => handleMouseLeave()}
                >
                    <Typography
                        component="span"
                        sx={{
                            display: {
                                xs: 'none',
                                md: 'block',
                            },
                        }}
                    >
                        {showCopyIcon
                            ? (
                                <Button
                                    className={`
                            ${iconButton} 
                            ${copied ? classes.successTheme : ''}`
                                    }
                                    data-testid={`${contactDetails.name}-button`}
                                    disableRipple
                                    onClick={
                                        () => handleClick(contactDetails.phone)
                                    }
                                >
                                    {copied
                                        ? <DoneIcon />
                                        : <FileCopyIcon />
                                    }
                                </Button>
                            )
                            : null
                        }
                    </Typography>
                    <Link
                        href={`tel:${contactDetails.phone}`}
                        data-testid={`${contactDetails.name}-number`}
                    >
                        {contactDetails.phone}
                    </Link>
                </div>
            </Grid>
        </>
    );
};

export default ContactInformation;
