
import { Grid } from '@mui/material';
import { DataGridPro, GridColumns, GridValidRowModel } from '@mui/x-data-grid-pro';
import React from 'react';
import { useTranslation } from 'react-i18next';

import useDataGridStyles from '../../../../styles/DataGrid';
import { useSituationsColumns } from '../hooks/useSituationsColumns';
import { useSituationsDetailsContent } from '../hooks/useSituationsDetailsContent';
import SummaryBasicCard from '../SummaryBasicCard';
import SummaryListCard from '../SummaryListCard';

const rows = [
    {
        id: 1,
        pickUpTime: '15 Mar 2033 at 16:00 PM',
        rideNumber: '000-000-0000',
        driver: 'Dani California',
        sitationType: 'Driver late',
    },
    {
        id: 2,
        pickUpTime: '15 Mar 2033 at 16:00 PM',
        rideNumber: '000-000-0000',
        driver: 'Dani California',
        sitationType: 'Driver late',
    },
    {
        id: 3,
        pickUpTime: '15 Mar 2033 at 16:00 PM',
        rideNumber: '000-000-0000',
        driver: 'Dani California',
        sitationType: 'Driver late',
    },
];

const SituationsTab = () => {
    const { t } = useTranslation();
    const dataGridClases = useDataGridStyles();
    const columns = useSituationsColumns();

    return (
        <Grid container width="100%">
            <Grid container columnSpacing={3} marginTop={2}>
                <Grid item xs={12} md={4} lg={4}>
                    <SummaryBasicCard
                        title={t('pages.reports.rateOfSituations')}
                        percentage1="0.23"
                        text1="0.43% in previous period"
                        text2="16 Mar 2033- 23 Mar 2033"
                    />
                </Grid>
                <Grid item xs={12} md={4} lg={4}>
                    <SummaryListCard
                        title={t('pages.reports.situationsByType')}
                        driverLate={1}
                        driverNoShow={0}
                        driverBehaviour={0}
                        wrongVehicle={1}
                    />
                </Grid>
            </Grid>
            <DataGridPro
                className={dataGridClases.dataGrid}
                autoHeight
                hideFooter
                disableColumnSelector
                disableColumnFilter
                rows={rows}
                columns={columns as GridColumns<GridValidRowModel>}
                getDetailPanelContent={useSituationsDetailsContent()}
                getDetailPanelHeight={() => 'auto'}
            />
        </Grid>
    );
};

export default SituationsTab;

