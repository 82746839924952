import { MessageDialog } from '@get-e/react-components';
import { Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import React from 'react';

import { useTranslation } from 'react-i18next';
import { COLORS } from '../../../constants/colors';

const useStyles = makeStyles({
    confirmButton: {
        background: COLORS.ORANGE,
        '&:hover': { background: COLORS.RED },
    },
    closeButton: { marginLeft: '.5rem' },
});

const UpdateRideConfirmModal = ({
    onClose,
    onConfirm,
}: {
    onClose: () => void;
    onConfirm: () => void;
}) => {
    const { t } = useTranslation();
    const classes = useStyles();

    return (
        <MessageDialog
            onClose={onClose}
            onConfirm={onConfirm}
            title={t('modals.updateRide.title')}
            isConfirmButtonDisplayed
            closeButtonLabel={t('buttonName.back')}
            confirmButtonLabel={t('buttonName.update')}
            closeButtonType="secondary"
            confirmButtonClassName={classes.confirmButton}
            closeButtonClassName={classes.closeButton}
            areButtonsFullWidth
        >
            <Typography paddingTop="1rem">{t('modals.updateRide.description')}</Typography>
            <Typography
                style={{
                    fontWeight: 700,
                    marginBottom: '2rem',
                    marginTop: '1rem',
                }}
            >
                {t('doYouWantToProceed')}
            </Typography>
        </MessageDialog>
    );
};

export default UpdateRideConfirmModal;
