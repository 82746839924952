import { createContext, useContext } from 'react';

export enum Severity {
    Info,
    Error
}

export interface NotificationContextValue {
    showNotification: (
        message: string,
        severity?: Severity,
    ) => void;
}

export const NotificationContext = createContext<NotificationContextValue | null>(null);

export const useNotificationContext = (): NotificationContextValue => {
    const context = useContext(NotificationContext);

    if (context === null) {
        throw new Error('NotificationContext should not be null');
    }

    return context;
};
