import { Button, Typography } from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';

import support from '../../../public/assets/icons/support.svg';
import badgeStyles from './Badge.styles';
import simpleMenuContainerStyles from './SimpleMenuContainer.styles';

const SupportButton = (
    { onClick }: { onClick: () => void}
): JSX.Element => {
    const { t } = useTranslation();
    const containerClasses = simpleMenuContainerStyles();
    const { avatar } = badgeStyles();

    return (
        <div className={containerClasses.menuContainer}>
            <Button
                className={containerClasses.userMenu}
                data-testid="open-contact-modal"
                onClick={onClick}
            >
                <Typography
                    component="span"
                    sx={{
                        display: {
                            xs: 'none',
                            md: 'block',
                        },
                    }}
                >
                    {t('navigation.customer.contact')}
                </Typography>

                <img
                    src={support}
                    alt={'navigation.customer.contactAlt'}
                    className={avatar}
                />
            </Button>
        </div>
    );
};

export default SupportButton;
