import gql from 'graphql-tag';

export interface UpdateUserActivation {
    id: string;
    isDeactivated: boolean;
}

export const UPDATE_USER_ACTIVATION = gql`
    mutation UPDATE_USER_ACTIVATION(
        $userId: ID!, 
        $isDeactivated: Boolean!
    ){
        updateUser(
            input: {
                userId: $userId,
                patch: {
                    isDeactivated: $isDeactivated
                }
            }
        ) {
            id
            isDeactivated
        }
    }
`;
