import { Modal, PrimaryButton, SecondaryButton } from '@get-e/react-components';
import FlagOutlinedIcon from '@mui/icons-material/FlagOutlined';
import RepeatOutlinedIcon from '@mui/icons-material/RepeatOutlined';
import SportsScoreOutlinedIcon from '@mui/icons-material/SportsScoreOutlined';
import { Box, Tooltip, Typography } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import clsx from 'clsx';
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { REPEATS } from '../../../../constants';
import { COLORS } from '../../../../constants/colors';
import { BUTTON_WIDTH } from '../../../../constants/layout';
import { BlockedPeriodMessage } from '../../../../helpers/repeats';

const useStyles = makeStyles({
    heading: {
        marginBottom: '1.875rem',
        color: COLORS.BLUE,
    },
    buttonFixedWidth: {
        width: `${BUTTON_WIDTH} !important`,
        height: '44px',
    },
    mobileContent: {
        color: COLORS.BLACK,
        marginLeft: '0.3rem !important',
    },
    cancelButton: {
        width: '150px',
        marginLeft: '1rem',
        color: COLORS.BLUE,
        border: `2px solid ${COLORS.BLUE}`,
        outline: '2px',
        '&:hover': { border: `2px solid ${COLORS.BLUE}` },
    },
});

interface ConfirmCreateModalProps {
    isModalOpen: boolean;
    onClose: () => void;
    repeats: REPEATS;
    blockedPeriodMessage: BlockedPeriodMessage;
    submitForm: () => Promise<void>;
    isEditing: boolean;
    isSaving?: boolean;
    isFieldDisabled?: boolean;
}

const ConfirmCreateModal = ({
    isModalOpen,
    onClose,
    repeats,
    blockedPeriodMessage,
    submitForm,
    isEditing,
    isSaving,
    isFieldDisabled,
}: ConfirmCreateModalProps) => {
    const classes = useStyles();
    const { t } = useTranslation();

    const modalTitle = useMemo(
        () => (isEditing ? t('pages.availability.editBlockedPeriod') : t('pages.availability.newBlockedPeriod')),
        [isEditing, t]
    );

    return (
        <Modal
            open={isModalOpen}
            onClose={onClose}
            maxWidth="md"
        >
            <Typography variant="h2" component="h2" className={classes.heading}>
                {modalTitle}
            </Typography>
            <Typography
                sx={{
                    color: COLORS.BLACK,
                    marginBottom: '1rem !important',
                }}
            >
                {t('pages.availability.confirmCreateBlockPeriodMessage')}:
            </Typography>
            <Box display="flex" flexDirection="column" alignItems="flex-start">
                <Box
                    display="flex"
                    alignItems="center"
                    margin="0 0.5rem 0.5rem -0.3rem"
                    color={COLORS.SLATE_GREY}
                >
                    <Tooltip title={t('pages.availability.startsOn')} arrow>
                        <FlagOutlinedIcon />
                    </Tooltip>
                    <Typography className={classes.mobileContent}>
                        {blockedPeriodMessage.part1}{' '}
                    </Typography>
                </Box>
                <Box
                    display="flex"
                    alignItems="center"
                    margin="0 0.5rem 0.5rem -0.3rem"
                    color={COLORS.SLATE_GREY}
                >
                    <Tooltip title={t('pages.availability.endsOn')} arrow>
                        <SportsScoreOutlinedIcon />
                    </Tooltip>
                    <Typography className={classes.mobileContent}>
                        {blockedPeriodMessage.part2}
                    </Typography>
                </Box>
                {repeats !== REPEATS.DO_NOT_REPEAT && (
                    <Box
                        display="flex"
                        alignItems="center"
                        margin="0 0.5rem 0 -0.3rem"
                        color={COLORS.SLATE_GREY}
                    >
                        <Tooltip title={t('pages.availability.repeatingRules')} arrow>
                            <RepeatOutlinedIcon />
                        </Tooltip>
                        <Typography className={classes.mobileContent}>
                            {blockedPeriodMessage.part3}
                        </Typography>
                    </Box>
                )}
                <Typography
                    sx={{
                        marginTop: '1rem !important',
                        color: COLORS.BLACK,
                        fontWeight: 700,
                    }}
                >
                    {t('doYouWantToProceed')}
                </Typography>
            </Box>
            <Box
                display="flex"
                justifyContent="flex-start"
                margin="2.5rem 0 0 0"
                color={COLORS.SLATE_GREY}
            >
                <PrimaryButton
                    className={classes.buttonFixedWidth}
                    onClick={submitForm}
                    loading={isSaving}
                    disabled={isSaving || isFieldDisabled}
                >
                    {isEditing ? t('buttonName.update') : t('buttonName.create')}
                </PrimaryButton>
                <SecondaryButton
                    onClick={onClose}
                    className={clsx(classes.cancelButton)}
                >
                    {t('buttonName.back')}
                </SecondaryButton>
            </Box>
        </Modal>
    );
};

export default ConfirmCreateModal;
