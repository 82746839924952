import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import {
    AppBar,
    Chip,
    Drawer,
    Grid,
    IconButton,
    Link,
    Slide,
    Toolbar,
    useMediaQuery,
    useScrollTrigger,
    useTheme,
} from '@mui/material';
import clsx from 'clsx';
import React, { FunctionComponent, ReactNode } from 'react';
import { useTranslation } from 'react-i18next';

import config from '../../config';
import currentEnvironment from '../../environment/currentEnvironment';
import Environment from '../../environment/Environment';
import { UserRole } from '../../helpers/getUserRole';
import containerStyles from '../../styles/Container';
import typographyStyles from '../../styles/Typography';
import NavigationLogo from '../logos/NavigationLogo';
import BackOfficeList from './backOffice/BackOfficeList';
import CustomerList from './customers/CustomerList';
import navigationStyles from './NavigationMenu.styles';
import SupplierList from './suppliers/SupplierList';

interface Props {
    children: ReactNode;
    closed: boolean;
    closeMenu: () => void;
    openMenu: () => void;
    role: UserRole;
}

const NavigationMenu: FunctionComponent<Props> = ({
    children,
    closed,
    closeMenu,
    openMenu,
    role,
}) => {
    const classes = navigationStyles();
    const { subtitle1Override, whiteLink } = typographyStyles();
    const { companyInformationContainer } = containerStyles();
    const theme = useTheme();
    const mobileView = useMediaQuery(theme.breakpoints.down('xs'));
    const trigger = useScrollTrigger();
    const { t } = useTranslation();

    const onMobileClick = (): void => {
        if (mobileView) {
            closeMenu();
        }
    };

    const getNavigation = (userRole: UserRole): JSX.Element => {
        switch (userRole) {
            case UserRole.BackOffice:
                return <BackOfficeList/>;
            case UserRole.CustomerAndSupplier:
                throw new Error('Unauthorized user');
            case UserRole.Supplier:
                return <SupplierList/>;
            case UserRole.Customer:
                return <CustomerList/>;
            default:
                throw new Error('Unhandled user role');
        }
    };

    return (
        <>
            {mobileView
                ? (
                    <Slide appear={false} direction="down" in={!trigger}>
                        <AppBar
                            position="fixed"
                            className={clsx(
                                classes.appBar,
                                { [classes.appBarShift]: !closed }
                            )}
                        >
                            <Toolbar>
                                <IconButton
                                    color="default"
                                    aria-label="open drawer"
                                    onClick={() => openMenu()}
                                    data-testid="open-menu-button"
                                    edge="start"
                                    className={
                                        clsx(
                                            classes.menuButton,
                                            { [classes.hide]: !closed }
                                        )
                                    }
                                >
                                    <ChevronRightIcon />
                                </IconButton>
                                <Grid
                                    container
                                    justifyContent="flex-end"
                                    alignItems="center"
                                >
                                    {children}
                                </Grid>
                            </Toolbar>
                        </AppBar>
                    </Slide>
                )
                : (
                    <AppBar
                        position="fixed"
                        className={
                            clsx(
                                classes.appBar,
                                { [classes.appBarShift]: !closed }
                            )
                        }
                    >
                        <Toolbar>
                            <Grid
                                container
                                justifyContent="space-between"
                                alignItems="center"
                            >
                                {children}
                            </Grid>
                        </Toolbar>
                    </AppBar>
                )
            }
            <Drawer
                className={clsx(classes.drawer, {
                    [classes.drawerOpen]: !closed,
                    [classes.drawerClose]: closed,
                })}
                classes={{
                    paper: clsx({
                        [classes.drawerOpen]: !closed,
                        [classes.drawerClose]: closed,
                    }),
                }}
                variant="permanent"
                anchor="left"
                open={!closed}
                data-testid={`navigation-menu-${closed ? 'closed' : 'open'}`}
            >
                <div className={classes.toolbar}>
                    {currentEnvironment === Environment.Production
                        ? null
                        : <Chip label={Environment[currentEnvironment]} />}
                    <IconButton
                        onClick={() => (closed ? openMenu() : closeMenu())}
                        title={`menu-${closed ? 'open' : 'close'}`}
                        aria-label="close drawer"
                        data-testid="desktop-menu-toggle"
                    >
                        {closed ? <ChevronRightIcon /> : <ChevronLeftIcon />}
                    </IconButton>
                </div>
                <Grid
                    container
                    direction="column"
                    justifyContent="space-between"
                >
                    <Grid item>
                        <div onClick={() => onMobileClick()}>
                            {getNavigation(role)}
                        </div>
                    </Grid>
                    <Grid item className={companyInformationContainer}>
                        <NavigationLogo closed={closed}/>
                        {closed
                            ? null
                            : (
                                <>
                                    <Link
                                        className={`${subtitle1Override} ${whiteLink}`}
                                        href={`${config.geteUrl}/privacy`}
                                        target="_blank"

                                    >
                                        <strong>
                                            {t('accountActivation.privacyPolicy')}
                                        </strong>
                                        <span className="sr-only">
                                            {t('screenReader.newWindow')}
                                        </span>
                                    </Link>
                                    <br/>
                                    <Link
                                        className={`${subtitle1Override} ${whiteLink}`}
                                        href={`${config.geteUrl}/terms-and-conditions`}
                                        target="_blank"
                                    >
                                        <strong>{t('accountActivation.termsAndConditions')
                                        }
                                        </strong>
                                        <span className="sr-only">
                                            {t('screenReader.newWindow')}
                                        </span>
                                    </Link>
                                </>
                            )
                        }
                    </Grid>
                </Grid>
            </Drawer>
        </>
    );
};

export default NavigationMenu;

