import { SecondaryButton } from '@get-e/react-components';
import { Grid, Link, Typography } from '@mui/material';
import React, { FunctionComponent } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import config from '../../config';

import car from '../../public/assets/icons/car.svg';
import useButtonStyles from '../../styles/Button';
import containerStyles from '../../styles/Container';
import typographyStyles from '../../styles/Typography';

const LogInMessage: FunctionComponent = () => {
    const { t } = useTranslation();
    const classes = useButtonStyles();
    const typeClasses = typographyStyles();
    const containerClasses = containerStyles();

    return (
        <Grid
            container
            direction="column"
            justifyContent="center"
            alignItems="center"
            className={containerClasses.fullHeight}
            data-testid="log-in-again"
        >
            <Grid item>
                <img src={car} alt={t('errors.somethingWentWrong')}/>
            </Grid>
            <Grid item style={{ margin: '1em 0' }}>
                <Typography variant="body2" align="center">
                    {t('errors.somethingWentWrong')}
                </Typography>
                <Typography
                    variant="body2"
                    align="center"
                    className={typeClasses.subtitle}
                    style={{ whiteSpace: 'pre-line' }}
                >
                    <Trans i18nKey="unexpectedErrorMessage">
                        <Link
                            href={`mailto:${t('contact.emailAddress')}`}
                        >
                            {t('contact.emailAddress')}
                        </Link>
                        <Link
                            href={`tel:${t('contact.phoneNumber')}`}
                        >
                            {t('contact.phoneNumber')}
                        </Link>
                    </Trans>
                </Typography><br/>
            </Grid>
            <Grid item>
                <SecondaryButton
                    onClick={() => window.location.reload()}
                    className={classes.inlineButtonMargin}
                >
                    {t('errors.refresh')}
                </SecondaryButton>
                <SecondaryButton
                    onClick={() => {
                        window.location.href = config.logOutUrl;
                    }}
                >
                    {t('errors.logIn')}
                </SecondaryButton>
            </Grid>
        </Grid>
    );
};

export default LogInMessage;
