import OpenInNewIcon from '@material-ui/icons/OpenInNew';
import { Grid, Link } from '@mui/material';
import React, { FunctionComponent } from 'react';
import tableStyles from '../../../styles/Table';
import typographyStyles from '../../../styles/Typography';

const Document: FunctionComponent<{ label: string; url: string}> = (
    { label, url }
) => {
    const { tableRow, tableRowHover } = tableStyles();
    const classes = typographyStyles();

    return (
        <Grid container className={`${tableRowHover} ${tableRow}`}>
            <Grid
                container
                item
                xs={10}
                sm={12}
                alignItems="baseline"
            >
                <Link
                    href={url}
                    target="_blank"
                    underline="none"
                    className={classes.black}
                >
                    {label}
                    <OpenInNewIcon/>
                </Link>
            </Grid>
        </Grid>
    );
};

export default Document;
