import { NumberedTab, NumberedTabs, PrimaryButton } from '@get-e/react-components';
import AddIcon from '@material-ui/icons/Add';
import { Grid, Typography } from '@mui/material';
import React, { FunctionComponent, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useRouteMatch } from 'react-router-dom';

import { COLORS } from '../../../constants/colors';
import { useCurrentUserContext } from '../../../context/CurrentUserContext';
import { initializeZendeskStyles } from '../../../helpers/prefillZendeskConfig';
import UnauthorizedUser from '../../errorPages/UnauthorizedUser';
import ActiveUsersDataGrid from './components/ActiveUsersDataGrid';
import DeactivatedUsersDataGrid from './components/DeactivatedUsersDataGrid';
import InvitedUsersDataGrid from './components/InvitedUsersDataGrid';
import InviteUserModal from './components/InviteUserModal';
import userGridStyles from './Users.styles';

export enum ActiveTab {
    Active,
    Invited,
    Deactivated
}

const getActiveTab = (url: string): ActiveTab => {
    switch (url) {
        case '/users/invited':
            return ActiveTab.Invited;
        case '/users':
        case '/users/active':
            return ActiveTab.Active;
        case '/users/deactivated':
            return ActiveTab.Deactivated;
        default:
            throw new Error(`Unhandled URL "${url}"`);
    }
};

const Users: FunctionComponent = () => {
    const history = useHistory();
    const { pathname } = history.location;
    const activeTab = getActiveTab(pathname);
    const [activeCount, setActiveCount] = useState<number|null>(null);
    const [invitedCount, setInvitedCount] = useState<number|null>(null);
    const [deactivatedCount, setDeactivatedCount] = useState<number|null>(null);
    const { t } = useTranslation();
    const classes = userGridStyles();
    const [activeLoading, setActiveLoading] = useState(true);
    const [deactivatedLoading, setDeactivatedLoading] = useState(true);
    const [invitedLoading, setInvitedLoading] = useState(true);
    const [openModal, setOpenModal] = useState(false);
    const { currentUser } = useCurrentUserContext();
    const { url } = useRouteMatch();

    useEffect(() => () => {
        initializeZendeskStyles();
    }, []);

    if (!currentUser.permissions.manageUsers) {
        return <UnauthorizedUser />;
    }

    const onInviteUser = (): void => {
        history.push(`${url}/invited`);
    };

    return (
        <>
            <InviteUserModal
                open={openModal}
                onClose={() => setOpenModal(false)}
                onInviteUser={onInviteUser}
            />
            <Grid
                container
                justifyContent="space-between"
                alignItems="flex-end"
                style={{ padding: '2rem' }}
            >
                <Grid
                    container
                    justifyContent="space-between"
                    className={classes.usersHeader}
                >
                    <Grid item xs={9}>
                        <Typography
                            style={{
                                color: COLORS.BLUE,
                                fontSize: '1.5rem',
                                fontWeight: 700,
                            }}
                        >Users
                        </Typography>
                    </Grid>
                    <Grid item>
                        <PrimaryButton
                            onClick={() => setOpenModal(true)}
                            loading={false}
                            icon={<AddIcon />}
                        >
                            {t('pages.users.inviteUser')}
                        </PrimaryButton>
                    </Grid>
                </Grid>
                <Grid item xs={12}>
                    <NumberedTabs value={activeTab}>
                        <NumberedTab
                            number={activeCount}
                            label={t('pages.users.tabs.active')}
                            url={`${url}/active`}
                            index={ActiveTab.Active}
                            selectedIndex={activeTab}
                            loading={activeLoading}
                        />
                        <NumberedTab
                            number={invitedCount}
                            label={t('pages.users.tabs.invited')}
                            url={`${url}/invited`}
                            index={ActiveTab.Invited}
                            selectedIndex={activeTab}
                            loading={invitedLoading}
                        />
                        <NumberedTab
                            number={deactivatedCount}
                            url={`${url}/deactivated`}
                            label={t('pages.users.tabs.deactivated')}
                            loading={deactivatedLoading}
                            index={ActiveTab.Deactivated}
                            selectedIndex={activeTab}
                        />
                    </NumberedTabs>
                </Grid>
                <Grid container spacing={0} className={classes.content}>
                    <ActiveUsersDataGrid
                        value={activeTab}
                        onUserCountChange={userCount => setActiveCount(userCount)}
                        onLoaded={() => setActiveLoading(false)}
                    />
                    <InvitedUsersDataGrid
                        value={activeTab}
                        onUserCountChange={userCount => setInvitedCount(userCount)}
                        onLoaded={() => setInvitedLoading(false)}
                    />
                    <DeactivatedUsersDataGrid
                        value={activeTab}
                        onUserCountChange={
                            userCount => setDeactivatedCount(userCount)
                        }
                        onLoaded={() => setDeactivatedLoading(false)}
                    />
                </Grid>
            </Grid>
        </>
    );
};

export default Users;
