import { useMutation } from '@apollo/react-hooks';
import { MessageDialog } from '@get-e/react-components';
import { Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import React, { FunctionComponent } from 'react';
import { useTranslation } from 'react-i18next';

import { useNotificationContext, Severity } from '../../../../context/NotificationContext';
import { report } from '../../../../helpers/report';
import typographyStyles from '../../../../styles/Typography';
import { GET_EXISTING_USERS, User } from '../Users.graphql';
import { UPDATE_USER_ACTIVATION, UpdateUserActivation } from './UpdateUserActivation.graphql';

const useStyles = makeStyles({ closeButton: { marginLeft: '.5rem' } });

const ReactivateUserModal: FunctionComponent<{
    onClose: () => void;
    user: User;
}> = ({ onClose, user }) => {
    const { t } = useTranslation();
    const typographyClasses = typographyStyles();
    const { fullName } = user;
    const { showNotification } = useNotificationContext();
    const classes = useStyles();

    const [updateUser, { error }] = useMutation<UpdateUserActivation>(UPDATE_USER_ACTIVATION, {
        onCompleted() {
            onClose();
            showNotification(t('reactivateSuccess', { fullName }), Severity.Info);
        },

        /*
         *This onError prevents console errors / errors in our tests.
         *It is a known issue in the apollo community:
         *https://stackoverflow.com/questions/59465864/
         *handling-errors-with-react-apollo-usemutation-hook and there are
         *wider discussions about error handling with onError and the
         *error provided by useMutation hooks.
         */
        onError(apolloError) {
            report(apolloError);
        },
        awaitRefetchQueries: true,
        refetchQueries: [
            {
                query: GET_EXISTING_USERS,
                variables: {
                    isDeactivated: true,
                    after: null,
                    before: null,
                },
            },
            {
                query: GET_EXISTING_USERS,
                variables: {
                    isDeactivated: false,
                    after: null,
                    before: null,
                },
            },
        ],
    });

    const handleClick = async (): Promise<void> => {
        await updateUser({
            variables: {
                userId: user.id,
                isDeactivated: !user.isDeactivated,
            },
        });
    };

    return (
        <MessageDialog
            onClose={onClose}
            onConfirm={handleClick}
            title={t('modals.reactivate.title')}
            isConfirmButtonDisplayed
            closeButtonLabel={t('buttonName.cancel')}
            confirmButtonLabel={t('buttonName.reactivate')}
            closeButtonType="secondary"
            closeButtonClassName={classes.closeButton}
            areButtonsFullWidth
        >
            <Typography
                style={{
                    fontWeight: 'bold',
                    paddingTop: '1rem',
                }}
            >
                {t('modals.reactivate.description')}
            </Typography>
            <Typography
                style={{
                    paddingTop: '0.5rem',
                    paddingBottom: '1rem',
                }}
            >
                {t('modals.reactivate.descriptionDetailed')}
            </Typography>
            {error && (
                <div className={typographyClasses.errorContainer}>
                    <p className={typographyClasses.errorTheme}>{t('modals.reactivate.error')}</p>
                </div>
            )}
        </MessageDialog>
    );
};

export default ReactivateUserModal;
