import { DateField, Modal, MultipleSelect, PrimaryButton, SecondaryButton } from '@get-e/react-components';
import { Box, Typography } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import clsx from 'clsx';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { COLORS } from '../../../../constants/colors';
import { DATE_FORMATS } from '../../../../constants/dateFormats';
import { BUTTON_WIDTH } from '../../../../constants/layout';
import { RideStatusSupplierFilter, getRideStatusSupplierMap } from '../../../../helpers/rideStatusUtil';

const useStyles = makeStyles({
    heading: {
        marginBottom: '1.875rem',
        color: COLORS.BLUE,
    },
    dateFieldStart: {
        marginTop: '2.5rem',
        marginBottom: '1.25rem',
    },
    buttonFixedWidth: {
        width: `${BUTTON_WIDTH} !important`,
        height: '44px',
    },
    cancelButton: {
        width: '150px',
        marginLeft: '1rem',
        color: COLORS.BLUE,
        border: `2px solid ${COLORS.BLUE}`,
        outline: '2px',
        '&:hover': { border: `2px solid ${COLORS.BLUE}` },
    },
});

export interface RideFilters {
    statusIds: string[];
    startingDate: Date | null;
    endingDate: Date | null;
}

interface FilterRidesModalProps {
    isModalOpen: boolean;
    onClose: () => void;
    onFilter: (filters: RideFilters) => void;
    isSaving?: boolean;
}

const FilterRidesModal = ({ isModalOpen, onClose, onFilter, isSaving }: FilterRidesModalProps) => {
    const classes = useStyles();
    const { t } = useTranslation();
    const [statusIds, setStatusIds] = useState<string[]>([]);
    const [startDateTime, setStartDateTime] = useState<Date | null>(null);
    const [endDateTime, setEndDateTime] = useState<Date | null>(null);

    const handleFilter = () => {
        onFilter({
            statusIds,
            startingDate: startDateTime,
            endingDate: endDateTime,
        });
        onClose();
    };

    const onSetStatusIds = (ids: string[]): void => {
        setStatusIds(ids);
    };

    return (
        <Modal open={isModalOpen} onClose={onClose} maxWidth="md">
            <Typography variant="h2" component="h2" className={classes.heading}>
                {t('modals.filterRides.filterResults')}
            </Typography>
            <Box>
                <MultipleSelect
                    label="Status"
                    value={statusIds}
                    values={getRideStatusSupplierMap()}
                    onSetIds={onSetStatusIds}
                    defaultSelectedValue={[
                        RideStatusSupplierFilter.TO_CONFIRM,
                        RideStatusSupplierFilter.CONFIRMED,
                        RideStatusSupplierFilter.TO_CLOSE,
                    ]}
                />
                <DateField
                    className={classes.dateFieldStart}
                    label={t('pages.rides.startingDate')}
                    format={DATE_FORMATS['EEE, dd MMM yyyy']}
                    value={startDateTime}
                    onChange={newValue => {
                        setStartDateTime(newValue);
                    }}
                />
                <DateField
                    label={t('pages.rides.endingDate')}
                    format={DATE_FORMATS['EEE, dd MMM yyyy']}
                    value={endDateTime}
                    onChange={newValue => {
                        setEndDateTime(newValue);
                    }}
                />
            </Box>
            <Box display="flex" justifyContent="flex-start" marginTop="1.25rem">
                <PrimaryButton className={classes.buttonFixedWidth} onClick={handleFilter} loading={isSaving} disabled={isSaving}>
                    {t('filter')}
                </PrimaryButton>
                <SecondaryButton onClick={onClose} className={clsx(classes.cancelButton)}>
                    {t('buttonName.back')}
                </SecondaryButton>
            </Box>
        </Modal>
    );
};

export default FilterRidesModal;
