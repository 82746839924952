import { Dispatch, SetStateAction, useState } from 'react';
import { v4 as uuidv4 } from 'uuid';

import { InputError } from '../../../../helpers/inputValidation/InputError';
import isFilledString from '../../../../helpers/inputValidation/validators/isFilledString';
import { driverInformations } from '../dummyData';

export const initialDriverInformation = {
    id: uuidv4(),
    name: '',
    mobile: '',
    rating: '',
    reviewsNumber: '',
} as DriverInformation;

export const initialDriverInformationError = { nameError: null } as DriverInformationError;

export interface DriverInformation {
    id: string;
    name: string;
    mobile: string;
    rating: string;
    reviewsNumber: string;
}

export interface DriverInformationError {
    nameError: InputError | null;
}

const useSupplierDriverInformation = (): {
    driverInformations: DriverInformation[];
    driverInformation?: DriverInformation;
    setDriverInformation: Dispatch<SetStateAction<DriverInformation>>;
    driverInformationError: DriverInformationError;
    setDriverInformationError: Dispatch<SetStateAction<DriverInformationError>>;
    addDriverInformation: (driverInformation: DriverInformation) => void;
    validateDriverInformationFields: () => void;
} => {
    const [values, setValues] = useState<DriverInformation[]>(driverInformations);
    const [value, setValue] = useState<DriverInformation>(initialDriverInformation);
    const [error, setError] = useState<DriverInformationError>(initialDriverInformationError);

    const validateFields = () => {
        const validatedName = isFilledString(value?.name ?? '', InputError.Empty);
        const fieldErros = { nameError: validatedName.isValid ? null : validatedName.error };

        setError(fieldErros);
    };

    const handleAddDriverInformation = (driverInformation: DriverInformation) => {
        const updatedDriverInformations = [...values, driverInformation];

        setError(initialDriverInformationError);
        setValues(updatedDriverInformations);
        setValue(driverInformation);
    };

    return {
        driverInformations: values,
        driverInformation: value,
        setDriverInformation: setValue,
        driverInformationError: error,
        setDriverInformationError: setError,
        addDriverInformation: handleAddDriverInformation,
        validateDriverInformationFields: validateFields,
    };
};

export default useSupplierDriverInformation;
