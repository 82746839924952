import { TabPanel } from '@get-e/react-components';
import { Grid, Typography } from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { noResultsIcon } from '../../../../public/assets/icons';

import typographyStyles from '../../../../styles/Typography';
import { ActiveTab } from '../Users';

const NoResults = ({ index, value }: {
    index: number;
    value: ActiveTab;
}): JSX.Element => {
    const { t } = useTranslation();
    const classes = typographyStyles();

    return (
        <TabPanel selectedValue={value} value={index}>
            <Grid
                container
                spacing={0}
                direction="column"
                alignItems="center"
                justifyContent="center"
                data-testid="no-results-user-page"
            >
                <Grid item style={{ padding: '7em 0 0 0' }}>
                    <img src={noResultsIcon} alt="No user results"/>
                </Grid>
                <Grid item>
                    <Typography variant="body2" align="center">
                        {t('errors.noResults.description')}
                    </Typography>
                    <Typography align="center" className={classes.subtitle}>
                        {index === ActiveTab.Deactivated
                            ? t('errors.noResults.deactivatedNextSteps')
                            : t('errors.noResults.nextSteps')
                        }
                    </Typography>
                </Grid>
            </Grid>
        </TabPanel>
    );
};

export default NoResults;
