import React, { FunctionComponent } from 'react';

import LegacyView from '../../../../components/legacyView/LegacyView';

const RidesLegacy: FunctionComponent = () => (
    <LegacyView
        path={'/rides'}
        title="Rides"
    />
);

export default RidesLegacy;
