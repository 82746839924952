import { SecondaryButton } from '@get-e/react-components';
import LocalPhoneOutlinedIcon from '@mui/icons-material/LocalPhoneOutlined';
import PersonOutlineOutlinedIcon from '@mui/icons-material/PersonOutlineOutlined';
import { Typography, Box } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { GridRowParams } from '@mui/x-data-grid-pro';
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { COLORS } from '../../../constants/colors';

const useStyles = makeStyles({
    mobileContent: {
        color: COLORS.BLACK,
        marginLeft: '.5rem',
    },
    editButton: {
        width: '150px',
        marginRight: '1rem',
        marginLeft: '0.2rem',
        color: COLORS.BLUE,
        border: `2px solid ${COLORS.BLUE}`,
    },
    deleteButton: {
        width: '150px',
        color: COLORS.RED,
        border: `2px solid ${COLORS.RED}`,
    },
    container: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-start',
        padding: '1rem .5rem 1rem .5rem',
        borderBottom: '1px solid rgba(224, 224, 224, 1)',
    },
});

export const useDriversColumnsMobile = (handleDelete: () => void, handleEdit: () => void) => {
    const classes = useStyles();
    const { t } = useTranslation();

    return useMemo(() => ({ row }: GridRowParams) => (
        <Box className={classes.container}>
            <Box display="flex" alignItems="center" margin="0 0.5rem 0.75rem 0" color={COLORS.SLATE_GREY}>
                <PersonOutlineOutlinedIcon />
                <Typography className={classes.mobileContent}>{row.name}</Typography>
            </Box>
            <Box display="flex" alignItems="center" margin="0 0.5rem 0.75rem 0" color={COLORS.SLATE_GREY}>
                <LocalPhoneOutlinedIcon />
                <Typography className={classes.mobileContent}>{row.phoneNumber}</Typography>
            </Box>
            <Typography fontWeight={700}>
                {t('pages.drivers.rideAssigned')}: {row.rideAssigned}
            </Typography>
            <Box display="flex" justifyContent="flex-start" margin="2rem 0 0 0" color={COLORS.SLATE_GREY}>
                <SecondaryButton className={classes.editButton} onClick={handleEdit}>
                    {t('buttonName.edit')}
                </SecondaryButton>
                <SecondaryButton className={classes.deleteButton} variation="danger" onClick={handleDelete}>
                    {t('buttonName.delete')}
                </SecondaryButton>
            </Box>
        </Box>
    ), [classes.container, classes.deleteButton, classes.editButton, classes.mobileContent, handleDelete, handleEdit, t]);
};
