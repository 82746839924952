import gql from 'graphql-tag';

import config from '../../config';

export interface DocumentItem {
    label: string;
    url: string;
}

export interface DocumentOutput {
    me: {
        company: {
            documentsPage: {
                items: DocumentItem[];
                totalCount: number;
                previousCursor: string | null;
                nextCursor: string | null; };
        };
    };
}

export const GET_CURRENT_USER_DOCUMENTS = gql`
   query getCurrentUserDocuments($after: Cursor, $before: Cursor){
        me {
            id
            company {
                __typename
                documentsPage(
                    first: ${config.paginationLimit}, 
                    after: $after, 
                    before: $before
                ) {
                    items {
                        label
                        url
                    }
                    totalCount
                    previousCursor
                    nextCursor
                }
            }
        }
   }
`;
