import { Container, Grid, Typography } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { COLORS } from '../../../../constants/colors';
import SummaryBasicCard from '../SummaryBasicCard';
import SummaryListCard from '../SummaryListCard';

const useStyles = makeStyles({
    tabContentHeaderWrapper: {
        marginTop: '2rem',
        marginBottom: '1rem',
        padding: 0,
    },
    tabContentHeader: {
        fontWeight: 700,
        color: COLORS.BLUE_DARK,
    },
});

const SummaryTab = () => {
    const { t } = useTranslation();
    const classes = useStyles();

    return (
        <>
            <Container className={classes.tabContentHeaderWrapper}>
                <Typography className={classes.tabContentHeader}>{t('pages.reports.ridePerformances')}</Typography>
                <Grid container columnSpacing={3}>
                    <Grid item xs={12} md={4} lg={4}>
                        <SummaryBasicCard
                            title={t('pages.reports.totalRides')}
                            percentage1="0"
                            percentage2="+0"
                            text1="0% in previous period"
                            text2="16 Mar 2033- 23 Mar 2033"
                            titleTooltip={t('pages.reports.totalRidesTooltip')}
                        />
                    </Grid>
                    <Grid item xs={12} md={4} lg={4}>
                        <SummaryBasicCard
                            title={t('pages.reports.rateOfSituations')}
                            percentage1="0.23"
                            text1="0.43% in previous period"
                            text2="16 Mar 2033- 23 Mar 2033"
                            titleTooltip={t('pages.reports.rateOfSituationsTooltip')}
                        />
                    </Grid>
                    <Grid item xs={12} md={4} lg={4}>
                        <SummaryListCard
                            title={t('pages.reports.situationsByType')}
                            driverLate={2}
                            driverNoShow={0}
                            driverBehaviour={3}
                            wrongVehicle={1}
                        />
                    </Grid>
                </Grid>
            </Container>
            <Container className={classes.tabContentHeaderWrapper}>
                <Typography className={classes.tabContentHeader}>
                    {t('pages.reports.communicationsWithCustomers')}
                </Typography>
                <Grid container columnSpacing={3}>
                    <Grid item xs={12} md={4} lg={4}>
                        <SummaryBasicCard
                            title={t('pages.reports.driversAssigned')}
                            percentage1="100"
                            percentage2="+0"
                            text1="0% in previous period"
                            text2="16 Mar 2033- 23 Mar 2033"
                            titleTooltip={t('pages.reports.driversAssignedTooltip')}
                        />
                    </Grid>
                    <Grid item xs={12} md={4} lg={4}>
                        <SummaryBasicCard
                            title={t('pages.reports.ridesTrackedAccurately')}
                            percentage1="74"
                            percentage2="-2"
                            percentage2Color={COLORS.RED}
                            text1="74% in previous period"
                            text2="16 Mar 2033- 23 Mar 2033"
                            titleTooltip={t('pages.reports.ridesTrackedAccuratelyTooltip')}
                        />
                    </Grid>
                    <Grid item xs={12} md={4} lg={4}>
                        <SummaryBasicCard
                            title={t('pages.reports.ridesTrackedAccurately')}
                            rating={4.8}
                            numberOfReviews={709}
                            text1="74% in previous period"
                            text2="16 Mar 2033- 23 Mar 2033"
                        />
                    </Grid>
                </Grid>
            </Container>
        </>
    );
};

export default SummaryTab;
