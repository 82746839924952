import { COLORS } from '../constants/colors';

export enum FlightStatus {
    DIVERTED = 'Diverted',
    NO_INFO = 'No info',
    CANCELLED = 'Cancelled',
    DELAYED = 'Delayed',
    LANDED_ON_TIME = 'Landed',
    AT_GATE_ON_TIME = 'At gate',
    DEPARTED_ON_TIME = 'Departed',
    DEPARTED_LATE = 'Departed late',
    AT_GATE_LATE = 'At gate late',
    LANDED_LATE = 'Landed late',
    COMPLETED_ON_TIME = 'Completed',
    COMPLETED_LATE = 'Completed late',
    BOOKED = 'Booked',
}

export const FlightStatusColor = {
    NO_INFO: COLORS.LIGHT_RED,
    CANCELLED: COLORS.LIGHT_RED,
    DIVERTED: COLORS.LIGHT_RED,
    DELAYED: COLORS.MEDIUM_ORANGE,
    LANDED_ON_TIME: COLORS.LIGHT_GREEN,
    AT_GATE_ON_TIME: COLORS.LIGHT_GREEN,
    DEPARTED_ON_TIME: COLORS.LIGHT_GREEN,
    DEPARTED_LATE: COLORS.MEDIUM_ORANGE,
    AT_GATE_LATE: COLORS.MEDIUM_ORANGE,
    LANDED_LATE: COLORS.MEDIUM_ORANGE,
    COMPLETED_ON_TIME: COLORS.LIGHT_BLUE,
    COMPLETED_LATE: COLORS.LIGHT_ORANGE,
    BOOKED: COLORS.BLACK_12,
} as {
    [key in keyof typeof FlightStatus]: keyof typeof COLORS;
};

export const FlightStatusTextColor = {
    NO_INFO: COLORS.RED_WARNING,
    CANCELLED: COLORS.RED_WARNING,
    DIVERTED: COLORS.RED_WARNING,
    DELAYED: COLORS.RED_WARNING,
    LANDED_ON_TIME: COLORS.GREEN_TEXT,
    AT_GATE_ON_TIME: COLORS.GREEN_TEXT,
    DEPARTED_ON_TIME: COLORS.GREEN_TEXT,
    DEPARTED_LATE: COLORS.RED_WARNING,
    AT_GATE_LATE: COLORS.RED_WARNING,
    LANDED_LATE: COLORS.ORANGE_TEXT,
    COMPLETED_ON_TIME: COLORS.BLACK,
    COMPLETED_LATE: COLORS.ORANGE_TEXT,
    BOOKED: COLORS.BLACK,
} as {
    [key in keyof typeof FlightStatus]: keyof typeof COLORS;
};

export interface FlightStatusTextOption {
    strike: boolean;
    isDelayed: boolean;
}

export const FlightStatusTextType = {
    NO_INFO: {
        strike: false,
        isDelayed: false,
    },
    CANCELLED: {
        strike: true,
        isDelayed: false,
    },
    DIVERTED: {
        strike: true,
        isDelayed: false,
    },
    DELAYED: {
        strike: true,
        isDelayed: true,
    },
    DEPARTED_ON_TIME: {
        strike: false,
        isDelayed: false,
    },
    DEPARTED_LATE: {
        strike: true,
        isDelayed: true,
    },
    LANDED_ON_TIME: {
        strike: false,
        isDelayed: false,
    },
    LANDED_LATE: {
        strike: true,
        isDelayed: true,
    },
    AT_GATE_ON_TIME: {
        strike: false,
        isDelayed: false,
    },
    AT_GATE_LATE: {
        strike: true,
        isDelayed: true,
    },
    COMPLETED_ON_TIME: {
        strike: false,
        isDelayed: false,
    },
    COMPLETED_LATE: {
        strike: true,
        isDelayed: true,
    },
    BOOKED: {
        strike: false,
        isDelayed: false,
    },
} as {
    [key in keyof typeof FlightStatus]: FlightStatusTextOption;
};

export const FlightStatusTooltip = {
    NO_INFO: 'The information displayed might be incorrect or out-to-date',
    CANCELLED: 'The flight has been cancelled',
    DIVERTED: 'The flight has been diverted',
    DELAYED: 'The flight is delayed on departure',
    DEPARTED_ON_TIME: 'The flight departed',
    DEPARTED_LATE: 'The flight accumulated delay',
    LANDED_LATE: 'The flight landed with delay',
    LANDED_ON_TIME: 'The flight landed on time',
    AT_GATE_ON_TIME: 'The flight approached the gate on time',
    AT_GATE_LATE: 'The flight approached the gate with delay',
    COMPLETED_ON_TIME: 'Flight completed',
    COMPLETED_LATE: 'Flight completed with delay.',
    BOOKED: 'Flight scheduled',
} as {
    [key in keyof typeof FlightStatus]: string;
};

export const getFlightStatusTextColor = (status: keyof typeof FlightStatus) => FlightStatusTextColor[status];

export const getFlightStatusColor = (status: keyof typeof FlightStatus) => FlightStatusColor[status];

export const getFlightStatusKeyName = (value: string): keyof typeof FlightStatus => {
    return Object.entries(FlightStatus).find(([_, val]) => val === value)?.[0] as keyof typeof FlightStatus;
};

export const getFlightStatusTextType = (status: keyof typeof FlightStatus) => FlightStatusTextType[status];

export const getFlightStatusTooltip = (status: keyof typeof FlightStatus) => FlightStatusTooltip[status];
