import { Link, useMediaQuery } from '@mui/material';
import { GridRowParams, GRID_DETAIL_PANEL_TOGGLE_COL_DEF, GridRowId } from '@mui/x-data-grid-pro';
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { DetailPanelToggle } from '../../../../components/detailPanelToggle/DetailPanelToggle';
import useDataGridStyles from '../../../../styles/DataGrid';
import theme from '../../../../styles/theme';

const COLUMN_WIDTH = 230;
const COLUMN_WIDTH_MOBILE = 90;

export const useSituationsColumns = () => {
    const classes = useDataGridStyles();
    const { t } = useTranslation();
    const isMobile = useMediaQuery(theme.breakpoints.down('md'));

    return useMemo(() => {
        const columns = [
            {
                field: 'pickUpTime',
                headerName: t('pages.reports.pickUpTime'),
                minWidth: isMobile ? 200 : COLUMN_WIDTH,
                flex: 1,
            },
            {
                field: 'rideNumber',
                headerName: t('pages.reports.rideNumber'),
                minWidth: COLUMN_WIDTH,
                hide: isMobile,
                flex: 1,
                renderCell: (params: GridRowParams) => (
                    <Link onClick={() => {}} sx={{ textDecoration: 'underline' }}>
                        {params.row.rideNumber}
                    </Link>
                ),
            },
            {
                field: 'driver',
                headerName: t('pages.reports.driver'),
                minWidth: COLUMN_WIDTH,
                flex: 1,
                hide: isMobile,
            },
            {
                field: 'sitationType',
                headerName: t('pages.reports.situationType'),
                minWidth: isMobile ? COLUMN_WIDTH_MOBILE : COLUMN_WIDTH,
                flex: 1,
                headerClassName: classes.hideSeparator,
            },
            {
                ...GRID_DETAIL_PANEL_TOGGLE_COL_DEF,
                headerClassName: classes.hideSeparator,
                hide: !isMobile,
                renderCell: (params: { id: GridRowId; value: boolean }) =>
                    <DetailPanelToggle id={params.id} value={params.value} />,
            },
        ];

        return columns;
    }, [t, isMobile, classes.hideSeparator]);
};

