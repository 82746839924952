import ExploreOffOutlinedIcon from '@mui/icons-material/ExploreOffOutlined';
import PlaceIcon from '@mui/icons-material/Place';
import { Box, Typography } from '@mui/material';
import GoogleMapReact from 'google-map-react';
import { Moment } from 'moment';
import React, { FunctionComponent, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { COLORS } from '../../constants/colors';
import fetchDirections from '../../helpers/googleMaps/fetchDirections';
import { bootstrapURLKeys } from '../../helpers/googleMaps/googleApi';
import useEffectAsync from '../../hooks/useEffectAsync';
import { Coordinates } from '../../pages/rides/customer/api/types';

export type LatLng = google.maps.LatLng;
export type Place = google.maps.Place;
export type Map = google.maps.Map;

export interface Point {
    longitude: number;
    latitude: number;
}

export type DriverPosition = null | {
    timestamp: Moment;
    coordinates: {
        latitude: number;
        longitude: number;
    };
    coordinatesAccuracyMeters: number | null;
    bearingDegrees: number | null;
};

interface MarkerProps {
    lat: number;
    lng: number;
}

const Marker = ({ lat, lng }: MarkerProps) => (
    <PlaceIcon
        color="error"
        style={{
            position: 'absolute',
            transform: 'translate(-50%, -50%)',
        }}
    />
);

const DEFAULT_MAP_ZOOM = 11;

let cachedDirectionsRenderer: google.maps.DirectionsRenderer | null = null;

const getDirectionsRenderer = () => {
    if (cachedDirectionsRenderer === null) {
        cachedDirectionsRenderer = new google.maps.DirectionsRenderer();
    }

    return cachedDirectionsRenderer;
};

interface DirectonPlacesProps {
    pickupCoordinates?: Coordinates;
    dropoffCoordinates?: Coordinates;
    pickupPlaceId?: string;
    dropoffPlaceId?: string;
}

const getDirectionPlaces = (directionPlacesProps: DirectonPlacesProps): Place[] => {
    return [
        {
            location: directionPlacesProps.pickupCoordinates
                ? new google.maps.LatLng(directionPlacesProps.pickupCoordinates.lat, directionPlacesProps.pickupCoordinates.lon)
                : undefined,
            placeId: !directionPlacesProps.pickupCoordinates ? directionPlacesProps.pickupPlaceId : undefined,
        },
        {
            location: directionPlacesProps.dropoffCoordinates
                ? new google.maps.LatLng(directionPlacesProps.dropoffCoordinates.lat, directionPlacesProps.dropoffCoordinates.lon)
                : undefined,
            placeId: !directionPlacesProps.dropoffCoordinates ? directionPlacesProps.dropoffPlaceId : undefined,
        },
    ];
};

const GoogleMap: FunctionComponent<{
    points?: Array<Coordinates | undefined>;
    placeIds?: string[];
    driverPosition?: DriverPosition;
    onLoaded?: () => void;
    zoom?: number;
    showMarker?: boolean;
}> = ({ points, placeIds, zoom = DEFAULT_MAP_ZOOM, showMarker }) => {
    const { t } = useTranslation();
    const googleMap = useRef<Map | null>(null);
    const [isError, setIsError] = useState(false);

    const pickupCoordinates = points?.[0];
    const dropoffCoordinates = points?.[1];
    const pickupPlaceId = placeIds?.[0] ?? '';
    const dropoffPlaceId = placeIds?.[1] ?? '';

    const handleRenderRoute = async () => {
        const directionsRenderer = getDirectionsRenderer();

        const directionPlaces = getDirectionPlaces({
            pickupCoordinates,
            dropoffCoordinates,
            pickupPlaceId,
            dropoffPlaceId,
        });

        try {
            const directions = await fetchDirections(directionPlaces);

            directionsRenderer.setDirections(directions);
        } catch {
            setIsError(true);
        }
    };

    const handleApiLoaded = async (map: Map) => {
        googleMap.current = map;
        const directionsRenderer = getDirectionsRenderer();

        directionsRenderer.setMap(map);

        await handleRenderRoute();
    };

    useEffectAsync(async () => {
        if (googleMap.current) {
            setIsError(false);
            await handleRenderRoute();
        }
    }, [pickupCoordinates, dropoffCoordinates, pickupPlaceId, dropoffPlaceId]);

    return (
        <>
            {isError
                ? (
                    <Box
                        display="flex"
                        flexDirection="column"
                        alignItems="center"
                        justifyContent="center"
                        height="100%"
                    >
                        <ExploreOffOutlinedIcon
                            style={{
                                color: COLORS.SLATE_GREY,
                                fontSize: '3rem',
                            }}
                        />
                        <Typography marginTop="0.5rem">{ t('noRouteAvailable') }</Typography>
                    </Box>
                )
                : (
                    <GoogleMapReact
                        bootstrapURLKeys={bootstrapURLKeys}
                        center={{
                            lat: pickupCoordinates?.lat ?? 0,
                            lng: pickupCoordinates?.lon ?? 0,
                        }}
                        zoom={zoom}
                        yesIWantToUseGoogleMapApiInternals
                        onGoogleApiLoaded={({ map }) => handleApiLoaded(map)}
                    >
                        {showMarker && pickupCoordinates && <Marker lat={pickupCoordinates.lat} lng={pickupCoordinates.lon} />}
                    </GoogleMapReact>
                )
            }
        </>
    );
};

export default GoogleMap;
