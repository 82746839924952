import { Box, Tooltip, Typography } from '@mui/material';
import React from 'react';

import {
    FlightStatus,
    getFlightStatusColor,
    getFlightStatusTextColor,
    getFlightStatusTooltip,
} from '../../../helpers/flightStatusUtil';

interface FlightStatusBadgeProps {
    status: keyof typeof FlightStatus;
    width?: string;
    height?: string;
    showTooltip?: boolean;
}

const FlightStatusBadge = ({ status, width, height, showTooltip }: FlightStatusBadgeProps) => (
    <Tooltip title={showTooltip ? getFlightStatusTooltip(status) : ''} placement="top" arrow>
        <Box
            sx={{
                backgroundColor: getFlightStatusColor(status),
                width: width ?? '75px',
                height: height ?? '22px',
                borderRadius: '4px',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
            }}
        >
            <Typography
                sx={{
                    textAlign: 'center',
                    color: getFlightStatusTextColor(status),
                    fontSize: '0.75rem',
                    lineHeight: '0.875rem',
                }}
            >
                {FlightStatus[status]}
            </Typography>
        </Box>
    </Tooltip>
);

export default FlightStatusBadge;
