import * as Sentry from '@sentry/browser';

export type ErrorNumber = string;

export function setUserContext(context: UserContext): void {
    Sentry.setUser({
        id: context.userId.toString(),
        email: context.emailAddress,
    });
}

export function clearUserContext(): void {
    Sentry.setUser(null);
}

export function report(error: Error): ErrorNumber {
    return Sentry.captureException(error);
}

export interface UserContext {
    userId: number;
    emailAddress: string;
}
