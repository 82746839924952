import CarCrashOutlinedIcon from '@mui/icons-material/CarCrashOutlined';
import { Card, CardContent, Grid, Box, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { COLORS } from '../../../constants/colors';
import { BUTTON_WIDTH } from '../../../constants/layout';

const useStyles = makeStyles({
    image: {
        maxHeight: '100%',
        maxWidth: '100%',
    },
    imageContainer: {
        color: COLORS.SLATE_GREY,
        marginBottom: '1rem',
        lineHeight: 0,
        fontSize: '2.5rem',
    },
    cardContainer: {
        textAlign: 'center',
        marginBottom: '1rem',
        height: 'auto',
        margin: '0',
    },
    formField: {
        marginBottom: '1.75rem',
        width: '100%',
    },
    sendButton: {
        width: BUTTON_WIDTH,
        display: 'flex',
    },
});

const NoVehicleAvailable = () => {
    const classes = useStyles();
    const { t } = useTranslation();

    return (
        <Card variant="outlined" className={classes.cardContainer}>
            <CardContent>
                <Grid container flexDirection="column">
                    <Grid item xs>
                        <Box className={classes.imageContainer}>
                            <CarCrashOutlinedIcon fontSize="inherit" />
                        </Box>
                    </Grid>
                    <Grid item xs>
                        <Typography
                            sx={{
                                color: COLORS.SLATE_GREY,
                                paddingBottom: '.6rem',
                            }}
                        >
                            {t('pages.singleRide.noCarAvailable')}
                        </Typography>
                        <Typography sx={{ paddingBottom: '2rem' }}>{t('pages.singleRide.noCarAvailableDescription')}</Typography>
                    </Grid>
                </Grid>
            </CardContent>
        </Card>
    );
};

export default NoVehicleAvailable;
