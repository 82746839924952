import moment from 'moment';
import { TFunction } from 'react-i18next';

import { UserInvite } from '../../pages/users/customer/Users.graphql';
import getDateFormatKey from '../getDateFormatKey';

export const formatUserCreatedAt = (t: TFunction<string>, createdAt: string): string => {
    const inviteCreatedAt = moment(createdAt);

    return inviteCreatedAt.format(t(getDateFormatKey(inviteCreatedAt)));
};

export const mapInvitedUsers = (
    t: TFunction<string>,
    rows: UserInvite[]
): Array<Partial<UserInvite>> =>
    rows.map(row => ({
        id: row.id,
        fullName: `${row.fullName}` ?? '-',
        email: row.email,
        createdAt: formatUserCreatedAt(t, row.createdAt),
    }));
