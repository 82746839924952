import { PrimaryButton, TabPanel } from '@get-e/react-components';
import { Grid, Typography } from '@mui/material';
import React, { FunctionComponent } from 'react';
import { useTranslation } from 'react-i18next';

import { carIcon } from '../../../../public/assets/icons';
import typographyStyles from '../../../../styles/Typography';
import { ActiveTab } from '../Users';

const UsersTabError: FunctionComponent<{
    value: ActiveTab;
    index: number;
    onRetryClick: () => void;
}> = ({ value, index, onRetryClick }) => {
    const { t } = useTranslation();
    const classes = typographyStyles();

    return (
        <TabPanel
            selectedValue={value}
            value={index}
            data-testid={`${ActiveTab[value]}-users-error`}
        >
            <Grid
                container
                spacing={0}
                direction="column"
                alignItems="center"
                justifyContent="center"
                data-testid="no-results-user-page"
            >
                <Grid item style={{ padding: '7em 0 0' }}>
                    <img src={carIcon} alt={t('errors.unexpectedError.description')}/>
                </Grid>
                <Grid item style={{ margin: '1em 0' }}>
                    <Typography variant="body2" align="center">
                        {t('errors.unexpectedError.description')}
                    </Typography>
                    <Typography align="center" className={classes.subtitle}>
                        {t('errors.unexpectedError.nextSteps')}
                    </Typography>
                </Grid>
                <Grid item>
                    <PrimaryButton onClick={onRetryClick}>{t('errors.retry')}</PrimaryButton>
                </Grid>
            </Grid>
        </TabPanel>
    );
};

export default UsersTabError;
