import { useMemo } from 'react';
import { useQuery } from 'react-query';

import { USE_RIDES } from '../../../../constants/queryKeys';
import { listRides } from '../../../../services/rides';
import { RidesVariables } from './types';

export const useRides = (variables: Partial<RidesVariables>) => {
    const { query, startDate, endDate, statusFilters } = variables;

    const {
        data,
        refetch,
        isLoading,
        isRefetching,
    } = useQuery(
        [USE_RIDES + query + startDate + endDate + statusFilters],
        () =>
            listRides(variables),
        {
            keepPreviousData: true,
            refetchOnWindowFocus: false,
            retry: 1,
            cacheTime: 500,
        },
    );

    return useMemo(() => ({
        data,
        refetch,
        isLoading,
        isRefetching,
    }
    ), [data, isLoading, isRefetching, refetch]);
};
