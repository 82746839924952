import {
    ContactMail,
    FlightLand,
    Nightlight,
    PersonAdd,
    AirplanemodeInactiveOutlined,
} from '@mui/icons-material';
import { Box, Tooltip } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import React from 'react';
import { COLORS } from '../../../constants/colors';
import {
    getRideStatusOptionKey,
    RideStatusOption,
} from '../../../helpers/rideStatusUtil';

const useStyles = makeStyles({
    optionIcon: {
        marginRight: '0.5rem',
        fontSize: '1rem !important',
        color: COLORS.SLATE_GREY,
    },
});

interface CellTextProps {
    statusOptions?: Array<keyof typeof RideStatusOption>;
}

const StatusOptionBadge = ({ statusOptions }: CellTextProps) => {
    const classes = useStyles();

    const isRideStatusOptionVisible = (option: RideStatusOption) =>
        Boolean(statusOptions?.includes(getRideStatusOptionKey(option)));

    return (
        <Box
            sx={{
                width: 'auto',
                height: '32px',
                display: 'flex',
                alignItems: 'center',
            }}
        >
            {isRideStatusOptionVisible(RideStatusOption.DRIVER_KNOWN) && (
                <Tooltip title="Driver known" placement="bottom" arrow>
                    <ContactMail className={classes.optionIcon} />
                </Tooltip>
            )}
            {isRideStatusOptionVisible(RideStatusOption.NO_FLIGHT_INFORMATION) && (
                <Tooltip title="No flight information" placement="bottom" arrow>
                    <AirplanemodeInactiveOutlined className={classes.optionIcon} />
                </Tooltip>
            )}
            {isRideStatusOptionVisible(RideStatusOption.OVERNIGHT_FLIGHT) && (
                <Tooltip title="Overnight flight" placement="bottom" arrow>
                    <Nightlight className={classes.optionIcon} />
                </Tooltip>
            )}
            {isRideStatusOptionVisible(RideStatusOption.CONTACT_GREETER_INFO_KNOWN) && (
                <Tooltip title="Contact / Greeter info known" placement="bottom" arrow>
                    <PersonAdd className={classes.optionIcon} />
                </Tooltip>
            )}
            {isRideStatusOptionVisible(RideStatusOption.FLIGHT_TIMES_MISMATCH) && (
                <Tooltip title="Flight times mismatch" placement="bottom" arrow>
                    <FlightLand className={classes.optionIcon} sx={{ color: '#e58f03' }} />
                </Tooltip>
            )}
        </Box>
    );
};

export default StatusOptionBadge;
