import gql from 'graphql-tag';

export interface RevokeUserInviteModalInput {
    inviteId: string;
}

export const REVOKE_USER_INVITE = gql`
    mutation REVOKE_USER_INVITE(
        $inviteId: ID!, 
    ){
        revokeUserInvite(
            input: {
                inviteId: $inviteId,
            }
        ) {
            id
        }
    }
`;
