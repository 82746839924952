import { Theme } from '@mui/material/styles';
import { makeStyles, createStyles } from '@mui/styles';

const useButtonStyles = makeStyles((theme: Theme) => createStyles(
    {
        root: {
            width: '100%',
            '& > *': {
                marginRight: theme.spacing(1),
                marginTop: theme.spacing(2),
            },
        },
        rootWithoutError: {
            '& > *': {
                marginRight: theme.spacing(1),
                marginTop: theme.spacing(4),
            },
        },
        error: {
            backgroundColor: theme.palette.error.main,
            color: theme.palette.common.white,
            '&:hover': { backgroundColor: theme.palette.error.dark },
            '&:disabled': { backgroundColor: theme.palette.grey[300] },
        },
        success: {
            backgroundColor: theme.palette.success.main,
            color: theme.palette.common.white,
            '&:hover': { backgroundColor: theme.palette.success.dark },
            '&:disabled': { backgroundColor: theme.palette.grey[300] },
        },
        textButton: {
            color: theme.palette.primary.light,
            fontWeight: 'normal',
            margin: 0,
            padding: 0,
            textAlign: 'left',
            '&:hover': {
                backgroundColor: 'transparent',
                color: theme.palette.primary.main,
            },
        },
        defaultTextButton: {
            color: theme.palette.common.black,
            fontWeight: 'normal',
            margin: 0,
            padding: 0,
            textAlign: 'left',
            '&:hover': {
                backgroundColor: 'transparent',
                color: '#000',
            },
        },
        inlineButtonMargin: { marginRight: theme.spacing(1) },
        iconButton: {
            padding: theme.spacing(0, 0.25),
            margin: 0,
            minWidth: 0,
            backgroundColor: 'transparent',
            color: theme.palette.grey[300],
            '&:hover': {
                backgroundColor: 'transparent',
                color: theme.palette.grey[200],
            },
            '& .MuiSvgIcon-root': { fontSize: '1em' },
        },
    }
),);

export default useButtonStyles;
