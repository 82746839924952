import { Modal, PrimaryButton, SecondaryButton, TextField } from '@get-e/react-components';
import PhoneOutlinedIcon from '@mui/icons-material/PhoneOutlined';
import { Box, InputAdornment, Typography } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import clsx from 'clsx';
import { MuiTelInput } from 'mui-tel-input';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { v4 as uuidv4 } from 'uuid';

import { COLORS } from '../../../../constants/colors';
import { BUTTON_WIDTH } from '../../../../constants/layout';
import { getCurrentUserCountryCode } from '../../../../helpers/getCurrentUserCountryCode';
import { InputError } from '../../../../helpers/inputValidation/InputError';
import isFilledString from '../../../../helpers/inputValidation/validators/isFilledString';
import { DriverInformation } from '../hooks/useSupplierDriverInformation';

const useStyles = makeStyles({
    heading: {
        marginBottom: '1.875rem',
        color: COLORS.BLUE,
    },
    formField: {
        marginBottom: '1.25rem',
        width: '100%',
        '& .MuiFormHelperText-root.Mui-error': { padding: '0 .75rem' },
    },
    buttonFixedWidth: {
        width: `${BUTTON_WIDTH} !important`,
        height: '44px',
    },
    mobileContent: {
        color: COLORS.BLACK,
        marginLeft: '0.3rem !important',
    },
    cancelButton: {
        width: '150px',
        marginLeft: '1rem',
        color: COLORS.BLUE,
        border: `2px solid ${COLORS.BLUE}`,
        outline: '2px',
        '&:hover': { border: `2px solid ${COLORS.BLUE}` },
    },
});

interface AddDriverFields {
    name: string;
    mobile: string;
}

interface AddDriverFormErrors {
    name: InputError | null;
    mobile: InputError | null;
}

interface AddDriverModalProps {
    isModalOpen: boolean;
    onClose: () => void;
    onAddDriver: (driverInformation: DriverInformation) => void;
    isSaving?: boolean;
}

const defaultCountryCode = getCurrentUserCountryCode();

const DEFAULT_RATING = '3';

const AddDriverModal = ({ isModalOpen, onClose, onAddDriver, isSaving }: AddDriverModalProps) => {
    const classes = useStyles();
    const { t } = useTranslation();

    const [values, setValues] = useState<AddDriverFields>({
        name: '',
        mobile: '',
    });

    const [formErrors, setFormErrors] = useState<AddDriverFormErrors>({
        name: null,
        mobile: null,
    });

    const handleChange = <T extends keyof AddDriverFields>(key: T, newValue: AddDriverFields[T] & string): void => {
        setValues({
            ...values,
            [key]: newValue,
        });
    };

    const validateFields = (): boolean => {
        const validatedName = isFilledString(values.name, InputError.Empty);
        const validatedMobile = isFilledString(values.mobile, InputError.Empty);

        const fieldErros: AddDriverFormErrors = {
            name: validatedName.isValid ? null : validatedName.error,
            mobile: validatedMobile.isValid ? null : validatedMobile.error,
        };

        const isValid = Object.values(fieldErros).every(error => error === null);

        !isValid && setFormErrors(fieldErros);

        return isValid;
    };

    const handleSubmit = () => {
        if (!validateFields()) {
            return;
        }

        const driverInformation = {
            id: uuidv4(),
            name: values.name,
            mobile: values.mobile,
            rating: DEFAULT_RATING,
            reviewsNumber: '0',
        } as DriverInformation;

        onAddDriver(driverInformation);
    };

    return (
        <Modal open={isModalOpen} onClose={onClose} maxWidth="md">
            <Typography variant="h2" component="h2" className={classes.heading}>
                {t('pages.rides.addDriver')}
            </Typography>
            <Box>
                <TextField
                    className={classes.formField}
                    error={formErrors.name !== null}
                    helperText={formErrors.name ? t(formErrors.name) : null}
                    label={t('form.fields.nameAndSurname')}
                    name="name"
                    onChange={event => handleChange('name', event.target.value)}
                    required
                    autoComplete="off"
                    value={values.name}
                />
                <MuiTelInput
                    style={{ marginBottom: formErrors?.mobile !== null ? '1.25rem' : '2.5rem' }}
                    value={values.mobile}
                    defaultCountry={defaultCountryCode}
                    label={t('form.fields.mobile')}
                    className={classes.formField}
                    name="mobile"
                    onChange={value => handleChange('mobile', value)}
                    error={formErrors?.mobile !== null}
                    helperText={formErrors?.mobile && t(formErrors.mobile)}
                    variant="filled"
                    fullWidth
                    InputProps={{
                        endAdornment: (
                            <InputAdornment position="end">
                                <PhoneOutlinedIcon />
                            </InputAdornment>
                        ),
                    }}
                />
            </Box>
            <Box display="flex" justifyContent="flex-start" color={COLORS.SLATE_GREY}>
                <PrimaryButton className={classes.buttonFixedWidth} onClick={handleSubmit} loading={isSaving} disabled={isSaving}>
                    {t('buttonName.add')}
                </PrimaryButton>
                <SecondaryButton onClick={onClose} className={clsx(classes.cancelButton)}>
                    {t('buttonName.back')}
                </SecondaryButton>
            </Box>
        </Modal>
    );
};

export default AddDriverModal;
