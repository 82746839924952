import moment from 'moment';
import { TFunction } from 'react-i18next';

import { User } from '../../pages/users/customer/Users.graphql';
import getDateFormatKey from '../getDateFormatKey';

export const formatUserLastSeen = (t: TFunction<string>, lastSeen: string): string => {
    const userLastSeen = moment(lastSeen);

    const userLastSeenDate = lastSeen
        ? userLastSeen.format(t(getDateFormatKey(userLastSeen)))
        : t('pages.users.lastSeenNever');

    return userLastSeenDate;
};

export const mapUsers = (t: TFunction<string>, rows: User[]): Array<Partial<User>> =>
    rows.map(row => ({
        id: row.id,
        name: `${row?.firstName} ${row?.lastName}` ?? '-',
        email: row.email,
        permissions: row.permissions,
        lastSeen: row.lastSeen && formatUserLastSeen(t, row.lastSeen),
        firstName: row.firstName,
        lastName: row.lastName,
        fullName: row.fullName,
        isDeactivated: row.isDeactivated,
    }));
