import { RideTrackingStatus } from '../../../../helpers/rideStatusUtil';

export interface Location {
    id: string;
    name: string;
    address: string | null;
    type: string;
    coordinates: Coordinates;
}

export interface Coordinates {
    lat: number;
    lon: number;
}

export interface Passenger {
    id: number;
    firstName: string;
    lastName: string;
    email: string;
    phone: string;
    isLead: boolean;
}

export interface RideResponse {
    unid: string;
    prettifiedUnid: string;
    status: string;
    trackingStatus: keyof typeof RideTrackingStatus;
    pickUp: Departure;
    dropOff: Arrival;
    passengers: Passenger[];
    customerReference: string;
    flightDetails: FlightDetails | null;
    driver: Driver | null;
    contactPerson: ContactPerson | null;
    paymentMethod?: string;
}

export interface Arrival {
    arriveAt: string;
    arriveAtLocal: string;
    location: Location;
}

export interface Departure {
    departAt: string;
    departAtLocal: string;
    location: Location;
}

interface ContactPerson {
    firstName: string;
    lastName: string;
    phone: string;
    email: string;
}

interface Driver {
    name: string;
    phoneNumber: string;
}

export enum FlightType {
    ARRIVAL = 'Arrival',
    DEPARTURE = 'Departure'
}

export interface FlightDetails {
    number: string;
    type: FlightType;
    departure: {
        datetimeLocal: string;
        datetimeZulu: string;
        iata: string;
        name?: string;
    };
    arrival: {
        datetimeLocal: string;
        datetimeZulu: string;
        iata: string;
        name?: string;
    };
}
export interface RidesVariables {
    query: string;
    statusFilters: string[];
    startDate: string;
    endDate: string;
}

export interface EstimateDetails {
    distanceInMeters: number;
    durationInSeconds: number;
}

export interface AvailabilitiesResponse {
    estimateDetails: EstimateDetails;
    availabilities: VehicleResponse[];
}

export interface VehicleResponse {
    uuid: string;
    vehicleName: string;
    vehicleClass: string;
    vehicleType: string;
    vehicleImage: string;
    vehicleAttributes: string[];
    vehicleSeatsNumber: number;
    vehicleBagsCapacity: number;
    price: number;
    currency: string;
}

interface Flight {
    id: number;
    dep_datetime_local: string;
    arr_datetime_local: string;
    dep_datetime_zulu: string;
    arr_datetime_zulu: string;
    departure: {
        id: number;
        iata: string;
        name: string;
    };
    arrival: {
        id: number;
        iata: string;
        name: string;
    };
}

interface DataFlightDetails {
    flights: Flight[];
}

export interface ResponseFlightDetails {
    data: DataFlightDetails;
}

export interface DownloadPdfParams {
    unid: string;
    prettifiedUnid: string;
}

export interface DownloadExport {
    startDate: string;
    endDate: string;
    tripUnids?: string[];
}
