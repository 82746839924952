import NavigateBeforeIcon from '@material-ui/icons/NavigateBefore';
import { Button } from '@mui/material';
import React from 'react';

const PreviousButton = (
    { disabled, onClick }: {
        disabled: boolean;
        onClick: () => void;
    }
): JSX.Element => (
    <Button
        onClick={onClick}
        disabled={disabled}
        aria-label="Previous page"
        data-testid="previous-button"
    >
        <NavigateBeforeIcon fontSize="small"/>
    </Button>
);

export default PreviousButton;
