import 'moment/locale/es';
import { ComponentsProvider, NotificationSeverity, Notification } from '@get-e/react-components';
import { LicenseInfo } from '@mui/x-license-pro';
import { withLDProvider } from 'launchdarkly-react-client-sdk';
import moment from 'moment';
import React, {
    useCallback,
    useEffect,
    useState,
} from 'react';
import { QueryClient, QueryClientProvider } from 'react-query';
import { BrowserRouter } from 'react-router-dom';

import Routes from './components/routes/Routes';
import { LocaleContext, PortalLocale } from './context/LocaleContext';
import { NotificationContext, Severity } from './context/NotificationContext';
import findMomentLocale from './helpers/findMomentLocale';
import { setZendeskLocale } from './helpers/prefillZendeskConfig';
import i18n from './i18n';
import ErrorBoundary from './pages/errorPages/ErrorBoundary';
import Loading from './pages/loading/Loading';
import findSupportedLocale from './public/findSupportedLocale';

interface IeNavigator extends Navigator {
    userLanguage?: string;
    browserLanguage?: string;
}
LicenseInfo.setLicenseKey(process.env.REACT_APP_MUI_PRO_LICENCE_KEY ?? '');

const App = (): JSX.Element => {
    const queryClient = new QueryClient();
    const [locale, setLocale] = useState<PortalLocale>('en-GB');
    const [localeInitialized, setLocaleInitialized] = useState(false);

    const [notification, setNotification] = useState<{
        message: string;
        color: NotificationSeverity;
    } | null>(null);

    useEffect(() => {
        const ieNavigator: IeNavigator = window.navigator;
        const { userLanguage, browserLanguage } = ieNavigator;

        const navigatorLanguages = window.navigator.languages as readonly string[] | undefined;

        const navigatorLanguage = window.navigator.language as string | undefined;

        const uniqueUserLanguages = new Set([
            ...(navigatorLanguages ?? []),
            navigatorLanguage ?? '',
            userLanguage ?? '',
            browserLanguage ?? '',
        ]);

        const userLanguages = Array.from(uniqueUserLanguages).filter(language => Boolean(language));

        const portalLocale: PortalLocale = findSupportedLocale(userLanguages) ?? 'en-GB';

        // eslint-disable-next-line @typescript-eslint/no-floating-promises
        i18n.changeLanguage(portalLocale).then(() => setLocaleInitialized(true));

        setLocale(portalLocale);
        setZendeskLocale(portalLocale);
        moment.locale(findMomentLocale(portalLocale));
    }, []);

    const updateLocale = useCallback(async (value: PortalLocale): Promise<void> => {
        await i18n.changeLanguage(value);
        setLocale(value);
        moment.locale(findMomentLocale(value));
    }, []);

    const showNotification = (message: string, severity?: Severity): void => {
        const color = (() => {
            switch (severity) {
                case Severity.Info:
                case undefined:
                    return 'info';
                case Severity.Error:
                    return 'error';
                default:
                    throw new Error('Unsupported severity');
            }
        })();

        setNotification({
            message,
            color,
        });
    };

    const notificationElement = (() => {
        if (notification === null) {
            return null;
        }

        return (
            <Notification
                severity={notification.color}
                onClose={() => setNotification(null)}
            >
                {notification.message}
            </Notification>
        );
    })();

    if (!localeInitialized) {
        return <Loading framed={false} />;
    }

    return (
        <BrowserRouter>
            <LocaleContext.Provider
                value={{
                    locale,
                    updateLocale,
                }}
            >
                <QueryClientProvider client={queryClient}>
                    <ErrorBoundary message="logIn">
                        <ComponentsProvider>
                            <NotificationContext.Provider
                                value={{ showNotification }}
                            >
                                {notificationElement}
                                <Routes />
                            </NotificationContext.Provider>
                        </ComponentsProvider>
                    </ErrorBoundary>
                </QueryClientProvider>
            </LocaleContext.Provider>
        </BrowserRouter>
    );
};

const initialLdContext = {
    kind: 'user',
    key: 'test@get-e.com',
    name: 'get-e',
    email: 'test@get-e.com',
};

export default withLDProvider({
    clientSideID: process.env.REACT_APP_LAUNCH_DARKLY_CLIENT_SIDE_ID ?? '',
    context: initialLdContext,
})(App);
