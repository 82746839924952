import { RideTrackingStatus } from '../../../../helpers/rideStatusUtil';
import { Arrival, Departure, FlightDetails } from '../../../rides/customer/api/types';
import { UpdateTraveller } from '../../components/travellerInformation/TravellerInformation';

export interface RideResponse {
    unid: string;
    prettifiedUnid: string;
    status: string;
    trackingStatus: keyof typeof RideTrackingStatus;
    distanceInMeters: number;
    durationInSeconds: number;
    numberOfPassengers: number;
    numberOfBags: number;
    passengers: Passenger[];
    vehicle: Vehicle;
    dropOff: Arrival;
    pickUp: Departure;
    salesPricing: SalesPricing;
    specialRequest: string;
    customerReference: string;
    customerCancellationPolicies: CustomerCancellationPolicy[];
    driver: Driver;
    flightDetails: FlightDetails | null;
    paymentMethod?: string;
    booker?: Booker;
    pickupDescription?: string;
    cancelledAt: string | null;
}

export interface Booker {
    id: string;
    name: string;
}

interface Driver {
    name: string;
    phoneNumber: string;
}

export interface Passenger {
    id: number;
    firstName: string;
    lastName: string;
    email: string;
    phone: string;
    isLead: boolean;
    uuid: string;
}

interface Driver {
    phone: string;
    name: string;
}

interface Vehicle {
    name: string;
    imageUrl: string;
    seats: number;
    luggage: number;
}

interface SalesPricing {
    isoCurrency: string;
    amount: number;
}

interface CustomerCancellationPolicy {
    id: number;
    chargePercentage: number;
    cancelledUnderHours: number;
    vehicleTypes: string[];
}

export interface UpdateRideInformation {
    id: string;
    payload: {
        numberOfPassengers: number;
        numberOfBags: number;
        customerReference: string;
        specialRequest: string;
    };
}

export interface UpdateTravellerInformation {
    id: string;
    payload: UpdateTraveller[];
}

export interface UpdateAvailabilites {
    pickup: {
        transferPoint: {
            id: string;
            type: string;
        };
        pickupTimeZulu: string;
    };
    dropOff: {
        transferPoint: {
            id: string;
            type: string;
        };
    };
    numberOfPassengers: number;
    numberOfBags: number;
}

export interface SearchLocation {
    id: string;
    name: string;
    type: string;
    city?: string;
    country?: string;
    latitude: number;
    longitude: number;
}

export interface UpdateVehicleRide{
    rideId: string;
    carId: string;
}

export interface FlightDetailsVariables {
    id: string;
    payload: {
        flightNumber: string;
        departureDate: string;
    };
}

export enum PAYMENT_METHOD {
    CREDIT_CARD = 'Creditcard',
    INVOICE = 'Invoice',
}
