/* eslint-disable no-nested-ternary */
/* eslint-disable max-len */
import { DateField, Spinner, TextField, TimeField } from '@get-e/react-components';
import AirplanemodeInactiveOutlinedIcon from '@mui/icons-material/AirplanemodeInactiveOutlined';
import FlightIcon from '@mui/icons-material/Flight';
import FlightLandIcon from '@mui/icons-material/FlightLand';
import FlightTakeoffIcon from '@mui/icons-material/FlightTakeoff';
import LuggageOutlinedIcon from '@mui/icons-material/LuggageOutlined';
import PersonOutlineOutlinedIcon from '@mui/icons-material/PersonOutlineOutlined';
import ReportProblemOutlinedIcon from '@mui/icons-material/ReportProblemOutlined';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import { Grid, Box, Typography, InputAdornment, Button, Tooltip } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import moment from 'moment';
import React, { SetStateAction, Dispatch, useState, KeyboardEvent } from 'react';
import { useTranslation } from 'react-i18next';
import uuid from 'react-uuid';

import IconedData from '../../../components/iconedData/IconedData';
import { COLORS } from '../../../constants/colors';
import { DATE_FORMATS } from '../../../constants/dateFormats';
import { ENTER } from '../../../constants/keyCodes';
import { TIME_FORMATS } from '../../../constants/timeFormats';
import { InputError } from '../../../helpers/inputValidation/InputError';
import { PrimaryTimeZone } from '../../../services/types';
import { FlightDetails } from '../../rides/customer/api/types';
import { SearchLocation } from '../customer/api/types';
import { SearchLocationComponent } from './SearchLocationComponent';

const useStyles = makeStyles({
    centerContainer: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        '& > div > span': { fontWeight: 700 },
    },
    dataWarning: { marginTop: '1rem' },
});

export interface RideError {
    pickUpDate: InputError | null;
    pickUpTime: InputError | null;
    travellers: InputError | null;
    luggages: InputError | null;
    pickUpLocation: InputError | null;
    dropOffLocation: InputError | null;
}

export const initialRideErrors = {
    pickUpDate: null,
    pickUpTime: null,
    travellers: null,
    luggages: null,
    pickUpLocation: null,
    dropOffLocation: null,
};

export interface Ride {
    id: string;
    pickUpLocation: SearchLocation | null;
    dropOffLocation: SearchLocation | null;
    pickUpDate: Date | null;
    pickUpTime: Date | null;
    pickUpTimeZones: {
        pickUpTime: Date | null;
        pickUpTimeUtc: Date | null;
    } | null;
    pickUpDateZones: {
        pickUpDate: Date | null;
        pickUpDateUtc: Date | null;
    } | null;
    travellers: string;
    luggages: string;
    flightNumber: string;
    specialRequest: string;
    customReference: string;
    flightDetails: FlightDetails;
    primaryTimeZone: PrimaryTimeZone | null;
}

export const initialRide = {
    id: uuid(),
    pickUpLocation: null,
    dropOffLocation: null,
    pickUpDate: null,
    pickUpTime: null,
    pickUpTimeZones: null,
    pickUpDateZones: null,
    travellers: '',
    luggages: '',
    flightNumber: '',
    specialRequest: '',
    customReference: '',
    flightDetails: {} as FlightDetails,
    primaryTimeZone: null,
};

interface RideInformationProps {
    rideValues: Ride;
    setRideValues: Dispatch<SetStateAction<Ride>>;
    errors: RideError;
    setErrors: Dispatch<SetStateAction<RideError>>;
    handleFlightNumberFinishing: () => void;
    isLoadingFlight?: boolean;
    isErrorFlight?: boolean;
}

// eslint-disable-next-line complexity
const RideInformation = ({
    rideValues,
    setRideValues,
    errors,
    setErrors,
    handleFlightNumberFinishing,
    isLoadingFlight,
    isErrorFlight,
}: RideInformationProps) => {
    const { t } = useTranslation();
    const [isReferenceVisible, setIsReferenceVisible] = useState(Boolean(rideValues.customReference));
    const isFlightTracked = !isErrorFlight && Boolean(rideValues.flightDetails.number);
    const classes = useStyles();
    const [inputValue, setInputValue] = useState('');
    const [inputValueDropOff, setInputValueDropOff] = useState('');

    const handleChangeValue = (field: keyof Ride, value: string | Date | SearchLocation | null) => {
        const newValues = {
            ...rideValues,
            [field]: value,
        };

        const newErrors = {
            ...errors,
            [field]: null,

        };

        if (field === 'pickUpDate') {
            newValues.pickUpTime = moment(moment(value as Date).format(DATE_FORMATS['YYYY-MM-DDT']) + moment(newValues.pickUpTime).format(TIME_FORMATS['HH:mm:ss'])).toDate();
            newErrors.pickUpTime = null;
        }

        setRideValues(newValues);
        setErrors(newErrors);
    };

    const handleKeyDown = (event: KeyboardEvent<HTMLInputElement>) => {
        if (event.key === ENTER) {
            handleFlightNumberFinishing();
        }
    };

    return (
        <>
            <Box sx={{ marginBottom: '1rem' }}>
                <Typography
                    style={{
                        fontWeight: 700,
                        color: COLORS.BLUE_DARK,
                    }}
                >
                    {t('pages.singleRide.rideInformation')}
                </Typography>
                {rideValues.primaryTimeZone === PrimaryTimeZone.UTC && (
                    <Box className={classes.dataWarning}>
                        <IconedData
                            bottomMargin="1rem"
                            icon={ReportProblemOutlinedIcon}
                            data={t('pages.singleRide.datesInUTC')}
                            dataColor={COLORS.BLACK}
                            iconColor={COLORS.ORANGE_WARNING}
                            dataTextBold
                        />
                    </Box>
                )}
            </Box>
            <Grid item rowGap=".75rem" display="flex" flexDirection="column">
                <Grid gap={4} display="flex" flexDirection="row" marginBottom="1.2rem">
                    <Grid item xs={6}>
                        <SearchLocationComponent
                            error={errors.pickUpLocation}
                            helperText={(errors.pickUpLocation && t(errors.pickUpLocation)) ?? ''}
                            inputValue={inputValue}
                            setInputValue={setInputValue}
                            address={rideValues.pickUpLocation}
                            setAddress={(newValue: SearchLocation | null) => {
                                handleChangeValue('pickUpLocation', newValue);
                            }}
                            label={t('pages.singleRide.fields.pickUpLocation')}
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <SearchLocationComponent
                            error={errors.dropOffLocation}
                            helperText={(errors.dropOffLocation && t(errors.dropOffLocation)) ?? ''}
                            inputValue={inputValueDropOff}
                            setInputValue={setInputValueDropOff}
                            address={rideValues.dropOffLocation}
                            setAddress={(newValue: SearchLocation | null) => {
                                handleChangeValue('dropOffLocation', newValue);
                            }}
                            label={t('pages.singleRide.fields.dropOffLocation')}
                        />
                    </Grid>
                </Grid>
                <Grid gap={4} display="flex" flexDirection="row">
                    <Grid item xs={3}>
                        <DateField
                            label={t('pages.singleRide.fields.pickUpDate')}
                            required
                            error={errors.pickUpDate !== null}
                            helperText={(errors.pickUpDate && t(errors.pickUpDate)) ?? ''}
                            value={rideValues.pickUpDate}
                            onChange={newValue => handleChangeValue('pickUpDate', newValue)}
                            format={DATE_FORMATS['dd MMM yyyy']}
                        />
                    </Grid>
                    <Grid item xs={3}>
                        <TimeField
                            label={t('pages.singleRide.fields.pickUpTime')}
                            required
                            value={rideValues.pickUpTime}
                            onChange={newValue => handleChangeValue('pickUpTime', newValue)}
                            error={errors.pickUpTime !== null}
                            helperText={(errors.pickUpTime && t(errors.pickUpTime)) ?? ''}
                            format={TIME_FORMATS['HH:mm a']}
                        />
                    </Grid>
                    <Grid item xs={3}>
                        <TextField
                            label={t('pages.singleRide.fields.travellers')}
                            required
                            type="number"
                            value={rideValues.travellers}
                            onChange={event => handleChangeValue('travellers', event.target.value)}
                            error={errors.travellers !== null}
                            helperText={errors.travellers && t(errors.travellers)}
                            InputProps={{
                                endAdornment: (
                                    <InputAdornment position="end">
                                        <PersonOutlineOutlinedIcon />
                                    </InputAdornment>
                                ),
                                inputProps: { min: 1 },
                            }}
                        />
                    </Grid>
                    <Grid item xs={3}>
                        <TextField
                            label={t('pages.singleRide.fields.luggages')}
                            required
                            type="number"
                            value={rideValues.luggages}
                            onChange={event => handleChangeValue('luggages', event.target.value)}
                            error={errors.luggages !== null}
                            helperText={errors.luggages && t(errors.luggages)}
                            InputProps={{
                                endAdornment: (
                                    <InputAdornment position="end">
                                        <LuggageOutlinedIcon />
                                    </InputAdornment>
                                ),
                                inputProps: { min: 0 },
                            }}
                        />
                    </Grid>
                </Grid>
                <Grid gap={4} display="flex" flexDirection="row" marginBottom="1.25rem">
                    <Grid item xs={6}>
                        <TextField
                            label={t('pages.singleRide.fields.flightNumber')}
                            helperText={!isFlightTracked && t('pages.singleRide.fields.flightNumberHelper')}
                            value={rideValues.flightNumber}
                            onChange={event => handleChangeValue('flightNumber', event.target.value)}
                            onBlur={handleFlightNumberFinishing}
                            onKeyDown={handleKeyDown}
                            InputProps={{
                                endAdornment: (
                                    <InputAdornment position="end">
                                        <Tooltip title={isFlightTracked ? 'KLM' : t('pages.singleRide.fields.flightNotTracked')} arrow placement="top">
                                            {isLoadingFlight ? <Spinner size={24} /> : isFlightTracked ? <FlightIcon /> : <AirplanemodeInactiveOutlinedIcon />}
                                        </Tooltip>
                                    </InputAdornment>
                                ),
                            }}
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <Box className={classes.centerContainer}>
                            {isFlightTracked && (
                                <IconedData
                                    icon={FlightTakeoffIcon}
                                    data={rideValues.flightDetails.departure?.iata}
                                    dataColor={COLORS.BLACK}
                                    tooltip={rideValues.flightDetails.departure?.name}
                                />
                            )}
                            <Typography
                                style={{
                                    fontSize: '.75rem',
                                    fontWeight: 700,
                                    color: COLORS.BLUE_DARK,
                                }}
                                marginBottom=".25rem"
                                marginLeft="1rem"
                            >
                                {rideValues.flightDetails.departure?.datetimeLocal
                  && moment(rideValues.flightDetails.departure?.datetimeLocal).format(DATE_FORMATS['DD MMM at HH:mm A'])}
                            </Typography>
                        </Box>
                        <Box className={classes.centerContainer}>
                            {isFlightTracked && (
                                <IconedData
                                    icon={FlightLandIcon}
                                    data={rideValues.flightDetails.arrival?.iata}
                                    dataColor={COLORS.BLACK}
                                    tooltip={rideValues.flightDetails.arrival?.name}
                                />
                            )}
                            <Typography
                                style={{
                                    fontSize: '.75rem',
                                    fontWeight: 700,
                                    color: COLORS.BLUE_DARK,
                                }}
                                marginBottom=".25rem"
                                marginLeft="1rem"
                            >
                                {rideValues.flightDetails.arrival?.datetimeLocal
                  && moment(rideValues.flightDetails.arrival?.datetimeLocal).format(DATE_FORMATS['DD MMM at HH:mm A'])}
                            </Typography>
                        </Box>
                    </Grid>
                </Grid>
                <Grid item>
                    <TextField
                        value={rideValues.specialRequest}
                        label={t('pages.singleRide.fields.specialRequest')}
                        multiline
                        rows={4}
                        onChange={event => handleChangeValue('specialRequest', event.target.value)}
                    />
                </Grid>
                {isReferenceVisible && (
                    <Grid item key={rideValues.id}>
                        <TextField
                            label={t('pages.singleRide.fields.reference')}
                            value={rideValues.customReference}
                            onChange={event => handleChangeValue('customReference', event.target.value)}
                        />
                    </Grid>
                )}
                <Grid item display="flex" flexDirection="row-reverse">
                    <Button
                        variant="text"
                        startIcon={isReferenceVisible ? <VisibilityOffIcon /> : <VisibilityIcon />}
                        sx={{ color: COLORS.SLATE_GREY }}
                        onClick={() => setIsReferenceVisible(!isReferenceVisible)}
                    >
                        {isReferenceVisible ? t('pages.singleRide.fields.hideReferences') : t('pages.singleRide.fields.showReferences')}
                    </Button>
                </Grid>
            </Grid>
        </>
    );
};

export default RideInformation;
