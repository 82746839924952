import { SvgIconComponent } from '@mui/icons-material';
import { Box, SvgIcon, Tooltip, Typography } from '@mui/material';
import React from 'react';

import { COLORS } from '../../constants/colors';

interface IconedDataProps {
    icon: SvgIconComponent;
    data: string;
    tooltip?: string;
    iconColor?: string;
    dataColor?: string;
    bottomMargin?: string;
    iconSize?: string;
    onClick?: () => void;
    isLinked?: boolean;
    dataTextBold?: boolean;
}

const IconedData = ({
    icon,
    data,
    tooltip,
    iconColor,
    dataColor,
    bottomMargin,
    iconSize,
    onClick,
    isLinked,
    dataTextBold,
}: IconedDataProps) => {
    return (
        <Box
            marginBottom={bottomMargin ?? '0.25rem'}
            sx={{
                display: 'flex',
                alignItems: 'center',
                ...(onClick && {
                    ':hover': {
                        '*': {
                            color: COLORS.ORANGE,
                            cursor: 'pointer',
                        },
                    },
                }),
                ...(isLinked && {
                    textDecoration: 'underline',
                    color: COLORS.BLUE,
                    ':hover': {
                        '*': {
                            color: COLORS.BLUE,
                            cursor: 'pointer',
                        },
                    },
                }),
            }}
            onClick={onClick}
        >
            <Tooltip title={tooltip} hidden={!tooltip} placement="top" arrow>
                <SvgIcon
                    component={icon}
                    style={{
                        color: iconColor ?? COLORS.SLATE_GREY,
                        marginRight: '0.625rem',
                        fontSize: iconSize,
                    }}
                />
            </Tooltip>
            <Typography
                component="span"
                color={isLinked ? COLORS.BLUE : dataColor ?? COLORS.BLACK}
                lineHeight="1.125rem !important"
                fontWeight={dataTextBold ? 700 : 400}
            >
                {data}
            </Typography>
        </Box>
    );
};

export default IconedData;
