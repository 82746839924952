import gql from 'graphql-tag';

export interface InviteUserMutation {
    id: string;
    firstName: string;
    lastName: string;
    email: string;
    permissions: {
        manageRidesBookedByOthers: boolean;
        manageUsers: boolean;
    };
}

export interface InviteUserFieldsInput {
    firstName: string;
    lastName: string;
    email: string;
    manageUsers: boolean;
    manageRidesBookedByOthers: boolean;
}

export const INVITE_USER = gql`
    mutation INVITE_USER(
        $firstName: String!,  
        $lastName: String!, 
        $email: String!,
        $manageUsers: Boolean!, 
        $manageRidesBookedByOthers: Boolean!
    ){
        inviteUser(
            input: {
                firstName: $firstName,
                lastName: $lastName,
                email: $email,
                permissions: {
                    manageRidesBookedByOthers: $manageRidesBookedByOthers,
                    manageUsers: $manageUsers
                }
            }
        ) {
            id
        }
    } 
`;
