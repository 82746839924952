import { Modal, PrimaryButton, TextField, SecondaryButton } from '@get-e/react-components';
import PersonOutlineIcon from '@material-ui/icons/PersonOutline';
import EmailOutlinedIcon from '@mui/icons-material/EmailOutlined';
import PhoneOutlinedIcon from '@mui/icons-material/PhoneOutlined';
import { Checkbox, FormControlLabel, InputAdornment, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { MuiTelInput } from 'mui-tel-input';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { v4 as uuidv4 } from 'uuid';

import { COLORS } from '../../../constants/colors';
import { BUTTON_WIDTH } from '../../../constants/layout';
import { getCurrentUserCountryCode } from '../../../helpers/getCurrentUserCountryCode';
import { InputError } from '../../../helpers/inputValidation/InputError';
import and from '../../../helpers/inputValidation/validators/and';
import isEmail from '../../../helpers/inputValidation/validators/isEmail';
import isFilledString from '../../../helpers/inputValidation/validators/isFilledString';
import { Traveller } from './travellerInformation/TravellerInformation';

const useStyles = makeStyles({
    formButton: {
        width: BUTTON_WIDTH,
        marginRight: '1rem',
    },
    formField: {
        marginBottom: '1rem',
        width: '100%',
        '& .MuiFormHelperText-root.Mui-error': { padding: '0 .75rem' },
    },
    buttonsContainer: { marginTop: '2rem' },
    multipleSelectItem: {
        width: '100% !important',
        padding: '0.5rem !important',
        '& .MuiListItemText-root': { paddingLeft: '0.5rem !important' },
    },
});

interface AddTravellerErrors {
    firstName: InputError | null;
    lastName: InputError | null;
    email: InputError | null;
    phone: InputError | null;
}

interface AddTravellerModalProps {
    onClose: () => void;
    onSubmit: (values: Traveller) => void;
    isOpen: boolean;
    traveller?: Traveller;
}

const defaultCountryCode = getCurrentUserCountryCode();

const AddTravellerModal = ({ onClose, onSubmit, isOpen, traveller }: AddTravellerModalProps) => {
    const classes = useStyles();
    const { t } = useTranslation();

    const [values, setValues] = useState<Traveller>(
        traveller ?? {
            id: uuidv4(),
            firstName: '',
            lastName: '',
            email: '',
            phone: '',
            isLead: false,
        }
    );

    const [formErrors, setFormErrors] = useState<AddTravellerErrors>({
        firstName: null,
        lastName: null,
        email: null,
        phone: null,
    });

    const validateFields = (): boolean => {
        const validated = {
            firstName: isFilledString(values.firstName, InputError.Empty),
            lastName: isFilledString(values.lastName, InputError.Empty),
            email: and(isFilledString(values.email, InputError.Empty), () => isEmail(values.email, InputError.InvalidEmail)),
            phone: isFilledString(values.phone, InputError.Empty),
        };

        const fieldErros: AddTravellerErrors = {
            firstName: validated.firstName.isValid ? null : validated.firstName.error,
            lastName: validated.lastName.isValid ? null : validated.lastName.error,
            email: validated.email.isValid ? null : validated.email.error,
            phone: validated.phone.isValid ? null : validated.phone.error,
        };

        const isValid = Object.values(fieldErros).every(error => error === null);

        !isValid && setFormErrors(fieldErros);

        return isValid;
    };

    const handleSubmit = (): void => {
        if (!validateFields()) {
            return;
        }

        onSubmit(values);
    };

    const handleChange = <T extends keyof Traveller>(key: T, newValue: Traveller[T] & (string | boolean)): void => {
        setValues({
            ...values,
            [key]: newValue,
        });

        setFormErrors(prevStateForm => ({
            ...prevStateForm,
            [key]: null,
        }));
    };

    return (
        <Modal open={isOpen} onClose={onClose}>
            <Typography
                style={{
                    color: COLORS.BLUE_DARK,
                    fontSize: '1.5rem',
                    fontWeight: 700,
                    marginBottom: '2rem',
                }}
            >
                {t(traveller ? 'pages.rides.editTraveller' : 'pages.rides.addTraveller')}
            </Typography>
            <TextField
                className={classes.formField}
                error={formErrors.firstName !== null}
                helperText={formErrors.firstName ? t(formErrors.firstName) : null}
                label={t('pages.rides.firstName')}
                name="firstName"
                onChange={event => handleChange('firstName', event.target.value)}
                required
                autoComplete="off"
                value={values.firstName}
                InputProps={{
                    endAdornment: (
                        <InputAdornment position="end">
                            <PersonOutlineIcon />
                        </InputAdornment>
                    ),
                }}
            />
            <TextField
                className={classes.formField}
                error={formErrors.lastName !== null}
                helperText={formErrors.lastName ? t(formErrors.lastName) : null}
                label={t('pages.rides.lastName')}
                name="lastName"
                onChange={event => handleChange('lastName', event.target.value)}
                required
                autoComplete="off"
                value={values.lastName}
                InputProps={{
                    endAdornment: (
                        <InputAdornment position="end">
                            <PersonOutlineIcon />
                        </InputAdornment>
                    ),
                }}
            />
            <MuiTelInput
                style={{ marginBottom: formErrors?.phone !== null ? '1.25rem' : '2.5rem' }}
                value={values.phone}
                defaultCountry={defaultCountryCode}
                label={t('pages.rides.mobile')}
                className={classes.formField}
                name="mobile"
                onChange={value => handleChange('phone', value)}
                error={formErrors?.phone !== null}
                helperText={formErrors?.phone && t(formErrors.phone)}
                variant="filled"
                fullWidth
                InputProps={{
                    endAdornment: (
                        <InputAdornment position="end">
                            <PhoneOutlinedIcon />
                        </InputAdornment>
                    ),
                }}
            />
            <TextField
                className={classes.formField}
                error={formErrors.email !== null}
                helperText={formErrors.email ? t(formErrors.email) : null}
                label={t('pages.rides.email')}
                name="email"
                onChange={event => handleChange('email', event.target.value)}
                required
                autoComplete="off"
                value={values.email}
                InputProps={{
                    endAdornment: (
                        <InputAdornment position="end">
                            <EmailOutlinedIcon />
                        </InputAdornment>
                    ),
                }}
            />
            <FormControlLabel
                control={<Checkbox checked={values.isLead} onChange={event => handleChange('isLead', event.target.checked)} />}
                label={t('pages.rides.mainPassenger')}
                sx={{ width: '100%' }}
            />
            <div className={classes.buttonsContainer}>
                <PrimaryButton className={classes.formButton} onClick={handleSubmit}>
                    {t(traveller ? 'buttonName.update' : 'buttonName.add')}
                </PrimaryButton>
                <SecondaryButton className={classes.formButton} onClick={onClose}>
                    {t('buttonName.cancel')}
                </SecondaryButton>
            </div>
        </Modal>
    );
};

export default AddTravellerModal;
