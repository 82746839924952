import { useFlags } from 'launchdarkly-react-client-sdk';
import React, { FunctionComponent } from 'react';

import Ride from '../Ride';
import RideLegacy from '../rideLegacy/RideLegacy';

const RidePageSwitch: FunctionComponent = () => {
    const { useNewRidesPage } = useFlags();

    return useNewRidesPage ? <Ride /> : <RideLegacy />;
};

export default RidePageSwitch;
