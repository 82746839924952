/* eslint-disable @typescript-eslint/no-shadow */
import { AxiosError } from 'axios';
import { useMemo, useState } from 'react';
import { useQuery } from 'react-query';

import { USE_AVAILABILITIES } from '../../../../constants/queryKeys';
import { getAvailabilites } from '../../../../services/ride';
import { VehicleResponse } from '../../../rides/customer/api/types';
import { Vehicle } from '../../components/VehicleInformationCards';
import { UpdateAvailabilites } from './types';

const mapResponseToVehicle = (response: VehicleResponse): Vehicle => ({
    id: response.uuid,
    vehicleType: response.vehicleType,
    vehicleImage: response.vehicleImage,
    travellers: response.vehicleSeatsNumber.toString(),
    luggages: response.vehicleBagsCapacity.toString(),
    price: response.price,
    priceCurrancy: response.currency,
});

export function useAvailabilites(variables: UpdateAvailabilites) {
    const [errorCode, setErrorCode] = useState<number | null>(0);

    const { data, isLoading, refetch, error, isRefetching } = useQuery([USE_AVAILABILITIES], () => getAvailabilites(variables), {
        keepPreviousData: true,
        refetchOnWindowFocus: false,
        retry: 1,
        cacheTime: 500,
        enabled: false,
        onError: (error: AxiosError) => setErrorCode(error?.response?.status ?? null),
        onSuccess: () => setErrorCode(null),
    });

    return useMemo(() => {
        const vehicleList
            = errorCode ? null : data?.availabilities?.map((response: VehicleResponse) => mapResponseToVehicle(response)) ?? [];

        const newEstimateDistanceAndDuration = errorCode
            ? undefined
            : data?.estimateDetails;

        return {
            vehicleList,
            newEstimateDistanceAndDuration,
            isLoading,
            refetch,
            error,
            isRefetching,
        };
    }, [data, errorCode, isLoading, refetch, error, isRefetching]);
}
