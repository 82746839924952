import { Loader } from '@googlemaps/js-api-loader';

import config from '../../config';

export const DEFAULT_COORDINATES = {
    lat: 52.0842715,
    lon: 5.0124521,
};

export const googleMapsKey = config.googleAddressKey ?? '';

export const bootstrapURLKeys = {
    key: googleMapsKey,
    libraries: ['places'],
};

export const loader = new Loader({
    apiKey: config.googleAddressKey,
    libraries: ['places'],
});
