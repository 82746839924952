import { ListItem, ListItemText } from '@mui/material';
import React, { FunctionComponent } from 'react';
import { NavLink } from 'react-router-dom';

interface Props {
    url: string;
    title: string;
}

const CollapseMenuItem: FunctionComponent<Props> = (
    { url, title },
) => (
    <ListItem button component={NavLink} to={url}>
        <ListItemText>{title}</ListItemText>
    </ListItem>
);

export default CollapseMenuItem;
