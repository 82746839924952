export const AVAILABILITY = '/availability';
export const CREATE_AVAILABILITY = '/availability/create';
export const EDIT_AVAILABILITY = '/availability/:id/edit';

export const REPORTS = '/reports';
export const REPORTS_SUMMARY = `${REPORTS}/summary`;
export const REPORTS_SITUATIONS = `${REPORTS}/situations`;
export const REPORTS_DRIVER_ANALYSIS = `${REPORTS}/driverAnalysis`;
export const REPORTS_REVIEWS = `${REPORTS}/reviews`;

export const LOG_IN = '/log-in';
export const FORGOT_PASSWORD = '/forgot-password';
export const RESET_PASSWORD = '/reset-password';
export const ACCEPT_INVITE = '/accept-invite';

export const USERS = '/users';

export const RIDES = '/rides';

export const EXPORT = '/export';

export const DRIVERS = '/drivers';

export const BOOKING_TOOL = '/booking-tool';

export const getEditAvailabilityRoute = (id: string) =>
    EDIT_AVAILABILITY.replace(':id', id);

export const getSingleRideRoute = (id: string) => `${RIDES}/${id}`;

