import { Theme } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';

const tableStyles = makeStyles((theme: Theme) => ({
    maxWidthTablePage: {
        maxWidth: '980px',
        margin: '0 auto',
    },
    tableContainer: { '& [class*="tableRow"]:last-child': { borderBottom: `1px solid ${theme.palette.grey[500]}` } },
    tableHeading: {
        fontWeight: theme.typography.fontWeightBold,
        padding: theme.spacing(0, 2),
        '& > p': { margin: '0.5em 0' },
    },
    tableRow: {
        borderBottom: `1px solid ${theme.palette.grey[500]}`,
        width: '100%',
        padding: '0.85em 1em',
        position: 'relative',
        '& > div p': {
            margin: '0 0.5em 0.5em 0',
            wordBreak: 'break-word',
            lineHeight: '1',
            color: '#6B6B6B',
            [theme.breakpoints.up('sm')]: { color: theme.palette.common.black },
        },
        '& > div > div:first-child p': { color: theme.palette.common.black },
        [theme.breakpoints.up('sm')]: {
            borderTop: `1px solid ${theme.palette.grey[500]}`,
            padding: '0.5em 1em',
            borderBottom: '0',
            margin: 0,
        },
    },
    tableRowHover: {
        padding: '1em',
        cursor: 'pointer',
        '& [class*="MuiSvgIcon-root"]': {
            display: 'inline-block',
            fontSize: '1em',
            margin: '0 0.25em',
            position: 'relative',
            top: '3px',
        },
        [theme.breakpoints.up('sm')]: { '& [class*="MuiSvgIcon-root"]': { display: 'none' } },
        '&:hover': {
            background: theme.palette.grey[100],
            '& [class*="MuiSvgIcon-root"]': { display: 'inline-block' },
        },
    },
    mobile: {
        position: 'absolute',
        right: 0,
        top: '0.5em',
    },
}));

export default tableStyles;
