import DownloadOutlinedIcon from '@mui/icons-material/DownloadOutlined';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import RequestQuoteOutlinedIcon from '@mui/icons-material/RequestQuoteOutlined';
import StarIcon from '@mui/icons-material/Star';
import { Box, Grid, Rating, Typography } from '@mui/material';
import { GridRowParams } from '@mui/x-data-grid-pro';
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import DropdownButtonMenu from '../../../../components/dropdownButtonMenu/DropdownButtonMenu';
import { COLORS } from '../../../../constants/colors';
import { RideStatusOption, getRideStatusOptionKey } from '../../../../helpers/rideStatusUtil';
import useDataGridStyles from '../../../../styles/DataGrid';
import BigStatusBadge from '../../components/BigStatusBadge';
import CellText from '../../components/CellText';
import CellWrapper from '../../components/CellWrapper';
import FlightStatusBadge from '../../components/FlightStatusBadge';
import StatusOptionBadge from '../../components/StatusOptionBadge';
import { ChangeStatus } from '../Rides';

export const useRidesColumns = (
    handleChangeStatusButtonClick: (changeStatus: ChangeStatus, data: unknown) => void,
    handleEdit: (id: string) => void,
    handleReferenceAndRemarks: (id: string) => void,
    handleDownloadRideOrder: (id: string) => void
) => {
    const dataGridClases = useDataGridStyles();
    const { t } = useTranslation();

    const desktopColumns = useMemo(() => {
        const columns = [
            {
                field: 'status',
                headerName: t('pages.rides.status'),
                width: 110,
                sortable: false,
                renderCell: (params: GridRowParams) => (
                    <CellWrapper>
                        <BigStatusBadge status={params.row.status} />
                    </CellWrapper>
                ),
            },
            {
                field: 'pickup',
                headerName: t('pages.rides.pickup'),
                width: 120,
                sortable: false,
                renderCell: (params: GridRowParams) => (
                    <CellWrapper>
                        <CellText>{params.row.pickup}</CellText>
                    </CellWrapper>
                ),
            },
            {
                field: 'route',
                headerName: t('pages.rides.route'),
                minWidth: 250,
                flex: 1,
                sortable: false,
                renderCell: (params: GridRowParams) => (
                    <CellWrapper>
                        <CellText marginBottom="0.5rem">
                            <strong>From: </strong>
                            {params.row.route.from}
                        </CellText>
                        <CellText>
                            <strong>To: </strong>
                            {params.row.route.to}
                        </CellText>
                        <Box display="flex" alignItems="center" justifyContent="flex-start" paddingTop="0.5rem">
                            <StatusOptionBadge statusOptions={[getRideStatusOptionKey(RideStatusOption.NO_FLIGHT_INFORMATION)]} />
                            <Typography
                                sx={{
                                    fontSize: '0.75rem',
                                    lineHeight: '0.875rem',
                                    color: COLORS.SLATE_GREY,
                                    marginRight: '0.5rem',
                                }}
                            >
                                <strong>Flight: </strong>
                                {params.row.route.flight}
                            </Typography>
                            <FlightStatusBadge status={params.row.flightStatus} showTooltip />
                        </Box>
                    </CellWrapper>
                ),
            },
            {
                field: 'rideInformation',
                headerName: t('pages.rides.rideInformation'),
                width: 200,
                sortable: false,
                renderCell: (params: GridRowParams) => {
                    return (
                        <CellWrapper>
                            <CellText marginBottom="0.5rem">
                                <strong>{t('pages.rides.mainPassenger')}: </strong>
                                {params.row.passengers[0]}
                            </CellText>
                            <Box display="flex">
                                <CellText>
                                    <strong>{t('travellers')}: </strong>
                                </CellText>
                                <CellText tooltip={params.row.passengers as string[]} marginLeft="0.1rem">
                                    {params.row.passengersCount}
                                </CellText>
                            </Box>
                            <CellText>
                                <strong>{t('luggages')}: </strong>
                                {params.row.luggages}
                            </CellText>
                            <CellText>
                                <strong>{t('carType')}: </strong>
                                {params.row.vehicleType}
                            </CellText>
                        </CellWrapper>
                    );
                },
            },
            {
                field: 'driver',
                headerName: t('pages.rides.driver'),
                width: 160,
                sortable: false,
                renderCell: (params: GridRowParams) => (
                    <CellWrapper>
                        <CellText>{params.row.driver?.name ?? 'N/A'}</CellText>
                        {params.row.driver && (
                            <>
                                <Rating
                                    style={{ marginLeft: '-1px' }}
                                    icon={<StarIcon style={{ color: COLORS.ORANGE_OFFICIAL }} />}
                                    value={Number(params.row.driver.rating)}
                                    readOnly
                                />
                                <CellText>
                                    <strong>
                                        {params.row.driver.rating}
                                    </strong> ({params.row.driver.reviewsNumber} {t('reviews').toLowerCase()})
                                </CellText>
                            </>
                        )}
                    </CellWrapper>
                ),
            },
            {
                field: 'references',
                headerName: t('pages.rides.references'),
                width: 340,
                sortable: false,
                headerClassName: dataGridClases.hideSeparator,
                renderCell: (params: GridRowParams) => (
                    <CellWrapper>
                        <Box display="flex" justifyContent="space-between" alignItems="flex-start" width="100%">
                            <Box width="160px">
                                <CellText marginBottom="0.5rem">
                                    <strong>{t('pages.rides.rideNumber')}</strong>: {params.row.rideNumber}
                                </CellText>
                                <CellText>
                                    <strong>{t('pages.rides.reference')}</strong>: {params.row.reference ?? '-'}
                                </CellText>
                            </Box>
                            <DropdownButtonMenu
                                buttonLabel={params.row.changeStatus as ChangeStatus}
                                onClick={() => handleChangeStatusButtonClick(params.row.changeStatus as ChangeStatus, params.row)}
                                options={[
                                    {
                                        id: 1,
                                        label: t('buttonName.edit'),
                                        icon: EditOutlinedIcon,
                                        onClick: () => handleEdit(params.row.id),
                                    },
                                    {
                                        id: 2,
                                        label: t('pages.rides.reference&remarks'),
                                        icon: RequestQuoteOutlinedIcon,
                                        onClick: () => handleReferenceAndRemarks(params.row.id),
                                    },
                                    {
                                        id: 3,
                                        label: t('pages.rides.downloadRideOrder'),
                                        icon: DownloadOutlinedIcon,
                                        onClick: () => handleDownloadRideOrder(params.row.id),
                                    },
                                ]}
                            />
                        </Box>
                    </CellWrapper>
                ),
            },
        ];

        return columns;
    }, [
        dataGridClases.hideSeparator,
        handleChangeStatusButtonClick,
        handleDownloadRideOrder,
        handleEdit,
        handleReferenceAndRemarks,
        t,
    ]);

    const mobileColumns = useMemo(() => {
        const columns = [
            {
                field: 'mobileRideCell',
                width: '100%',
                flex: 1,
                renderCell: (params: GridRowParams) => (
                    <Grid
                        container
                        key={params.row.id}
                        padding="1rem"
                        border={`1px solid ${COLORS.BLACK_12}`}
                        borderRadius="4px"
                    >
                        <Grid
                            item
                            display="flex"
                            width="100%"
                            alignItems="center"
                            justifyContent="space-between"
                            paddingBottom="1rem"
                            borderBottom={`1px solid ${COLORS.BLACK_12}`}
                        >
                            <Typography color={COLORS.BLUE_DARK} fontWeight={700}>
                                {params.row.pickup}
                            </Typography>
                            <BigStatusBadge width="76px" status={params.row.status} />
                        </Grid>
                        <Grid item>
                            <CellText marginBottom="0.5rem" marginTop="1rem">
                                <strong>From: </strong>
                                {params.row.route.from}
                            </CellText>
                            <CellText>
                                <strong>To: </strong>
                                {params.row.route.to}
                            </CellText>
                            <Box display="flex" alignItems="center" justifyContent="flex-start" paddingTop="0.5rem">
                                <StatusOptionBadge
                                    statusOptions={[getRideStatusOptionKey(RideStatusOption.NO_FLIGHT_INFORMATION)]}
                                />
                                <Typography
                                    sx={{
                                        fontSize: '0.75rem',
                                        lineHeight: '0.875rem',
                                        color: COLORS.SLATE_GREY,
                                        marginRight: '0.5rem',
                                    }}
                                >
                                    <strong>Flight: </strong>
                                    {params.row.route.flight}
                                </Typography>
                                <FlightStatusBadge status={params.row.flightStatus} showTooltip />
                            </Box>
                            <CellText marginBottom="0.5rem" marginTop="0.5rem">
                                <strong>{t('pages.rides.mainPassenger')}: </strong>
                                {params.row.passengers[0]}
                            </CellText>
                            <Box display="flex">
                                <CellText>
                                    <strong>{t('travellers')}: </strong>
                                </CellText>
                                <CellText tooltip={params.row.passengers as string[]} marginLeft="0.1rem">
                                    {params.row.passengersCount}
                                </CellText>
                            </Box>
                            <CellText>
                                <strong>{t('luggages')}: </strong>
                                {params.row.luggages}
                            </CellText>
                            <CellText>
                                <strong>{t('carType')}: </strong>
                                {params.row.vehicleType}
                            </CellText>
                            <CellText marginTop="1rem">
                                <strong>{t('pages.rides.driver')}: </strong>
                                {params.row.driver?.name ?? '-'}
                            </CellText>
                            {params.row.driver && (
                                <Box display="flex">
                                    <Rating
                                        style={{ marginLeft: '-1px' }}
                                        icon={<StarIcon style={{ color: COLORS.ORANGE_OFFICIAL }} />}
                                        value={Number(params.row.driver.rating)}
                                        readOnly
                                    />
                                    <CellText marginTop="0.3125rem" marginLeft="0.5rem">
                                        <strong>
                                            {params.row.driver.rating}
                                        </strong> ({params.row.driver.reviewsNumber} {t('reviews').toLowerCase()})
                                    </CellText>
                                </Box>
                            )}
                            <CellText marginTop="0.5rem">
                                <strong>{t('pages.rides.rideNumber')}</strong>: {params.row.rideNumber}
                            </CellText>
                            <CellText marginBottom="1rem">
                                <strong>{t('pages.rides.reference')}</strong>: {params.row.reference ?? '-'}
                            </CellText>
                            <DropdownButtonMenu
                                buttonLabel={params.row.changeStatus as ChangeStatus}
                                onClick={() => handleChangeStatusButtonClick(params.row.changeStatus as ChangeStatus, params.row)}
                                options={[
                                    {
                                        id: 1,
                                        label: t('buttonName.edit'),
                                        icon: EditOutlinedIcon,
                                        onClick: () => handleEdit(params.row.id),
                                    },
                                    {
                                        id: 2,
                                        label: t('pages.rides.reference&remarks'),
                                        icon: RequestQuoteOutlinedIcon,
                                        onClick: () => handleReferenceAndRemarks(params.row.id),
                                    },
                                    {
                                        id: 3,
                                        label: t('pages.rides.downloadRideOrder'),
                                        icon: DownloadOutlinedIcon,
                                        onClick: () => handleDownloadRideOrder(params.row.id),
                                    },
                                ]}
                            />
                        </Grid>
                    </Grid>
                ),
            },
        ];

        return columns;
    }, [handleChangeStatusButtonClick, handleDownloadRideOrder, handleEdit, handleReferenceAndRemarks, t]);

    return [desktopColumns, mobileColumns];
};
