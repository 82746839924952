import { Typography } from '@mui/material';
import React, { FunctionComponent } from 'react';
import { useTranslation } from 'react-i18next';

import smallUser from '../../../public/assets/icons/smallUser.svg';
import badgeStyles from './Badge.styles';

const UserMenuButtonName: FunctionComponent<{
    userName: string;
}> = ({ userName }) => {
    const { avatar } = badgeStyles();
    const { t } = useTranslation();

    return (
        <>
            <Typography
                component="span"
                sx={{
                    display: {
                        xs: 'none',
                        md: 'block',
                    },
                }}
            >
                {userName}
            </Typography>
            <img src={smallUser} alt={t('authentication.alt')} className={avatar}/>
        </>
    );
};

export default UserMenuButtonName;
