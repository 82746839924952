export enum PrimaryTimeZone {
    LOCAL = 'LOCAL',
    UTC = 'UTC'
}

export interface User {
    id: string;
    customLogin: string | null;
    firstName: string;
    lastName: string;
    fullName: string;
    email: string;
    joinedAt: string | null;
    lastSeen: string | null;
    isDeactivated: boolean;
    accountType?: string;
    primaryTimeZone?: PrimaryTimeZone;
    permissions: {
        manageUsers: boolean;
        manageRidesBookedByOthers: boolean;
    };
}

export interface UserProfileRequest {
    email: string;
    firstName: string;
    lastName: string;
}
