import gql from 'graphql-tag';

export interface EditUserMutation {
    id: string;
    firstName: string;
    lastName: string;
    email: string;
    permissions: {
        manageRidesBookedByOthers: boolean;
        manageUsers: boolean;
    };
}

export interface EditUserMutationInput {
    userId: string;
    firstName: string;
    lastName: string;
    email: string;
    manageUsers?: boolean;
    manageRidesBookedByOthers?: boolean;
}

export const UPDATE_USER = gql`
    mutation UPDATE_USER(
        $userId: ID!, 
        $firstName: String!, 
        $lastName: String!, 
        $email: String!,
        $manageUsers: Boolean, 
        $manageRidesBookedByOthers: Boolean
    ){
        updateUser(
            input: {
                userId: $userId,
                patch: {
                    firstName: $firstName,
                    lastName: $lastName,
                    email: $email,
                    permissions: {
                        manageRidesBookedByOthers: $manageRidesBookedByOthers,
                        manageUsers: $manageUsers
                    }
                }
            }
        ) {
            id
            firstName
            lastName
            fullName
            email
            permissions {
                manageRidesBookedByOthers
                manageUsers
            }
        }
    } 
`;
