import { Box, Typography } from '@mui/material';
import React from 'react';

import { RideStatus, getRideStatusColor, getRideStatusTextColor } from '../../../helpers/rideStatusUtil';

interface BigStatusBadgeProps {
    status: keyof typeof RideStatus;
    width?: string;
    height?: string;
}

const BigStatusBadge = ({ status, width, height }: BigStatusBadgeProps) => (
    <Box
        sx={{
            backgroundColor: getRideStatusColor(status),
            width: width ?? '100%',
            height: height ?? '32px',
            borderRadius: '4px',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
        }}
    >
        <Typography
            sx={{
                textAlign: 'center',
                color: getRideStatusTextColor(status),
                fontSize: '0.75rem',
                lineHeight: '0.875rem',
            }}
        >
            {RideStatus[status]}
        </Typography>
    </Box>
);

export default BigStatusBadge;
