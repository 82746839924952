import List from '@material-ui/core/List';
import AddIcon from '@material-ui/icons/Add';
import BarChartIcon from '@material-ui/icons/BarChart';
import FolderOutlinedIcon from '@material-ui/icons/FolderOutlined';
import ListIcon from '@material-ui/icons/List';
import PersonOutlineIcon from '@material-ui/icons/PersonOutline';
import ScheduleIcon from '@material-ui/icons/Schedule';
import ContactMailOutlinedIcon from '@mui/icons-material/ContactMailOutlined';
import { useFlags } from 'launchdarkly-react-client-sdk';
import React, { FunctionComponent } from 'react';
import { useTranslation } from 'react-i18next';

import { BOOKING_TOOL, DRIVERS, EXPORT, RIDES, USERS } from '../../../constants/urlPaths';
import { useCurrentUserContext } from '../../../context/CurrentUserContext';
import { MenuListItem } from '../../menuListItem/MenuListItem';
import navigationStyles from '../NavigationMenu.styles';

const CustomerList: FunctionComponent = () => {
    const classes = navigationStyles();
    const { t } = useTranslation();
    const { currentUser } = useCurrentUserContext();
    const { useDriversPage } = useFlags();

    return (
        <List role="menu" className={classes.listContainer} data-testid="customer-navigation">
            <MenuListItem icon={<AddIcon fontSize="small" />} label={t('navigation.customer.bookingTool')} href={BOOKING_TOOL} />
            <MenuListItem icon={<ListIcon fontSize="small" />} label={t('navigation.customer.rides')} href={RIDES} />
            {useDriversPage && (
                <MenuListItem
                    icon={<ContactMailOutlinedIcon fontSize="small" />}
                    label={t('navigation.supplier.drivers')}
                    href={DRIVERS}
                />
            )}
            <MenuListItem icon={<BarChartIcon fontSize="small" />} label={t('navigation.customer.export')} href={EXPORT} />
            {currentUser.permissions.manageUsers && (
                <MenuListItem
                    icon={<PersonOutlineIcon fontSize="small" />}
                    label={t('authentication.users')}
                    href={USERS}
                    data-testid="users-tab"
                />
            )}
            {currentUser.company.documentsPage.items.length
                ? (
                    <MenuListItem
                        icon={<FolderOutlinedIcon fontSize="small" />}
                        label={t('navigation.customer.documents')}
                        href="/documents"
                        data-testid="document-tab"
                    />
                )
                : null}
            {currentUser.company.customer.features.cityJetPendingChanges && (
                <MenuListItem
                    icon={<ScheduleIcon fontSize="small" />}
                    label={t('navigation.customer.pendingChanges')}
                    href="/pending-changes"
                    data-testid="pending-changes-tab"
                />
            )}
        </List>
    );
};

export default CustomerList;
