/* eslint-disable max-lines-per-function */
/* eslint-disable max-statements */
/* eslint-disable @typescript-eslint/no-floating-promises */
/* eslint-disable complexity */
/* eslint-disable max-len */
import { PrimaryButton, SecondaryButton, Spinner } from '@get-e/react-components';
import { Grid, Link, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { Box } from '@mui/system';
import clsx from 'clsx';
import _ from 'lodash';
import moment from 'moment';
import React, { useState, SetStateAction, useEffect, MouseEvent, useMemo, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useMutation } from 'react-query';
import { useHistory, useParams } from 'react-router';

import { COLORS } from '../../../constants/colors';
import { DATE_FORMATS } from '../../../constants/dateFormats';
import { RIDES } from '../../../constants/urlPaths';
import { Severity, useNotificationContext } from '../../../context/NotificationContext';
import { getDateTimeFromDateTimePartials } from '../../../helpers/getDateTimeFromDateTimePartials';
import { getDateTimeWithoutOffset } from '../../../helpers/getDateTimeWithoutOffset';
import getRemainingTimeString from '../../../helpers/getRemainingTimeString';
import getTimeDelta from '../../../helpers/getTimeDelta';
import { isElementPartialVisible } from '../../../helpers/isElementPartialVisible';
import { mapRideToSummaryRide } from '../../../helpers/maps/mapRideToSummaryRide';
import { RideStatus, RideTrackingStatus, getRideStatusKey } from '../../../helpers/rideStatusUtil';
import {
    updateRideInformation,
    updateTravellerInformation,
    cancelRide,
    updateRideVehicle,
    updateFlightNumber,
} from '../../../services/ride';
import { PrimaryTimeZone } from '../../../services/types';
import { useUserProfile } from '../../pageContainer/api/useUserProfile';
import StatusBadge from '../../rides/components/StatusBadge';
import downloadBookingConfirmation from '../../rides/customer/api/downloadBookingConfirmation';
import downloadReceipt from '../../rides/customer/api/downloadReceipt';
import { FlightDetails } from '../../rides/customer/api/types';
import DriverInformation from '../components/DriverInformation';
import RideInformation, { Ride as RideType } from '../components/RideInformation';
import RideSummaryCustomer from '../components/RideSummaryCustomer';
import SendBookingConfirmationModal, { BookingConfirmationEmail } from '../components/SendBookingConfirmationModal';
import TravellerInformation, { Traveller } from '../components/travellerInformation/TravellerInformation';
import UpdateRideConfirmModal from '../components/UpdateRideConfirmModal';
import VehicleInformationCards, { Vehicle } from '../components/VehicleInformationCards';
import { RideContext, SummaryRideType } from '../context/RideContext';
import { useAvailabilites } from './api/useAvailabilites';
import { useFlightNumberDetails } from './api/useFlightNumberDetails';
import { useRide } from './api/useRide';
import useCarInformation from './hooks/useCarInformation';
import useRideInformation from './hooks/useRideInformation';
import useTravellerInformation from './hooks/useTravellerInformation';

const useStyles = makeStyles({
    header: {
        position: 'sticky',
        top: '3.6rem',
        backgroundColor: COLORS.WHITE,
        display: 'flex',
        zIndex: 10,
    },
    tabs: {
        padding: '1rem 0',
        fontWeight: 700,
        borderBottom: `1px solid ${COLORS.SLATE_GREY}`,
    },
    tab: {
        textDecoration: 'none',
        color: COLORS.SLATE_GREY,
        padding: '1rem',
        '&:hover': { borderBottom: 'none' },
    },
    activeTab: {
        borderBottom: `2px solid ${COLORS.BLUE}`,
        lineHeight: '1.3rem',
        color: `${COLORS.BLACK} !important`,
        '&:hover': { borderBottom: `2px solid ${COLORS.BLUE}` },
    },
    updateButton: { marginRight: '1rem' },
    buttons: { width: '150px' },
    heading: {
        marginBottom: '1rem',
        color: COLORS.BLUE_DARK,
        fontSize: '1rem',
        fontWeight: 700,
    },
});

enum ActiveElement {
    RIDE = 'RIDE',
    CAR = 'CAR',
    TRAVELLER = 'TRAVELLER',
    POLICIES = 'POLICIES',
}

const onPress = (event: MouseEvent<HTMLAnchorElement>) => {
    event.preventDefault();
    const targetId = event.currentTarget.getAttribute('href');
    const target = targetId && window.document.querySelector(targetId);

    if (target) {
        target.scrollIntoView({ behavior: 'smooth' });
    }
};

const getTabLink = (tab: string) => `#${tab}`;

const Ride = () => {
    const [isTypingFlightNumberFinished, setIsTypingFlightNumberFinished] = useState(false);
    const [isVehicleNotSelected, setIsVehicleNotSelected] = useState(false);
    const [isRefetchedAvailabileVehicleList, setIsRefetchedAvailabileVehicleList] = useState(false);
    const [isUpdateRideModalOpen, setIsUpdateRideModalOpen] = useState(false);
    const [isSendBookingConfirmationModalOpen, setIsSendBookingConfirmationModalOpen] = useState(false);
    const [activeElement, setActiveElement] = useState<keyof typeof ActiveElement>(ActiveElement.RIDE);
    const classes = useStyles();
    const { t } = useTranslation();
    const { rideId }: { rideId: string } = useParams();
    const history = useHistory();
    const { showNotification } = useNotificationContext();
    const { data, isLoading, refetch } = useRide(rideId);
    const { data: currentUser } = useUserProfile();
    const initialRideValues = useRef<RideType | null>(null);
    const initialVehicleValues = useRef<Vehicle | null>(null);
    const initialTravellerValues = useRef<Traveller[] | null>(null);

    const { rideValues, setRideValues, rideErrors, setRideErrors, validateRideFields } = useRideInformation();

    const { travellerValues, setTravellerValues, travellerErrors, setTravellerErrors, validateTravellerFields } = useTravellerInformation();

    const { selectedVehicle, setSelectedVehicle } = useCarInformation();

    const { mutate: downloadBookingConfirmationMutation, isLoading: isDownloadingBookingConfirmation }
    = useMutation(downloadBookingConfirmation);

    const { mutate: downloadReceiptMutation, isLoading: isDownloadingReceipt } = useMutation(downloadReceipt);

    const { mutate: updateRideInformationMutation, isLoading: isUpdatingRideInformation } = useMutation(updateRideInformation, {
        onSuccess: () => {
            showNotification(t('alert.edit.success'), Severity.Info);
            history.push(RIDES);
        },
        onError: (error: Error) => {
            showNotification(t('alert.edit.error'), Severity.Error);
        },
    });

    const { mutate: updateTravellerInformationMutation, isLoading: isUpdatingTravellers } = useMutation(updateTravellerInformation, {
        onSuccess: () => {
            showNotification(t('alert.edit.success'), Severity.Info);
            history.push(RIDES);
        },
        onError: (error: Error) => {
            showNotification(t('alert.edit.error'), Severity.Error);
        },
    });

    const { mutate: updateFlightNumberMutation, isLoading: isUpdatingFlightNumber } = useMutation(updateFlightNumber, {
        onSuccess: () => {
            showNotification(t('alert.edit.success'), Severity.Info);
            history.push(RIDES);
        },
        onError: (error: Error) => {
            showNotification(t('alert.edit.error'), Severity.Error);
        },
    });

    const {
        vehicleList,
        newEstimateDistanceAndDuration,
        refetch: refetchAvailabileVehicleList,
        isLoading: isLoadingAvailabilites,
        isRefetching: isRefetchingAvailabilites,
    } = useAvailabilites({
        pickup: {
            transferPoint: {
                id: rideValues.pickUpLocation?.id ?? '',
                type: rideValues.pickUpLocation?.type.toUpperCase() ?? '',
            },
            pickupTimeZulu: moment(rideValues.pickUpTime).format(DATE_FORMATS['YYYY-MM-DDTHH:mm:ss']),
        },
        dropOff: {
            transferPoint: {
                id: rideValues.dropOffLocation?.id ?? '',
                type: rideValues.dropOffLocation?.type.toUpperCase() ?? '',
            },
        },
        numberOfPassengers: parseInt(rideValues.travellers),
        numberOfBags: parseInt(rideValues.luggages),
    });

    const {
        flightDetails,
        refetch: refetchFlightDetails,
        isLoading: isLoadingFlight,
        isError: isErrorFlight,
    } = useFlightNumberDetails({
        payload: {
            flightNumber: rideValues.flightNumber,
            departureDate: moment(rideValues.pickUpDate).format(DATE_FORMATS['YYYY-MM-DD']),
        },
    });

    const { mutate: updateVehicleMutation, isLoading: isUpdatingVehicle } = useMutation(updateRideVehicle, {
        onSuccess: () => {
            showNotification(t('alert.edit.successVehicleChange'), Severity.Info);
            history.push(RIDES);
        },
        onError: (error: Error) => {
            showNotification(t('alert.edit.error'), Severity.Error);
        },
    });

    useEffect(() => {
        if (!data || isLoading) {
            return;
        }

        const newRideValues = {
            ...rideValues,
            flightNumber: data?.flightDetails?.number ?? '',
            flightDetails: data?.flightDetails ?? ({} as FlightDetails),
            id: data?.unid ?? '',
            pickUpDate:
        currentUser?.primaryTimeZone === PrimaryTimeZone.UTC
            ? getDateTimeWithoutOffset(data?.pickUp?.departAt)
            : getDateTimeWithoutOffset(data?.pickUp?.departAtLocal),
            pickUpTime:
        currentUser?.primaryTimeZone === PrimaryTimeZone.UTC
            ? getDateTimeWithoutOffset(data?.pickUp?.departAt)
            : getDateTimeWithoutOffset(data?.pickUp?.departAtLocal),
            pickUpTimeZones: {
                pickUpTime: getDateTimeWithoutOffset(data?.pickUp?.departAtLocal),
                pickUpTimeUtc: getDateTimeWithoutOffset(data?.pickUp?.departAt),
            },
            pickUpDateZones: {
                pickUpDate: getDateTimeWithoutOffset(data?.pickUp?.departAtLocal),
                pickUpDateUtc: getDateTimeWithoutOffset(data?.pickUp?.departAt),
            },
            travellers: data?.numberOfPassengers?.toString() ?? '',
            luggages: data?.numberOfBags?.toString() ?? '',
            specialRequest: data?.specialRequest ?? '',
            customReference: data?.customerReference ?? '',
            pickUpLocation: {
                id: data?.pickUp?.location?.id ?? '',
                name: data?.pickUp?.location?.address ?? data?.pickUp?.location?.name ?? '',
                type: data?.pickUp?.location?.type ?? '',
                latitude: data?.pickUp?.location?.coordinates?.lat ?? 0,
                longitude: data?.pickUp?.location?.coordinates?.lon ?? 0,
            },
            dropOffLocation: {
                id: data?.dropOff?.location?.id ?? '',
                name: data?.dropOff?.location?.address ?? data?.dropOff?.location?.name ?? '',
                type: data?.dropOff?.location?.type ?? '',
                latitude: data?.dropOff?.location?.coordinates?.lat ?? 0,
                longitude: data?.dropOff?.location?.coordinates?.lon ?? 0,
            },
            primaryTimeZone: currentUser?.primaryTimeZone ?? null,
        };

        setRideValues(newRideValues);

        const defaultTravellers: Traveller[] = data
            ? data.passengers.map(({ id, ...rest }) => ({
                id: id.toString(),
                ...rest,
            }))
            : [];

        setTravellerValues(defaultTravellers);

        const newVehicleValues = {
            id: data?.unid ?? '',
            vehicleType: data?.vehicle?.name ?? '',
            vehicleImage: data?.vehicle?.imageUrl ?? '',
            travellers: data?.vehicle?.seats?.toString() ?? '',
            luggages: data?.vehicle?.luggage?.toString() ?? '',
            price: data?.salesPricing?.amount ?? 0,
            priceCurrancy: data?.salesPricing?.isoCurrency ?? '',
        };

        setSelectedVehicle(newVehicleValues);

        const updatedErrorsValues = defaultTravellers.map(traveller => ({
            ...traveller,
            id: traveller.id,
            firstNameError: null,
            lastNameError: null,
            emailError: null,
            phoneError: null,
        }));

        setTravellerErrors(updatedErrorsValues);

        initialRideValues.current = newRideValues;
        initialVehicleValues.current = newVehicleValues;
        initialTravellerValues.current = defaultTravellers;
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [data, isLoading]);

    useEffect(() => {
        const defaultFlightNumber = data?.flightDetails?.number ?? '';
        const defaultPickUpDate = getDateTimeWithoutOffset(data?.pickUp?.departAtLocal ?? '');

        if (defaultFlightNumber !== rideValues.flightNumber && defaultPickUpDate !== rideValues.pickUpDate) {
            refetchFlightDetails();
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isTypingFlightNumberFinished]);

    useEffect(() => {
        setRideValues({
            ...rideValues,
            flightDetails: isErrorFlight ? ({} as FlightDetails) : flightDetails ?? ({} as FlightDetails),
        });
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [flightDetails, isErrorFlight]);

    useEffect(() => {
        function checkVisibility() {
            const elements = document.querySelectorAll('.tab-elements');
            const allTabs: Array<SetStateAction<string>> = [];

            elements.forEach(element => {
                const isVisible = isElementPartialVisible(element as HTMLInputElement, 350);

                if (isVisible) {
                    allTabs.push(element.id as keyof typeof ActiveElement);
                }
            });

            if (window.pageYOffset === 0) {
                setActiveElement(allTabs[0] as keyof typeof ActiveElement);
            } else {
                setActiveElement(allTabs[allTabs.length - 1] as keyof typeof ActiveElement);
            }
        }

        window.addEventListener('scroll', checkVisibility, { passive: true });

        return () => {
            window.removeEventListener('scroll', checkVisibility);
        };
    }, []);

    useEffect(() => {
        if (selectedVehicle?.id && selectedVehicle?.id !== rideValues?.id) {
            const target = window.document.querySelector(getTabLink(ActiveElement.CAR));

            if (target) {
                target.scrollIntoView({ behavior: 'smooth' });
            }
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedVehicle]);

    useEffect(() => {
        const isRideSetted = Boolean(rideValues.pickUpLocation?.id) && Boolean(rideValues.dropOffLocation?.id);

        const isInitialRideValues = (initialRideValues.current?.pickUpLocation?.id === rideValues.pickUpLocation?.id)
        && (initialRideValues.current?.dropOffLocation?.id === rideValues.dropOffLocation?.id)
        && (initialRideValues.current?.pickUpDate === rideValues?.pickUpDate)
        && (initialRideValues.current?.pickUpTime === rideValues?.pickUpTime);

        if (isRideSetted && validateRideFields() && !isInitialRideValues) {
            refetchAvailabileVehicleList();
            setSelectedVehicle(null);
            setIsRefetchedAvailabileVehicleList(true);
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [rideValues.pickUpLocation, rideValues.dropOffLocation, rideValues.pickUpDate, rideValues.pickUpTime]);

    useEffect(() => {
        if (rideValues.travellers !== data?.numberOfPassengers?.toString()) {
            if (parseInt(rideValues.travellers) > parseInt(selectedVehicle?.travellers ?? '-1')) {
                refetchAvailabileVehicleList();
                setSelectedVehicle(null);
                setIsRefetchedAvailabileVehicleList(true);
            }

            if (parseInt(rideValues.travellers) <= Number(data?.vehicle?.seats ?? -1)) {
                setIsRefetchedAvailabileVehicleList(false);
                setSelectedVehicle({
                    id: data?.unid ?? '',
                    vehicleType: data?.vehicle?.name ?? '',
                    vehicleImage: data?.vehicle?.imageUrl ?? '',
                    travellers: data?.vehicle?.seats?.toString() ?? '',
                    luggages: data?.vehicle?.luggage?.toString() ?? '',
                    price: data?.salesPricing?.amount ?? 0,
                    priceCurrancy: data?.salesPricing?.isoCurrency ?? '',
                });
            }
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [rideValues.travellers]);

    const areRideInformationValuesChanged = () => {
        const defaultValues = {
            travellers: data?.numberOfPassengers?.toString() ?? '',
            luggages: data?.numberOfBags?.toString() ?? '',
            specialRequest: data?.specialRequest ?? '',
            customReference: data?.customerReference ?? '',
        };

        return (
            JSON.stringify(defaultValues)
      !== JSON.stringify({
          travellers: rideValues.travellers,
          luggages: rideValues.luggages,
          specialRequest: rideValues.specialRequest,
          customReference: rideValues.customReference,
      })
        );
    };

    const areTravellerInformationValuesChanged = () => {
        const defaultPassengers = data?.passengers.map(({ id, ...keepAttrs }) => keepAttrs);
        const changedTravellerValues = travellerValues.map(({ id, ...keepAttrs }) => keepAttrs);

        return JSON.stringify(defaultPassengers) !== JSON.stringify(changedTravellerValues);
    };

    function handleUpdateRideInformation() {
        if (areRideInformationValuesChanged()) {
            updateRideInformationMutation({
                id: rideId,
                payload: {
                    numberOfPassengers: parseInt(rideValues.travellers),
                    numberOfBags: parseInt(rideValues.luggages),
                    customerReference: rideValues.customReference,
                    specialRequest: rideValues.specialRequest,
                },
            });
        }
    }

    function handleUpdateTravellerInformation() {
        if (areTravellerInformationValuesChanged()) {
            updateTravellerInformationMutation({
                id: rideId,
                payload: travellerValues.map(({ id, ...keepAttrs }) => keepAttrs),
            });
        }
    }

    function handleUpdateFlightNumber() {
        const defaultFlightNumber = data?.flightDetails?.number ?? '';
        const defaultPickUpDate = getDateTimeWithoutOffset(data?.pickUp?.departAtLocal ?? '');

        if (defaultFlightNumber !== rideValues.flightNumber && defaultPickUpDate !== rideValues.pickUpDate) {
            updateFlightNumberMutation({
                id: rideId,
                payload: {
                    flightNumber: rideValues.flightNumber,
                    departureDate: moment(rideValues.pickUpDate).format(DATE_FORMATS['YYYY-MM-DD']),
                },
            });
        }
    }

    const onUpdateHandler = () => {
    // Update vehicle - swap estimate options
        if (selectedVehicle?.id && selectedVehicle?.id !== rideValues?.id) {
            updateVehicleMutation({
                rideId: rideValues.id,
                carId: selectedVehicle.id,
            });
        } else if (isRefetchedAvailabileVehicleList) {
            setIsVehicleNotSelected(!isVehicleNotSelected);
            setIsUpdateRideModalOpen(false);
            return;
        }

        handleUpdateTravellerInformation();

        handleUpdateFlightNumber();
        handleUpdateRideInformation();

        setIsUpdateRideModalOpen(false);
    };

    const validateFields = () => validateTravellerFields() && validateRideFields();

    const handleRideUpdate = () => {
        if (validateFields()) {
            setIsUpdateRideModalOpen(true);
        }
    };

    const onCancelHandler = async () => {
        try {
            const response = await cancelRide(rideId);

            response.status === 204
                ? showNotification(t('pages.rides.successCancelRide'), Severity.Info)
                : showNotification(t('errors.cancelRide'), Severity.Error);
            await refetch();
        } catch (error) {
            showNotification(t('errors.cancelRide'), Severity.Error);
        }
    };

    const isActiveClass = (tab: string) => (ActiveElement[activeElement] === tab ? classes.activeTab : '');

    const handleFlightNumberFinishing = () => setIsTypingFlightNumberFinished(!isTypingFlightNumberFinished);

    const initialBookingConfirmationEmails: BookingConfirmationEmail[] = travellerValues.map(traveller => ({
        id: traveller.email,
        email: traveller.email,
        isIncluded: traveller.isLead,
    }));

    const cancelledAtDateTimeFormatted = useMemo(
        () => `${moment(data?.cancelledAt).utc()
            .format(DATE_FORMATS['ddd DD-MMM-YYYY HH:mm A'])} UTC`,
        [data?.cancelledAt]
    );

    const combinedDateTime
    = rideValues.pickUpDate && rideValues.pickUpTime ? getDateTimeFromDateTimePartials(rideValues.pickUpDate, rideValues.pickUpTime) : null;

    const pickupTimeDifference = useMemo(
        () => getRemainingTimeString(data?.cancelledAt ?? '', combinedDateTime?.toUTCString() ?? ''),
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [data?.cancelledAt, rideValues.pickUpDate, rideValues.pickUpTime]
    );

    const cancelledPickupTimeDelta = useMemo(
        () => getTimeDelta(data?.cancelledAt ?? '', combinedDateTime?.toUTCString() ?? ''),
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [data?.cancelledAt, rideValues.pickUpDate, rideValues.pickUpTime]
    );

    const isTravellerUpdated = () => {
        const initialTravellerKeys = initialTravellerValues.current?.map(
            traveller => traveller.firstName + traveller.lastName + traveller.email + traveller.phone + traveller.isLead
        );

        const currentTravellerKeys = travellerValues.map(
            traveller => traveller.firstName + traveller.lastName + traveller.email + traveller.phone + traveller.isLead
        );

        return !_.isEqual(initialTravellerKeys, currentTravellerKeys);
    };

    const isUpdatedDisabled = useMemo(() => {
        if (!initialRideValues.current || !vehicleList || !selectedVehicle) {
            return true;
        }

        if (
            initialRideValues.current.pickUpLocation?.name !== rideValues.pickUpLocation?.name
      || initialRideValues.current.dropOffLocation?.name !== rideValues.dropOffLocation?.name
      || initialRideValues.current.pickUpDate !== rideValues.pickUpDate
      || initialRideValues.current.pickUpTime !== rideValues.pickUpTime
      || initialRideValues.current.travellers !== rideValues.travellers
      || initialRideValues.current.luggages !== rideValues.luggages
      || initialRideValues.current.flightNumber !== rideValues.flightNumber
      || initialRideValues.current.specialRequest !== rideValues.specialRequest
      || initialRideValues.current.customReference !== rideValues.customReference
      || initialVehicleValues.current?.id !== selectedVehicle?.id
      || isTravellerUpdated()
        ) {
            return false;
        }

        return true;
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [rideValues, selectedVehicle, travellerValues]);

    return (
        <>
            <RideContext.Provider
                value={{ summary: data ? mapRideToSummaryRide(data, rideValues, selectedVehicle, newEstimateDistanceAndDuration) : ({} as SummaryRideType) }}
            >
                <Grid container alignItems="center" sx={{ padding: '0 2rem 2rem 2rem' }}>
                    <Grid item xs className={classes.header} flexDirection="column">
                        <Grid item xs marginBottom="3rem" display="flex" alignItems="center" justifyContent="space-between" marginTop="4rem">
                            <Grid
                                item
                                sx={{
                                    display: 'flex',
                                    alignItems: 'center',
                                }}
                                className="tab-elements"
                            >
                                <Typography
                                    style={{
                                        color: COLORS.BLUE,
                                        fontSize: '1.5rem',
                                        fontWeight: 700,
                                        paddingRight: '1rem',
                                    }}
                                >
                                    {t('pages.singleRide.ride')} {data?.prettifiedUnid}
                                </Typography>
                                <StatusBadge
                                    status={data?.status as keyof typeof RideStatus}
                                    trackingStatus={data?.trackingStatus as keyof typeof RideTrackingStatus}
                                    pickupDateTimeUtc={data?.pickUp.departAt}
                                />
                            </Grid>
                            <Grid item>
                                <PrimaryButton
                                    onClick={handleRideUpdate}
                                    className={clsx(classes.updateButton, classes.buttons)}
                                    loading={isUpdatingRideInformation || isUpdatingVehicle || isUpdatingTravellers || isUpdatingFlightNumber}
                                    disabled={isUpdatedDisabled}
                                >
                                    {t('pages.singleRide.update')}
                                </PrimaryButton>
                                {data
                  && data.status !== getRideStatusKey(RideStatus.TO_CLOSE)
                  && data.status !== getRideStatusKey(RideStatus.CANCELLED) && (
                                    <SecondaryButton onClick={onCancelHandler} className={classes.buttons} variation="danger">
                                        {t('pages.singleRide.cancelRide')}
                                    </SecondaryButton>
                                )}
                            </Grid>
                        </Grid>
                        <Grid item xs className={classes.tabs}>
                            <Link
                                onClick={onPress}
                                href={getTabLink(ActiveElement.RIDE)}
                                className={clsx(classes.tab, isActiveClass(ActiveElement.RIDE))}
                            >
                                {t('pages.singleRide.rideInformation')}
                            </Link>
                            <Link onClick={onPress} href={getTabLink(ActiveElement.CAR)} className={clsx(classes.tab, isActiveClass(ActiveElement.CAR))}>
                                {t('pages.singleRide.carInformation')}
                            </Link>
                            <Link
                                onClick={onPress}
                                href={getTabLink(ActiveElement.TRAVELLER)}
                                className={clsx(classes.tab, isActiveClass(ActiveElement.TRAVELLER))}
                            >
                                {t('pages.singleRide.travellerInformation')}
                            </Link>
                            <Link
                                onClick={onPress}
                                href={getTabLink(ActiveElement.POLICIES)}
                                className={clsx(classes.tab, isActiveClass(ActiveElement.POLICIES))}
                            >
                                {t('pages.singleRide.policies')}
                            </Link>
                        </Grid>
                    </Grid>
                    <Grid container style={{ marginTop: '2rem' }} direction="row" columnSpacing={4}>
                        <Grid item xs={7.5}>
                            <Grid id={ActiveElement.RIDE} marginBottom="2.5rem" className="tab-elements">
                                <RideInformation
                                    rideValues={rideValues}
                                    setRideValues={setRideValues}
                                    errors={rideErrors}
                                    setErrors={setRideErrors}
                                    handleFlightNumberFinishing={handleFlightNumberFinishing}
                                    isLoadingFlight={isLoadingFlight}
                                    isErrorFlight={isErrorFlight}
                                />
                            </Grid>
                            <Grid id={ActiveElement.CAR} marginBottom="2.5rem" marginTop="2rem" className="tab-elements">
                                <Box sx={{ marginBottom: '1rem' }}>
                                    <Typography
                                        style={{
                                            fontWeight: 700,
                                            color: COLORS.BLUE_DARK,
                                        }}
                                    >
                                        {t('pages.ride.carInformation')}
                                    </Typography>
                                </Box>
                                {isLoadingAvailabilites || isRefetchingAvailabilites
                                    ? (
                                        <Spinner size={32} />
                                    )
                                    : (
                                        <VehicleInformationCards
                                            selectedVehicle={selectedVehicle}
                                            setSelectedVehicle={setSelectedVehicle}
                                            vehicleList={vehicleList}
                                            isError={isVehicleNotSelected}
                                            setIsError={() => setIsVehicleNotSelected(false)}
                                        />
                                    )}
                            </Grid>
                            <Grid id={ActiveElement.TRAVELLER} marginBottom="2.5rem" className="tab-elements">
                                <TravellerInformation
                                    values={travellerValues}
                                    setValues={setTravellerValues}
                                    errors={travellerErrors}
                                    setErrors={setTravellerErrors}
                                    maxTravellers={rideValues.travellers}
                                />
                            </Grid>
                            <Grid id={ActiveElement.POLICIES} marginBottom="2.5rem" className="tab-elements">
                                <Typography variant="h3" component="h4" className={classes.heading}>
                                    {t('pages.singleRide.polices.cancellationPolicies')}
                                </Typography>
                                {data?.customerCancellationPolicies.map(policy => (
                                    <div key={policy.id}>
                                        <Typography key={policy.id}>
                                            {t('pages.singleRide.polices.cancelled', {
                                                cancelledUnderHours: policy.cancelledUnderHours,
                                                chargePercentage: policy.chargePercentage,
                                            })}
                                        </Typography>
                                        {selectedVehicle && policy.vehicleTypes.includes(selectedVehicle.vehicleType) && (
                                            <Typography key={policy.id}>
                                                {t('pages.singleRide.polices.appliableFor', { appliableCarTypes: selectedVehicle.vehicleType })}
                                            </Typography>
                                        )}
                                    </div>
                                ))}
                                <br />
                                {data?.cancelledAt && data?.status === RideStatus.CANCELLED && (
                                    <Typography variant="h3" component="h4" className={classes.heading}>
                                        {t('pages.singleRide.polices.cancelledAt')}
                                        <Typography component="span" style={{ color: COLORS.BLACK }}>
                                            {cancelledAtDateTimeFormatted}{' '}
                                            {`(${pickupTimeDifference} ${cancelledPickupTimeDelta < 0 ? 'after' : 'before'} pickup time)`}
                                        </Typography>
                                    </Typography>
                                )}
                            </Grid>
                        </Grid>
                        {data && (
                            <Grid item xs={4.5}>
                                <RideSummaryCustomer
                                    isDownloading={isDownloadingBookingConfirmation || isDownloadingReceipt}
                                    sendBookingHandler={() => setIsSendBookingConfirmationModalOpen(true)}
                                    downloadBookingHandler={() =>
                                        downloadBookingConfirmationMutation({
                                            unid: data.unid,
                                            prettifiedUnid: data?.prettifiedUnid,
                                        })
                                    }
                                    downloadReceiptHandler={() =>
                                        downloadReceiptMutation({
                                            unid: data.unid,
                                            prettifiedUnid: data?.prettifiedUnid,
                                        })
                                    }
                                />
                                <DriverInformation
                                    rideStatus={data?.status as keyof typeof RideStatus}
                                    name={data?.driver?.name}
                                    phone={data?.driver?.phoneNumber}
                                    pickupDescription={data?.pickupDescription}
                                />
                            </Grid>
                        )}
                    </Grid>
                </Grid>
            </RideContext.Provider>
            {isUpdateRideModalOpen && <UpdateRideConfirmModal onClose={() => setIsUpdateRideModalOpen(false)} onConfirm={onUpdateHandler} />}
            {isSendBookingConfirmationModalOpen && (
                <SendBookingConfirmationModal
                    initialBookingConfirmationEmails={initialBookingConfirmationEmails}
                    isOpen={isSendBookingConfirmationModalOpen}
                    onClose={() => setIsSendBookingConfirmationModalOpen(false)}
                    rideId={rideId}
                />
            )}
        </>
    );
};

export default Ride;
