import { Theme } from '@mui/material/styles';
import { makeStyles, createStyles } from '@mui/styles';

import { TOOLBAR_HEIGHT } from '../../styles/theme';

const DRAWER_WIDTH = 200;

const CLOSED_DRAWER_WITH = 48;

const navigationStyles = makeStyles((theme: Theme) => createStyles({
    appBar: {
        zIndex: theme.zIndex.drawer,
        background: theme.palette.common.white,
        transition: theme.transitions.create(['width', 'margin'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
        [theme.breakpoints.up('sm')]: { width: `calc(100% - ${CLOSED_DRAWER_WITH}px)` },
    },
    appBarShift: {
        marginLeft: DRAWER_WIDTH,
        width: `calc(100% - ${DRAWER_WIDTH}px)`,
        transition: theme.transitions.create(['width', 'margin'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
    },
    hide: { display: 'none' },
    drawer: {
        width: DRAWER_WIDTH,
        flexShrink: 0,
        whiteSpace: 'nowrap',
        '& [class*="MuiGrid-container"]': { height: '100%' },
    },
    drawerOpen: {
        width: '100%',
        zIndex: 1200,
        transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
        [theme.breakpoints.up('sm')]: { width: DRAWER_WIDTH },
        '& [class*="MuiGrid-root"]': { flexWrap: 'nowrap' },
    },
    drawerClose: {
        width: 0,
        transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
        overflowX: 'hidden',
        [theme.breakpoints.up('sm')]: { width: CLOSED_DRAWER_WITH },
    },
    listContainer: {
        padding: 0,
        '& [class*="MuiListItem-root"]': {
            paddingTop: '12px',
            paddingBottom: '12px',
        },
    },
    toolbar: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end',
        padding: theme.spacing(0, 1),
        height: TOOLBAR_HEIGHT,
        minHeight: TOOLBAR_HEIGHT,
        '& [class*="MuiChip-root"]': {
            fontWeight: 'bold',
            color: theme.palette.common.white,
            backgroundColor: '#b712c3',
            flexGrow: 1,
        },
    },
    menuButton: { marginRight: 36 },
}));

export default navigationStyles;
