import { Box, Grid, Typography } from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { COLORS } from '../../../constants/colors';

interface SummaryListCardProps {
    title?: string;
    driverLate?: number;
    driverNoShow?: number;
    driverBehaviour?: number;
    wrongVehicle?: number;
}

const SummaryListCard = ({
    title = '',
    driverLate,
    driverNoShow,
    driverBehaviour,
    wrongVehicle,
}: SummaryListCardProps) => {
    const { t } = useTranslation();

    return (
        <Grid
            container
            style={{
                width: '100%',
                height: 'calc(100% - 1rem)',
                flexDirection: 'column',
                border: `1px solid ${COLORS.BLACK_12}`,
                borderRadius: '3px',
                marginTop: '1rem',
            }}
        >
            <Grid item padding="1rem" height="100%" display="flex" flexDirection="column" justifyContent="space-between">
                <Box display="flex" justifyContent="space-between" alignItems="center" marginBottom="1.25rem">
                    <Typography fontWeight="700" color={COLORS.SLATE_GREY}>
                        {title}
                    </Typography>
                </Box>
                <Box display="flex" flexDirection="column" paddingRight="2px">
                    <Box display="flex" justifyContent="space-between" alignItems="center">
                        <Typography color={COLORS.BLACK}>{t('pages.reports.driverLate')}</Typography>
                        <Typography color={COLORS.SLATE_GREY}>{driverLate}</Typography>
                    </Box>
                    <Box display="flex" justifyContent="space-between" alignItems="center" marginTop="0.25rem">
                        <Typography color={COLORS.BLACK}>{t('pages.reports.driverNoShow')}</Typography>
                        <Typography color={COLORS.GREEN}>{driverNoShow}</Typography>
                    </Box>
                    <Box display="flex" justifyContent="space-between" alignItems="center" marginTop="0.25rem">
                        <Typography color={COLORS.BLACK}>{t('pages.reports.driverBehaviour')}</Typography>
                        <Typography color={COLORS.GREEN}>{driverBehaviour}</Typography>
                    </Box>
                    <Box display="flex" justifyContent="space-between" alignItems="center" marginTop="0.25rem">
                        <Typography color={COLORS.BLACK}>{t('pages.reports.wrongVehicle')}</Typography>
                        <Typography color={COLORS.RED}>{wrongVehicle}</Typography>
                    </Box>
                </Box>
            </Grid>
        </Grid>
    );
};

export default SummaryListCard;
