import { useQuery } from '@apollo/react-hooks';
import React, { FunctionComponent, useState } from 'react';
import { useParams } from 'react-router-dom';

import LegacyView from '../../../../components/legacyView/LegacyView';
import config from '../../../../config';
import EditUserModalNew from '../../../users/customer/components/EditUserModalNew';
import { GET_USER, GetUser } from '../Ride.graphql';

const Ride: FunctionComponent = () => {
    const { rideId }: {rideId: string} = useParams();
    const [userId, setUserId] = useState<string|null>(null);

    const { data } = useQuery<GetUser>(GET_USER, {
        variables: { id: userId },
        skip: userId === null,
    });

    const handleCloseModal = (): void => {
        const iframe = document
            .getElementById(config.legacyIframeId) as HTMLIFrameElement;

        setUserId(null);

        // eslint-disable-next-line no-implicit-coercion
        iframe.src += '';
    };

    return (
        <>
            {!data || !data.user || userId === null
                ? null
                : (
                    <EditUserModalNew
                        user={data.user}
                        onClose={handleCloseModal}
                    />
                )}
            <LegacyView
                path={`/rides/${rideId}`}
                title={`Ride: ${rideId}`}
                onOpenUser={id => setUserId(id)}
            />
        </>
    );
};

export default Ride;
