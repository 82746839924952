import { useQuery } from '@apollo/react-hooks';
import { Grid } from '@mui/material';
import { ApolloQueryResult, FetchMoreOptions } from 'apollo-client';
import React, { FunctionComponent, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';

import Pagination from '../../components/navigation/Pagination';
import config from '../../config';
import {
    initializeZendeskStyles,
    setZendeskVerticalOffset,
} from '../../helpers/prefillZendeskConfig';
import tableStyles from '../../styles/Table';
import UnexpectedError from '../errorPages/UnexpectedError';
import Loading from '../loading/Loading';
import Document from './components/Document';
import { DocumentOutput, GET_CURRENT_USER_DOCUMENTS } from './Document.graphql';

const Documents: FunctionComponent = () => {
    const {
        maxWidthTablePage,
        tableContainer,
        tableHeading,
    } = tableStyles();

    const { t } = useTranslation();
    const history = useHistory();

    const {
        data,
        loading,
        error,
        fetchMore,
        refetch,
    } = useQuery<DocumentOutput>(GET_CURRENT_USER_DOCUMENTS, {
        variables: {
            after: null,
            before: null,
        },
        notifyOnNetworkStatusChange: true,
    });

    useEffect((): void => {
        if (!data) {
            return;
        }

        if (data.me.company.documentsPage.totalCount > config.paginationLimit) {
            setZendeskVerticalOffset(50);
        } else {
            initializeZendeskStyles();
        }
    }, [data]);

    if (loading) {
        return <Loading framed={true}/>;
    }

    if (error || !data) {
        return <UnexpectedError onRetry={() => refetch()}/>;
    }

    const handleNextClick = (): Promise<ApolloQueryResult<DocumentOutput>>|null => {
        const { nextCursor } = data.me.company.documentsPage;

        if (nextCursor !== null) {
            history.push({ search: `?after=${nextCursor}` });
        }

        return fetchMore({
            variables: {
                after: nextCursor,
                before: null,
            },
            updateQuery,
        });
    };

    const handlePreviousClick = (): Promise<ApolloQueryResult<DocumentOutput>> | null => {
        const { previousCursor } = data.me.company.documentsPage;

        if (previousCursor !== null) {
            history.push({ search: `?before=${previousCursor}` });
        }

        return fetchMore({
            variables: {
                before: previousCursor,
                after: null,
            },
            updateQuery,
        });
    };

    const handleBackToStartClick = ():
    Promise<ApolloQueryResult<DocumentOutput>>
    | null => {
        history.push({ search: '' });
        return fetchMore({
            variables: {
                before: null,
                after: null,
            },
            updateQuery,
        });
    };

    const updateQuery: FetchMoreOptions<DocumentOutput>['updateQuery'] = (
        previousResult,
        { fetchMoreResult }
    ) => {
        if (!fetchMoreResult) {
            return previousResult;
        }

        return fetchMoreResult;
    };

    const {
        items,
        totalCount,
        nextCursor,
        previousCursor,
    } = data.me.company.documentsPage;

    return (
        <Grid
            container
            className={`${tableContainer} ${maxWidthTablePage}`}
        >
            <Grid
                container
                className={tableHeading}
                style={{ marginBottom: '1em' }}
            >
                {t('pages.users.fields.name')}
            </Grid>
            {items.map((item, idx) => (
                <Document
                    key={`${item.url}-${idx}`}
                    label={item.label}
                    url={item.url}
                />
            ))}
            {totalCount > config.paginationLimit
                ? (
                    <Pagination
                        onNextClick={handleNextClick}
                        onPreviousClick={handlePreviousClick}
                        hasNext={nextCursor === null}
                        hasPrevious={previousCursor === null}
                        onBackToStartClick={handleBackToStartClick}
                    />
                )
                : null
            }
        </Grid>
    );
};

export default Documents;
