import { MessageDialog } from '@get-e/react-components';
import { Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { COLORS } from '../../../constants/colors';

const useStyles = makeStyles({
    confirmButton: {
        background: COLORS.RED,
        '&:hover': { background: COLORS.RED_WARNING },
    },
    closeButton: { marginLeft: '.5rem' },
});

const DeleteDriverModal = ({ onClose }: {onClose: () => void}) => {
    const { t } = useTranslation();
    const classes = useStyles();

    const handleClick = () => {};

    return (
        <MessageDialog
            onClose={onClose}
            onConfirm={handleClick}
            title={t('modals.deleteDriver.title')}
            isConfirmButtonDisplayed
            closeButtonLabel= {t('buttonName.cancel')}
            confirmButtonLabel={t('buttonName.delete')}
            closeButtonType="secondary"
            confirmButtonClassName={classes.confirmButton}
            closeButtonClassName={classes.closeButton}
            areButtonsFullWidth
        >
            <Typography
                style={{
                    fontWeight: 700,
                    paddingTop: '1rem',
                }}
            >
                {t('modals.deleteDriver.description')}
            </Typography>
            <Typography
                style={{
                    paddingTop: '0.5rem',
                    paddingBottom: '1rem',
                }}
            >
                {t('modals.deleteDriver.descriptionDetailed')}
            </Typography>
        </MessageDialog>
    );
};

export default DeleteDriverModal;
