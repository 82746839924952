import { DateField, Select, SelectOption, Tab, Tabs } from '@get-e/react-components';
import { Grid, Typography, useMediaQuery } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import React, { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';

import { PERIODS } from '../../constants';
import { COLORS } from '../../constants/colors';
import { DATE_FORMATS } from '../../constants/dateFormats';
import { REPORTS, REPORTS_DRIVER_ANALYSIS, REPORTS_REVIEWS, REPORTS_SITUATIONS, REPORTS_SUMMARY } from '../../constants/urlPaths';
import theme from '../../styles/theme';
import DriverAnalysisTab from './components/tabs/DriverAnalysisTab';
import ReviewsTab from './components/tabs/ReviewsTab';
import SituationsTab from './components/tabs/SituationsTab';
import SummaryTab from './components/tabs/SummaryTab';

const useStyles = makeStyles({
    mainContainer: {
        justifyContent: 'space-between',
        alignItems: 'flex-end',
        padding: '2rem',
    },
    pageHeaderWrapper: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        color: COLORS.BLACK,
    },
    pageHeader: {
        color: COLORS.BLUE,
        fontSize: '1.5rem',
        fontWeight: 700,
    },
});

export enum ReportsActiveTab {
    Summary,
    Situations,
    DriverAnalysis,
    Reviews,
}

const getActiveTab = (url: string): ReportsActiveTab => {
    switch (url) {
        case REPORTS:
        case REPORTS_SUMMARY:
            return ReportsActiveTab.Summary;
        case REPORTS_SITUATIONS:
            return ReportsActiveTab.Situations;
        case REPORTS_DRIVER_ANALYSIS:
            return ReportsActiveTab.DriverAnalysis;
        case REPORTS_REVIEWS:
            return ReportsActiveTab.Reviews;
        default:
            throw new Error(`Unhandled URL "${url}"`);
    }
};

const Reports = () => {
    const history = useHistory();
    const classes = useStyles();
    const isSmallDevice = useMediaQuery(theme.breakpoints.down('md'));
    const { pathname } = history.location;
    const { t } = useTranslation();
    const [period, setPeriod] = useState(PERIODS.LAST_7_DAYS);
    const [month, setMonth] = useState<Date | null>(null);

    const activeTab = useMemo(() => getActiveTab(pathname), [pathname]);

    const handlePeriods = (newValue: PERIODS) => {
        setPeriod(newValue);
        newValue !== period && onFilterChange();
    };

    const handleMonth = (newValue: Date | null) => {
        setMonth(newValue);
        newValue !== month && onFilterChange();
    };

    const onFilterChange = () => {};

    return (
        <Grid container className={classes.mainContainer}>
            <Grid container className={classes.pageHeaderWrapper}>
                <Grid item xs>
                    <Typography className={classes.pageHeader}>{t('pages.reports.qualityReport')}</Typography>
                </Grid>
                <Grid container marginTop="2rem">
                    <Grid item xs={12} md={3} xl={3}>
                        <Select label={t('selectPeriod')} value={period} onChangeValue={newValue => handlePeriods(newValue)}>
                            {Object.keys(PERIODS).map(periodValue => (
                                <SelectOption key={periodValue} value={PERIODS[periodValue as keyof typeof PERIODS]}>
                                    {PERIODS[periodValue as keyof typeof PERIODS]}
                                </SelectOption>
                            ))}
                        </Select>
                    </Grid>
                    <Grid item xs={12} md={3} xl={3} marginLeft={isSmallDevice ? 0 : '2rem'}>
                        <DateField
                            views={['month']}
                            label={t('selectMonth')}
                            format={DATE_FORMATS.MMMM}
                            value={month}
                            onChange={handleMonth}
                        />
                    </Grid>
                </Grid>
            </Grid>
            <Grid item xs>
                <Tabs value={activeTab}>
                    <Tab
                        label={t('pages.reports.summary')}
                        url={REPORTS_SUMMARY}
                        index={ReportsActiveTab.Summary}
                        selectedIndex={activeTab}
                    />
                    <Tab
                        label={t('pages.reports.situations')}
                        url={REPORTS_SITUATIONS}
                        index={ReportsActiveTab.Situations}
                        selectedIndex={activeTab}
                    />
                    <Tab
                        label={t('pages.reports.driverAnalysis')}
                        url={REPORTS_DRIVER_ANALYSIS}
                        index={ReportsActiveTab.DriverAnalysis}
                        selectedIndex={activeTab}
                    />
                    <Tab
                        label={t('pages.reports.reviews')}
                        url={REPORTS_REVIEWS}
                        index={ReportsActiveTab.Reviews}
                        selectedIndex={activeTab}
                    />
                </Tabs>
            </Grid>
            <Grid container>
                <Grid item display={activeTab === ReportsActiveTab.Summary ? 'initial' : 'none'} width="100%">
                    <SummaryTab />
                </Grid>
                <Grid item xs display={activeTab === ReportsActiveTab.Situations ? 'initial' : 'none'}>
                    <SituationsTab />
                </Grid>
                <Grid item xs display={activeTab === ReportsActiveTab.Reviews ? 'initial' : 'none'}>
                    <ReviewsTab />
                </Grid>
                <Grid item display={activeTab === ReportsActiveTab.DriverAnalysis ? 'initial' : 'none'} width="100%">
                    <DriverAnalysisTab />
                </Grid>
            </Grid>
        </Grid>
    );
};

export default Reports;
