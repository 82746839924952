import { useQuery } from 'react-query';

import { USE_SEARCH_LOCATION } from '../../../../constants/queryKeys';
import { listSearchLocationOptions } from '../../../../services/ride';

export function useSearchLocation(search: string) {
    const { data: searchOptions, isLoading } = useQuery([USE_SEARCH_LOCATION + search], () => listSearchLocationOptions(search), {
        keepPreviousData: true,
        refetchOnWindowFocus: false,
        retry: 1,
        cacheTime: 500,
        enabled: Boolean(search),
    });

    return {
        searchOptions,
        isLoading,
    };
}
