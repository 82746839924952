import { useMemo } from 'react';
import { useQuery } from 'react-query';

import { USE_NEW_FLIGHT_DETAILS } from '../../../../constants/queryKeys';
import { getFlightNumberDetails } from '../../../../services/ride';
import { FlightDetails, FlightType } from '../../../rides/customer/api/types';
import { FlightDetailsVariables } from './types';

export function useFlightNumberDetails(variables: Partial<FlightDetailsVariables>) {
    const { data, isLoading, refetch, isError } = useQuery(
        [USE_NEW_FLIGHT_DETAILS],
        () => getFlightNumberDetails(variables),
        {
            keepPreviousData: true,
            refetchOnWindowFocus: false,
            retry: 1,
            enabled: false,
        },
    );

    return useMemo(() => {
        const response = data?.data?.flights?.[0];

        const flightDetails: FlightDetails = {
            number: response?.id.toString() ?? '',
            type: 'Arrival' as FlightType,
            departure: {
                datetimeLocal: response?.dep_datetime_local ?? '',
                datetimeZulu: response?.dep_datetime_zulu ?? '',
                iata: response?.departure.iata ?? '',
                name: response?.departure.name ?? '',
            },
            arrival: {
                datetimeLocal: response?.arr_datetime_local ?? '',
                datetimeZulu: response?.arr_datetime_zulu ?? '',
                iata: response?.arrival.iata ?? '',
                name: response?.arrival.name ?? '',
            },
        };

        return {
            flightDetails,
            isLoading,
            refetch,
            isError,
        };
    }, [data?.data?.flights, isLoading, refetch, isError]);
}
