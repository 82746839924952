import List from '@material-ui/core/List';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
import { Collapse, ListItem, ListItemIcon, ListItemText } from '@mui/material';
import React, { FunctionComponent, ReactNode } from 'react';

interface Props {
    children: ReactNode;
    onClick: () => void;
    text: string;
    isOpen: boolean;
    icon: JSX.Element;
}

const CollapseMenu: FunctionComponent<Props> = props => (
    <>
        <ListItem button onClick={() => props.onClick()}>
            <ListItemIcon>
                {props.icon}
            </ListItemIcon>
            <ListItemText primary={props.text} />
            {props.isOpen ? <ExpandLess /> : <ExpandMore />}
        </ListItem>

        <Collapse
            in={props.isOpen}
            timeout="auto"
            unmountOnExit
        >
            <List component="div" disablePadding>
                {props.children}
            </List>
        </Collapse>
    </>
);

export default CollapseMenu;
