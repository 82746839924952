const ROUTE_PREFIX = 'portal-api/';

const RIDE_ID_PARAM = 'unid';

export const RIDES = `${ROUTE_PREFIX}trips`;

export const USER_PROFILE = `${ROUTE_PREFIX}users/profile`;

export const ACCEPT_INVITE = '/accept-invite';

export const RIDE = `${ROUTE_PREFIX}trips/${RIDE_ID_PARAM}`;

export const RIDE_CHANGE_INFORMATION = `${RIDE}/change-information`;

export const RIDE_CHANGE_PASSENGERS = `${RIDE}/change-passengers`;

export const RIDE_AVAILABILITIES = `${ROUTE_PREFIX}availabilities`;

export const SEARCH_LOCATION = `${ROUTE_PREFIX}locations/search`;

export const RIDE_SWAP_ESTIMATE_OPTION = `${RIDES}/${RIDE_ID_PARAM}/swap-estimate-option`;

export const DOWNLOAD_BOOKING_CONFIRMATION = `${RIDE}/get-booking-confirmation-pdf`;

export const DOWNLOAD_RECEIPT_ROUTE = `${RIDE}/get-receipt-pdf`;

const FLIGHT_NUMBER = 'flight-number';
const DEPARTURE_DATE = 'departure-date';

export const RIDE_FLIGHT_NUMBER_DETAILS = `/api/v1/flights/layover/${FLIGHT_NUMBER}/${DEPARTURE_DATE}`;

export const EXPORT_RIDES_TO_EXCEL = `${RIDES}/export-to-excel`;

export const getRideRoute = (id: string) =>
    RIDE.replace(RIDE_ID_PARAM, id);

export const getCancelRideRoute = (id: string) =>
    `${RIDE.replace(RIDE_ID_PARAM, id)}/cancel`;

export const getUpdateRideInformation = (id: string) =>
    RIDE_CHANGE_INFORMATION.replace(RIDE_ID_PARAM, id);

export const getUpdateTravellerInformation = (id: string) =>
    RIDE_CHANGE_PASSENGERS.replace(RIDE_ID_PARAM, id);

export const getUpdateVehicleRideRoute = (id: string) =>
    RIDE_SWAP_ESTIMATE_OPTION.replace(RIDE_ID_PARAM, id);

export const getUpdateFlightNumberRoute = (id: string) =>
    `${RIDE.replace(RIDE_ID_PARAM, id)}/change-flight`;

export const getFlightNumberDetailsRoute = (flightNumber: string, departureDate: string) =>
    `${RIDE_FLIGHT_NUMBER_DETAILS.replace(FLIGHT_NUMBER, flightNumber).replace(DEPARTURE_DATE, departureDate)}`;

export const getSendBookingConfirmationEmailRoute = (id: string) =>
    `${RIDE.replace(RIDE_ID_PARAM, id)}/send-booking-confirmation-email`;

export const getDownloadBookingConfirmationRoute = (id: string) =>
    `${DOWNLOAD_BOOKING_CONFIRMATION.replace(RIDE_ID_PARAM, id)}`;

export const getDownloadReceiptRoute = (id: string) =>
    `${DOWNLOAD_RECEIPT_ROUTE.replace(RIDE_ID_PARAM, id)}`;
