
export enum UserRole {
    Customer,
    Supplier,
    BackOffice,
    CustomerAndSupplier
}

interface UserProps {
    isBackOffice: boolean;
    isCustomer: boolean;
    isSupplier: boolean;
}

export default function getUserRole(user: UserProps): UserRole {
    if (user.isBackOffice) {
        return UserRole.BackOffice;
    }

    if (user.isCustomer && user.isSupplier) {
        return UserRole.CustomerAndSupplier;
    }

    if (user.isCustomer) {
        return UserRole.Customer;
    }

    if (user.isSupplier) {
        return UserRole.Supplier;
    }

    throw new Error('User must log in');
}
