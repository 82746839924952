import gql from 'graphql-tag';

export interface GetRideIdInput {
    id: string;
}

export interface GetRideIdOutput {
    ride: {
        rideNumber: string;
    } | null;
}

export const GET_RIDE_NUMBER = gql`
    query GET_RIDE_NUMBER(
        $id: ID!,  
    ){
        ride(
            id: $id,
        ) {
            rideNumber
        }
    } 
`;
