import { PortalLocale } from '../context/LocaleContext';
import findZendeskLocale from './findZendeskLocale';

const prefillZendeskUser = (
    name: string,
    email: string,
): void => {
    if (!window.zE) {
        throw new Error('Zendesk chat should be loaded');
    }

    window.zE('webWidget', 'prefill', {
        name: {
            value: name,
            readOnly: true,
        },
        email: {
            value: email,
            readOnly: true,
        },
    });
};

const setZendeskLocale = (locale: PortalLocale): void => {
    if (!window.zE) {
        throw new Error('Zendesk chat should be loaded');
    }

    window.zE('webWidget', 'setLocale', findZendeskLocale(locale));
};

const openZendeskLiveChat = (): void => {
    if (!window.zE) {
        throw new Error('Zendesk chat should be loaded');
    }

    window.zE('webWidget', 'open');
};

const initializeZendeskStyles = (): void => {
    if (!window.zE) {
        throw new Error('Zendesk chat should be loaded');
    }

    window.zE('webWidget', 'updateSettings', {
        webWidget: {
            zIndex: 1100,
            offset: {
                mobile: {
                // Prevents widget from covering mobile pagination
                    vertical: '0px',
                },
            },
        },
    });
};

const setZendeskVerticalOffset = (offset: number): void => {
    if (!window.zE) {
        throw new Error('Zendesk chat should be loaded');
    }

    window.zE('webWidget', 'updateSettings', {
        webWidget: {
            offset: {
                mobile: {
                // Prevents widget from covering mobile pagination
                    vertical: `${offset}px`,
                },
            },
        },
    });
};

export {
    prefillZendeskUser,
    setZendeskLocale,
    openZendeskLiveChat,
    setZendeskVerticalOffset,
    initializeZendeskStyles,
};
