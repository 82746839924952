import { BlockedPeriod } from '../api/types';

export const blockedPeriods: BlockedPeriod[] = [
    {
        id: 1,
        start: '2022-11-14 15:00:32',
        end: '2022-11-14 15:00:33',
        description: 'Description 1',
        applicableOnDays: ['THURSDAY', 'FRIDAY'],
        applicableStartTime: '12:00:30',
        applicableEndTime: '12:00:31',
    },
    {
        id: 2,
        start: '2022-11-16 18:00:32',
        end: '2022-11-16 19:00:33',
        description: 'Description 2',
        applicableOnDays: ['MONDAY', 'THURSDAY', 'WEDNESDAY', 'FRIDAY', 'SATURDAY', 'SUNDAY'],
        applicableStartTime: '14:00:30',
        applicableEndTime: '14:00:31',
    },
    {
        id: 3,
        start: '2023-11-16 18:00:32',
        end: '2023-12-16 19:00:33',
        description: 'Description 3',
        applicableOnDays: null,
        applicableStartTime: null,
        applicableEndTime: null,
    },
    {
        id: 4,
        start: '2023-07-16 07:00:32',
        end: '2023-09-16 16:00:33',
        description: 'Description 4',
        applicableOnDays: ['MONDAY'],
        applicableStartTime: '09:00:00',
        applicableEndTime: '18:00:31',
    },
];
