import { EffectCallback } from 'react';

const afterDelay = (
    onDelayPassed: () => void,
    delay: number,
): ReturnType<EffectCallback> => {
    const wait = setTimeout(() => {
        onDelayPassed();
    }, delay);

    return (): void => {
        clearTimeout(wait);
    };
};

export default afterDelay;
