import { PortalLocale } from '../context/LocaleContext';

export type LegacyViewLocale = 'en' | 'es';

const findLegacyLocale = (value: PortalLocale): LegacyViewLocale => {
    switch (value) {
        case 'en-GB':
            return 'en';
        default:
            throw new Error(`${value as string} is unsupported`);
    }
};

export default findLegacyLocale;
