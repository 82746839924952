import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import LuggageOutlinedIcon from '@mui/icons-material/LuggageOutlined';
import PersonOutlineOutlinedIcon from '@mui/icons-material/PersonOutlineOutlined';
import { Card, CardContent, Grid, Typography, IconButton, Box } from '@mui/material';
import { makeStyles } from '@mui/styles';
import React from 'react';
import { useTranslation } from 'react-i18next';

import IconedData from '../../../components/iconedData/IconedData';
import { COLORS } from '../../../constants/colors';

const useStyles = makeStyles({
    image: {
        maxHeight: '100%',
        maxWidth: '100%',
    },
    imageContainer: {
        color: COLORS.SLATE_GREY,
        margin: '-.5rem',
        width: '2rem',
    },
    cardContainer: {
        marginBottom: '1rem',
        cursor: 'pointer',
        height: 'auto',
        margin: '0',
        overflow: 'hidden',
        transition: 'height 1.2s ease-in-out, margin 1.2s ease-in-out',
        '&:hover': {
            backgroundColor: COLORS.EXTRA_LIGHT_GRAY,
            boxShadow: '0px 12px 24px -4px rgba(148, 158, 171, 0.12), 0px 0px 2px rgba(148, 158, 171, 0.2)',
        },
    },
    cardSelected: { backgroundColor: COLORS.EXTRA_LIGHT_GRAY },
    cardHidden: {
        height: 0,
        margin: 0,
        padding: 0,
        border: 'none',
    },
});

interface VehicleInformationCardProps {
    vehicleType?: string;
    vehicleImage?: string;
    travellers?: string;
    luggages?: string;
    price?: string;
    priceCurrancy?: string;
    isVehicleSelected?: boolean;
    onCardClick?: () => void;
    onEditClick?: () => void;
    isCardHidden?: boolean;
}

const VehicleInformationCard = ({
    vehicleType = 'Comfort sedan',
    vehicleImage,
    travellers = '0',
    luggages = '0',
    price = '0',
    priceCurrancy = 'eur',
    onEditClick,
    onCardClick,
    isVehicleSelected,
    isCardHidden,
}: VehicleInformationCardProps) => {
    const classes = useStyles();
    const { t } = useTranslation();

    return (
        <Card
            variant="outlined"
            className={`${classes.cardContainer} ${isCardHidden ? classes.cardHidden : ''}`}
            onClick={onCardClick}
        >
            <CardContent>
                <Grid container flex="row" justifyContent="space-between">
                    <Grid item xs={5}>
                        <Typography
                            sx={{
                                color: COLORS.BLUE_DARK,
                                paddingBottom: '.6rem',
                            }}
                        >
                            {vehicleType}
                        </Typography>
                        <Box
                            sx={{
                                display: 'flex',
                                flexDirection: 'column',
                                gap: '2rem',
                            }}
                        >
                            <Box
                                sx={{
                                    display: 'flex',
                                    flexDirection: 'row',
                                    gap: '1rem',
                                }}
                            >
                                <IconedData icon={PersonOutlineOutlinedIcon} data={travellers} dataColor={COLORS.SLATE_GREY} />
                                <IconedData icon={LuggageOutlinedIcon} data={luggages} dataColor={COLORS.SLATE_GREY} />
                            </Box>
                            <Box
                                sx={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                }}
                            >
                                <Typography
                                    variant="h5"
                                    style={{
                                        color: COLORS.ORANGE,
                                        fontWeight: 700,
                                        lineHeight: '1.25rem',
                                    }}
                                >
                                    {priceCurrancy} {price}
                                </Typography>
                                <Typography
                                    variant="caption"
                                    style={{
                                        color: COLORS.SLATE_GREY,
                                        lineHeight: '.9rem',
                                        paddingTop: '.5rem',
                                    }}
                                >
                                    {t('pages.singleRide.priceDescription')}
                                </Typography>
                            </Box>
                        </Box>
                    </Grid>
                    <Grid item display="flex">
                        <img src={vehicleImage} alt="Vehicle" className={classes.image} />
                    </Grid>
                    <Box className={classes.imageContainer}>
                        {isVehicleSelected && (
                            <IconButton onClick={onEditClick}>
                                <EditOutlinedIcon fontSize="small" />
                            </IconButton>
                        )}
                    </Box>
                </Grid>
            </CardContent>
        </Card>
    );
};

export default VehicleInformationCard;
