import { Autocomplete } from '@get-e/react-components';
import FlightIcon from '@mui/icons-material/Flight';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import { Box, Typography } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import React from 'react';

import { COLORS } from '../../../constants/colors';
import { InputError } from '../../../helpers/inputValidation/InputError';
import { SearchLocation } from '../customer/api/types';
import { useSearchLocation } from '../customer/api/useSearchLocation';

const useStyles = makeStyles({
    centerContainer: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        '& > div > span': { fontWeight: 700 },
    },
    boxItem: {
        padding: '.75rem 1rem',
        '&:hover': { backgroundColor: COLORS.LIGHT_GRAY },
    },
    secondaryText: { color: COLORS.DARK_GRAY },
});

interface SearchLocationProps {
    error: InputError | null;
    helperText?: string;
    inputValue: string;
    setInputValue: (inputValue: string) => void;
    address: SearchLocation | null;
    setAddress: (address: SearchLocation | null) => void;
    label: string;
}

export const SearchLocationComponent = ({
    error = null,
    helperText,
    inputValue = '',
    setInputValue,
    address = null,
    setAddress,
    label = '',
}: SearchLocationProps) => {
    const { searchOptions = [], isLoading } = useSearchLocation(inputValue);
    const classes = useStyles();

    return (
        <Autocomplete
            required
            filterOptions={(option): SearchLocation[] => option }
            label={label}
            loading={isLoading}
            options={searchOptions}
            getOptionLabel={(option: SearchLocation) => option.name}
            isError={error !== null}
            helperText={helperText}
            renderOption={(prop, option: SearchLocation) => {
                const renderIcon = option.type === 'AIRPORT' ? FlightIcon : LocationOnIcon;

                return (
                    <Box
                        component="li"
                        {...prop}
                        style={{
                            display: 'flex',
                            flexDirection: 'row',
                            alignItems: 'center',
                        }}
                        className={classes.boxItem}
                    >
                        <Box
                            component={renderIcon}
                            sx={{
                                color: COLORS.BLUE_DARK,
                                mr: 2,
                            }}
                        />
                        <Box>
                            <Typography
                                sx={{ marginRight: '1rem' }}
                            >
                                {option.name}
                            </Typography>
                            <Typography
                                className={classes.secondaryText}
                            >
                                {option?.city} {option?.country && `, ${option?.country}`}
                            </Typography>
                        </Box>
                    </Box>
                );
            }}
            value={address}
            onChange={(_, newValue) => {
                setAddress?.(newValue ?? null);
            }}
            onInputChange={(_, newInputValue) => {
                setInputValue?.(newInputValue);
            }}
        />
    );
};
