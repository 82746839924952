import {
    Form,
    Modal,
    PrimaryButton,
    SecondaryButton,
    TextField,
} from '@get-e/react-components';
import { Box, Grid, Typography, useMediaQuery } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import React, {
    ChangeEvent,
    useMemo,
    useState,
} from 'react';
import { useTranslation } from 'react-i18next';

import { COLORS } from '../../../../constants/colors';
import { InputError } from '../../../../helpers/inputValidation/InputError';
import { IS_DECIMAL_NUMBER } from '../../../../helpers/inputValidation/regexRules';
import { Validated } from '../../../../helpers/inputValidation/Validator';
import allValid from '../../../../helpers/inputValidation/validators/allValid';
import isFilledString from '../../../../helpers/inputValidation/validators/isFilledString';
import theme from '../../../../styles/theme';

const useStyles = makeStyles({
    container: { margin: '0 auto' },
    containerMobile: {
        padding: 0,
        width: '100%',
        margin: '0 auto',
    },
    heading: {
        marginBottom: '2rem',
        color: COLORS.BLUE,
        fontSize: '1.5rem',
    },
    formField: { marginBottom: '1.5rem' },
    buttonAdd: { width: '150px' },
    buttonBack: {
        marginLeft: '1rem',
        width: '150px',
    },
});

export interface ReferencesAndremarksData {
    referenceNumber: string;
    minutesWaiting: string;
    minutesCosts: string;
    parkingCosts: string;
    otherCosts: string;
    remarks: string;
}

interface ValidatedFormField {
    [key: string]: Validated<string, InputError>;
}
interface ViewRideRemarksModalProps {
    isModalOpen: boolean;
    onClose: () => void;
    supplierReference?: string;
}

const ReferencesAndRemarksModal = ({
    isModalOpen,
    onClose,
    supplierReference,
}: ViewRideRemarksModalProps) => {
    const { t } = useTranslation();
    const classes = useStyles();
    const isMobile = useMediaQuery(theme.breakpoints.down('md'));
    const isSmallDevice = useMediaQuery(theme.breakpoints.down('sm'));
    const [bookingReference, setBookingReference] = useState(supplierReference);
    const [extraWaitingTime, setExtraWaitingTime] = useState<string | null>('');
    const [waitingTimeCosts, setWaitingTimeCosts] = useState<string | null>('');
    const [parkingTollCosts, setParkingTollCosts] = useState<string | null>('');
    const [otherCosts, setOtherCosts] = useState<string | null>('');
    const [remarks, setRemarks] = useState<string>('');
    const [remarksError, setRemarksError] = useState<InputError | null>(null);
    const [isSaving, setIsSaving] = useState(false);

    const isRemarksRequired = useMemo(() => {
        if (
            bookingReference
        || extraWaitingTime
        || waitingTimeCosts
        || parkingTollCosts
        || otherCosts
        ) {
            return false;
        }

        return true;
    }, [
        bookingReference,
        extraWaitingTime,
        waitingTimeCosts,
        parkingTollCosts,
        otherCosts,
    ]);

    const handleExtraWaitingTime = (
        event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
    ) => {
        setExtraWaitingTime(Math.floor(Number(event.target.value)).toString());
    };

    const handleWaitingTimeCosts = (
        event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
    ) => {
        if (event.target.value === '' || IS_DECIMAL_NUMBER.test(event.target.value)) {
            setWaitingTimeCosts(event.target.value);
        }
    };

    const handleParkingTollCosts = (
        event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
    ) => {
        if (event.target.value === '' || IS_DECIMAL_NUMBER.test(event.target.value)) {
            setParkingTollCosts(event.target.value);
        }
    };

    const handleOtherCosts = (
        event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
    ) => {
        if (event.target.value === '' || IS_DECIMAL_NUMBER.test(event.target.value)) {
            setOtherCosts(event.target.value);
        }
    };

    const handleSubmit = () => {
        if (validateFormFields()) {
            // eslint-disable-next-line @typescript-eslint/no-unused-vars
            const postData = {
                referenceNumber: bookingReference,
                minutesWaiting: extraWaitingTime,
                minutesCosts: waitingTimeCosts,
                parkingCosts: parkingTollCosts,
                otherCosts,
                remarks,
            } as ReferencesAndremarksData;

            try {
                setIsSaving(true);

                // Call API to submit references and remarks using postData
            } catch (error) {
                setIsSaving(false);
            } finally {
                onClose();
            }
        }
    };

    const validateFormFields = (): boolean => {
        const validated = {} as ValidatedFormField;

        if (isRemarksRequired) {
            validated.remarks = isFilledString(remarks, InputError.Empty);
        }

        if (!allValid(validated)) {
            setRemarksError(
                validated.remarks.isValid ? null : validated.remarks.error
            );

            return false;
        }

        return true;
    };

    return (
        <Modal
            open={isModalOpen}
            onClose={onClose}
            maxWidth="md"
        >
            <Form
                onSubmit={handleSubmit}
                className={isMobile ? classes.containerMobile : classes.container}
            >
                <Typography variant="h2" component="h2" className={classes.heading}>
                    {t('modals.referencesAndRemarks.addReferencesAndRemarks')}
                </Typography>
                <Typography
                    sx={{
                        color: COLORS.BLUE_DARK,
                        fontWeight: '700',
                        paddingBottom: '1rem',
                    }}
                >
                    {t('pages.rides.referenceNumber')}
                </Typography>
                <TextField
                    className={classes.formField}
                    label={t('modals.referencesAndRemarks.yourBookingReference')}
                    value={bookingReference}
                    onChange={event => {
                        setBookingReference(event.target.value);
                    }}
                    disabled={isSaving}
                />
                <Grid
                    item
                    sx={{
                        paddingLeft: '0',
                        paddingBottom: '2rem',
                    }}
                >
                    <Typography
                        sx={{
                            color: COLORS.BLUE_DARK,
                            fontWeight: '700',
                            paddingBottom: '1rem',
                        }}
                    >
                        {t('modals.referencesAndRemarks.additionalCosts')}
                    </Typography>
                    <Typography
                        sx={{
                            paddingBottom: '0.25rem',
                            color: COLORS.BLACK,
                        }}
                    >
                        {t('modals.referencesAndRemarks.additionalCostsDescription')}
                    </Typography>
                    <Typography sx={{ color: COLORS.BLACK }}>
                        {t('modals.referencesAndRemarks.enteredAmpountsDescription')}
                    </Typography>
                </Grid>
                <Grid
                    container
                    rowSpacing={1}
                    columnSpacing={{
                        xs: 1,
                        sm: 2,
                        md: 3,
                    }}
                >
                    <Grid item xs={12} md={6} sx={{ paddingTop: '0' }}>
                        <TextField
                            className={classes.formField}
                            type="number"
                            name="extraWaitingTime"
                            label={t('modals.referencesAndRemarks.extraWaitingTime')}
                            value={extraWaitingTime}
                            onChange={event => handleExtraWaitingTime(event)}
                            disabled={isSaving}
                        />
                    </Grid>
                    <Grid item xs={12} md={6} sx={{ paddingTop: '0' }}>
                        <TextField
                            className={classes.formField}
                            type="number"
                            name="waitingTimeCosts"
                            label={t('modals.referencesAndRemarks.waitingTimeCosts')}
                            value={waitingTimeCosts}
                            onChange={event => handleWaitingTimeCosts(event)}
                            disabled={isSaving}
                        />
                    </Grid>
                    <Grid item xs={12} md={6} sx={{ paddingTop: '0' }}>
                        <TextField
                            className={classes.formField}
                            type="number"
                            name="parkingTollCosts"
                            label={t('modals.referencesAndRemarks.parkingTollCosts')}
                            value={parkingTollCosts}
                            onChange={event => handleParkingTollCosts(event)}
                            disabled={isSaving}
                        />
                    </Grid>
                    <Grid item xs={12} md={6} sx={{ paddingTop: '0' }}>
                        <TextField
                            className={classes.formField}
                            type="number"
                            name="otherCosts"
                            label={t('modals.referencesAndRemarks.otherCosts')}
                            value={otherCosts}
                            onChange={event => handleOtherCosts(event)}
                            disabled={isSaving}
                        />
                    </Grid>
                </Grid>
                <TextField
                    className={classes.formField}
                    label={t('pages.rides.remarks')}
                    value={remarks}
                    onChange={event => {
                        setRemarks(event.target.value);
                        setRemarksError(null);
                    }}
                    InputProps={{
                        minRows: '3',
                        multiline: true,
                    }}
                    disabled={isSaving}
                    error={isRemarksRequired && remarksError !== null}
                    helperText={isRemarksRequired && remarksError ? t(remarksError) : undefined}
                    required={isRemarksRequired}
                />
                <Box display="flex" justifyContent={isSmallDevice ? 'space-between' : 'flex-start'}>
                    <PrimaryButton
                        onClick={handleSubmit}
                        submitsForm
                        loading={isSaving}
                        disabled={isSaving}
                        className={classes.buttonAdd}
                    >
                        {t('buttonName.add')}
                    </PrimaryButton>
                    <SecondaryButton
                        onClick={onClose}
                        className={classes.buttonBack}
                    >
                        {t('buttonName.back')}
                    </SecondaryButton>
                </Box>
            </Form>
        </Modal>
    );
};

export default ReferencesAndRemarksModal;
