import { RideResponse, RidesVariables } from '../pages/rides/customer/api/types';
import apiClient from './api';
import { RIDES } from './routes';

export const listRides = async (
    variables: Partial<RidesVariables>,
): Promise<RideResponse[]> => {
    const { data } = await apiClient.get<RideResponse[]>(RIDES, { params: variables });

    return data;
};
