import { PrimaryButton } from '@get-e/react-components';
import CalendarTodayOutlinedIcon from '@mui/icons-material/CalendarTodayOutlined';
import CloudDownloadOutlinedIcon from '@mui/icons-material/CloudDownloadOutlined';
import {
    Box,
    TextField,
    Typography,
    Grid,
    InputAdornment,
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import {
    DateRange,
    DateRangePicker,
    LocalizationProvider,
} from '@mui/x-date-pickers-pro';
import { AdapterMoment } from '@mui/x-date-pickers-pro/AdapterMoment';
import clsx from 'clsx';
import { Moment } from 'moment';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useMutation } from 'react-query';

import { COLORS } from '../../constants/colors';
import { DATE_FORMATS } from '../../constants/dateFormats';
import { InputError } from '../../helpers/inputValidation/InputError';
import isValidDate from '../../helpers/inputValidation/validators/isValidDate';
import typographyStyles from '../../styles/Typography';
import exportRidesToExcel from '../rides/customer/api/exportRidesToExcel';

const useStyles = makeStyles({ exportButton: { width: '150px' } });

export interface CustomerExportProps {
    startDate?: Moment;
    endDate?: Moment;
}

interface CustomerExportFormErrors {
    startDate: InputError | null;
    endDate: InputError | null;
}

const CustomerExport = ({ startDate, endDate }: CustomerExportProps) => {
    const [dateRange, setDateRange] = React.useState<DateRange<Moment>>([
        startDate ?? null,
        endDate ?? null,
    ]);

    const [formErrors, setFormErrors] = useState<CustomerExportFormErrors>({
        startDate: null,
        endDate: null,
    });

    const { t } = useTranslation();
    const classes = useStyles();
    const typeClasses = typographyStyles();

    const { mutate: exportRidesToExcelMutation, isError, isLoading } = useMutation(exportRidesToExcel);

    const validateFields = (): boolean => {
        const fieldErrors = {
            startDate: isValidDate(dateRange[0]?.toDate() ?? null),
            endDate: isValidDate(dateRange[1]?.toDate() ?? null),
        } as CustomerExportFormErrors;

        setFormErrors({ ...fieldErrors });

        return fieldErrors.startDate === null && fieldErrors.endDate === null;
    };

    const handleClick = (): void => {
        if (!validateFields()) {
            return;
        }

        exportRidesToExcelMutation({
            startDate: dateRange[0]?.format(DATE_FORMATS['YYYY-MM-DDTHH:mm:ss']) ?? '',
            endDate: dateRange[1]?.format(DATE_FORMATS['YYYY-MM-DDTHH:mm:ss']) ?? '',
        });
    };

    return (
        <Box
            sx={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'flexStart',
                height: 'calc(100vh - 200px)',
            }}
        >
            <Typography
                component="p"
                sx={{
                    fontWeight: '700 !important',
                    color: `${COLORS.BLUE_DARK}`,
                }}
            >
                {t('pages.export.description1')}
            </Typography>
            <Typography component="p" sx={{ color: `${COLORS.BLACK} !important` }}>
                {t('pages.export.description2')}
            </Typography>
            <Grid
                container
                spacing={1}
                style={{
                    marginBottom: '2.5em',
                    marginTop: '1rem',
                }}
            >
                <Grid item sm={12}>
                    <LocalizationProvider
                        dateAdapter={AdapterMoment}
                        localeText={{
                            start: 'Starting date',
                            end: 'Ending date',
                        }}
                    >
                        <DateRangePicker
                            value={dateRange}
                            minDate={dateRange[0]}
                            onChange={newValue => {
                                setDateRange(newValue);
                            }}
                            inputFormat={DATE_FORMATS['DD MMM yyyy']}
                            disableMaskedInput
                            renderInput={(startProps, endProps) => (
                                <>
                                    <TextField
                                        id="startingDate"
                                        autoComplete="off"
                                        {...startProps}
                                        variant="filled"
                                        sx={{
                                            width: {
                                                xl: '270px',
                                                lg: '270px',
                                                md: '270px',
                                            },
                                            '& .MuiFormLabel-root.Mui-focused':
                                                { color: `${COLORS.BLUE} !important` },
                                        }}
                                        InputProps={{
                                            endAdornment: (
                                                <InputAdornment position="end">
                                                    <CalendarTodayOutlinedIcon />
                                                </InputAdornment>
                                            ),
                                        }}
                                        error={formErrors.startDate !== null}
                                        helperText={formErrors.startDate ? t(formErrors.startDate) : null}
                                    />
                                    <Box
                                        sx={{
                                            mx: 2,
                                            display: 'flex',
                                            alignSelf: 'center',
                                        }}
                                    >
                                        to
                                    </Box>
                                    <TextField
                                        id="endingDate"
                                        autoComplete="off"
                                        {...endProps}
                                        variant="filled"
                                        sx={{
                                            width: {
                                                xl: '270px',
                                                lg: '270px',
                                                md: '270px',
                                            },
                                            '& .MuiFormLabel-root.Mui-focused': { color: `${COLORS.BLUE} !important` },
                                        }}
                                        InputProps={{
                                            endAdornment: (
                                                <InputAdornment position="end">
                                                    <CalendarTodayOutlinedIcon />
                                                </InputAdornment>
                                            ),
                                        }}
                                        error={formErrors.endDate !== null}
                                        helperText={formErrors.endDate ? t(formErrors.endDate) : null}
                                    />
                                </>
                            )}
                        />
                    </LocalizationProvider>
                </Grid>
            </Grid>
            {isError && (
                <div
                    style={{
                        display: 'block',
                        width: '100%',
                    }}
                >
                    <p
                        className={clsx(
                            typeClasses.errorTheme,
                            typeClasses.errorMessageMargin
                        )}
                    >
                        {t('modals.unexpectedError')}
                    </p>
                </div>
            )}
            <div>
                <PrimaryButton
                    className={classes.exportButton}
                    disabled={dateRange[0] === null || dateRange[1] === null}
                    loading={isLoading}
                    onClick={handleClick}
                    icon={<CloudDownloadOutlinedIcon />}
                >
                    {t('pages.export.download')}
                </PrimaryButton>
            </div>
        </Box>
    );
};

export default CustomerExport;
