import { Box } from '@mui/material';
import React, { ReactNode } from 'react';

import { COLORS } from '../../constants/colors';

interface CircleWrapperProps {
    sizePx?: string;
    color?: string;
    children?: ReactNode;
}

const CircleWrapper = ({ sizePx, color, children }: CircleWrapperProps) => {
    return (
        <Box
            sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                background: COLORS.BLACK ?? color,
                borderRadius: '50%',
                width: '48px' ?? sizePx,
                height: '48px' ?? sizePx,
            }}
        >{children}</Box>
    );
};

export default CircleWrapper;
