
/* eslint-disable no-case-declarations */
import { useMutation } from '@apollo/react-hooks';
import { Form, MultipleSelect, PrimaryButton, SecondaryButton, TextField } from '@get-e/react-components';
import { Typography, Grid } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { ApolloError } from 'apollo-client';
import React, { FunctionComponent, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { causesRedirect } from '../../../../ApolloClient/createErrorHandler';
import { COLORS } from '../../../../constants/colors';
import { MANAGE_RIDES_BOOKED_BY_OTHERS_ID, MANAGE_USERS_ID } from '../../../../constants/ids';
import { useCurrentUserContext } from '../../../../context/CurrentUserContext';
import { useNotificationContext, Severity } from '../../../../context/NotificationContext';
import { InputError } from '../../../../helpers/inputValidation/InputError';
import { ApiValidationError, emailAlreadyExists, parseInputErrors }
    from '../../../../helpers/inputValidation/parseFormErrors';
import { report } from '../../../../helpers/report';
import { User } from '../../../../services/types';
import useFormStyles from '../../../../styles/Form';
import typographyStyles from '../../../../styles/Typography';
import { EditUserMutation, EditUserMutationInput, UPDATE_USER } from './EditUserForm.graphql';

const useStyles = makeStyles({
    button: { width: '100%' },
    multipleSelectItem: {
        width: '100% !important',
        padding: '0.5rem !important',
        '& .MuiListItemText-root': { paddingLeft: '0.5rem !important' },
    },
});

interface EditUserFields {
    id: string;
    firstName: string;
    lastName: string;
    email: string;
    permissions: string[];
}

interface EditUserError {
    firstName: InputError | null;
    lastName: InputError | null;
    email: InputError | EditUserFormError | null;
    authorizationError: EditUserFormError | null;
    unexpectedError: boolean | null;
}

interface EditUserValidationErrors {
    validationErrors: {
        input?: {
            patch?: {
                firstName?: ApiValidationError[];
                lastName?: ApiValidationError[];
                email?: ApiValidationError[];
            };
        };
    };
}

interface SubmittedUserPermissions {
    manageUsers?: boolean;
    manageRidesBookedByOthers: boolean;
}

enum EditUserFormError {
    Exists = 'errors.emailTaken',
    Unauthorized = 'modals.edit.helperText.unauthorized',
}

const EditUserFormNew: FunctionComponent<{
    user: User;
    onClose: () => void;
}> = ({ user, onClose }) => {
    const formClasses = useFormStyles();
    const classes = useStyles();
    const typographyClasses = typographyStyles();
    const { t } = useTranslation();
    const { currentUser } = useCurrentUserContext();
    const { showNotification } = useNotificationContext();
    const [roleIds, setRoleIds] = useState<string[]>([]);

    const [values, setValues] = useState<EditUserFields>({
        id: user.id,
        firstName: user.firstName,
        lastName: user.lastName,
        email: user.email,
        permissions: [
            ...(user.permissions.manageUsers ? [`${t('pages.users.fields.manageUsers')}`] : []),
            ...(user.permissions.manageRidesBookedByOthers
                ? [`${t('pages.users.fields.manageRidesBookedByOthers')}`]
                : []),
        ],
    });

    const roles = new Map([
        [MANAGE_USERS_ID, `${t('pages.users.fields.manageUsers')}`],
        [MANAGE_RIDES_BOOKED_BY_OTHERS_ID, `${t('pages.users.fields.manageRidesBookedByOthers')}`],
    ]);

    const disabledRoles = [
        currentUser.id === user.id ? `${t('pages.users.fields.manageUsers')}` : '',
        !currentUser.permissions.manageUsers ? `${t('pages.users.fields.manageRidesBookedByOthers')}` : '',
    ];

    const [formErrors, setFormErrors] = useState<EditUserError>({
        firstName: null,
        lastName: null,
        email: null,
        authorizationError: null,
        unexpectedError: null,
    });

    const onSetIds = (ids: string[]) => {
        setRoleIds(ids);
    };

    const getEditFormErrors = (newError: ApolloError): EditUserError => {
        const fields: EditUserError = {
            firstName: null,
            lastName: null,
            email: null,
            authorizationError: null,
            unexpectedError: null,
        };

        if (newError.graphQLErrors.length) {
            for (const graphqlError of newError.graphQLErrors) {
                switch (graphqlError?.extensions?.code) {
                    case 'BAD_USER_INPUT':
                        const { validationErrors } = graphqlError.extensions as EditUserValidationErrors;

                        try {
                            fields.email = emailAlreadyExists(validationErrors.input?.patch?.email)
                                ? EditUserFormError.Exists
                                : parseInputErrors(validationErrors.input?.patch?.email);
                            fields.firstName = parseInputErrors(validationErrors.input?.patch?.firstName);
                            fields.lastName = parseInputErrors(validationErrors.input?.patch?.lastName);
                        } catch {
                            fields.unexpectedError = true;
                        }

                        break;
                    case 'UNAUTHORIZED':
                        fields.authorizationError = EditUserFormError.Unauthorized;
                        break;
                    default:
                        fields.unexpectedError = true;
                        continue;
                }
            }
        }

        const errorUnhandled = Object.values(fields).every(field => field === null);

        if (errorUnhandled) {
            fields.unexpectedError = true;
        }

        return fields;
    };

    const [updateUser, { loading, error }] = useMutation<EditUserMutation, EditUserMutationInput>(UPDATE_USER, {
        onCompleted() {
            onClose();
            showNotification(t('alert.edit.success'), Severity.Info);
        },
        onError(apolloError) {
            const fieldErrors = getEditFormErrors(apolloError);

            if (fieldErrors.unexpectedError) {
                report(apolloError);
            }

            setFormErrors({ ...fieldErrors });
        },
    });

    const submitForm = async (event?: React.FormEvent<HTMLFormElement>): Promise<void> => {
        event?.preventDefault();

        const permissionsToUpdate = ((): SubmittedUserPermissions | null => {
            if (currentUser.id === values.id && currentUser.permissions.manageUsers) {
                return { manageRidesBookedByOthers: roleIds.includes(MANAGE_RIDES_BOOKED_BY_OTHERS_ID) };
            }

            if (currentUser.permissions.manageUsers) {
                return {
                    manageUsers: roleIds.includes(MANAGE_USERS_ID),
                    manageRidesBookedByOthers: roleIds.includes(MANAGE_RIDES_BOOKED_BY_OTHERS_ID),
                };
            }

            return null;
        })();

        await updateUser({
            variables: {
                userId: values.id,
                firstName: values.firstName.trim(),
                lastName: values.lastName.trim(),
                email: values.email.trim(),
                ...permissionsToUpdate,
            },
        });
    };

    const handleChange = <T extends keyof EditUserFields>(key: T, newValue: EditUserFields[T] & string): void => {
        setValues({
            ...values,
            [key]: newValue,
        });
        setFormErrors(prevStateForm => ({
            ...prevStateForm,
            [key]: null,
        }));
    };

    const errorMessage = (): string => {
        if (formErrors.authorizationError) {
            return formErrors.authorizationError;
        }

        if (formErrors.unexpectedError) {
            return 'modals.unexpectedError';
        }

        return 'modals.outstandingErrors';
    };

    if (error && causesRedirect(error)) {
        return null;
    }

    return (
        <Form className={formClasses.root} autoComplete="off" noValidate onSubmit={submitForm}>
            <Typography
                style={{
                    color: COLORS.BLUE_DARK,
                    fontSize: '1rem',
                    fontWeight: 700,
                    marginBottom: '1rem',
                }}
            >
                {t('modals.edit.profileInformation')}
            </Typography>
            <TextField
                className={formClasses.textField}
                error={formErrors.firstName !== null}
                helperText={formErrors.firstName && t(formErrors.firstName)}
                inputProps={{ 'data-testid': 'firstName' }}
                label={t('pages.users.fields.firstName')}
                name="firstName"
                onChange={event => handleChange('firstName', event.target.value)}
                required
                value={values.firstName}
            />
            <TextField
                className={formClasses.textField}
                error={formErrors.lastName !== null}
                inputProps={{ 'data-testid': 'lastName' }}
                helperText={formErrors.lastName && t(formErrors.lastName)}
                label={t('pages.users.fields.lastName')}
                name="lastName"
                onChange={event => handleChange('lastName', event.target.value)}
                required
                value={values.lastName}
            />
            <TextField
                className={formClasses.textField}
                error={formErrors.email !== null}
                inputProps={{
                    autoCapitalize: 'none',
                    'data-testid': 'email',
                }}
                helperText={formErrors.email && t(formErrors.email)}
                label={t('pages.users.fields.email')}
                name="email"
                onChange={event => handleChange('email', event.target.value)}
                required
                value={values.email}
            />
            {user.customLogin && (
                <TextField
                    label={t('pages.users.fields.customLogin')}
                    value={user.customLogin}
                    disabled={true}
                    className={formClasses.customLogin}
                />
            )}
            <Typography
                style={{
                    color: COLORS.BLUE_DARK,
                    fontSize: '1rem',
                    fontWeight: 700,
                    marginBottom: '1rem',
                }}
            >
                {t('pages.users.fields.userPermissions')}
            </Typography>
            <div className={formClasses.textField}>
                <MultipleSelect
                    label={t('pages.users.fields.role')}
                    value={roleIds}
                    values={roles}
                    onSetIds={onSetIds}
                    defaultSelectedValue={values.permissions}
                    classNames={{ item: classes.multipleSelectItem }}
                    disabledValue={disabledRoles}
                />
            </div>
            {error && (
                <div data-testid="error-container" className={typographyClasses.errorContainer}>
                    <span className={typographyClasses.errorTheme}>{t(errorMessage())}</span>
                </div>
            )}
            <Grid container columnSpacing={4} spacing={4}>
                <Grid item xs={6}>
                    <PrimaryButton
                        loading={loading}
                        disabled={loading}
                        onClick={() => submitForm()}
                        className={classes.button}
                    >
                        {t('buttonName.edit')}
                    </PrimaryButton>
                </Grid>
                <Grid item xs={6}>
                    <SecondaryButton onClick={() => onClose()} disabled={loading} className={classes.button}>
                        {t('buttonName.cancel')}
                    </SecondaryButton>
                </Grid>
            </Grid>
        </Form>
    );
};

export default EditUserFormNew;
