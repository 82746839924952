import React, { FunctionComponent } from 'react';
import { useTranslation } from 'react-i18next';
import LegacyView from '../../components/legacyView/LegacyView';
import { useCurrentUserContext } from '../../context/CurrentUserContext';
import UnauthorizedUser from '../errorPages/UnauthorizedUser';

const PendingChanges: FunctionComponent = () => {
    const { currentUser } = useCurrentUserContext();
    const { t } = useTranslation();

    if (!currentUser.company.customer.features.cityJetPendingChanges) {
        return <UnauthorizedUser />;
    }

    return (
        <LegacyView
            path="/integrations/sabre-air-centre/pending-changes"
            title={t('iframe.title.pendingChanges')}
        />
    );
};

export default PendingChanges;
