import React, { FunctionComponent, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';

import config from '../../config';
import { useLocaleContext } from '../../context/LocaleContext';
import afterDelay from '../../helpers/afterDelay';
import findLegacyLocale from '../../helpers/findLegacyLocale';
import handleLegacyIframeMessage from '../../helpers/handleLegacyIframeMessage';
import UnauthorizedUser from '../../pages/errorPages/UnauthorizedUser';
import Loading from '../../pages/loading/Loading';
import iframeStyles from '../../styles/Iframe';

interface Props {
    path: string;
    title: string;
    onOpenUser?: (id: string) => void;
}

const LegacyView: FunctionComponent<Props> = ({ title, path, onOpenUser }) => {
    const classes = iframeStyles();
    const url = config.iframeUrl + path;
    const { locale } = useLocaleContext();
    const legacyLocale = findLegacyLocale(locale);
    const [loading, setLoading] = useState(false);
    const [iframeIsLoading, setIframeIsLoading] = useState(true);
    const urlHasParams = url.includes('?');
    const paramSymbol = urlHasParams ? '&' : '?';
    const [refreshingCookie, setRefreshingCookie] = useState(false);
    const history = useHistory();
    const [unauthorized, setUnauthorized] = useState(false);

    const iframeUrl = `${url
        + paramSymbol}locale=${legacyLocale}`;

    useEffect(() => {
        if (iframeIsLoading) {
            return afterDelay(() => {
                if (!iframeIsLoading) {
                    return;
                }

                setLoading(true);
            }, 1000);
        }

        setLoading(false);
    }, [iframeIsLoading]);

    useEffect(() => {
        window.addEventListener(
            'message',
            event => handleLegacyIframeMessage(event, history, {
                onRefreshingCookie: () => setRefreshingCookie(true),
                onCookieRefreshed: () => setRefreshingCookie(false),
                onUnauthorized: () => setUnauthorized(true),
                onOpenUser: onOpenUser ? id => onOpenUser(id) : undefined,
            }),
        );
    }, [history, onOpenUser]);

    useEffect(() => {
        if (!refreshingCookie) {
            const iframe = document
                .getElementById(config.legacyIframeId) as HTMLIFrameElement;

            iframe.src = iframeUrl;
        }
    }, [iframeUrl, refreshingCookie]);

    if (unauthorized) {
        return <UnauthorizedUser />;
    }

    if (refreshingCookie) {
        return <Loading framed={true}/>;
    }

    return (
        <>
            {loading
                ? <Loading framed={true}/>
                : null
            }
            <iframe
                className={classes.iframe}
                id={config.legacyIframeId}
                src={iframeUrl}
                title={title}
                data-testid="iframe"
                onLoad={() => setIframeIsLoading(false)}
                style={{ display: `${iframeIsLoading ? 'none' : 'block'}` }}
            />
        </>
    );
};

export default LegacyView;
