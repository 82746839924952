import { CountryCode } from 'libphonenumber-js/types';
import { countries, zones } from 'moment-timezone/data/meta/latest.json';

interface Country {
    [code: string]: {
        name: string;
        abbr: string;
        zones: string[];
    };
}

interface Zones {
    [code: string]: {
        name: string;
        lat: number;
        long: number;
        countries: string[];
        comments: string;
    };
}

export const getCurrentUserCountryCode = (): CountryCode | undefined => {
    const timeZoneCityToCountry: Country = {};

    Object.keys(zones).forEach(zone => {
        const cityArr = zone.split('/');
        const city = cityArr[cityArr.length - 1];

        timeZoneCityToCountry[city] = (countries as Country)[
            (zones as Zones)[zone].countries[0]
        ];
    });

    const userTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    const tzArr = userTimeZone.split('/');
    const userCity = tzArr[tzArr.length - 1];

    return timeZoneCityToCountry[userCity]?.abbr as CountryCode;
};
