import { Invalid, Validated } from '../Validator';

export default function and<
    TFirstValue,
    TFirstError,
    TSecondValue,
    TSecondError,
>(
    firstResult: Validated<TFirstValue, TFirstError>,
    createSecond: (input: TFirstValue) => Validated<TSecondValue, TSecondError>
): Invalid<TFirstError> | Validated<TSecondValue, TSecondError> {
    return firstResult.isValid
        ? createSecond(firstResult.value)
        : firstResult;
}
