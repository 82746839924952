import React from 'react';

import { emptyAirline } from '../../public/assets/images';

interface CustomNoRowsOverlayProps {
    noRows?: string;
    noResultImage?: string;
}

export function CustomNoRowsOverlay(props: CustomNoRowsOverlayProps): JSX.Element {
    const { noRows, noResultImage } = props;

    return (
        <div
            style={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'center',
                height: '100%',
            }}
        >
            <img
                src={noResultImage ?? emptyAirline}
                alt={noRows}
                style={{ paddingBottom: '2rem' }}
            />
            {noRows}
        </div>
    );
}
