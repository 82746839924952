import moment from 'moment';

export default function getRemainingTimeString(startTime: string | null, endTime: string | null) {
    let remainingTimeString = '';
    const startTimeUtcSec = moment(startTime).valueOf() / 1000;
    const endTimeUtcSec = moment(endTime).valueOf() / 1000;

    let delta = Math.abs(endTimeUtcSec - startTimeUtcSec);
    const remainingDays = Math.floor(delta / 86400);

    delta -= remainingDays * 86400;
    const remainingHours = Math.floor(delta / 3600) % 24;

    if (remainingDays === 0) {
        remainingTimeString += getHoursString(remainingHours);
    } else if (remainingDays === 1) {
        remainingTimeString = `${remainingDays} day `;
        remainingTimeString += getHoursString(remainingHours);
    } else if (remainingDays > 1) {
        remainingTimeString = `${remainingDays} days `;
        remainingTimeString += getHoursString(remainingHours);
    }

    return remainingTimeString;
}

const getHoursString = (hours: number) => {
    if (hours < 1) {
        return '';
    } else if (hours === 1) {
        return `${hours} hour`;
    } else if (hours > 1) {
        return `${hours} hours`;
    }
};
