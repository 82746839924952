import MoodBadOutlinedIcon from '@mui/icons-material/MoodBadOutlined';
import MoodOutlinedIcon from '@mui/icons-material/MoodOutlined';
import SentimentNeutralOutlinedIcon from '@mui/icons-material/SentimentNeutralOutlined';
import { Box, Typography } from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { COLORS } from '../../../constants/colors';

const DriverUpdatesTooltip = () => {
    const { t } = useTranslation();

    return (
        <Box padding="0.5rem">
            <Box display="flex" alignItems="center">
                <MoodOutlinedIcon
                    style={{
                        color: COLORS.GREEN,
                        marginRight: '0.5rem',
                    }}
                />
                <Typography>{t('pages.reports.allUpdatesReceivedAccurately')}</Typography>
            </Box>
            <Box display="flex" alignItems="center">
                <MoodBadOutlinedIcon
                    style={{
                        color: COLORS.RED,
                        marginRight: '0.5rem',
                    }}
                />
                <Typography>{t('pages.reports.notAllUpdatesReceived')}</Typography>
            </Box>
            <Box display="flex" alignItems="center">
                <SentimentNeutralOutlinedIcon
                    style={{
                        color: COLORS.ORANGE_OFFICIAL,
                        marginRight: '0.5rem',
                    }}
                />
                <Typography>{t('pages.reports.noUpdatesReceived')}</Typography>
            </Box>
        </Box>
    );
};

export default DriverUpdatesTooltip;
