import React, { FunctionComponent } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

import LegacyView from '../../components/legacyView/LegacyView';

const BookingConfirmation: FunctionComponent = () => {
    const { rideId }: {rideId: string} = useParams();
    const { t } = useTranslation();

    const iframeUrl = 'template_mail/create?check-settings=0'
        + '&slug=PASSENGER_CONFIRMATION_EMAIL';

    return (
        <LegacyView
            path={`/rides/${rideId}/${iframeUrl}`}
            title={t('iframe.title.bookingConfirmation')}
        />
    );
};

export default BookingConfirmation;
