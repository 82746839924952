import React, { Component } from 'react';

import LogInMessage from '../../components/loginMessage/LogInMessage';
import { report } from '../../helpers/report';
import UnexpectedError from './UnexpectedError';

interface Props {
    children: JSX.Element;
    message: 'logIn' | 'reload';
    framed?: boolean;
}

interface State {
    hasError: boolean;
}

class ErrorBoundary extends Component<Props, State> {
    constructor(props: Props) {
        super(props);
        this.state = { hasError: false };
    }

    componentDidCatch(error: Error): void {
        report(error);
        this.setState({ hasError: true });
    }

    render(): JSX.Element {
        if (this.state.hasError) {
            if (this.props.message === 'logIn') {
                return <LogInMessage/>;
            }

            return <UnexpectedError framed={this.props.framed ?? false}/>;
        }

        return this.props.children;
    }
}
export default ErrorBoundary;
