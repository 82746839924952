import { FormHelperText } from '@mui/material';
import React, { Dispatch, SetStateAction, useState } from 'react';
import { useTranslation } from 'react-i18next';

import NoVehicleAvailable from './NoVehicleAvailable';
import VehicleInformationCard from './VehicleInformationCard';

export interface Vehicle {
    id: string;
    vehicleType: string;
    vehicleImage: string;
    travellers: string;
    luggages: string;
    price: number;
    priceCurrancy: string;
}

interface VehicleInformationCardsProp {
    selectedVehicle: Vehicle | null;
    setSelectedVehicle: Dispatch<SetStateAction<Vehicle | null>>;
    vehicleList: Vehicle[] | null;
    isError?: boolean;
    setIsError?: () => void;
}

const VehicleInformationCards = ({
    selectedVehicle,
    setSelectedVehicle,
    vehicleList = [],
    isError = false,
    setIsError,
}: VehicleInformationCardsProp) => {
    const [isVehicleSelected, setIsVehicleSelected] = useState(Boolean(selectedVehicle));
    const [isVehicleListVisible, setIsVehicleListVisible] = useState(Boolean(selectedVehicle));
    const { t } = useTranslation();

    const onCardClick = (vehicle: Vehicle) => {
        setIsVehicleSelected(!isVehicleSelected);
        setIsVehicleListVisible(!isVehicleListVisible);
        setSelectedVehicle(vehicle);
        setIsError?.();
    };

    return (
        <>
            {selectedVehicle && (
                <VehicleInformationCard
                    key={selectedVehicle.id}
                    isVehicleSelected={isVehicleSelected}
                    onCardClick={() => onCardClick(selectedVehicle)}
                    onEditClick={() => setIsVehicleListVisible(!isVehicleListVisible)}
                    vehicleType={selectedVehicle.vehicleType}
                    vehicleImage={selectedVehicle.vehicleImage}
                    luggages={selectedVehicle.luggages}
                    travellers={selectedVehicle.travellers}
                    price={(Math.round((selectedVehicle?.price ?? 0) * 100) / 100).toFixed(2)}
                    priceCurrancy={selectedVehicle.priceCurrancy}
                />
            )}
            { vehicleList
                ? vehicleList.filter(vehicle => vehicle.id !== selectedVehicle?.id).map((vehicle: Vehicle) => (
                    <VehicleInformationCard
                        key={vehicle.id}
                        isVehicleSelected={isVehicleSelected}
                        onCardClick={() => onCardClick(vehicle)}
                        onEditClick={() => setIsVehicleListVisible(!isVehicleListVisible)}
                        isCardHidden={isVehicleListVisible}
                        vehicleType={vehicle.vehicleType}
                        vehicleImage={vehicle.vehicleImage}
                        luggages={vehicle.luggages}
                        travellers={vehicle.travellers}
                        price={(Math.round((vehicle.price ?? 0) * 100) / 100).toFixed(2)}
                        priceCurrancy={vehicle.priceCurrancy}
                    />
                ))
                : !selectedVehicle && <NoVehicleAvailable />}
            {isError && <FormHelperText error>{t('errors.selectVehicle')}</FormHelperText>}
        </>
    );
};

export default VehicleInformationCards;
