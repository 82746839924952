import { AxiosResponse } from 'axios';

import apiClient from './api';
import { USER_PROFILE } from './routes';
import { User, UserProfileRequest } from './types';

export const getUserProfile = async (): Promise<User> => {
    const { data } = await apiClient.get<User>(USER_PROFILE);

    return data;
};

export const updateUserProfile = async (
    variables: UserProfileRequest,
): Promise<AxiosResponse> => {
    const { email, firstName, lastName } = variables;

    const response = await apiClient.put(USER_PROFILE, {
        email,
        firstName,
        lastName,
    });

    return response;
};
