import gql from 'graphql-tag';

import { User } from '../../users/customer/Users.graphql';

export interface GetUser {
    user: User | null;
}
export const GET_USER = gql`
    query getUser($id: ID!) {
        user(id: $id) {
            id
            firstName
            lastName
            email
            permissions {
                manageRidesBookedByOthers
                manageUsers
            }
        }
    }
`;
