export function isElementPartialVisible(element: HTMLInputElement, headerPadding?: number) {
    const elementRect = element.getBoundingClientRect();
    const viewportHeight = window.innerHeight || document.documentElement.clientHeight;

    const isPartiallyVisible = (
        elementRect.bottom > (0 + (headerPadding ?? 0))
      && elementRect.top < (viewportHeight - (headerPadding ?? 0))
    );

    return isPartiallyVisible;
}
