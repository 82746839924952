import { Container, Grid, useMediaQuery } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { DataGridPro, GridColumns, GridRowsProp, GridValidRowModel } from '@mui/x-data-grid-pro';
import clsx from 'clsx';
import React from 'react';
import { useTranslation } from 'react-i18next';
import uuid from 'react-uuid';

import { CustomNoRowsOverlay } from '../../../../components/noRowsDataGridOverlay/CustomNoRowsOverlay';
import { COLORS } from '../../../../constants/colors';
import useDataGridStyles from '../../../../styles/DataGrid';
import theme, { TABLE_ROW_HEIGHT } from '../../../../styles/theme';
import { useDriverAnalysisColumns } from '../hooks/useDriverAnalysisColumns';
import SummaryBasicCard from '../SummaryBasicCard';

const useStyles = makeStyles({
    wrapper: {
        marginTop: '1rem',
        marginBottom: '1rem',
        padding: 0,
    },
});

export enum DriverUpdatesStatus {
    ACCURATE = 'Accurate',
    NOT_ACCURATE = 'Not accurate',
    NO_UPDATES = 'No updates',
}

export enum LocationSharingStatus {
    ACCURATE = 'Accurate',
    NOT_ACCURATE = 'Not accurate',
    NO_UPDATES = 'No updates',
}

const rows: GridRowsProp = [
    {
        id: uuid(),
        driverName: 'Dani California',
        rating: 4.80,
        numberOfReviews: '179',
        totalRides: '1090',
        ridesTrackedAccuratelyPercent: '100',
        rides: [
            {
                id: uuid(),
                pickup: '15 Mar 2033 at 16:00 PM',
                rideNumber: '000-000-0000',
                driverUpdatesStatus: DriverUpdatesStatus.ACCURATE,
                locationSharingStatus: LocationSharingStatus.NO_UPDATES,
            },
            {
                id: uuid(),
                pickup: '17 Mar 2033 at 16:00 PM',
                rideNumber: '111-111-1111',
                driverUpdatesStatus: DriverUpdatesStatus.NOT_ACCURATE,
                locationSharingStatus: LocationSharingStatus.NOT_ACCURATE,
            },
        ],
    },
    {
        id: uuid(),
        driverName: 'Dani California',
        rating: 4.96,
        numberOfReviews: '655',
        totalRides: '3232',
        ridesTrackedAccuratelyPercent: '88',
        rides: [
            {
                id: uuid(),
                pickup: '15 Mar 2033 at 16:00 PM',
                rideNumber: '000-000-0000',
                driverUpdatesStatus: DriverUpdatesStatus.ACCURATE,
                locationSharingStatus: LocationSharingStatus.NO_UPDATES,
            },
        ],
    },
    {
        id: uuid(),
        driverName: 'Dani California',
        rating: 5.00,
        numberOfReviews: '345',
        totalRides: '8676',
        ridesTrackedAccuratelyPercent: '99',
        rides: [
            {
                id: uuid(),
                pickup: '15 Mar 2033 at 16:00 PM',
                rideNumber: '000-000-0000',
                driverUpdatesStatus: DriverUpdatesStatus.ACCURATE,
                locationSharingStatus: LocationSharingStatus.NO_UPDATES,
            },
        ],
    },
];

const DriverAnalysisTab = () => {
    const { t } = useTranslation();
    const classes = useStyles();
    const dataGridClases = useDataGridStyles();
    const isMobile = useMediaQuery(theme.breakpoints.down('lg'));
    const [columns, rowDetails, rowDetailsMobile] = useDriverAnalysisColumns();

    return (
        <>
            <Container className={classes.wrapper}>
                <Grid container columnSpacing={3}>
                    <Grid item xs={12} md={4} lg={4}>
                        <SummaryBasicCard
                            title={t('pages.reports.driversAssigned')}
                            percentage1="100"
                            percentage2="+0"
                            text1="0% in previous period"
                            text2="16 Mar 2033- 23 Mar 2033"
                            titleTooltip={t('pages.reports.driversAssignedTooltip')}
                        />
                    </Grid>
                    <Grid item xs={12} md={4} lg={4}>
                        <SummaryBasicCard
                            title={t('pages.reports.ridesTrackedAccurately')}
                            percentage1="74"
                            percentage2="-2"
                            percentage2Color={COLORS.RED}
                            text1="74% in previous period"
                            text2="16 Mar 2033- 23 Mar 2033"
                            titleTooltip={t('pages.reports.ridesTrackedTooltip1')}
                        />
                    </Grid>
                </Grid>
            </Container>
            <Container className={classes.wrapper}>
                <DataGridPro
                    className={clsx({
                        [dataGridClases.dataGrid]: true,
                        [dataGridClases.dataGridNoRows]: rows.length === 0,
                    })}
                    autoHeight
                    hideFooter
                    disableColumnSelector
                    disableColumnFilter
                    rows={rows}
                    rowHeight={isMobile ? 100 : TABLE_ROW_HEIGHT}
                    columns={columns as unknown as GridColumns<GridValidRowModel>}
                    components={{ NoRowsOverlay: CustomNoRowsOverlay }}
                    componentsProps={{ noRowsOverlay: { noRows: t('noResultsFound') } }}
                    getDetailPanelHeight={() => 'auto'}
                    getDetailPanelContent={isMobile ? rowDetailsMobile : rowDetails}
                />
            </Container>
        </>
    );
};

export default DriverAnalysisTab;
