import { useFlags } from 'launchdarkly-react-client-sdk';
import React, { FunctionComponent } from 'react';

import Rides from '../Rides';
import RidesLegacy from '../ridesLegacy/RidesLegacy';

const RidesPageSwitch: FunctionComponent = () => {
    const { useNewRidesPage } = useFlags();

    return useNewRidesPage ? <Rides /> : <RidesLegacy />;
};

export default RidesPageSwitch;
