/* eslint-disable max-statements */
import { useQuery } from '@apollo/react-hooks';
import { TabPanel } from '@get-e/react-components';
import { GridRowId } from '@mui/x-data-grid';
import { DataGridPro } from '@mui/x-data-grid-pro';
import { ApolloQueryResult, FetchMoreOptions } from 'apollo-client';
import React, { FunctionComponent, useEffect, useState, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { Route, Switch, useRouteMatch } from 'react-router-dom';

import { causesRedirect } from '../../../../ApolloClient/createErrorHandler';
import config from '../../../../config';
import { mapInvitedUsers } from '../../../../helpers/maps/mapInvitedUsers';
import { initializeZendeskStyles, setZendeskVerticalOffset } from '../../../../helpers/prefillZendeskConfig';
import { report } from '../../../../helpers/report';
import { default as useURLQuery } from '../../../../hooks/useQuery';
import useDataGridStyles from '../../../../styles/DataGrid';
import { ActiveTab } from '../Users';
import { GET_INVITED_USERS, GetInvitedUsers, UserInvite } from '../Users.graphql';
import { getInvitedColumns } from './InvitedUsersDataGridData';
import NoResults from './NoResults';
import RevokeUserInviteModal from './RevokeUserInviteModal';
import UsersTabError from './UsersTabError';

const InvitedUsersDataGrid: FunctionComponent<{
    value: ActiveTab;
    onUserCountChange: (val: number) => void;
    onLoaded: () => void;
}> = ({ value, onUserCountChange, onLoaded }) => {
    const query = useURLQuery();
    const after = query.get('after');
    const before = query.get('before');
    const { t } = useTranslation();
    const classes = useDataGridStyles();
    const { path } = useRouteMatch();
    const [revokedUser, setRevokedUser] = useState<UserInvite | null>(null);
    const mapPageToNextCursor = useRef<{ [page: number]: GridRowId }>({});
    const mapPageToPreviousCursor = useRef<{ [page: number]: GridRowId }>({});
    const [page, setPage] = useState(0);
    const [nextCursor, setNextCursor] = useState<string | null>('');
    const [previousCursor, setPreviousCursor] = useState<string | null>('');
    const [totalCount, setTotalCount] = useState<number>(0);
    const [rowCount, setRowCount] = useState(totalCount ?? 0);

    /*
     * TODO: Prevent unrelated tables updating queries on pagination.
     * As all URLs use the after / before params, all tables run queries on
     * when a table is paginatied through.
     * useURLQuery contains a watcher that is triggering related
     * queries on the update of the URL location.
     */
    const onRevokeClick = (user: UserInvite): void => {
        setRevokedUser(user);
    };

    const columns = getInvitedColumns(t, onRevokeClick);

    const { data, loading, error, fetchMore, refetch } = useQuery<GetInvitedUsers>(GET_INVITED_USERS, {
        variables: {
            after,
            before,
        },
        onCompleted() {
            onLoaded();

            if (data) {
                onUserCountChange(data.me.company.userInvitesPage.totalCount);
            }
        },
        onError(apolloError) {
            onLoaded();
            report(apolloError);
        },
        notifyOnNetworkStatusChange: true,
    });

    useEffect(() => {
        if (data) {
            onUserCountChange(data.me.company.userInvitesPage.totalCount);
        }
    }, [data, onUserCountChange]);

    useEffect(() => {
        if (data) {
            mapPageToNextCursor.current[page] = '';
            mapPageToPreviousCursor.current[page] = '';
            setPage(0);
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [totalCount]);

    useEffect(() => {
        if (data) {
            setNextCursor(data.me.company.userInvitesPage.nextCursor);
            setPreviousCursor(data.me.company.userInvitesPage.previousCursor);
            setTotalCount(data.me.company.userInvitesPage.totalCount);
        }
    }, [data, mapPageToNextCursor, mapPageToPreviousCursor]);

    useEffect(() => {
        if (nextCursor) {
            mapPageToNextCursor.current[page] = nextCursor;
        }

        if (previousCursor) {
            mapPageToPreviousCursor.current[page] = previousCursor;
        }
    }, [page, nextCursor, previousCursor]);

    useEffect(() => {
        setRowCount(prevRowCount => totalCount ?? prevRowCount);
    }, [totalCount, setRowCount]);

    const handlePageChange = (newPage: number): Promise<ApolloQueryResult<GetInvitedUsers>> | null => {
        const isAfter = newPage > page;

        if (newPage === 0 || mapPageToNextCursor.current[newPage - 1] || mapPageToPreviousCursor.current[newPage - 1]) {
            setPage(newPage);
        }

        return fetchMore({
            variables: {
                after: isAfter ? nextCursor : null,
                before: !isAfter ? previousCursor : null,
            },
            updateQuery,
        });
    };

    const updateQuery: FetchMoreOptions<GetInvitedUsers>['updateQuery'] = (previousResult, { fetchMoreResult }) => {
        if (!fetchMoreResult) {
            return previousResult;
        }

        return fetchMoreResult;
    };

    useEffect((): void => {
        if (!data || value !== ActiveTab.Invited) {
            return;
        }

        if (data.me.company.userInvitesPage.totalCount > config.paginationLimit) {
            setZendeskVerticalOffset(50);
        } else {
            initializeZendeskStyles();
        }
    }, [data, value]);

    if (error) {
        if (causesRedirect(error)) {
            return null;
        }

        return <UsersTabError value={value} index={ActiveTab.Invited} onRetryClick={refetch} />;
    }

    if (!data?.me.company.userInvitesPage.items.length) {
        return <NoResults value={value} index={ActiveTab.Invited} />;
    }

    const { items } = data.me.company.userInvitesPage;

    return (
        <TabPanel selectedValue={value} value={ActiveTab.Invited}>
            <Switch>
                <Route path={`${path}/invited`}>
                    <DataGridPro
                        disableColumnMenu
                        autoHeight
                        rows={mapInvitedUsers(t, items)}
                        columns={columns}
                        className={classes.dataGrid}
                        pagination
                        pageSize={config.paginationLimit}
                        rowsPerPageOptions={[config.paginationLimit]}
                        rowCount={rowCount}
                        paginationMode="server"
                        onPageChange={handlePageChange}
                        page={page}
                        loading={loading || !data}
                        hideFooterSelectedRowCount
                    />
                    {revokedUser && <RevokeUserInviteModal onClose={() => setRevokedUser(null)} user={revokedUser} />}
                </Route>
            </Switch>
        </TabPanel>
    );
};

export default React.memo(InvitedUsersDataGrid, (previous, next) => previous.value === next.value);
