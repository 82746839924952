import { Modal, SecondaryButton } from '@get-e/react-components';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import DoneIcon from '@material-ui/icons/Done';
import FileCopyIcon from '@material-ui/icons/FileCopy';
import { Button, Grid, Typography, Link } from '@mui/material';
import clsx from 'clsx';
import copy from 'copy-to-clipboard';
import React, { FunctionComponent, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useCurrentUserContext } from '../../../context/CurrentUserContext';
import { openZendeskLiveChat } from '../../../helpers/prefillZendeskConfig';
import useButtonStyles from '../../../styles/Button';
import typographyStyles from '../../../styles/Typography';
import ContactInformation from './ContactInformation';

const contactDetails = [
    {
        name: 'uk',
        phone: '+44 203 856 8655',
    },
];

const ContactUsModal: FunctionComponent<{
    onClose: () => void;
}> = ({ onClose }) => {
    const { t } = useTranslation();
    const classes = typographyStyles();
    const buttonClasses = useButtonStyles();
    const [showCopyIcon, setShowCopyIcon] = useState(false);
    const [copied, setCopied] = useState(false);
    const { currentUser } = useCurrentUserContext();

    const handleClick = (): void => {
        onClose();
        openZendeskLiveChat();
    };

    const handleCopyClick = (text: string): void => {
        setCopied(true);
        copy(text);
    };

    const handleMouseLeave = (): void => {
        setShowCopyIcon(false);
        setCopied(false);
    };

    const { airlinePhoneNumber } = currentUser.company.customer.features;

    return (
        <Modal
            open={true}
            onClose={onClose}
        >
            <Grid container>
                <Grid item>
                    <Typography variant="h2" component="h2">
                        {t('modals.contact.title')}
                    </Typography>
                    <Typography variant="body1" component="p">
                        {t('modals.contact.description')}
                    </Typography>
                    <section style={{ marginTop: '2em' }}>
                        <article>
                            <Typography
                                variant="h6"
                                component="h3"
                                className={
                                    clsx(
                                        classes.subtitle1Override,
                                        classes.capitalize
                                    )
                                }
                            >
                                {t('modals.contact.liveChat')}
                            </Typography>
                            <Button
                                onClick={handleClick}
                                disableRipple
                                disableFocusRipple
                                disableTouchRipple
                                className={buttonClasses.defaultTextButton}
                            >
                                {t('modals.contact.startAConversation')}
                                <ArrowForwardIcon color="secondary"/>
                            </Button>
                        </article>
                    </section>
                    <section style={{ marginTop: '2em' }}>
                        <article>
                            <Typography
                                variant="h6"
                                component="h3"
                                className={
                                    clsx(
                                        classes.subtitle1Override,
                                        classes.capitalize
                                    )
                                }
                            >
                                {t('modals.contact.email')}
                            </Typography>
                            <div
                                style={{
                                    height: '19px',
                                    paddingBottom: '0.75em',
                                }}
                                onMouseOver={() => setShowCopyIcon(true)}
                                onMouseLeave={() => handleMouseLeave()}
                            >
                                <Link
                                    href={`mailto:${t('contact.emailAddress')}`}
                                >
                                    {t('contact.emailAddress')}
                                </Link>
                                {showCopyIcon
                                    ? (
                                        <Typography
                                            component="span"
                                            sx={{
                                                display: {
                                                    xs: 'none',
                                                    md: 'block',
                                                },
                                            }}
                                        >
                                            <Button
                                                className={`${buttonClasses.iconButton} 
                                        ${copied ? classes.successTheme : ''}`}
                                                data-testid="email-button"
                                                disableRipple
                                                onClick={
                                                    () => handleCopyClick(
                                                        t('contact.emailAddress')
                                                    )
                                                }
                                            >
                                                {copied
                                                    ? <DoneIcon />
                                                    : <FileCopyIcon/>
                                                }
                                            </Button>
                                        </Typography>
                                    )
                                    : null
                                }
                            </div>
                        </article>
                    </section>
                    <section style={{ marginTop: '2em' }}>
                        <article>
                            <Typography
                                variant="h6"
                                component="h3"
                                className={
                                    clsx(
                                        classes.subtitle1Override,
                                        classes.capitalize
                                    )
                                }
                            >
                                {t('modals.contact.phone')}
                            </Typography>
                            <Grid container justifyContent="space-between" spacing={1}>
                                {airlinePhoneNumber
                                    ? (
                                        <ContactInformation
                                            contactDetails={{
                                                name: 'airline',
                                                phone: '+1 844 960 2850',
                                            }}
                                        />
                                    )
                                    : null
                                }
                                {contactDetails.map(details => (
                                    <ContactInformation
                                        key={details.name}
                                        contactDetails={details}
                                    />
                                ))}
                            </Grid>
                        </article>
                    </section>
                    <section className={buttonClasses.rootWithoutError}>
                        <SecondaryButton
                            onClick={onClose}
                        >
                            {t('buttonName.close')}
                        </SecondaryButton>
                    </section>
                </Grid>
            </Grid>
        </Modal>
    );
};

export default ContactUsModal;
