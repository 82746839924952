/* eslint-disable @typescript-eslint/restrict-template-expressions */
import {
    PrimaryButton,
    TableBody,
    TableContainer,
    TableHead,
} from '@get-e/react-components';
import AddIcon from '@mui/icons-material/Add';
import {
    FormControlLabel,
    Grid,
    Switch,
    Table,
    TableCell,
    TableRow,
    Typography,
    useMediaQuery,
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import moment from 'moment';
import React, { FunctionComponent, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';

import NoResults from '../../components/noResults/NoResults';
import { DUMMY_DATE } from '../../constants';
import { COLORS } from '../../constants/colors';
import { CREATE_AVAILABILITY, getEditAvailabilityRoute } from '../../constants/urlPaths';
import {
    getWhenMessage,
    resolveRepeatsInitialValue,
} from '../../helpers/repeats';
import theme from '../../styles/theme';
import TableRowDesktop from './components/TableRowDesktop';
import TableRowMobile from './components/TableRowMobile';
import { blockedPeriods } from './dummyData/dummyData';

const useStyles = makeStyles({
    root: {
        margin: '0',
        padding: '2rem',
    },
    rootMobile: {
        margin: '0 auto',
        padding: '1rem',
    },
    pageHeader: {
        display: 'flex',
        alignItems: 'center',
        paddingBottom: '2rem',
        color: COLORS.BLACK,
    },
    tableHead: { height: '56px' },
    pageHeaderMobile: {
        display: 'flex',
        flexDirection: 'column',
        paddingBottom: '2rem',
        color: COLORS.BLACK,
    },
    tableContainer: { background: COLORS.WHITE },
    tableCell: {
        color: COLORS.BLACK,
        background: COLORS.WHITE,
    },
    addButton: {
        width: '150px',
        paddingLeft: '0.25rem',
        paddingRight: '0.25rem',
    },
});

const Availability: FunctionComponent = () => {
    const classes = useStyles();
    const [showPastPeriod, setShowPastPeriod] = useState(false);
    const mobileView = useMediaQuery(theme.breakpoints.down('lg'));
    const { t } = useTranslation();
    const history = useHistory();

    const data = showPastPeriod ? blockedPeriods : blockedPeriods;

    const handleAddBlockPeriod = () => {
        history.push(CREATE_AVAILABILITY);
    };

    const handleEditBlockPeriod = (id: number) => {
        history.push(getEditAvailabilityRoute(id.toString()));
    };

    const handleDeleteBlockPeriod = async (id: number) => {

    };

    const togglePastPeriod = (isChecked: boolean) => {
        setShowPastPeriod(isChecked);
    };

    return (
        <Grid
            container
            alignItems="flex-start"
            sx={{
                padding: {
                    xs: '1rem',
                    md: '2rem',
                    xl: '2rem',
                },
            }}
        >
            <Grid item xs={6} md={8} xl={8}>
                <Typography
                    sx={{
                        color: COLORS.BLUE,
                        fontSize: '1.5rem',
                        fontWeight: 700,
                    }}
                >
                    {t('availability')}
                </Typography>
            </Grid>
            <Grid
                item
                xs={6}
                md={4}
                xl={4}
                sx={{
                    display: 'flex',
                    justifyContent: 'flex-end',
                    margin: '0',
                }}
            >
                <PrimaryButton
                    className={classes.addButton}
                    onClick={handleAddBlockPeriod}
                    icon={<AddIcon />}
                >
                    {t('blockPeriod')}
                </PrimaryButton>
            </Grid>
            <Grid container marginTop="2rem">
                <Grid item xs={12}>
                    <TableContainer className={classes.tableContainer}>
                        <Table size="small">
                            <TableHead
                                columns={mobileView ? 2 : 3}
                                className={classes.tableHead}
                            >
                                <TableRow>
                                    <TableCell
                                        className={classes.tableCell}
                                        style={{
                                            paddingRight: '0.5rem',
                                            paddingLeft: '0.5rem',
                                            color: COLORS.BLACK,
                                        }}
                                    >
                                        <Typography
                                            sx={{
                                                lineHeight: '2rem',
                                                borderRight: mobileView
                                                    ? 'none'
                                                    : `1px solid ${COLORS.BLACK_12}`,
                                            }}
                                        >
                                            {t('pages.availability.description')}
                                        </Typography>
                                    </TableCell>
                                    {!mobileView && (
                                        <TableCell
                                            className={classes.tableCell}
                                            style={{ paddingLeft: '0' }}
                                        >
                                            <Typography sx={{ lineHeight: '2rem' }}>
                                                {t('when')}
                                            </Typography>
                                        </TableCell>
                                    )}
                                    <TableCell
                                        style={{
                                            textAlign: 'right',
                                            paddingRight: '0.5rem',
                                        }}
                                    >
                                        <FormControlLabel
                                            control={
                                                <Switch
                                                    checked={showPastPeriod}
                                                    onChange={event =>
                                                        togglePastPeriod(event.target.checked)
                                                    }
                                                />
                                            }
                                            label="Show past period"
                                            sx={{
                                                marginLeft: 'auto',
                                                marginRight: '0',
                                                color: COLORS.BLACK,
                                            }}
                                        />
                                    </TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody loading={false}>
                                {!data.length && (
                                    <TableRow>
                                        <TableCell colSpan={3}>
                                            <NoResults />
                                        </TableCell>
                                    </TableRow>
                                )}
                                {data?.map(blockedPeriod => {
                                    const repeats = resolveRepeatsInitialValue(
                                        blockedPeriod.applicableOnDays
                                    );

                                    const blockedPeriodMessage = getWhenMessage(
                                        repeats,
                                        moment(blockedPeriod.start).toDate(),
                                        moment(blockedPeriod.end).toDate(),
                                        moment(
                                            `${DUMMY_DATE} ${blockedPeriod.applicableStartTime}`
                                        ).toDate(),
                                        moment(
                                            `${DUMMY_DATE} ${blockedPeriod.applicableEndTime}`
                                        ).toDate(),
                                        blockedPeriod.applicableOnDays ?? []
                                    );

                                    if (mobileView) {
                                        return (
                                            <TableRowMobile
                                                key={blockedPeriod.id}
                                                repeats={repeats}
                                                blockedPeriod={blockedPeriod}
                                                blockedPeriodMessage={blockedPeriodMessage}
                                                handleEditBlockPeriod={handleEditBlockPeriod}
                                                handleDeleteBlockPeriod={handleDeleteBlockPeriod}
                                            />
                                        );
                                    }

                                    return (
                                        <TableRowDesktop
                                            key={blockedPeriod.id}
                                            repeats={repeats}
                                            blockedPeriod={blockedPeriod}
                                            blockedPeriodMessage={blockedPeriodMessage}
                                            handleEditBlockPeriod={handleEditBlockPeriod}
                                            handleDeleteBlockPeriod={handleDeleteBlockPeriod}
                                        />
                                    );
                                })}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Grid>
            </Grid>
        </Grid>
    );
};

export default Availability;
