import { Form, PrimaryButton, SecondaryButton, TextField } from '@get-e/react-components';
import { Grid } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { AxiosError, AxiosResponse } from 'axios';
import React, { FunctionComponent, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useMutation as useReactQueryMutation } from 'react-query';

import { InputError } from '../../../../helpers/inputValidation/InputError';
import and from '../../../../helpers/inputValidation/validators/and';
import isEmail from '../../../../helpers/inputValidation/validators/isEmail';
import isFilledString from '../../../../helpers/inputValidation/validators/isFilledString';
import { User } from '../../../../services/types';
import { updateUserProfile } from '../../../../services/userProfile';
import useFormStyles from '../../../../styles/Form';

const useStyles = makeStyles({ button: { width: '100%' } });

interface EditUserFields {
    id: string;
    firstName: string;
    lastName: string;
    email: string;
}

interface EditUserError {
    firstName: string | null;
    lastName: string | null;
    email: string | null;
}

const UpdateUserProfileForm: FunctionComponent<{
    user: User;
    onClose: () => void;
    onUserProfileUpdated?: () => Promise<void>;
}> = ({ user, onClose, onUserProfileUpdated }) => {
    const formClasses = useFormStyles();
    const classes = useStyles();
    const { t } = useTranslation();

    const [values, setValues] = useState<EditUserFields>({
        id: user.id,
        firstName: user.firstName,
        lastName: user.lastName,
        email: user.email,
    });

    const [formErrors, setFormErrors] = useState<EditUserError>({
        firstName: null,
        lastName: null,
        email: null,
    });

    const { mutate: updateUserProfileMutation, isLoading } = useReactQueryMutation(updateUserProfile, {
        onSuccess: () => {
            onUserProfileUpdated && onUserProfileUpdated();
            onClose();
        },
        onError: (error: AxiosError) => {
            onUserProfileUpdated && onUserProfileUpdated();
            handleServerErrors(error);
        },
    });

    const handleServerErrors = (errors: AxiosError) => {
        const serverErrors = (errors.response as AxiosResponse).data.errors;

        const fieldErrors: EditUserError = {
            firstName: serverErrors.firstName ? serverErrors.firstName[0] : null,
            lastName: serverErrors.lastName ? serverErrors.lastName[0] : null,
            email: serverErrors.email ? serverErrors.email[0] : null,
        };

        setFormErrors({ ...fieldErrors });
    };

    const handleUpdateProfile = () => {
        updateUserProfileMutation({
            email: values.email.trim(),
            firstName: values.firstName.trim(),
            lastName: values.lastName.trim(),
        });
    };

    const handleSubmit = (event?: React.FormEvent<HTMLFormElement>) => {
        event?.preventDefault();

        if (!validateFields()) {
            return;
        }

        handleUpdateProfile();
    };

    const handleChange = <T extends keyof EditUserFields>(key: T, newValue: EditUserFields[T] & string): void => {
        setValues({
            ...values,
            [key]: newValue,
        });
        setFormErrors(prevStateForm => ({
            ...prevStateForm,
            [key]: null,
        }));
    };

    const validateFields = (): boolean => {
        const validated = {
            firstName: isFilledString(values.firstName, InputError.Empty),
            lastName: isFilledString(values.lastName, InputError.Empty),
            email: and(isFilledString(values.email, InputError.Empty), () => isEmail(values.email, InputError.InvalidEmail)),
        };

        const fieldErrors: EditUserError = {
            firstName: validated.firstName.isValid ? null : t(validated.firstName.error),
            lastName: validated.lastName.isValid ? null : t(validated.lastName.error),
            email: validated.email.isValid ? null : t(validated.email.error),
        };

        const isValid = Object.values(fieldErrors).every(error => error === null);

        !isValid && setFormErrors(fieldErrors);

        return isValid;
    };

    return (
        <Form className={formClasses.root} autoComplete="off" noValidate onSubmit={handleSubmit}>
            <TextField
                className={formClasses.textField}
                error={formErrors.firstName !== null}
                helperText={formErrors.firstName && formErrors.firstName}
                label={t('pages.users.fields.firstName')}
                name="firstName"
                onChange={event => handleChange('firstName', event.target.value)}
                required
                value={values.firstName}
            />
            <TextField
                className={formClasses.textField}
                error={formErrors.lastName !== null}
                helperText={formErrors.lastName && formErrors.lastName}
                label={t('pages.users.fields.lastName')}
                name="lastName"
                onChange={event => handleChange('lastName', event.target.value)}
                required
                value={values.lastName}
            />
            <TextField
                className={formClasses.textField}
                error={formErrors.email !== null}
                inputProps={{ autoCapitalize: 'none' }}
                helperText={formErrors.email && formErrors.email}
                label={t('pages.users.fields.email')}
                name="email"
                onChange={event => handleChange('email', event.target.value)}
                required
                value={values.email}
            />
            {user.customLogin && (
                <TextField
                    label={t('pages.users.fields.customLogin')}
                    value={user.customLogin}
                    disabled={true}
                    className={formClasses.customLogin}
                />
            )}
            <Grid container columnSpacing={4} spacing={4}>
                <Grid item xs={6}>
                    <PrimaryButton
                        loading={isLoading}
                        disabled={isLoading}
                        onClick={() => handleSubmit()}
                        className={classes.button}
                    >
                        {t('buttonName.update')}
                    </PrimaryButton>
                </Grid>
                <Grid item xs={6}>
                    <SecondaryButton onClick={() => onClose()} disabled={isLoading} className={classes.button}>
                        {t('buttonName.cancel')}
                    </SecondaryButton>
                </Grid>
            </Grid>
        </Form>
    );
};

export default UpdateUserProfileForm;
