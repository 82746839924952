import { Rating, Typography, useMediaQuery } from '@mui/material';
import { Container } from '@mui/system';
import { GridRowParams, GRID_DETAIL_PANEL_TOGGLE_COL_DEF, GridRowId } from '@mui/x-data-grid-pro';
import clsx from 'clsx';
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { DetailPanelToggle } from '../../../../components/detailPanelToggle/DetailPanelToggle';

import { COLORS } from '../../../../constants/colors';
import useDataGridStyles from '../../../../styles/DataGrid';
import theme from '../../../../styles/theme';

const COLUMN_WIDTH = 230;

export const useReviewsColumns = () => {
    const classes = useDataGridStyles();
    const { t } = useTranslation();
    const isMobile = useMediaQuery(theme.breakpoints.down('md'));

    return useMemo(() => {
        const columns = [
            {
                field: 'pickUpTime',
                minWidth: COLUMN_WIDTH,
                flex: 1,
                sortable: false,
                disableColumnMenu: true,
                renderHeader: () => (
                    <Container
                        className={clsx({
                            [classes.tableHeader]: true,
                            [classes.noRightBorder]: isMobile,
                        })}
                    >
                        <Typography>{isMobile ? t('ride') : t('pages.reports.pickUpTime')}</Typography>
                    </Container>
                ),
            },
            {
                field: 'rideNumber',
                minWidth: COLUMN_WIDTH,
                hide: isMobile,
                flex: 1,
                sortable: false,
                disableColumnMenu: true,
                renderHeader: () => (
                    <Container className={classes.tableHeader}>
                        <Typography>{t('pages.reports.rideNumber')}</Typography>
                    </Container>
                ),
                renderCell: (params: GridRowParams) => (
                    <Link to={'/'}>
                        <Typography color={COLORS.BLUE}>{params.row.rideNumber}</Typography>
                    </Link>
                ),
            },
            {
                field: 'driver',
                minWidth: COLUMN_WIDTH,
                flex: 1,
                sortable: false,
                hide: isMobile,
                disableColumnMenu: true,
                renderHeader: () => (
                    <Container className={classes.tableHeader}>
                        <Typography>{t('pages.reports.driver')}</Typography>
                    </Container>
                ),
            },
            {
                field: 'averageRating',
                minWidth: COLUMN_WIDTH,
                flex: 1,
                headerClassName: classes.hideSeparator,
                sortable: false,
                disableColumnMenu: true,
                hide: isMobile,
                renderHeader: () => (
                    <Container className={clsx(classes.tableHeader, classes.noRightBorder)}>
                        <Typography>{t('pages.reports.averageRating')}</Typography>
                    </Container>
                ),
                renderCell: (params: GridRowParams) => (
                    <>
                        {params.row.averageRating}
                        <Rating name="read-only" value={params.row.averageRating} readOnly sx={{ paddingLeft: '1rem' }} />
                    </>
                ),
            },
            {
                ...GRID_DETAIL_PANEL_TOGGLE_COL_DEF,
                headerClassName: classes.hideSeparator,
                renderCell: (params: { id: GridRowId; value: boolean }) =>
                    <DetailPanelToggle id={params.id} value={params.value} />,
            },
        ];

        return columns;
    }, [t, classes.hideSeparator, classes.tableHeader, classes.noRightBorder, isMobile]);
};

