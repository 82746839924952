import SkipPreviousIcon from '@material-ui/icons/SkipPrevious';
import { Button } from '@mui/material';
import React from 'react';

const SkipPreviousButton = (
    { disabled, onClick }: {
        disabled: boolean;
        onClick: () => void;
    }
): JSX.Element => (
    <Button
        onClick={onClick}
        disabled={disabled}
        data-testid="skip-previous-button"
    >
        <SkipPreviousIcon fontSize="small"/>
    </Button>
);

export default SkipPreviousButton;
