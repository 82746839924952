
/* eslint-disable max-statements */
import { useQuery } from '@apollo/react-hooks';
import { TabPanel } from '@get-e/react-components';
import { GridRowId } from '@mui/x-data-grid';
import { DataGridPro } from '@mui/x-data-grid-pro';
import { ApolloQueryResult, FetchMoreOptions } from 'apollo-client';
import React, { FunctionComponent, useEffect, useState, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { Route, Switch, useRouteMatch } from 'react-router-dom';

import { causesRedirect } from '../../../../ApolloClient/createErrorHandler';
import config from '../../../../config';
import { useCurrentUserContext } from '../../../../context/CurrentUserContext';
import { mapUsers } from '../../../../helpers/maps/mapUsers';
import { initializeZendeskStyles, setZendeskVerticalOffset } from '../../../../helpers/prefillZendeskConfig';
import { report } from '../../../../helpers/report';
import { default as useURLQuery } from '../../../../hooks/useQuery';
import useDataGridStyles from '../../../../styles/DataGrid';
import { useUserColumns } from '../hooks/useUserColumns';
import { ActiveTab } from '../Users';
import { GET_EXISTING_USERS, GetAllUsers, User } from '../Users.graphql';
import NoResults from './NoResults';
import ReactivateUserModal from './ReactivateUserModal';
import UsersTabError from './UsersTabError';

const DeactivatedUsersDataGrid: FunctionComponent<{
    value: ActiveTab;
    onUserCountChange: (val: number) => void;
    onLoaded: () => void;
}> = ({ value, onUserCountChange, onLoaded }) => {
    const query = useURLQuery();
    const after = query.get('after');
    const before = query.get('before');
    const { path } = useRouteMatch();
    const { t } = useTranslation();
    const [reactivatedUser, setReactivatedUser] = useState<User | null>(null);
    const classes = useDataGridStyles();
    const mapPageToNextCursor = useRef<{ [page: number]: GridRowId }>({});
    const mapPageToPreviousCursor = useRef<{ [page: number]: GridRowId }>({});
    const [page, setPage] = useState(0);
    const [nextCursor, setNextCursor] = useState<string | null>('');
    const [previousCursor, setPreviousCursor] = useState<string | null>('');
    const [totalCount, setTotalCount] = useState<number>(0);
    const [rowCount, setRowCount] = useState(totalCount ?? 0);
    const onReactivateClick = (user: User): void => setReactivatedUser(user);
    const { currentUser } = useCurrentUserContext();
    const columns = useUserColumns(t, { onReactivateClick }, currentUser.id);

    const { data, loading, error, fetchMore, refetch } = useQuery<GetAllUsers>(GET_EXISTING_USERS, {
        variables: {
            isDeactivated: true,
            after,
            before,
        },
        onCompleted() {
            onLoaded();

            if (data) {
                onUserCountChange(data.me.company.usersPage.totalCount);
            }
        },
        onError(apolloError) {
            onLoaded();
            report(apolloError);
        },
        notifyOnNetworkStatusChange: true,
    });

    useEffect(() => {
        if (data) {
            onUserCountChange(data.me.company.usersPage.totalCount);
        }
    }, [data, onUserCountChange]);

    useEffect(() => {
        if (data) {
            mapPageToNextCursor.current[page] = '';
            mapPageToPreviousCursor.current[page] = '';
            setPage(0);
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [totalCount]);

    useEffect(() => {
        if (data) {
            setNextCursor(data.me.company.usersPage.nextCursor);
            setPreviousCursor(data.me.company.usersPage.previousCursor);
            setTotalCount(data.me.company.usersPage.totalCount);
        }
    }, [data, mapPageToNextCursor, mapPageToPreviousCursor]);

    useEffect(() => {
        if (nextCursor) {
            mapPageToNextCursor.current[page] = nextCursor;
        }

        if (previousCursor) {
            mapPageToPreviousCursor.current[page] = previousCursor;
        }
    }, [page, nextCursor, previousCursor]);

    useEffect(() => {
        setRowCount(prevRowCount => totalCount ?? prevRowCount);
    }, [totalCount, setRowCount]);

    const handlePageChange = (newPage: number): Promise<ApolloQueryResult<GetAllUsers>> | null => {
        const isAfter = newPage > page;

        if (newPage === 0 || mapPageToNextCursor.current[newPage - 1] || mapPageToPreviousCursor.current[newPage - 1]) {
            setPage(newPage);
        }

        return fetchMore({
            variables: {
                after: isAfter ? nextCursor : null,
                before: !isAfter ? previousCursor : null,
            },
            updateQuery,
        });
    };

    const updateQuery: FetchMoreOptions<GetAllUsers>['updateQuery'] = (previousResult, { fetchMoreResult }) => {
        if (!fetchMoreResult) {
            return previousResult;
        }

        return fetchMoreResult;
    };

    useEffect((): void => {
        if (!data || value !== ActiveTab.Deactivated) {
            return;
        }

        if (data.me.company.usersPage.totalCount > config.paginationLimit) {
            setZendeskVerticalOffset(50);
        } else {
            initializeZendeskStyles();
        }
    }, [data, value]);

    if (error) {
        if (causesRedirect(error)) {
            return null;
        }

        return <UsersTabError value={value} index={ActiveTab.Deactivated} onRetryClick={refetch} />;
    }

    if (!data?.me.company.usersPage.items.length) {
        return <NoResults value={value} index={ActiveTab.Deactivated} />;
    }

    const { items } = data.me.company.usersPage;

    return (
        <TabPanel selectedValue={value} value={ActiveTab.Deactivated}>
            <Switch>
                <Route path={`${path}/deactivated`}>
                    <DataGridPro
                        disableColumnMenu
                        autoHeight
                        rows={mapUsers(t, items)}
                        columns={columns}
                        className={classes.dataGrid}
                        pagination
                        pageSize={config.paginationLimit}
                        rowsPerPageOptions={[config.paginationLimit]}
                        rowCount={rowCount}
                        paginationMode="server"
                        onPageChange={handlePageChange}
                        page={page}
                        loading={loading || !data}
                        hideFooterSelectedRowCount
                    />
                    {reactivatedUser
                    && <ReactivateUserModal onClose={() => setReactivatedUser(null)} user={reactivatedUser} />}
                </Route>
            </Switch>
        </TabPanel>
    );
};

export default DeactivatedUsersDataGrid;
