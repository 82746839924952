import TuneOutlinedIcon from '@mui/icons-material/TuneOutlined';
import { ButtonBase } from '@mui/material';
import React, { FunctionComponent } from 'react';
import { useTranslation } from 'react-i18next';

import { COLORS } from '../../../constants/colors';

interface FilterButtonProps {
    onClick?: () => void;
}

const FilterButton: FunctionComponent<FilterButtonProps> = ({ onClick }) => {
    const { t } = useTranslation();

    return (
        <ButtonBase
            component="div"
            onClick={onClick}
            style={{
                position: 'absolute',
                background: COLORS.WHITE,
                bottom: '-33px',
                left: 'calc(50% - 4rem)',
                width: '150px',
                height: '44px',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                boxShadow: `0px 4px 20px ${COLORS.BLACK_12}`,
                borderRadius: '4px',
            }}
        >
            {t('filter')}<TuneOutlinedIcon style={{ paddingLeft: '0.5rem' }} />
        </ButtonBase>
    );
};

export default FilterButton;
