import { Menu, Button } from '@mui/material';
import React, { FunctionComponent, ReactNode, useRef } from 'react';

import simpleMenuContainerStyles from './SimpleMenuContainer.styles';

export interface SimpleMenuProps {
    testId: string;
    buttonName: string|JSX.Element;
    children: ReactNode;
    open: boolean;
    onOpen: () => void;
    onClose: () => void;
    className?: string;
}

const SimpleMenuContainer: FunctionComponent<SimpleMenuProps> = ({
    testId,
    buttonName,
    children,
    open,
    onOpen,
    onClose,
    className,
}) => {
    const classes = simpleMenuContainerStyles();
    const buttonRef = useRef(null);
    const anchorEl = open ? buttonRef.current : null;

    return (
        <div className={classes.menuContainer}>
            <Button
                aria-controls={`${testId}-simple-menu`}
                aria-haspopup="true"
                onClick={onOpen}
                data-testid={`${testId}-menu-button`}
                ref={buttonRef}
                className={className ?? ''}
            >
                {buttonName}
            </Button>
            <Menu
                id={`${testId}-simple-menu`}
                className={classes.menu}
                anchorEl={anchorEl}
                keepMounted
                open={open}
                onClose={onClose}
                data-testid={`${testId}-menu`}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'center',
                }}
            >
                {children}
            </Menu>
        </div>
    );
};

export default SimpleMenuContainer;
