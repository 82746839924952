export const DATE_FORMATS = {
    'DD MMM yyyy': 'DD MMM yyyy',
    'ddd, DD MMM YYYY': 'ddd, DD MMM YYYY',
    'ddd DD MMM YYYY': 'ddd DD MMM YYYY',
    'ddd DD-MMM-YYYY HH:mm A': 'ddd DD-MMM-YYYY HH:mm A',
    'YYYY-MM-DDTHH:mm:ss': 'YYYY-MM-DDTHH:mm:ss',
    'ddd, DD MMM YYYY at HH:mm A': 'ddd, DD MMM YYYY [at] HH:mm A',
    'ddd DD MMM YYYY at HH:mm A': 'ddd DD MMM YYYY [at] HH:mm A',
    'DD MMM at HH:mm A': 'DD MMM [at] HH:mm A',
    'YYYY-MM-DD HH:mm:ss': 'YYYY-MM-DD HH:mm:ss',
    'YYYY-MM-DD': 'YYYY-MM-DD',
    'dd MMM yyyy hh:mm a': 'dd MMM yyyy hh:mm a',
    'EEE, dd MMM yyyy at HH:mm a': "EEE, dd MMM yyyy 'at' HH:mm a",
    'dd MMM yyyy': 'dd MMM yyyy',
    'EEE, dd MMM yyyy': 'EEE, dd MMM yyyy',
    'MMM Do HH:mm A': 'MMM Do HH:mm A',
    MMM: 'MMM',
    MMMM: 'MMMM',
    'YYYY-MM-DDT': 'YYYY-MM-DDT',
};
