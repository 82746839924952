import gql from 'graphql-tag';
import config from '../../config';
import { DocumentItem } from '../documents/Document.graphql';
import { User } from '../users/customer/Users.graphql';

export interface CurrentUserProps {
    me: {
        id: string;
        customLogin: string | null;
        email: string;
        firstName: string;
        fullName: string;
        isDeactivated: boolean;
        joinedAt: string | null;
        lastName: string;
        lastSeen: string | null;
        permissions: {
            manageUsers: boolean;
            manageRidesBookedByOthers: boolean;
        };
        company: {
            isBackOffice: boolean;
            isCustomer: boolean;
            isSupplier: boolean;
            documentsPage: {
                items: DocumentItem[];
            };
            customer: {
                bookingToolSubdomain: string | null;
                features: {
                    airlinePhoneNumber: boolean;
                    cityJetPricing: boolean;
                    cityJetPendingChanges: boolean;
                };
            };
        };
    };
}

export interface CurrentUser extends User {
    company: {
        documentsPage: {
            items: DocumentItem[];
        };
        customer: {
            bookingToolSubdomain: string | null;
            features: {
                airlinePhoneNumber: boolean;
                cityJetPricing: boolean;
                cityJetPendingChanges: boolean;
            };
        };
    };
}

export const GET_CURRENT_USER = gql`
    query {
        me {
            id
            email
            customLogin
            firstName
            lastName
            fullName
            lastSeen
            joinedAt
            isDeactivated
            company {
                isBackOffice
                isCustomer
                isSupplier
                documentsPage (
                    first: ${config.paginationLimit}, 
                ) {
                    items {
                        label
                        url
                    }
                }
                customer {
                    bookingToolSubdomain
                    features {
                        airlinePhoneNumber
                        cityJetPricing
                        cityJetPendingChanges
                    }
                }
            }
            permissions {
                manageUsers
                manageRidesBookedByOthers
            }
        }
    }
`;
