import { PrimaryButton, TextField as CustomTextField } from '@get-e/react-components';
import AddIcon from '@mui/icons-material/Add';
import SearchIcon from '@mui/icons-material/Search';
import { Grid, Typography, InputAdornment } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { DataGridPro, GridColumns, GridValidRowModel, GridRowsProp } from '@mui/x-data-grid-pro';
import clsx from 'clsx';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import uuid from 'react-uuid';

import { CustomNoRowsOverlay } from '../../components/noRowsDataGridOverlay/CustomNoRowsOverlay';
import { COLORS } from '../../constants/colors';
import useDataGridStyles from '../../styles/DataGrid';
import AddDriverModal from '../ride/supplier/components/AddDriverModal';
import useSupplierDriverInformation, { DriverInformation } from '../ride/supplier/hooks/useSupplierDriverInformation';
import DeleteDriverModal from './components/DeleteDriverModal';
import EditDriverModal from './components/EditDriverModal';
import { useDriversColumns } from './hooks/useDriversColumns';
import { useDriversColumnsMobile } from './hooks/useDriversColumnsMobile';

const useStyles = makeStyles({ addRideButton: { width: '150px' } });

const rows: GridRowsProp = [
    {
        id: uuid(),
        name: 'name',
        phoneNumber: 'Phone number',
        rideAssigned: '1',
    },
    {
        id: uuid(),
        name: 'Name',
        phoneNumber: 'Phone number',
        rideAssigned: '1',
    },
    {
        id: uuid(),
        name: 'Name',
        phoneNumber: 'Phone number',
        rideAssigned: '1',
    },
    {
        id: uuid(),
        name: 'Name',
        phoneNumber: 'Phone number',
        rideAssigned: '1',
    },
];

const Drivers = () => {
    const classes = useStyles();
    const dataGridClases = useDataGridStyles();
    const { t } = useTranslation();
    const [searchPhrase, setSearchPhrase] = useState('');
    const [isAddDriverModalOpen, setIsAddDriverModalOpen] = useState(false);

    const [isConfirmDeleteModalOpen, setIsConfirmDeleteModalOpen] = useState(false);

    const [isEditDriverModalOpen, setIsEditDriverModalOpen] = useState(false);

    const handleDelete = () => setIsConfirmDeleteModalOpen(true);

    const handleEdit = () => setIsEditDriverModalOpen(true);

    const columns = useDriversColumns(handleDelete, handleEdit);

    const { addDriverInformation } = useSupplierDriverInformation();

    const handleAddDriverInformation = (newDriverInformation: DriverInformation) => {
        addDriverInformation(newDriverInformation);
        setIsAddDriverModalOpen(false);
    };

    const handleEditDriverInformation = (newDriverInformation: DriverInformation) => {
        setIsEditDriverModalOpen(false);
    };

    return (
        <Grid container alignItems="flex-start" padding="2rem">
            <Grid item xs={12} md={8} xl={8}>
                <Typography
                    sx={{
                        color: COLORS.BLUE,
                        fontSize: '1.5rem',
                        fontWeight: 700,
                    }}
                >
                    {t('pages.drivers.drivers')}
                </Typography>
            </Grid>
            <Grid
                item
                xs={12}
                md={4}
                xl={4}
                sx={{
                    display: 'flex',
                    justifyContent: ['flex-start', 'flex-start', 'flex-end'],
                    marginTop: ['1rem', '1rem', '0'],
                }}
            >
                <PrimaryButton className={classes.addRideButton} onClick={() => setIsAddDriverModalOpen(true)} icon={<AddIcon />}>
                    {t('pages.drivers.newDriver')}
                </PrimaryButton>
            </Grid>
            <Grid container sx={{ marginTop: '2rem' }}>
                <Grid item xs={12} md={4} xl={4}>
                    <CustomTextField
                        id="searchByNameOrPhoneNumber"
                        autoComplete="off"
                        value={searchPhrase}
                        noHelperTextSpace
                        onChange={event => {
                            setSearchPhrase(event.target.value);
                        }}
                        label={t('pages.drivers.searchByNameOrPhoneNumber')}
                        name="search"
                        InputProps={{
                            endAdornment: (
                                <InputAdornment position="end">
                                    <SearchIcon />
                                </InputAdornment>
                            ),
                        }}
                    />
                </Grid>
            </Grid>
            <DataGridPro
                className={clsx({
                    [dataGridClases.dataGrid]: true,
                    [dataGridClases.dataGridNoRows]: Boolean(rows),
                })}
                autoHeight
                hideFooter
                disableColumnSelector
                disableColumnFilter
                rows={rows}
                columns={columns as unknown as GridColumns<GridValidRowModel>}
                components={{ NoRowsOverlay: CustomNoRowsOverlay }}
                componentsProps={{ noRowsOverlay: { noRows: t('noResultsFound') } }}
                getDetailPanelContent={useDriversColumnsMobile(handleDelete, handleEdit)}
                getDetailPanelHeight={() => 'auto'}
            />
            {isAddDriverModalOpen && (
                <AddDriverModal
                    isModalOpen={isAddDriverModalOpen}
                    onClose={() => setIsAddDriverModalOpen(false)}
                    onAddDriver={handleAddDriverInformation}
                    isSaving={false}
                />
            )}
            {isEditDriverModalOpen && (
                <EditDriverModal
                    isModalOpen={isEditDriverModalOpen}
                    onClose={() => setIsEditDriverModalOpen(false)}
                    onEditDriver={handleEditDriverInformation}
                    isSaving={false}
                />
            )}
            {isConfirmDeleteModalOpen && <DeleteDriverModal onClose={() => setIsConfirmDeleteModalOpen(false)} />}
        </Grid>
    );
};

export default Drivers;
