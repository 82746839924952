import Skeleton from '@material-ui/lab/Skeleton';
import { Grid } from '@mui/material';
import { makeStyles } from '@mui/styles';
import React from 'react';

const useStyles = makeStyles({
    skeletonContainer: {
        width: '100%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        '& [class*="MuiSkeleton-text"]': { margin: '1em 0.5em 1em 0' },
    },
});

const BookingToolSkeleton = (): JSX.Element => {
    const classes = useStyles();

    return (
        <div className={classes.skeletonContainer}>
            <Grid container alignContent="space-between" spacing={2}>
                <Grid
                    container
                    justifyContent="space-between"
                    spacing={3}
                    alignItems="center"
                    item
                    style={{
                        maxWidth: '1000px',
                        margin: '0 auto',
                        height: 'auto',
                    }}
                >
                    <Grid item>
                        <Skeleton
                            animation="wave"
                            variant="rect"
                            height="50px"
                            width="160px"
                        />
                    </Grid>
                    <Grid item>
                        <Grid container spacing={2}>
                            <Grid item xs>
                                <Skeleton
                                    animation="wave"
                                    variant="rect"
                                    height="35px"
                                    width="85px"
                                />
                            </Grid>
                            <Grid item>

                                <Skeleton
                                    animation="wave"
                                    variant="rect"
                                    height="35px"
                                    width="45px"
                                />
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={12}>
                    <Skeleton
                        animation="wave"
                        variant="rect"
                        height="590px"
                        width="100%"
                    />
                </Grid>
                <Grid item xs={12}>
                    <Grid container>
                        <Grid item xs={12}>
                            <Skeleton
                                animation="wave"
                                variant="rect"
                                height="40px"
                                width="200px"
                                style={{ margin: '3em auto 0' }}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <Skeleton
                                animation="wave"
                                variant="rect"
                                height="40px"
                                width="670px"
                                style={{ margin: '1em auto 0' }}
                            />
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </div>
    );
};

export default BookingToolSkeleton;
