import { AppBar, Grid, Toolbar, useMediaQuery, useTheme } from '@mui/material';
import React from 'react';
import useAppBarStyles from '../../styles/AppBar';
import NextButton from '../buttons/pagination/NextButton';
import PreviousButton from '../buttons/pagination/PreviousButton';
import SkipPreviousButton from '../buttons/pagination/SkipPreviousButton';

interface PaginationProps {
    onNextClick: () => void;
    onPreviousClick: () => void;
    onBackToStartClick: () => void;
    hasNext: boolean;
    hasPrevious: boolean;
}

const Pagination = (
    {
        onNextClick,
        onPreviousClick,
        hasNext,
        hasPrevious,
        onBackToStartClick,
    }: PaginationProps
): JSX.Element => {
    const theme = useTheme();
    const mobileView = useMediaQuery(theme.breakpoints.down('xs'));
    const classes = useAppBarStyles();

    const content = (
        <>
            <Grid item xs={mobileView}>
                <Grid container justifyContent="flex-end">
                    <nav
                        role="navigation"
                        aria-label="Pagination"
                        data-testid="pagination"
                    >
                        <SkipPreviousButton
                            onClick={onBackToStartClick}
                            disabled={hasPrevious}
                        />
                        <PreviousButton
                            onClick={onPreviousClick}
                            disabled={hasPrevious}
                        />
                        <NextButton
                            onClick={onNextClick}
                            disabled={hasNext}
                        />
                    </nav>
                </Grid>
            </Grid></>
    );

    if (mobileView) {
        return (
            <AppBar position="fixed" className={classes.mobileAppBar}>
                <Toolbar>
                    <Grid
                        container
                        direction="row"
                        justifyContent="space-between"
                        alignItems="center"
                    >
                        {content}
                    </Grid>
                </Toolbar>
            </AppBar>
        );
    }

    return (
        <Grid
            container
            justifyContent="flex-end"
            alignItems="center"
            style={{ margin: '0.75em 0 0.25em' }}
        >
            {content}
        </Grid>
    );
};

export default Pagination;
