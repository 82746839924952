/* eslint-disable @typescript-eslint/no-explicit-any */
import {
    Box,
    TableCell,
    TableRow,
    Typography,
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import React from 'react';
import { Link } from 'react-router-dom';

import { COLORS } from '../../../constants/colors';
import { TABLE_ROW_HEIGHT } from '../../../styles/theme';
import { getStatusIcon } from '../helpers';

const useStyles = makeStyles({
    tableRow: {
        height: `${TABLE_ROW_HEIGHT}px`,

        transition: '150ms all ease-in-out',
    },
    tableCell: {
        color: COLORS.BLACK,
        background: COLORS.EXTRA_LIGHT_GRAY,
    },
});

interface DetailsRowProps {
    ride: any;
}

const DetailsRow = ({ ride }: DetailsRowProps) => {
    const classes = useStyles();

    return (
        <>
            <TableRow
                key={ride.id}
                className={classes.tableRow}
                hover
            >
                <TableCell
                    className={classes.tableCell}
                    sx={{ padding: '0.5rem' }}
                >
                    {ride.pickup}
                </TableCell>
                <TableCell
                    className={classes.tableCell}
                    sx={{ padding: '0.5rem' }}
                >
                    <Link to={'/'}>
                        <Typography color={COLORS.BLUE}>{ride.rideNumber}</Typography>
                    </Link>
                </TableCell>
                <TableCell
                    className={classes.tableCell}
                    sx={{ padding: '0.5rem 0.5rem 0.5rem 0' }}
                >
                    <Box display="flex" alignItems="center">
                        {getStatusIcon(ride.driverUpdatesStatus)}
                        {ride.driverUpdatesStatus}
                    </Box>
                </TableCell>
                <TableCell
                    className={classes.tableCell}
                    sx={{ padding: '0.5rem 0.5rem 0.5rem 0' }}
                >
                    <Box display="flex" alignItems="center">
                        {getStatusIcon(ride.locationSharingStatus)}
                        {ride.locationSharingStatus}
                    </Box>
                </TableCell>
            </TableRow>
        </>
    );
};

export default DetailsRow;
