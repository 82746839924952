import Environment from './Environment';

const currentEnvironment = ((): Environment => {
    const nodeEnv = process.env.NODE_ENV as string;

    switch (nodeEnv) {
        case 'development':
            return Environment.Development;
        case 'test':
            return Environment.Test;
        case 'production':
            return Environment.Production;
        default:
            throw new Error(`Unknown environment "${nodeEnv}"`);
    }
})();

export default currentEnvironment;
