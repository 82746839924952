import { PortalLocale } from '../context/LocaleContext';

const supportedLocales: Array<{locale: string; code: PortalLocale}> = [
    {
        locale: 'en',
        code: 'en-GB',
    },
    {
        locale: 'en-au',
        code: 'en-GB',
    },
    {
        locale: 'en-ca',
        code: 'en-GB',
    },
    {
        locale: 'en-cb',
        code: 'en-GB',
    },
    {
        locale: 'en-gb',
        code: 'en-GB',
    },
    {
        locale: 'en-ie',
        code: 'en-GB',
    },
    {
        locale: 'en-in',
        code: 'en-GB',
    },
    {
        locale: 'en-jm',
        code: 'en-GB',
    },
    {
        locale: 'en-ph',
        code: 'en-GB',
    },
    {
        locale: 'en-nz',
        code: 'en-GB',
    },
    {
        locale: 'en-tt',
        code: 'en-GB',
    },
    {
        locale: 'en-us',
        code: 'en-GB',
    },
    {
        locale: 'en-za',
        code: 'en-GB',
    },
];

export default supportedLocales;
