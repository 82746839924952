import { ApolloError } from 'apollo-client';
import { fromPromise } from 'apollo-link';
import { ErrorHandler, ErrorResponse } from 'apollo-link-error';
import refreshCookie from '../helpers/refreshCookie';

export const causesRedirect = (error: ApolloError): boolean => error.graphQLErrors.some(
    graphqlErr => {
        const isUnauthenticated = graphqlErr?.extensions?.code === 'UNAUTHENTICATED';
        const hasInvalidToken = graphqlErr?.extensions?.code === 'INVALID_TOKEN';

        return isUnauthenticated || hasInvalidToken;
    }
);

export const createErrorHandler = (
    redirect: (path: string) => void
): ErrorHandler => (
    { graphQLErrors, operation, forward }: ErrorResponse
) => {
    if (!graphQLErrors) {
        return;
    }

    for (const error of graphQLErrors) {
        switch (error?.extensions?.code) {
            case 'INVALID_TOKEN':
                return fromPromise(
                    refreshCookie().then(result => {
                        switch (result.code) {
                            case 'UNAUTHENTICATED':
                                return redirect('/invalid-cookies');
                            case 'SUCCESS':
                                return;
                            default:
                                throw new Error('Unhandled result code');
                        }
                    })
                ).flatMap(() => forward(operation));
            case 'UNAUTHENTICATED':
                return fromPromise(
                    refreshCookie().then(result => {
                        switch (result.code) {
                            case 'UNAUTHENTICATED':
                                return redirect('/log-in');
                            case 'SUCCESS':
                                return;
                            default:
                                throw new Error('Unhandled result code');
                        }
                    })
                ).flatMap(() => forward(operation));
            default:
                continue;
        }
    }
};

export default createErrorHandler;
