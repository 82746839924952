import { Theme } from '@mui/material/styles';
import { makeStyles, createStyles } from '@mui/styles';

const simpleMenuContainerStyles = makeStyles((theme: Theme) => createStyles({
    menuContainer: {
        ...theme.typography.button,
        backgroundColor: theme.palette.background.paper,
        display: 'inline-block',
        textAlign: 'right',
        '& [class*="MuiButton-label"]': { fontWeight: theme.typography.fontWeightRegular },
        '& [class*="MuiButton-text"]': {
            padding: '0.5em 0',
            minWidth: 'unset',
        },
        textTransform: 'none',
    },
    menu: {
        '& [class*="MuiListItem-root"]': { '&:hover': { backgroundColor: theme.palette.grey[100] } },
        '& [class*="MuiListItem-gutters"]': { padding: theme.spacing(1.5, 2) },
        '& [class*="MuiList-padding"]': { padding: 0 },
        '& [class*="MuiListItemText-root"]': { margin: 0 },
    },
    userMenu: { padding: `${theme.spacing(1, 2)} !important` },
}));

export default simpleMenuContainerStyles;
