import TokenError from '../../constants/TokenError';
import { InputError } from './InputError';

export interface ApiValidationError {
    code: string;
}

const parseInputErrors = (
    errors: ApiValidationError[]|undefined,
): InputError|null => {
    if (!errors || !errors.length) {
        return null;
    }

    const { 0: priorityError } = errors;

    return mapInputError(priorityError);
};

const mapInputError = (error: ApiValidationError): InputError => {
    if (error.code === 'EMPTY') {
        return InputError.Empty;
    }

    if (error.code === 'CONTAINS_SPACES') {
        return InputError.ContainsWhiteSpace;
    }

    if (error.code === 'REQUIRES_LOWERCASE_LETTER') {
        return InputError.LowerCaseRequired;
    }

    if (error.code === 'REQUIRES_UPPERCASE_LETTER') {
        return InputError.UpperCaseRequired;
    }

    if (error.code === 'REQUIRES_NUMBER') {
        return InputError.NumberRequired;
    }

    if (error.code === 'REQUIRES_SPECIAL_CHARACTER') {
        return InputError.SpecialCharacterRequired;
    }

    if (error.code === 'TOO_LONG') {
        return InputError.TooLong;
    }

    if (error.code === 'INVALID_EMAIL_FORMAT') {
        return InputError.InvalidEmail;
    }

    throw new Error(`Unhandled error code ${error.code}`);
};

const parseTokenErrors = (errors: ApiValidationError[]|undefined): TokenError|null => {
    if (!errors || !errors.length) {
        return null;
    }

    const errorArray = errors.map(({ code }) => code);

    if (errorArray.includes('NOT_FOUND')) {
        return TokenError.NotFound;
    }

    if (errorArray.includes('EXPIRED')) {
        return TokenError.Expired;
    }

    throw new Error(`Unhandled error code ${errors[0].code}`);
};

const containsTooShort = (passwordError: ApiValidationError[]|undefined): boolean => {
    if (!passwordError || !passwordError.length) {
        return false;
    }

    return passwordError.some(error => error.code === 'TOO_SHORT');
};

const emailAlreadyExists = (emailError: ApiValidationError[]|undefined): boolean => {
    if (!emailError || !emailError.length) {
        return false;
    }

    return emailError.some(error => error.code === 'EXISTS');
};

export {
    parseTokenErrors,
    parseInputErrors,
    containsTooShort,
    emailAlreadyExists,
};
