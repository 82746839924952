import { v4 as uuidv4 } from 'uuid';

import { DriverInformation } from '../../ride/supplier/hooks/useSupplierDriverInformation';

export const getDriverInformation
    = {
        id: uuidv4(),
        name: 'Milica Okiljevic',
        mobile: '+381 23 233 2323',
        rating: '5.0',
        reviewsNumber: '30',
    } as DriverInformation;
