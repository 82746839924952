export const USE_STOP_SALES_RULE = 'use-stop-sales-rule';
export const USE_STOP_SALES_RULES = 'use-stop-sales-rules';
export const USE_PAST_STOP_SALES_RULES = 'use-past-stop-sales-rules';
export const USE_RIDES = 'use-rides';
export const USE_USER_PROFILE = 'use-user-profile';

export const USE_RIDE = 'use-ride';
export const USE_AVAILABILITIES = 'use-availabilities';
export const USE_SEARCH_LOCATION = 'use-search-location';
export const USE_NEW_FLIGHT_DETAILS = 'use-new-flight-details';
