import List from '@material-ui/core/List';
import FolderOutlinedIcon from '@material-ui/icons/FolderOutlined';
import ListIcon from '@material-ui/icons/List';
import PersonOutlineIcon from '@material-ui/icons/PersonOutline';
import ContactMailOutlinedIcon from '@mui/icons-material/ContactMailOutlined';
import EventBusyOutlinedIcon from '@mui/icons-material/EventBusyOutlined';
import StarBorderOutlinedIcon from '@mui/icons-material/StarBorderOutlined';
import { useFlags } from 'launchdarkly-react-client-sdk';
import React, { FunctionComponent } from 'react';
import { useTranslation } from 'react-i18next';

import { AVAILABILITY, DRIVERS, REPORTS, RIDES, USERS } from '../../../constants/urlPaths';
import { useCurrentUserContext } from '../../../context/CurrentUserContext';
import { MenuListItem } from '../../menuListItem/MenuListItem';
import navigationStyles from '../NavigationMenu.styles';

const SupplierList: FunctionComponent = () => {
    const classes = navigationStyles();
    const { t } = useTranslation();
    const { currentUser } = useCurrentUserContext();
    const { useDriversPage } = useFlags();

    return (
        <List
            role="menu"
            className={classes.listContainer}
            data-testid="supplier-navigation"
        >
            <MenuListItem
                icon={<ListIcon fontSize="small" />}
                label={t('navigation.supplier.rides')}
                href={RIDES}
            />
            {useDriversPage && (
                <MenuListItem
                    icon={<ContactMailOutlinedIcon fontSize="small" />}
                    label={t('navigation.supplier.drivers')}
                    href={DRIVERS}
                />
            )}
            <MenuListItem
                icon={<EventBusyOutlinedIcon fontSize="small" />}
                label={t('navigation.supplier.availability')}
                href={AVAILABILITY}
            />
            <MenuListItem
                icon={<StarBorderOutlinedIcon fontSize="small" />}
                label={t('navigation.supplier.reports')}
                href={REPORTS}
            />
            {currentUser.permissions.manageUsers
                && (
                    <MenuListItem
                        icon={<PersonOutlineIcon fontSize="small"/>}
                        label= {t('authentication.users')}
                        href={USERS}
                        data-testid="users-tab"
                    />
                )
            }
            {currentUser.company.documentsPage.items.length
                ? (
                    <MenuListItem
                        icon={<FolderOutlinedIcon fontSize="small"/>}
                        label={t('navigation.supplier.documents')}
                        href="/documents"
                        data-testid="document-tab"
                    />
                )
                : null
            }
        </List>
    );
};

export default SupplierList;
