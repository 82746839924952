import { createContext, useContext } from 'react';
import { CurrentUser } from '../pages/pageContainer/PageContainer.graphql';

export interface CurrentUserProps {
    currentUser: CurrentUser;
}

export const CurrentUserContext = createContext<CurrentUserProps|null>(null);

export const useCurrentUserContext = (): CurrentUserProps => {
    const currentUserContext = useContext(CurrentUserContext);

    if (currentUserContext === null) {
        throw new Error('CurrentUserContext should not be null');
    }

    return currentUserContext;
};
