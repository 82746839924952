/* eslint-disable @typescript-eslint/no-floating-promises */
import { MultipleSelect, PrimaryButton, Spinner, TextField } from '@get-e/react-components';
import AddIcon from '@mui/icons-material/Add';
import CloudDownloadOutlinedIcon from '@mui/icons-material/CloudDownloadOutlined';
import SearchIcon from '@mui/icons-material/Search';
import { Box, Grid, InputAdornment, Typography, TextField as CustomTextField } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import {
    DataGridPro, GridColumns, GridFooterContainer,
    GridRowParams, GridValidRowModel,
} from '@mui/x-data-grid-pro';
import { LocalizationProvider } from '@mui/x-date-pickers-pro';
import { AdapterMoment } from '@mui/x-date-pickers-pro/AdapterMoment';
import { DateRangePicker, DateRange } from '@mui/x-date-pickers-pro/DateRangePicker';
import clsx from 'clsx';
import moment from 'moment';
import React, { useEffect, useMemo, useState, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useMutation } from 'react-query';
import { useHistory } from 'react-router-dom';

import IconedData from '../../../components/iconedData/IconedData';
import { CustomNoRowsOverlay } from '../../../components/noRowsDataGridOverlay/CustomNoRowsOverlay';
import { COLORS } from '../../../constants/colors';
import { DATE_FORMATS } from '../../../constants/dateFormats';
import { BUTTON_WIDTH } from '../../../constants/layout';
import { BOOKING_TOOL, getSingleRideRoute } from '../../../constants/urlPaths';
import { mapRidesToRows } from '../../../helpers/maps/mapRidesToRows';
import { getRideStatusKey, getRideStatusMap, RideStatusFilter } from '../../../helpers/rideStatusUtil';
import useDataGridStyles from '../../../styles/DataGrid';
import { useUserProfile } from '../../pageContainer/api/useUserProfile';
import SendBookingConfirmationModal, { BookingConfirmationEmail } from '../../ride/components/SendBookingConfirmationModal';
import exportRidesToExcel from './api/exportRidesToExcel';
import { Passenger } from './api/types';
import { useRides } from './api/useRides';
import { BookingConfirmationRow, useRidesColumns } from './hooks/useRidesColumns';

const useStyles = makeStyles({
    addRideButton: { width: BUTTON_WIDTH },
    footer: {
        display: 'flex',
        justifyContent: 'flex-start',
        paddingLeft: '2rem',
    },
});

const CustomFooter = (handleExport: () => void) => {
    const classes = useStyles();
    const { t } = useTranslation();

    return (
        <GridFooterContainer className={classes.footer}>
            <IconedData
                icon={CloudDownloadOutlinedIcon}
                data={t('pages.rides.export')}
                dataColor={COLORS.SLATE_GREY}
                onClick={handleExport}
                dataTextBold
            />
        </GridFooterContainer>
    );
};

export enum RideAction {
    EDIT_RIDE,
    SEND_CONFIRMATION,
    DOWNLOAD_CONFIRMATION,
    DOWNLOAD_RECEIPT,
}

const currentDate = new Date();

const initialStatusIds = [
    getRideStatusKey(RideStatusFilter.BOOKED),
    getRideStatusKey(RideStatusFilter.ACTIVE),
];

const defaultSelectedValue = [RideStatusFilter.BOOKED, RideStatusFilter.ACTIVE];

const Rides = () => {
    const classes = useStyles();
    const history = useHistory();
    const dataGridClases = useDataGridStyles();
    const [isSendBookingConfirmationModalOpen, setIsSendBookingConfirmationModalOpen] = useState(false);
    const [selectedRow, setSelectedRow] = useState<BookingConfirmationRow>();
    const [searchPhrase, setSearchPhrase] = useState('');

    const [statusIds, setStatusIds]
     = useState<string[]>(initialStatusIds);

    const [selectedDateRange, setSelectedDateRange] = useState<DateRange<Date | null>>([
        currentDate, moment().add(6, 'M')
            .toDate(),
    ]);

    const { data: currentUser } = useUserProfile();
    const { t } = useTranslation();

    const {
        data = [],
        refetch,
        isLoading,
        isRefetching,
    } = useRides({
        query: searchPhrase,
        statusFilters: statusIds,
        startDate: moment(selectedDateRange[0]).set({
            hour: 0,
            minute: 0,
            seconds: 0,
        })
            .format(),
        endDate: moment(selectedDateRange[1]).set({
            hour: 0,
            minute: 0,
            seconds: 0,
        })
            .format(),
    });

    useEffect(() => {
        refetch();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [statusIds, selectedDateRange, searchPhrase]);

    const onSetStatusIds = (ids: string[]): void => {
        setStatusIds(ids);
    };

    const handleRowClick = (params: GridRowParams) => {
        history.push(getSingleRideRoute(params.id.toString()));
    };

    const handleSendConfirmation = (row: BookingConfirmationRow) => {
        setSelectedRow(row);
        setIsSendBookingConfirmationModalOpen(true);
    };

    const handleEditRide = (rideId: string) => {
        history.push(getSingleRideRoute(rideId));
    };

    const columns = useRidesColumns(
        useCallback(handleSendConfirmation, []),
        // eslint-disable-next-line react-hooks/exhaustive-deps
        useCallback(handleEditRide, []),
        currentUser?.primaryTimeZone
    );

    const { mutate: exportRidesToExcelMutation } = useMutation(exportRidesToExcel);

    const handleNewRide = (): void => {
        history.push(BOOKING_TOOL);
    };

    const handleExport = (): void => {
        const rideResultIds = data?.map(ride => ride.unid);

        exportRidesToExcelMutation({
            startDate: moment(selectedDateRange[0]).format(DATE_FORMATS['YYYY-MM-DDTHH:mm:ss']),
            endDate: moment(selectedDateRange[1]).format(DATE_FORMATS['YYYY-MM-DDTHH:mm:ss']),
            tripUnids: rideResultIds,
        });
    };

    const initialBookingConfirmationEmails: BookingConfirmationEmail[] = useMemo(() => {
        return selectedRow?.passengers
            ? selectedRow?.passengers?.map((traveller: Passenger) => ({
                id: traveller.email,
                email: traveller.email,
                isIncluded: traveller.isLead,
            }))
            : ([] as BookingConfirmationEmail[]);
    }, [selectedRow]);

    const dataRows = useMemo(() => {
        if (data) {
            return mapRidesToRows(data, currentUser);
        }

        return [];
    }, [currentUser, data]);

    return (
        <>
            <Grid container alignItems="flex-start" sx={{ padding: '2rem' }}>
                <Grid item xs={12} md={8} xl={8}>
                    <Typography
                        sx={{
                            color: COLORS.BLUE,
                            fontSize: '1.5rem',
                            fontWeight: 700,
                        }}
                    >
                        {t('pages.rides.rides')}
                    </Typography>
                </Grid>
                <Grid
                    item
                    xs={12}
                    md={4}
                    xl={4}
                    sx={{
                        display: 'flex',
                        justifyContent: ['flex-start', 'flex-start', 'flex-end'],
                        marginTop: ['1rem', '1rem', '0'],
                    }}
                >
                    <PrimaryButton className={classes.addRideButton} onClick={handleNewRide} loading={false} icon={<AddIcon />}>
                        {t('pages.rides.newRide')}
                    </PrimaryButton>
                </Grid>
                <Grid container sx={{ marginTop: '2rem' }}>
                    <Grid item xs={12} md={4} xl={4}>
                        <TextField
                            type="text"
                            autoComplete="off"
                            value={searchPhrase}
                            noHelperTextSpace
                            onChange={event => {
                                setSearchPhrase(event.target.value);
                            }}
                            label={t('pages.rides.searchByTravellerReferenceRide')}
                            name="search"
                            InputProps={{
                                endAdornment: (
                                    <InputAdornment position="end">
                                        {isLoading || isRefetching ? <Spinner size={16}/> : <SearchIcon/>}
                                    </InputAdornment>
                                ),
                                onBlur: event => setSearchPhrase(event.target.value),
                            }}
                            onBlur={ event => setSearchPhrase(event.target.value)}
                        />
                    </Grid>
                    <Grid item xs={12} md={4} xl={4} sx={{ paddingLeft: ['0', '0', '2rem'] }}>
                        <MultipleSelect
                            label="Status"
                            value={statusIds}
                            values={getRideStatusMap()}
                            onSetIds={onSetStatusIds}
                            defaultSelectedValue={defaultSelectedValue}
                        />
                    </Grid>
                    <Grid
                        item
                        xs={12}
                        md={4}
                        xl={4}
                        sx={{
                            paddingLeft: ['0', '0', '2rem'],
                            marginTop: ['1rem', '1rem', 0],
                            display: 'flex',
                            justifyContent: 'flex-end',
                        }}
                    >
                        <LocalizationProvider
                            dateAdapter={AdapterMoment}
                            localeText={{
                                start: t('pages.rides.startingDate'),
                                end: t('pages.rides.endingDate'),
                            }}
                        >
                            <DateRangePicker
                                value={selectedDateRange}
                                inputFormat={DATE_FORMATS['DD MMM yyyy']}
                                disableMaskedInput
                                onChange={newValue => {
                                    setSelectedDateRange(newValue);
                                }}
                                renderInput={(startProps, endProps) => (
                                    <>
                                        <CustomTextField id="startingDate" autoComplete="off" {...startProps} variant="filled" />
                                        <Box
                                            sx={{
                                                mx: 2,
                                                display: 'flex',
                                                alignSelf: 'center',
                                            }}
                                        >
                                            {t('to')}
                                        </Box>
                                        <CustomTextField id="endingDate" autoComplete="off" {...endProps} variant="filled" />
                                    </>
                                )}
                            />
                        </LocalizationProvider>
                    </Grid>
                </Grid>
                <Grid container>
                    <Box sx={{ width: '100%' }}>
                        <DataGridPro
                            className={clsx({
                                [dataGridClases.dataGrid]: true,
                                [dataGridClases.dataGridNoRows]: dataRows?.length === 0,
                            })}
                            hideFooterRowCount
                            autoHeight
                            disableColumnSelector
                            disableColumnFilter
                            rows={dataRows}
                            columns={columns as GridColumns<GridValidRowModel>}
                            getRowHeight={() => 'auto'}
                            onRowClick={handleRowClick}
                            loading={isLoading || !dataRows}
                            components={{
                                NoRowsOverlay: CustomNoRowsOverlay,
                                Footer: () => (dataRows?.length ? CustomFooter(handleExport) : null),
                            }}
                            componentsProps={{ noRowsOverlay: { noRows: t('noResultsFound') } }}
                        />
                    </Box>
                </Grid>
            </Grid>
            {isSendBookingConfirmationModalOpen && (
                <SendBookingConfirmationModal
                    initialBookingConfirmationEmails={initialBookingConfirmationEmails}
                    isOpen={isSendBookingConfirmationModalOpen}
                    onClose={() => setIsSendBookingConfirmationModalOpen(false)}
                    rideId={selectedRow?.rideId ?? ''}
                />
            )}
        </>
    );
};

export default Rides;
