import { useQuery } from '@apollo/react-hooks';
import Skeleton from '@material-ui/lab/Skeleton';
import { Breadcrumbs, Link, LinkProps, Typography } from '@mui/material';
import React, { FunctionComponent, ReactNode } from 'react';
import { useTranslation } from 'react-i18next';
import { matchPath, Route } from 'react-router';
import { useHistory } from 'react-router-dom';

import { DRIVERS, REPORTS, RIDES, USERS } from '../../constants/urlPaths';
import { isRideIdView } from '../../helpers/isIframeView';
import { report } from '../../helpers/report';
import {
    GET_RIDE_NUMBER,
    GetRideIdInput,
    GetRideIdOutput,
} from './PortalBreadcrumbs.graphql';

interface LinkRouterProps extends LinkProps {
    to: string;
    replace?: boolean;
}

const LinkRouter = (
    props: LinkRouterProps
): JSX.Element => (
    <Link
        {...props}
        component={Link}
    />
);

interface BreadcrumbNode {
    name: ReactNode;
    path: string;
    previous?: BreadcrumbNode;
}

const PortalBreadcrumbs: FunctionComponent = () => {
    const { t } = useTranslation();
    const history = useHistory();

    const regex = /\/rides\/(?<rideId>[\d-]+)\/?.*$/i;
    const regexResult = regex.exec(history.location.pathname);

    const urlRideId = regexResult?.groups?.rideId ? regexResult.groups.rideId : '';
    const iframeView = isRideIdView(history.location.pathname);

    const {
        data,
        loading,
        error: queryError,
    } = useQuery<GetRideIdOutput, GetRideIdInput>(
        GET_RIDE_NUMBER,
        {
            skip: !iframeView,
            variables: { id: urlRideId },
            onError(error) {
                report(error);
            },
        }
    );

    const rideNumberBreadcrumb = (() => {
        if (loading) {
            return <Skeleton width={104} />;
        }

        if (!data?.ride || queryError) {
            return urlRideId;
        }

        return data.ride.rideNumber;
    })();

    return (
        <Route>
            { ({ location, match }) => {
                const { pathname } = location;
                const { rideId: matchedRideId } = match?.params as {rideId: string};

                const rides = {
                    name: t('breadcrumbs.rides'),
                    path: RIDES,
                };

                const ride = {
                    name: rideNumberBreadcrumb,
                    path: `${RIDES}/${matchedRideId}`,
                    previous: rides,
                };

                const users = {
                    name: t('breadcrumbs.users'),
                    path: USERS,
                };

                const routeBreadcrumbs: { [route: string]: BreadcrumbNode } = {
                    '/rides': rides,
                    '/booking-tool': {
                        name: t('breadcrumbs.newRide'),
                        path: '/booking-tool',
                    },
                    '/rides/:rideId': ride,
                    '/rides/:rideId/booking-confirmation': {
                        name: t('breadcrumbs.bookingConfirmation'),
                        path: `/rides/${matchedRideId}/booking-confirmation`,
                        previous: ride,
                    },
                    '/rides/:rideId/change/route': {
                        name: t('breadcrumbs.changeRoute'),
                        path: `/rides/${matchedRideId}/change/route`,
                        previous: ride,
                    },
                    '/rides/:rideId/change/flight': {
                        name: t('breadcrumbs.changeFlight'),
                        path: `/rides/${matchedRideId}/change/flight`,
                        previous: ride,
                    },
                    '/rides/:rideId/change/tracked-flight': {
                        name: t('breadcrumbs.changeTrackedFlight'),
                        path: `/rides/${matchedRideId}/change/tracked-flight`,
                        previous: ride,
                    },
                    '/rides/:rideId/change/passengers': {
                        name: t('breadcrumbs.changePassengers'),
                        path: `/rides/${matchedRideId}/change/passengers`,
                        previous: ride,
                    },
                    '/users': users,
                    '/users/active': users,
                    '/users/invited': users,
                    '/users/deactivated': users,
                    '/contact': {
                        name: t('breadcrumbs.contact'),
                        path: '/contact',
                    },
                    '/documents': {
                        name: t('breadcrumbs.documents'),
                        path: '/documents',
                    },
                    '/export': {
                        name: t('breadcrumbs.export'),
                        path: '/export',
                    },
                    '/reports': {
                        name: t('breadcrumbs.reports'),
                        path: REPORTS,
                    },
                    '/pending-changes': {
                        name: t('breadcrumbs.pendingChanges'),
                        path: '/pending-changes',
                    },
                    '/drivers': {
                        name: t('breadcrumbs.drivers'),
                        path: DRIVERS,
                    },
                };

                const pathMatch = Object.entries(routeBreadcrumbs)
                    .find(([key]) => matchPath(pathname, {
                        path: key,
                        exact: true,
                        strict: true,
                    }) !== null);

                return (
                    <Breadcrumbs
                        sx={{
                            display: {
                                xs: 'none',
                                md: 'block',
                            },
                        }}
                        aria-label="breadcrumb"
                        data-testid="breadcrumbs"
                    >
                        {pathMatch ? linkBreadcrumbs(pathMatch[1]) : []}
                    </Breadcrumbs>
                );
            }}
        </Route>
    );
};

function linkBreadcrumbs(headNode: BreadcrumbNode): ReactNode {
    const result = [
        <Typography
            color="textPrimary"
            key={headNode.path}
        >
            {headNode.name}
        </Typography>,
    ];

    let currentNode = headNode.previous;

    while (currentNode) {
        result.unshift(
            <LinkRouter
                to={currentNode.path}
                href={currentNode.path}
                key={currentNode.path}
            >
                {currentNode.name}
            </LinkRouter>
        );

        currentNode = currentNode.previous;
    }

    return result;
}

export default PortalBreadcrumbs;
