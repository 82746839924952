import { GridRowsProp } from '@mui/x-data-grid-pro';

import { PAYMENT_METHOD } from '../../pages/ride/customer/api/types';
import { RideResponse } from '../../pages/rides/customer/api/types';
import { User } from '../../services/types';
import { getDateTimeWithoutOffset } from '../getDateTimeWithoutOffset';
import { isFlightMismatch } from '../isFlightMismatch';
import { isOvernightFlight } from '../isOvernightFlight';

export const mapRidesToRows = (rows: RideResponse[], currentUser?: User): GridRowsProp => {
    const mappedRows: GridRowsProp = rows.map(row => {
        const pickUpDepartAt = getDateTimeWithoutOffset(row?.pickUp?.departAt);
        const pickUpDepartAtLocal = getDateTimeWithoutOffset(row?.pickUp?.departAtLocal);
        const hasDriver = Boolean(row.driver);
        const hasFlight = Boolean(row.flightDetails?.number);
        const hasContact = Boolean(row.contactPerson);
        const isFlightMismatchValue = isFlightMismatch(row.flightDetails, row.pickUp.location, row.dropOff.location);
        const isOvernightFlightValue = isOvernightFlight(row.pickUp.departAtLocal, row.dropOff.arriveAtLocal);
        const isPaymentMethodCreditCard = row.paymentMethod === PAYMENT_METHOD.CREDIT_CARD;

        return {
            id: row.unid,
            status: row.status,
            trackingStatus: row.trackingStatus,
            pickup: pickUpDepartAt,
            pickupLocal: pickUpDepartAtLocal,
            primaryTimeZone: currentUser?.primaryTimeZone,
            passengers: row.passengers,
            route: {
                from: row.pickUp.location.name,
                to: row.dropOff.location.name,
                flight: row.flightDetails?.number,
            },
            reference: row.customerReference,
            rideNumber: row.prettifiedUnid,
            newStatusOptions: {
                hasDriver,
                hasFlight,
                hasContact,
                isFlightMismatch: isFlightMismatchValue,
                isOvernightFlight: isOvernightFlightValue,
            },
            isPaymentMethodCreditCard,
        };
    });

    return mappedRows;
};
