import 'whatwg-fetch';
import React, { FunctionComponent, useEffect, useState } from 'react';
import { Redirect } from 'react-router-dom';

import config from '../../config';
import hasProperty from '../../helpers/hasProperty';
import { report } from '../../helpers/report';
import useQuery from '../../hooks/useQuery';
import Loading from '../../pages/loading/Loading';
import LogInMessage from '../loginMessage/LogInMessage';

const Authenticate: FunctionComponent = () => {
    const query = useQuery();
    const [authorizing, setAuthorizing] = useState(true);
    const [authorized, setAuthorized] = useState(false);
    const authorizationCode = query.get('code');
    const [tokenIsInvalid, setTokenIsInvalid] = useState(false);

    useEffect(() => {
        fetch(config.setCookieUrl, {
            method: 'POST',
            body: JSON.stringify({ authorizationCode }),
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
            },
            credentials: 'include',
        }).then(async response => {
            if (response.status >= 200 && response.status < 300) {
                setAuthorizing(false);
                setAuthorized(true);
                return;
            }

            const errorResponse = await response.json() as unknown;

            const authCodeInvalid = hasProperty(errorResponse, 'errorCode')
                                        && errorResponse
                                            .errorCode === 'INVALID_AUTHORIZATION_CODE';

            if (authCodeInvalid) {
                setTokenIsInvalid(true);
                return;
            }

            throw Error(`Unexpected response status ${response.status}`);
        })
            .catch(fetchError => {
                report(fetchError);
                setAuthorizing(false);
            });
    }, [authorizationCode]);

    if (tokenIsInvalid) {
        return <Redirect push to="/invalid-auth-code"/>;
    }

    if (authorizing) {
        return <Loading framed={false}/>;
    }

    if (authorized) {
        return <Redirect push to="/rides"/>;
    }

    return <LogInMessage />;
};

export default Authenticate;
