import { useMediaQuery } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { useFlags } from 'launchdarkly-react-client-sdk';
import React, { FunctionComponent } from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';

import config from '../../config';
import TokenError from '../../constants/TokenError';
import {
    ACCEPT_INVITE,
    AVAILABILITY,
    BOOKING_TOOL,
    CREATE_AVAILABILITY,
    DRIVERS,
    EDIT_AVAILABILITY,
    FORGOT_PASSWORD,
    LOG_IN,
    REPORTS,
    RESET_PASSWORD,
    RIDES,
    USERS,
} from '../../constants/urlPaths';
import AcceptInvite from '../../pages/acceptInvite/AcceptInvite';
import Availability from '../../pages/availability/Availability';
import CreateAvailability from '../../pages/availability/createAvailability/CreateAvailability';
import BookingConfirmation from '../../pages/bookingConfirmation/BookingConfirmation';
import BookingTool from '../../pages/bookingTool/BookingTool';
import CustomerExport from '../../pages/customerExport/CustomerExport';
import Documents from '../../pages/documents/Documents';
import Drivers from '../../pages/drivers/Drivers';
import InvalidAuthCode from '../../pages/errorPages/InvalidAuthCode';
import InvalidCookie from '../../pages/errorPages/InvalidCookie';
import InvalidInviteToken from '../../pages/errorPages/InvalidInviteToken';
import InvalidPasswordToken from '../../pages/errorPages/InvalidPasswordToken';
import NotFound from '../../pages/errorPages/NotFound';
import ForgotPassword from '../../pages/forgotPassword/ForgotPassword';
import Login from '../../pages/login/Login';
import PageContainer from '../../pages/pageContainer/PageContainer';
import PendingChanges from '../../pages/pendingChanges/PendingChanges';
import Reports from '../../pages/reports/Reports';
import ResetPassword from '../../pages/resetPassword/ResetPassword';
import RidePageSwitch from '../../pages/ride/customer/ridePageSwitch/RidePageSwitch';
import Flight from '../../pages/rides/customer/change/Flight';
import Passengers from '../../pages/rides/customer/change/Passengers';
import { default as RideRoute } from '../../pages/rides/customer/change/Route';
import TrackedFlight from '../../pages/rides/customer/change/TrackedFlight';
import RidesPageSwitch from '../../pages/rides/customer/ridesPageSwitch/RidesPageSwitch';
import Rides from '../../pages/rides/supplier/Rides';
import Users from '../../pages/users/customer/Users';
import { default as SupplierUsers } from '../../pages/users/supplier/Users';
import theme from '../../styles/theme';
import Authenticate from '../authenticate/Authenticate';
import LegacyView from '../legacyView/LegacyView';
import PrivateRoute from '../privateRoute/PrivateRoute';

const useStyles = makeStyles({
    rideMainContainer: { padding: '0rem 4rem 2rem 4rem' },
    rideMainContainerMobile: { padding: '1rem' },
});

const usePrivateRoutes = () => {
    const classes = useStyles();
    const isMobile = useMediaQuery(theme.breakpoints.down('lg'));
    const { useDriversPage, useSupplierTesting = false } = useFlags();

    // eslint-disable-next-line @typescript-eslint/no-unsafe-return
    return [
        <PrivateRoute
            key="ride-route"
            path="/rides/:rideId/change/route"
            component={RideRoute}
        />,

        <PrivateRoute
            key="passengers"
            path="/rides/:rideId/change/passengers"
            component={Passengers}
        />,

        <PrivateRoute
            key="flight"
            path="/rides/:rideId/change/flight"
            component={Flight}
        />,

        <PrivateRoute
            key="tracked-flight"
            path="/rides/:rideId/change/tracked-flight"
            component={TrackedFlight}
        />,

        <PrivateRoute
            key="booking-confirmation"
            path="/rides/:rideId/booking-confirmation"
            component={BookingConfirmation}
        />,

        <PrivateRoute
            key="ride"
            path="/rides/:rideId"
            mainClassName={isMobile ? classes.rideMainContainerMobile : classes.rideMainContainer}
            component={RidePageSwitch}
        />,

        <PrivateRoute
            key="rides"
            path={RIDES}
            component={useSupplierTesting ? Rides : RidesPageSwitch}
        />,

        useDriversPage && <PrivateRoute key="drivers" path={DRIVERS} component={Drivers} />,

        <PrivateRoute
            key="availability-create"
            path={CREATE_AVAILABILITY}
            component={CreateAvailability}
        />,

        <PrivateRoute
            key="availability-edit"
            path={EDIT_AVAILABILITY}
            component={CreateAvailability}
        />,

        <PrivateRoute
            key="availability"
            path={AVAILABILITY}
            component={Availability}
        />,

        <PrivateRoute
            key="reports"
            path={REPORTS}
            component={Reports}
        />,

        <PrivateRoute key="documents" path="/documents" component={Documents} />,

        <Route key="accounts" path="/accounts">
            <PageContainer>
                <LegacyView path={''} title="" />
            </PageContainer>
        </Route>,

        <Route key="booking-tool" path={BOOKING_TOOL}>
            <PageContainer>
                <BookingTool />
            </PageContainer>
        </Route>,

        <Route key="contact" path="/contact">
            <PageContainer>
                <LegacyView path={''} title="" />
            </PageContainer>
        </Route>,

        <Route key="in-creation" path="/in-creation">
            <PageContainer>
                <LegacyView path={''} title="" />
            </PageContainer>
        </Route>,

        <Route key="new-rides" path="/new-rides">
            <PageContainer>
                <LegacyView path={''} title="" />
            </PageContainer>
        </Route>,

        <Route key="send-sms" path="/send-sms">
            <PageContainer>
                <LegacyView path={''} title="" />
            </PageContainer>
        </Route>,

        <Route key="statistics" path="/statistics">
            <PageContainer>
                <LegacyView path={''} title="" />
            </PageContainer>
        </Route>,

        <Route key="wake-standby" path="/wake-standby">
            <PageContainer>
                <LegacyView path={''} title="" />
            </PageContainer>
        </Route>,

        <PrivateRoute
            key="users"
            path={USERS}
            component={useSupplierTesting ? SupplierUsers : Users}
        />,

        <PrivateRoute key="export" path="/export" component={CustomerExport} />,
        <PrivateRoute
            key="pending-changes"
            path="/pending-changes"
            component={PendingChanges}
        />,
    ];
};

const Routes: FunctionComponent = () => (
    <Switch>
        {usePrivateRoutes()}
        <Route
            path={LOG_IN}
            render={() => {
                window.location.href = config.signInUrl;
                return null;
            }}
        />

        <Route key="log-in" path="/login">
            <Login />
        </Route>,
        <Route path="/invalid-auth-code" component={InvalidAuthCode} />
        <Route path="/invalid-cookie" component={InvalidCookie} />
        <Route path="/invalid-invite-token">
            <InvalidInviteToken errorType={TokenError.NotFound} />
        </Route>
        <Route path="/expired-invite-token">
            <InvalidInviteToken errorType={TokenError.Expired} />
        </Route>
        <Route path="/authenticate">
            <Authenticate />
        </Route>
        <Route path="/invalid-password-token">
            <InvalidPasswordToken errorType={TokenError.NotFound} />
        </Route>
        <Route path="/expired-password-token">
            <InvalidPasswordToken errorType={TokenError.Expired} />
        </Route>
        <Route path="/reset-password" component={ResetPassword} />
        <Route path={FORGOT_PASSWORD} component={ForgotPassword} />
        <Route path={RESET_PASSWORD} component={ResetPassword} />
        <Route path={ACCEPT_INVITE} component={AcceptInvite} />
        <Redirect key="rides-redirect" exact from="/" to="/rides" />,
        <PrivateRoute key="not-found" path="*" component={NotFound} />
    </Switch>
);

export default Routes;
