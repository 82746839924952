/* eslint-disable no-case-declarations */
import { useMutation } from '@apollo/react-hooks';
import {
    TextField,
    SecondaryButton,
    PrimaryButton,
    MultipleSelect,
} from '@get-e/react-components';
import { Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { ApolloError } from 'apollo-client';
import React, { FunctionComponent, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { causesRedirect } from '../../../../ApolloClient/createErrorHandler';
import { COLORS } from '../../../../constants/colors';
import { useNotificationContext, Severity } from '../../../../context/NotificationContext';
import { InputError } from '../../../../helpers/inputValidation/InputError';
import {
    ApiValidationError,
    emailAlreadyExists,
    parseInputErrors,
} from '../../../../helpers/inputValidation/parseFormErrors';
import { report } from '../../../../helpers/report';
import typographyStyles from '../../../../styles/Typography';
import { GET_INVITED_USERS } from '../Users.graphql';
import {
    INVITE_USER,
    InviteUserFieldsInput,
    InviteUserMutation,
} from './InviteUserForm.graphql';

const useStyles = makeStyles({
    heading: {
        marginBottom: '1rem',
        color: COLORS.BLUE_DARK,
        fontSize: '1rem',
    },
    formButton: {
        width: '150px',
        marginRight: '1rem',
    },
    formField: {
        marginBottom: '1rem',
        width: '100%',
        '& .MuiFormHelperText-root.Mui-error': { padding: '0 .75rem' },
    },
    buttonsContainer: { marginTop: '2rem' },
    multipleSelectItem: {
        width: '100% !important',
        padding: '0.5rem !important',
        '& .MuiListItemText-root': { paddingLeft: '0.5rem !important' },
    },
});

enum UserRoles {
    MANAGE_USERS = 'manageUsers',
    MANAGE_RIDES_BOOKED_BY_OTHERS = 'manageRidesBookedByOthers',
}

type Permissions = { [key in UserRoles]: boolean };

interface InviteUserFields {
    firstName: string;
    lastName: string;
    email: string;
    permissions: Permissions;
}

interface InviteUserValidationErrors {
    validationErrors: {
        input?: {
            firstName?: ApiValidationError[];
            lastName?: ApiValidationError[];
            email?: ApiValidationError[];
        };
    };
}

interface InviteUserFormErrors {
    firstName: InputError | null;
    lastName: InputError | null;
    email: InputError | InviteUserFormError | null;
    authorizationError: InviteUserFormError | null;
    unexpectedError: boolean | null;
}

enum InviteUserFormError {
    Exists = 'errors.emailTaken',
    Unauthorized = 'modals.invite.helperText.unauthorized',
}

const InviteUserForm: FunctionComponent<{
    onClose: () => void;
    onInviteUser: () => void;
}> = ({ onClose, onInviteUser }) => {
    const classes = useStyles();
    const typographyClasses = typographyStyles();
    const { t } = useTranslation();
    const { showNotification } = useNotificationContext();
    const [roleIds, setRoleIds] = useState<string[]>([]);

    const [values, setValues] = useState({
        firstName: '',
        lastName: '',
        email: '',
        permissions: {
            [UserRoles.MANAGE_USERS]: false,
            [UserRoles.MANAGE_RIDES_BOOKED_BY_OTHERS]: true,
        },
    });

    const [formErrors, setFormErrors] = useState<InviteUserFormErrors>({
        firstName: null,
        lastName: null,
        email: null,
        authorizationError: null,
        unexpectedError: null,
    });

    const getInviteUserFormErrors = (
        newError: ApolloError
    ): InviteUserFormErrors => {
        const fields: InviteUserFormErrors = {
            firstName: null,
            lastName: null,
            email: null,
            authorizationError: null,
            unexpectedError: null,
        };

        if (newError.graphQLErrors.length) {
            for (const graphqlError of newError.graphQLErrors) {
                switch (graphqlError?.extensions?.code) {
                    case 'BAD_USER_INPUT':
                        const { validationErrors }
              = graphqlError.extensions as InviteUserValidationErrors;

                        try {
                            fields.email = emailAlreadyExists(validationErrors.input?.email)
                                ? InviteUserFormError.Exists
                                : parseInputErrors(validationErrors.input?.email);
                            fields.firstName = parseInputErrors(
                                validationErrors.input?.firstName
                            );
                            fields.lastName = parseInputErrors(
                                validationErrors.input?.lastName
                            );
                        } catch {
                            fields.unexpectedError = true;
                        }

                        break;
                    case 'UNAUTHORIZED':
                        fields.authorizationError = InviteUserFormError.Unauthorized;
                        break;
                    default:
                        fields.unexpectedError = true;
                        continue;
                }
            }
        }

        const errorUnhandled = Object.values(fields).every(
            field => field === null
        );

        if (errorUnhandled) {
            fields.unexpectedError = true;
        }

        return fields;
    };

    const [inviteUser, { loading, error }] = useMutation<
    InviteUserMutation,
    InviteUserFieldsInput
    >(INVITE_USER, {
        onCompleted() {
            onInviteUser();
            onClose();
            const { email } = values;

            showNotification(t('userInviteSuccess', { email }), Severity.Info);
        },
        onError(apolloError) {
            const fieldErrors = getInviteUserFormErrors(apolloError);

            if (fieldErrors.unexpectedError) {
                report(apolloError);
            }

            setFormErrors({ ...fieldErrors });
        },

        awaitRefetchQueries: true,
        refetchQueries: [
            {
                query: GET_INVITED_USERS,
                variables: {
                    after: null,
                    before: null,
                },
            },
        ],
    });

    const submitForm = async (): Promise<void> => {
        await inviteUser({
            variables: {
                firstName: values.firstName,
                lastName: values.lastName,
                email: values.email,
                manageUsers: values.permissions[UserRoles.MANAGE_USERS],
                manageRidesBookedByOthers:
          values.permissions[UserRoles.MANAGE_RIDES_BOOKED_BY_OTHERS],
            },
        });
    };

    const handleChange = <T extends keyof InviteUserFields>(
        key: T,
        newValue: InviteUserFields[T] & string
    ): void => {
        setValues({
            ...values,
            [key]: newValue,
        });
    };

    const errorMessage = (): string => {
        if (formErrors.authorizationError) {
            return formErrors.authorizationError;
        }

        if (formErrors.unexpectedError) {
            return 'modals.unexpectedError';
        }

        return 'modals.outstandingErrors';
    };

    const handleError = (newError: ApolloError): JSX.Element | null => {
        if (causesRedirect(newError)) {
            return null;
        }

        return (
            <div className={typographyClasses.errorContainer}>
                <span className={typographyClasses.errorTheme}>
                    {t(errorMessage())}
                </span>
            </div>
        );
    };

    const onSetRoleIds = (ids: string[]): void => {
        setRoleIds(ids);

        const permissionValues = Array.from(roleMapValues.keys()).reduce(
            (acc, key) =>
                Object.assign(acc, { [UserRoles[key as keyof typeof UserRoles]]: ids.includes(key) }),
            {}
        );

        const newValues = {
            ...values,
            permissions: permissionValues as Permissions,
        };

        setValues(newValues);
    };

    const defaultRoleValues = useMemo(() => {
        const result: string[] = [];

        for (const [key, value] of Object.entries(values.permissions)) {
            value && result.push(t(`pages.users.fields.${key}`));
        }

        return result;
    }, [t, values.permissions]);

    const roleMapValues = useMemo(() => {
        const rolesMap: Map<string, string> = new Map([]);

        Object.keys(UserRoles).map(value =>
            rolesMap.set(
                value,
                t(`pages.users.fields.${UserRoles[value as keyof typeof UserRoles]}`)
            ));

        return rolesMap;
    }, [t]);

    return (
        <>
            <Typography variant="h3" component="h4" className={classes.heading}>
                {t('pages.users.fields.profileInformation')}
            </Typography>
            <TextField
                className={classes.formField}
                error={formErrors.firstName !== null}
                helperText={formErrors.firstName ? t(formErrors.firstName) : null}
                inputProps={{ 'data-testid': 'firstName' }}
                label={t('pages.users.fields.firstName')}
                name="firstName"
                onChange={event => handleChange('firstName', event.target.value)}
                required
                autoComplete="off"
                value={values.firstName}
            />
            <TextField
                className={classes.formField}
                error={formErrors.lastName !== null}
                helperText={formErrors.lastName ? t(formErrors.lastName) : null}
                inputProps={{ 'data-testid': 'lastName' }}
                label={t('pages.users.fields.lastName')}
                name="lastName"
                onChange={event => handleChange('lastName', event.target.value)}
                required
                autoComplete="off"
                value={values.lastName}
            />
            <TextField
                className={classes.formField}
                error={formErrors.email !== null}
                helperText={formErrors.email ? t(formErrors.email) : null}
                inputProps={{
                    autoCapitalize: 'none',
                    'data-testid': 'email',
                }}
                label={t('pages.users.fields.email')}
                name="email"
                onChange={event => handleChange('email', event.target.value)}
                required
                autoComplete="off"
                value={values.email}
            />
            <Typography variant="h3" component="h4" className={classes.heading}>
                {t('pages.users.fields.userPermissions')}
            </Typography>
            <MultipleSelect
                className={classes.formField}
                classNames={{ item: classes.multipleSelectItem }}
                label="Role"
                value={roleIds}
                values={roleMapValues}
                onSetIds={onSetRoleIds}
                defaultSelectedValue={defaultRoleValues}
            />
            {error ? handleError(error) : null}
            <div className={classes.buttonsContainer}>
                <PrimaryButton
                    className={classes.formButton}
                    onClick={submitForm}
                    disabled={loading}
                >
                    {t('buttonName.invite')}
                </PrimaryButton>
                <SecondaryButton
                    className={classes.formButton}
                    onClick={onClose}
                    disabled={loading}
                >
                    {t('buttonName.cancel')}
                </SecondaryButton>
            </div>
        </>
    );
};

export default InviteUserForm;
