import { PortalLocale } from '../context/LocaleContext';

export type MomentLocale = 'en';

const findMomentLocale = (value: PortalLocale): MomentLocale => {
    switch (value) {
        case 'en-GB':
            return 'en';
        default:
            throw new Error(`${value as string} is unsupported`);
    }
};

export default findMomentLocale;
