/* eslint-disable @typescript-eslint/no-unused-vars */
import { PrimaryButton, SecondaryButton } from '@get-e/react-components';
import { Link, TextField, Typography, Grid } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { Box, Container } from '@mui/system';
import clsx from 'clsx';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { COLORS } from '../../constants/colors';
import { InputError } from '../../helpers/inputValidation/InputError';
import allValid from '../../helpers/inputValidation/validators/allValid';
import and from '../../helpers/inputValidation/validators/and';
import isEmail from '../../helpers/inputValidation/validators/isEmail';
import isFilledString from '../../helpers/inputValidation/validators/isFilledString';
import { logo } from '../../public/assets/images';
import containerStyles from '../../styles/Container';

const useStyles = makeStyles({
    container: {
        display: 'flex',
        flexDirection: 'column',
        textAlign: 'center',
        maxWidth: '300px',
        color: '#676a6c',
    },
    logoImage: {
        height: '47px',
        width: '200px',
        marginBottom: '1.5rem',
    },
    textField: { marginBottom: '1rem' },
    registerButton: {
        backgroundColor: 'white',
        padding: '6px 12px',
    },
    loginButton: {
        backgroundColor: COLORS.BLUE,
        padding: '6px 12px',
        '&:hover': { backgroundColor: COLORS.BLUE },
    },
    inputField: {
        backgroundColor: 'white',
        color: 'inherit',
        border: '1px solid #e5e6e7',
        borderRadius: '.1rem',
        padding: '6px 12px',
        fontSize: '.9rem',
        transition: 'border-color 0.15s ease-in-out 0s, box-shadow 0.15s ease-in-out 0s',
    },
});

const Login = () => {
    const containerClasses = containerStyles();
    const classes = useStyles();
    const [email, setEmail] = useState('');
    const [emailError, setEmailError] = useState<InputError | null>(null);
    const [password, setPassword] = useState('');
    const [passwordError, setPasswordError] = useState<InputError | null>(null);
    const { t } = useTranslation();

    function submitForm() {
        const validated = {
            email: and(isFilledString(email, InputError.Empty), value => isEmail(email, InputError.InvalidEmail)),
            password: isFilledString(password, InputError.Empty),
        };

        if (!allValid(validated)) {
            setEmailError(validated.email.isValid ? null : validated.email.error);
            setPasswordError(validated.password.isValid ? null : validated.password.error);
        }
    }

    return (
        <Container className={clsx(containerClasses.rootWithoutNavigation, containerClasses.fullHeight)} maxWidth={false}>
            <main className={containerClasses.contentWithoutNavigation}>
                <Grid display="flex" justifyContent="center">
                    <Box className={classes.container}>
                        <Box>
                            <img src={logo} alt="Get-e" className={classes.logoImage} />
                        </Box>
                        <Typography fontWeight="600" marginBottom="1rem">
                            {t('pages.login.welcomeToGetE')}
                        </Typography>
                        <Typography marginBottom="2rem">{t('pages.login.loginWithCredentials')}</Typography>
                        <input
                            value={email}
                            onChange={event => {
                                setEmail(event.target.value);
                                setEmailError(null);
                            }}
                            placeholder="Email"
                            className={clsx(classes.inputField, classes.textField)}
                            required
                        />
                        <input
                            placeholder={t('pages.login.password')}
                            type="password"
                            value={password}
                            onChange={event => {
                                setPassword(event.target.value);
                                setPasswordError(null);
                            }}
                            className={clsx(classes.inputField, classes.textField)}
                            required
                        />
                        <PrimaryButton
                            onClick={submitForm}
                            className={clsx(classes.textField, classes.loginButton)}
                        >
                            {t('pages.login.login')}
                        </PrimaryButton>
                        <Typography marginBottom="1rem">{t('pages.login.dontHaveAccount')}</Typography>
                        <SecondaryButton onClick={() => {}} className={clsx(classes.textField, classes.registerButton)}>
                            {t('pages.login.register')}
                        </SecondaryButton>
                        <p style={{ margin: 0 }}>
                            {t('pages.login.byProceeding')}
                            <u>
                                <Link href="https://get-e.com/terms-and-conditions/">
                                    {t('accountActivation.termsAndConditions')}
                                </Link>
                            </u>
                        </p>
                        <p>
                            <small>&copy; {t('accountActivation.gete')}</small>
                        </p>
                    </Box>
                </Grid>
            </main>
        </Container>
    );
};

export default Login;
