import { useQuery } from '@apollo/react-hooks';
import { Grid } from '@mui/material';
import { Container } from '@mui/system';
import clsx from 'clsx';
import { useFlags, useLDClient } from 'launchdarkly-react-client-sdk';
import React, { FunctionComponent, useState } from 'react';
import { useHistory } from 'react-router-dom';

import { causesRedirect } from '../../ApolloClient/createErrorHandler';
import LogInMessage from '../../components/loginMessage/LogInMessage';
import NavigationMenu from '../../components/navigation/NavigationMenu';
import PortalBreadcrumbs from '../../components/navigation/PortalBreadcrumbs';
import { BOOKING_TOOL } from '../../constants/urlPaths';
import { CurrentUserContext } from '../../context/CurrentUserContext';
import getUserRole, { UserRole } from '../../helpers/getUserRole';
import getValue from '../../helpers/getValue';
import { isIframeView } from '../../helpers/isIframeView';
import { isMatchPath } from '../../helpers/isMatchPath';
import setMopinionMetadata from '../../helpers/mopinionMetadataConfig';
import { prefillZendeskUser } from '../../helpers/prefillZendeskConfig';
import { report } from '../../helpers/report';
import containerStyles from '../../styles/Container';
import ErrorBoundary from '../errorPages/ErrorBoundary';
import Loading from '../loading/Loading';
import { useUserProfile } from './api/useUserProfile';
import ContactUsModal from './components/ContactUsModal';
import SupportButton from './components/SupportButton';
import UserMenu from './components/UserMenu';
import { CurrentUserProps, GET_CURRENT_USER } from './PageContainer.graphql';

interface Props {
    children: JSX.Element;
    mainClassName?: string;
}

enum MenuState {
    Open = 'open',
    Closed = 'closed',
}

const PageContainer: FunctionComponent<Props> = ({ children, mainClassName }: Props) => {
    const mobileView = window.innerWidth;
    const previousMenuState = localStorage.getItem('menuState');
    const [modalOpen, setModalOpen] = useState(false);
    const ldClient = useLDClient();
    const { useNewRidesPage, useSupplierTesting, useNewUserProfile } = useFlags();

    if (!previousMenuState) {
        localStorage.setItem('menuState', mobileView ? MenuState.Closed : MenuState.Open);
    }

    const classes = containerStyles();
    const history = useHistory();
    const iframeView = isIframeView(history.location.pathname) && !useNewRidesPage;
    const currentMenuState = localStorage.getItem('menuState') === MenuState.Closed;
    const [closed, setClosed] = useState(currentMenuState);

    const { data: userProfile, refetch: refetchUserProfile } = useUserProfile();

    const { data, loading, error } = useQuery<CurrentUserProps>(GET_CURRENT_USER, {
        onError(apolloError) {
            report(apolloError);
        },
        onCompleted(userData) {
            prefillZendeskUser(userData.me.fullName, userData.me.email);
            setMopinionMetadata({
                name: userData.me.fullName ?? null,
                email: userData.me.email ?? null,
                accountType: getValue(() => {
                    if (userData.me.company.isBackOffice) {
                        return 'BACKOFFICE';
                    }

                    if (userData.me.company.isCustomer) {
                        return 'CUSTOMER';
                    }

                    if (userData.me.company.isSupplier) {
                        return 'SUPPLIER';
                    }

                    return null;
                }),
            });

            const ldContext = {
                kind: 'user',
                key: userData.me.email,
                name: userData.me.fullName,
                email: userData.me.email,
            };

            ldClient?.identify(ldContext);
        },
    });

    const handleUserProfileUpdated = async () => {
        await refetchUserProfile();
    };

    if (loading) {
        return <Loading framed={false} />;
    }

    if (error || !data) {
        if (error && causesRedirect(error)) {
            return null;
        }

        return <LogInMessage />;
    }

    const role = useSupplierTesting ? UserRole.Supplier : getUserRole(data.me.company);

    const isBookingTool = isMatchPath(BOOKING_TOOL);

    return (
        <Container
            className={`${clsx(
                classes.root,
                {
                    [classes.iframePage]: iframeView,
                    [classes.rootBookingTool]: isBookingTool,
                }
            )} ${iframeView ? 'iframePage' : ''}`}
            maxWidth={iframeView ? false : 'xl'}
        >
            <CurrentUserContext.Provider value={{ currentUser: data.me }}>
                {modalOpen ? <ContactUsModal onClose={() => setModalOpen(false)} /> : null}
                <NavigationMenu
                    closeMenu={(): void => {
                        localStorage.setItem('menuState', MenuState.Closed);
                        setClosed(true);
                    }}
                    openMenu={(): void => {
                        localStorage.setItem('menuState', MenuState.Open);
                        setClosed(false);
                    }}
                    role={role}
                    closed={closed}
                >
                    <PortalBreadcrumbs />
                    <div>
                        <SupportButton onClick={() => setModalOpen(true)} />
                        {useNewUserProfile && userProfile && (
                            <UserMenu currentUser={userProfile} isUpdateProfile onUserProfileUpdated={handleUserProfileUpdated} />
                        )}
                        {!useNewUserProfile && <UserMenu currentUser={data.me} />}
                    </div>
                </NavigationMenu>
                <main
                    className={clsx(
                        classes.content,
                        mainClassName,

                        { [classes.contentShift]: !closed },

                        { [classes.iframePage]: iframeView },
                        { [classes.bookingTool]: isBookingTool }
                    )}
                >
                    <Grid container flexDirection="column" justifyContent="center" alignItems="center">
                        <Grid
                            container
                            item
                            flexDirection="row"
                            justifyContent="center"
                            xs={12}

                            // Prevents flex overlap in ie11
                            style={{ flex: '1 1 auto' }}
                        >
                            <ErrorBoundary message="reload" framed={true}>
                                {children}
                            </ErrorBoundary>
                        </Grid>
                    </Grid>
                </main>
            </CurrentUserContext.Provider>
        </Container>
    );
};

export default PageContainer;
