/* eslint-disable max-len */
import { Grid } from '@mui/material';
import { DataGridPro, GridColumns, GridValidRowModel } from '@mui/x-data-grid-pro';
import React from 'react';
import { useTranslation } from 'react-i18next';

import useDataGridStyles from '../../../../styles/DataGrid';
import AverageRatingDistributionCard from '../AverageRatingDistributionCard';
import { useReviewsColumns } from '../hooks/useReviewsColumns';
import { useReviewsDetailsContent } from '../hooks/useReviewsDetailsContent';
import SummaryBasicCard from '../SummaryBasicCard';
import SummaryListCard from '../SummaryListCard';

const rows = [
    {
        id: 1,
        pickUpTime: '15 Mar 2033 at 16:00 PM',
        rideNumber: '000-000-0000',
        driver: 'Dani California',
        averageRating: 5,
        service: {
            overall: 5,
            driver: 5,
            meetingDriver: 5,
            car: 5,
        },
        comment: 'Cras varius. In enim justo, rhoncus ut, imperdiet a, venenatis vitae, justo. Pellentesque libero tortor, tincidunt et, tincidunt eget, semper nec, quam. Duis lobortis massa imperdiet quam. Aenean posuere, tortor sed cursus feugiat, nunc augue blandit nunc, eu sollicitudin urna dolor sagittis lacus.',
    },
    {
        id: 2,
        pickUpTime: '15 Mar 2033 at 16:00 PM',
        rideNumber: '000-000-0000',
        driver: 'Dani California',
        averageRating: 2,
        service: {
            overall: 3,
            driver: 1,
            meetingDriver: 3,
            car: 2,
        },
        comment: 'Nesto',
    },
    {
        id: 3,
        pickUpTime: '15 Mar 2033 at 16:00 PM',
        rideNumber: '000-000-0000',
        driver: 'Dani California',
        averageRating: 5,
        service: {
            overall: 5,
            driver: 5,
            meetingDriver: 5,
            car: 5,
        },
        comment: 'Nesto 2',
    },
];

const ReviewsTab = () => {
    const { t } = useTranslation();
    const dataGridClases = useDataGridStyles();
    const columns = useReviewsColumns();

    return (
        <Grid container width="100%">
            <Grid container columnSpacing={3} marginTop={2}>
                <Grid item xs={12} md={4} lg={4}>
                    <SummaryBasicCard
                        title={t('pages.reports.rateOfSituations')}
                        percentage1="0.23"
                        text1="0.43% in previous period"
                        text2="16 Mar 2033- 23 Mar 2033"
                    />
                </Grid>
                <Grid item xs={12} md={4} lg={4}>
                    <AverageRatingDistributionCard
                        title={t('pages.reports.averageRatingDistribution')}
                        stars5={134}
                        stars4={34}
                        stars3={43}
                        stars2={10}
                        stars1={0}
                    />
                </Grid>
                <Grid item xs={12} md={4} lg={4}>
                    <SummaryListCard
                        title={t('pages.reports.situationsByType')}
                        driverLate={1}
                        driverNoShow={0}
                        driverBehaviour={0}
                        wrongVehicle={1}
                    />
                </Grid>
            </Grid>
            <DataGridPro
                className={dataGridClases.dataGrid}
                autoHeight
                hideFooter
                disableColumnSelector
                disableColumnFilter
                rows={rows}
                columns={columns as GridColumns<GridValidRowModel>}
                getDetailPanelContent={useReviewsDetailsContent()}
                getDetailPanelHeight={() => 'auto'}
            />
        </Grid>
    );
};

export default ReviewsTab;
