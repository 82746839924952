/* eslint-disable @typescript-eslint/no-explicit-any */
import { Modal, PrimaryButton, SecondaryButton, TextField } from '@get-e/react-components';
import { Box, Grid, Typography, useMediaQuery } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import React, { ChangeEvent, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { COLORS } from '../../../../constants/colors';
import { InputError } from '../../../../helpers/inputValidation/InputError';
import { IS_DECIMAL_NUMBER } from '../../../../helpers/inputValidation/regexRules';
import { Validated } from '../../../../helpers/inputValidation/Validator';
import allValid from '../../../../helpers/inputValidation/validators/allValid';
import isFilledString from '../../../../helpers/inputValidation/validators/isFilledString';
import { RideStatusOption, getRideStatusOptionKey } from '../../../../helpers/rideStatusUtil';
import theme from '../../../../styles/theme';
import CellText from '../../components/CellText';
import FlightStatusBadge from '../../components/FlightStatusBadge';
import StatusOptionBadge from '../../components/StatusOptionBadge';
import { ReferencesAndremarksData } from './ReferencesAndRemarksModal';

const useStyles = makeStyles({
    heading: {
        marginBottom: '1.875rem',
        color: COLORS.BLUE,
    },
    container: { margin: '2.5rem auto 0 auto' },
    containerMobile: {
        padding: 0,
        width: '100%',
        margin: '2.5rem auto 0 auto',
    },
    formField: { marginBottom: '1.25rem' },
    buttonClose: { width: '150px' },
    buttonBack: {
        marginLeft: '1rem',
        width: '150px',
    },
});

export interface CloseRideData {
    referenceNumber: string;
    remarks: string;
}

interface ValidatedFormField {
    [key: string]: Validated<string, InputError>;
}

interface CloseRideModalProps {
    isModalOpen: boolean;
    onClose: () => void;
    data: any;
    supplierReference?: string;
}

const CloseRideModal = ({ isModalOpen, onClose, data, supplierReference }: CloseRideModalProps) => {
    const classes = useStyles();
    const { t } = useTranslation();
    const isMobile = useMediaQuery(theme.breakpoints.down('md'));
    const isSmallDevice = useMediaQuery(theme.breakpoints.down('sm'));
    const [bookingReference, setBookingReference] = useState(supplierReference);
    const [extraWaitingTime, setExtraWaitingTime] = useState<string | null>('');
    const [waitingTimeCosts, setWaitingTimeCosts] = useState<string | null>('');
    const [parkingTollCosts, setParkingTollCosts] = useState<string | null>('');
    const [otherCosts, setOtherCosts] = useState<string | null>('');
    const [remarks, setRemarks] = useState<string>('');
    const [remarksError, setRemarksError] = useState<InputError | null>(null);
    const [isSaving, setIsSaving] = useState(false);

    const isRemarksRequired = useMemo(() => {
        if (
            bookingReference
        || extraWaitingTime
        || waitingTimeCosts
        || parkingTollCosts
        || otherCosts
        ) {
            return false;
        }

        return true;
    }, [
        bookingReference,
        extraWaitingTime,
        waitingTimeCosts,
        parkingTollCosts,
        otherCosts,
    ]);

    const handleExtraWaitingTime = (
        event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
    ) => {
        setExtraWaitingTime(Math.floor(Number(event.target.value)).toString());
    };

    const handleWaitingTimeCosts = (
        event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
    ) => {
        if (event.target.value === '' || IS_DECIMAL_NUMBER.test(event.target.value)) {
            setWaitingTimeCosts(event.target.value);
        }
    };

    const handleParkingTollCosts = (
        event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
    ) => {
        if (event.target.value === '' || IS_DECIMAL_NUMBER.test(event.target.value)) {
            setParkingTollCosts(event.target.value);
        }
    };

    const handleOtherCosts = (
        event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
    ) => {
        if (event.target.value === '' || IS_DECIMAL_NUMBER.test(event.target.value)) {
            setOtherCosts(event.target.value);
        }
    };

    const handleClose = () => {
        if (validateFormFields()) {
            // eslint-disable-next-line @typescript-eslint/no-unused-vars
            const postData = {
                referenceNumber: bookingReference,
                minutesWaiting: extraWaitingTime,
                minutesCosts: waitingTimeCosts,
                parkingCosts: parkingTollCosts,
                otherCosts,
                remarks,
            } as ReferencesAndremarksData;

            try {
                setIsSaving(true);

                // Call API to close ride and submit references and remarks using postData
            } catch (error) {
                setIsSaving(false);
            } finally {
                onClose();
            }
        }
    };

    const validateFormFields = (): boolean => {
        const validated = {} as ValidatedFormField;

        if (isRemarksRequired) {
            validated.remarks = isFilledString(remarks, InputError.Empty);
        }

        if (!allValid(validated)) {
            setRemarksError(
                validated.remarks.isValid ? null : validated.remarks.error
            );

            return false;
        }

        return true;
    };

    return (
        <Modal open={isModalOpen} onClose={onClose} maxWidth="md">
            <Grid container>
                <Grid item>
                    <Typography variant="h2" component="h2" className={classes.heading}>
                        {t('pages.rides.closeRide')}
                    </Typography>
                </Grid>
                <Grid item display="flex" width="100%" alignItems="center" justifyContent="space-between">
                    <Typography color={COLORS.BLUE_DARK} fontWeight={700}>
                        {data.pickup}
                    </Typography>
                </Grid>
                <Grid item>
                    <CellText marginBottom="0.5rem" marginTop="1rem" fontSize="1rem">
                        <strong>{t('pages.rides.from')}: </strong>
                        {data.route.from}
                    </CellText>
                    <CellText fontSize="1rem">
                        <strong>{t('pages.rides.to')}: </strong>
                        {data.route.to}
                    </CellText>
                    <Box display="flex" alignItems="center" justifyContent="flex-start" paddingTop="0.5rem">
                        <StatusOptionBadge statusOptions={[getRideStatusOptionKey(RideStatusOption.NO_FLIGHT_INFORMATION)]} />
                        <Typography
                            sx={{
                                fontSize: '1rem',
                                color: COLORS.SLATE_GREY,
                                marginRight: '0.5rem !important',
                            }}
                        >
                            <strong>{t('flight')}: </strong>
                            {data.route.flight}
                        </Typography>
                        <FlightStatusBadge status={data.flightStatus} showTooltip />
                    </Box>
                    <CellText marginBottom="0.5rem" marginTop="1rem" fontSize="1rem">
                        <strong>{t('pages.rides.mainPassenger')}: </strong>
                        {data.passengers[0]}
                    </CellText>
                    <Box display="flex">
                        <CellText fontSize="1rem">
                            <strong>{t('travellers')}: </strong>
                        </CellText>
                        <CellText tooltip={data.passengers as string[]} marginLeft="0.1rem" fontSize="1rem">
                            {data.passengersCount}
                        </CellText>
                    </Box>
                    <CellText fontSize="1rem">
                        <strong>{t('luggages')}: </strong>
                        {data.luggages}
                    </CellText>
                    <CellText fontSize="1rem">
                        <strong>{t('carType')}: </strong>
                        {data.vehicleType}
                    </CellText>
                    <CellText marginTop="1rem" fontSize="1rem">
                        <strong>{t('pages.rides.rideNumber')}</strong>: {data.rideNumber}
                    </CellText>
                    <CellText marginTop="1rem" fontSize="1rem">
                        <strong>{t('pages.rides.specialRequest')}</strong>: {data.reference ?? '-'}
                    </CellText>
                </Grid>
                <Grid item className={isMobile ? classes.containerMobile : classes.container}>
                    <Typography
                        sx={{
                            color: COLORS.BLUE_DARK,
                            fontWeight: '700',
                            paddingBottom: '1rem',
                        }}
                    >
                        {t('pages.rides.referenceNumber')}
                    </Typography>
                    <TextField
                        className={classes.formField}
                        label={t('modals.referencesAndRemarks.yourBookingReference')}
                        value={bookingReference}
                        onChange={event => {
                            setBookingReference(event.target.value);
                        }}
                        disabled={isSaving}
                    />
                    <Grid
                        item
                        sx={{
                            paddingLeft: '0',
                            paddingBottom: '2.5rem',
                        }}
                    >
                        <Typography
                            sx={{
                                color: COLORS.BLUE_DARK,
                                fontWeight: '700',
                                paddingBottom: '1rem',
                            }}
                        >
                            {t('modals.referencesAndRemarks.additionalCosts')}
                        </Typography>
                        <Typography
                            sx={{
                                paddingBottom: '0.25rem',
                                color: COLORS.BLACK,
                            }}
                        >
                            {t('modals.referencesAndRemarks.additionalCostsDescription')}
                        </Typography>
                        <Typography sx={{ color: COLORS.BLACK }}>
                            {t('modals.referencesAndRemarks.enteredAmpountsDescription')}
                        </Typography>
                    </Grid>
                    <Grid
                        container
                        rowSpacing={1}
                        columnSpacing={{
                            xs: 1,
                            sm: 2,
                            md: 3,
                        }}
                    >
                        <Grid item xs={12} md={6} paddingTop="0 !important">
                            <TextField
                                className={classes.formField}
                                type="number"
                                name="extraWaitingTime"
                                label={t('modals.referencesAndRemarks.extraWaitingTime')}
                                value={extraWaitingTime}
                                onChange={event => handleExtraWaitingTime(event)}
                                disabled={isSaving}
                            />
                        </Grid>
                        <Grid item xs={12} md={6} paddingTop="0 !important">
                            <TextField
                                className={classes.formField}
                                type="number"
                                name="waitingTimeCosts"
                                label={t('modals.referencesAndRemarks.waitingTimeCosts')}
                                value={waitingTimeCosts}
                                onChange={event => handleWaitingTimeCosts(event)}
                                disabled={isSaving}
                            />
                        </Grid>
                        <Grid item xs={12} md={6} paddingTop="0 !important">
                            <TextField
                                className={classes.formField}
                                type="number"
                                name="parkingTollCosts"
                                label={t('modals.referencesAndRemarks.parkingTollCosts')}
                                value={parkingTollCosts}
                                onChange={event => handleParkingTollCosts(event)}
                                disabled={isSaving}
                            />
                        </Grid>
                        <Grid item xs={12} md={6} paddingTop="0 !important">
                            <TextField
                                className={classes.formField}
                                type="number"
                                name="otherCosts"
                                label={t('modals.referencesAndRemarks.otherCosts')}
                                value={otherCosts}
                                onChange={event => handleOtherCosts(event)}
                                disabled={isSaving}
                            />
                        </Grid>
                    </Grid>
                    <TextField
                        className={classes.formField}
                        label={t('pages.rides.remarks')}
                        value={remarks}
                        onChange={event => {
                            setRemarks(event.target.value);
                            setRemarksError(null);
                        }}
                        InputProps={{
                            minRows: '3',
                            multiline: true,
                        }}
                        disabled={isSaving}
                        error={isRemarksRequired && remarksError !== null}
                        helperText={isRemarksRequired && remarksError ? t(remarksError) : undefined}
                        required={isRemarksRequired}
                    />
                    <Box display="flex" justifyContent={isSmallDevice ? 'space-between' : 'flex-start'}>
                        <PrimaryButton
                            onClick={handleClose}
                            submitsForm
                            loading={isSaving}
                            disabled={isSaving}
                            className={classes.buttonClose}
                        >
                            {t('buttonName.close')}
                        </PrimaryButton>
                        <SecondaryButton
                            onClick={onClose}
                            className={classes.buttonBack}
                        >
                            {t('buttonName.back')}
                        </SecondaryButton>
                    </Box>
                </Grid>
            </Grid>
        </Modal>
    );
};

export default CloseRideModal;
