import gql from 'graphql-tag';
import config from '../../../config';

export interface User {
    id: string;
    customLogin: string | null;
    email: string;
    lastSeen: string | null;
    isDeactivated: boolean;
    joinedAt: string | null;
    firstName: string;
    fullName: string;
    lastName: string;
    permissions: {
        manageUsers: boolean;
        manageRidesBookedByOthers: boolean;
    };
}

export interface UserInvite {
    id: string;
    email: string;
    fullName: string;
    createdAt: string;
}

export interface UserInvitesPage {
    items: UserInvite[];
    totalCount: number;
    nextCursor: string | null;
    previousCursor: string | null;
}

export interface UsersPage {
    items: User[];
    totalCount: number;
    nextCursor: string | null;
    previousCursor: string | null;
}

export interface GetAllUsers {
    me: {
        id: string;
        company: {
            __typename: string;
            usersPage: {
                items: User[];
                totalCount: number;
                nextCursor: string | null;
                previousCursor: string | null;
            };
        };
    };
}
export interface GetInvitedUsers {
    me: {
        id: string;
        company: {
            __typename: string;
            userInvitesPage: {
                items: UserInvite[];
                totalCount: number;
                previousCursor: string | null;
                nextCursor: string | null;
            };
        };
    };
}

export const GET_EXISTING_USERS = gql`
    query getExistingUsers(
        $after: Cursor, 
        $before: Cursor,
        $isDeactivated: Boolean!
    ) {
        me {
            id
            company {
                __typename
                usersPage(
                    after: $after,
                    before: $before,
                    first: ${config.paginationLimit}, 
                    filter: { isDeactivated: $isDeactivated }
                ) {
                    items {
                        id
                        customLogin
                        fullName
                        firstName
                        lastName
                        email
                        joinedAt
                        lastSeen
                        isDeactivated
                        permissions {
                            manageRidesBookedByOthers
                            manageUsers
                        }
                    }
                    totalCount
                    previousCursor
                    nextCursor
                }
            }
        }
    }
`;

export const GET_INVITED_USERS = gql`
    query getInvitedUsers($after: Cursor, $before: Cursor){
        me {
            id
            company {
                __typename
                userInvitesPage(
                    first: ${config.paginationLimit}, 
                    after: $after, 
                    before: $before
                    filter: { isExpired: false }
                ) {
                    items {
                        id
                        fullName
                        email
                        createdAt
                    }
                    totalCount
                    previousCursor
                    nextCursor
                }
            }
        }
    }
`;
