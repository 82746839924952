import AccountCircleOutlinedIcon from '@mui/icons-material/AccountCircleOutlined';
import AttachMoneyOutlinedIcon from '@mui/icons-material/AttachMoneyOutlined';
import DownloadOutlinedIcon from '@mui/icons-material/DownloadOutlined';
import EmailOutlinedIcon from '@mui/icons-material/EmailOutlined';
import ReceiptLongOutlinedIcon from '@mui/icons-material/ReceiptLongOutlined';
import { Divider, Grid, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import React, { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';

import IconedData from '../../../components/iconedData/IconedData';
import { COLORS } from '../../../constants/colors';
import { useCurrentUserContext } from '../../../context/CurrentUserContext';
import { RideStatus, getRideStatusKey } from '../../../helpers/rideStatusUtil';
import EditUserModalNew from '../../users/customer/components/EditUserModalNew';
import { RideContext } from '../context/RideContext';
import { PAYMENT_METHOD } from '../customer/api/types';
import RideSummary from './RideSummary';

const useStyles = makeStyles({
    heading: {
        marginBottom: '1rem',
        color: COLORS.ORANGE,
        fontSize: '1rem',
        fontWeight: 700,
    },
    verticalDivider: {
        height: '24px',
        width: '1px',
        border: 'none',
        margin: '0 1rem',
        background: COLORS.BLACK_12,
    },
    horizontalDivider: {
        margin: '1.5rem 1rem',
        height: '1px',
        border: 'none',
        background: COLORS.BLACK_12,
    },
});

interface RideSummaryProps {
    isDownloading: boolean;
    sendBookingHandler: () => void;
    downloadBookingHandler: () => void;
    downloadReceiptHandler?: () => void;
}

const RideSummaryCustomer = ({
    isDownloading,
    sendBookingHandler,
    downloadBookingHandler,
    downloadReceiptHandler,
}: RideSummaryProps) => {
    const classes = useStyles();
    const { t } = useTranslation();
    const { currentUser } = useCurrentUserContext();
    const [isOpenBooker, setIsOpenBooker] = useState(false);

    const { summary: { status, price, priceCurrency, paymentMethod, booker } } = useContext(RideContext);

    return (
        <>
            <Grid
                container
                style={{
                    width: '100%',
                    flexDirection: 'column',
                    border: `1px solid ${COLORS.BLACK_12}`,
                    borderRadius: '3px',
                }}
            >
                <RideSummary />
                <Divider className={classes.horizontalDivider} />
                <Grid
                    container
                    style={{
                        flexDirection: 'row',
                        padding: '0 1rem',
                    }}
                >
                    <Grid item display="flex" flexDirection="column" alignItems="flexStart" xs={12} md={7} lg={7}>
                        <Typography
                            variant="h3"
                            component="h4"
                            className={classes.heading}
                            style={{
                                color: COLORS.BLACK,
                                margin: 0,
                            }}
                        >
                            {t('pages.rides.total')}:
                        </Typography>
                        <Typography
                            style={{
                                color: COLORS.SLATE_GREY,
                                fontSize: '0.75rem',
                            }}
                        >
                            {t('pages.rides.totalHint')}:
                        </Typography>
                    </Grid>
                    <Grid
                        item
                        sx={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'flex-end',
                        }}
                        xs={12}
                        md={5}
                        lg={5}
                    >
                        <Typography
                            style={{
                                color: COLORS.ORANGE,
                                fontSize: '1.5rem',
                                fontWeight: 700,
                            }}
                        >
                            {priceCurrency} {price}
                        </Typography>
                    </Grid>
                </Grid>
                <Grid item padding="1.5rem 1rem 0rem 1rem" display="flex" flexDirection="column" alignItems="flexStart">
                    <IconedData
                        icon={AttachMoneyOutlinedIcon}
                        data={paymentMethod ?? '-'}
                        tooltip={t('pages.summary.paymentMethod')}
                    />
                    <IconedData
                        icon={AccountCircleOutlinedIcon}
                        data={booker?.name ?? '-'}
                        tooltip={t('pages.summary.booker')}
                        onClick={currentUser.id === booker?.id ? () => setIsOpenBooker(true) : undefined}
                        isLinked={currentUser.id === booker?.id}
                    />
                </Grid>
                <Grid item padding="1.5rem 1rem 1rem 1rem" display="flex" flexDirection="column" alignItems="flexStart">
                    <IconedData
                        icon={EmailOutlinedIcon}
                        data={t('pages.rides.sendBookingConfirmation')}
                        bottomMargin="0.5rem"
                        iconColor={COLORS.BLUE_DARK}
                        dataColor={COLORS.BLACK}
                        onClick={sendBookingHandler}
                    />
                    <IconedData
                        icon={DownloadOutlinedIcon}
                        data={t('pages.rides.downloadBookingConfirmation')}
                        iconColor={COLORS.BLUE_DARK}
                        dataColor={COLORS.BLACK}
                        onClick={isDownloading ? () => {} : downloadBookingHandler}
                    />
                    {paymentMethod === PAYMENT_METHOD.CREDIT_CARD
                && (status === getRideStatusKey(RideStatus.CONFIRMED) || status === getRideStatusKey(RideStatus.COMPLETED)) && (
                        <IconedData
                            icon={ReceiptLongOutlinedIcon}
                            data={t('pages.rides.downloadReceipt')}
                            iconColor={COLORS.BLUE_DARK}
                            dataColor={COLORS.BLACK}
                            onClick={isDownloading ? () => {} : downloadReceiptHandler}
                        />
                    )}
                </Grid>
            </Grid>
            {isOpenBooker && <EditUserModalNew onClose={() => setIsOpenBooker(false)} user={currentUser} />}
        </>
    );
};

export default RideSummaryCustomer;
