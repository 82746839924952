import { SecondaryButton } from '@get-e/react-components';
import AddOutlinedIcon from '@mui/icons-material/AddOutlined';
import { Typography, Box, FormHelperText } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { DataGridPro, GridColumns, GridValidRowModel } from '@mui/x-data-grid-pro';
import clsx from 'clsx';
import React, { Dispatch, SetStateAction, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { v4 as uuidv4 } from 'uuid';

import { CustomNoRowsOverlay } from '../../../../components/noRowsDataGridOverlay/CustomNoRowsOverlay';
import { COLORS } from '../../../../constants/colors';
import { BUTTON_WIDTH } from '../../../../constants/layout';
import { InputError } from '../../../../helpers/inputValidation/InputError';
import useDataGridStyles from '../../../../styles/DataGrid';
import AddTravellerModal from '../AddTravellerModal';
import RemoveTravellerConfirmModal from '../RemoveTravellerConfirmModal';
import { useTravellerInformationColumns } from './useTravellerInformationColumns';

const useStyles = makeStyles({
    buttons: { width: BUTTON_WIDTH },
    addTravellerButton: {
        marginRight: '1rem',
        paddingLeft: '0.4rem',
        paddingRight: '0.4rem',
    },
    buttonIcon: { marginRight: '0.5rem' },
    error: { marginTop: '2rem' },
});

export interface Traveller {
    id: string;
    firstName: string;
    lastName: string;
    email: string;
    phone: string;
    isLead: boolean;
    uuid?: string;
}

export interface UpdateTraveller {
    firstName: string;
    lastName: string;
    email: string;
    phone: string;
    isLead: boolean;
    uuid?: string;
}

export interface TravellerError {
    id: string;
    firstNameError: InputError | null;
    lastNameError: InputError | null;
    emailError: InputError | null;
    phoneError: InputError | null;
}

const initialTravellerId = uuidv4();

export const initialTraveller = {
    id: initialTravellerId,
    firstName: '',
    lastName: '',
    email: '',
    phone: '',
    isLead: true,
} as Traveller;

export const initialTravellerErrors = {
    id: initialTravellerId,
    firstNameError: null,
    lastNameError: null,
    emailError: null,
    phoneError: null,
} as TravellerError;

interface TravellerInformationProps {
    values: Traveller[];
    setValues: Dispatch<SetStateAction<Traveller[]>>;
    errors: TravellerError[];
    setErrors: Dispatch<SetStateAction<TravellerError[]>>;
    maxTravellers: string;
}

const TravellerInformation = ({ values, setValues, errors, setErrors, maxTravellers }: TravellerInformationProps) => {
    const classes = useStyles();
    const dataGridClases = useDataGridStyles();
    const { t } = useTranslation();
    const [isAddModalOpen, setIsAddModalOpen] = useState(false);
    const [isEditModalOpen, setIsEditModalOpen] = useState(false);
    const [isRemoveModalOpen, setIsRemoveModalOpen] = useState(false);
    const selectedTraveller = useRef<Traveller>();

    const handleEdit = (traveller: Traveller) => {
        selectedTraveller.current = traveller;
        setIsEditModalOpen(true);
    };

    const handleRemove = (traveller: Traveller) => {
        selectedTraveller.current = traveller;
        setIsRemoveModalOpen(true);
    };

    const handleSetLead = (id: string) => {
        handleChangeValue(id, 'isLead', true);
    };

    const columns = useTravellerInformationColumns(handleEdit, handleRemove, handleSetLead);

    const handleChangeValue = (id: string, field: keyof Traveller, value: string | boolean) => {
        const updatedValues = values.map(traveller => {
            if (traveller.id === id) {
                return {
                    ...traveller,
                    [field]: value,
                };
            }

            if (field === 'isLead') {
                return {
                    ...traveller,
                    [field]: false,
                };
            }

            return traveller;
        });

        setValues(updatedValues);
    };

    const handleAddTraveller = (newTraveller: Traveller) => {
        const oldValues = values.map(traveller => ({
            ...traveller,
            isLead: newTraveller.isLead ? false : traveller.isLead,
        }));

        const updatedValues = [...oldValues, { ...newTraveller }];

        const updatedErrors = [
            ...errors,
            {
                id: newTraveller.id,
                firstNameError: null,
                lastNameError: null,
                emailError: null,
                phoneError: null,
            },
        ];

        setValues(updatedValues);
        setErrors(updatedErrors);
        setIsAddModalOpen(false);
    };

    const handleEditTraveller = (updatedTraveller: Traveller) => {
        const oldValues = values.map(traveller => ({
            ...traveller,
            isLead: updatedTraveller.isLead ? false : traveller.isLead,
        }));

        const updatedValues = oldValues.map(traveller => (traveller.id === updatedTraveller.id ? updatedTraveller : traveller));

        setValues(updatedValues);
        setIsEditModalOpen(false);
    };

    const handleRemoveTraveller = (id: string) => {
        const updatedValues = values.filter(traveller => traveller.id !== id);
        const updatedErrors = errors.filter(traveller => traveller.id !== id);

        setValues(updatedValues);
        setErrors(updatedErrors);
        selectedTraveller.current = undefined;
    };

    return (
        <>
            <Box sx={{ marginBottom: '1rem' }}>
                <Typography
                    style={{
                        fontWeight: 700,
                        color: COLORS.BLUE_DARK,
                    }}
                >
                    {t('pages.rides.travellerInformation')}
                </Typography>
            </Box>
            <DataGridPro
                className={clsx({
                    [dataGridClases.dataGrid]: true,
                    [dataGridClases.noMarginTop]: true,
                    [dataGridClases.dataGridNoRows]: !values,
                })}
                autoHeight
                hideFooter
                disableColumnSelector
                disableColumnMenu
                disableColumnFilter
                rows={values}
                columns={columns as unknown as GridColumns<GridValidRowModel>}
                components={{ NoRowsOverlay: CustomNoRowsOverlay }}
                componentsProps={{ noRowsOverlay: { noRows: t('noResultsFound') } }}
            />
            {values.length >= parseInt(maxTravellers)
            && <FormHelperText error className={classes.error}>{t('errors.travellerMaximumReached')}</FormHelperText>}
            <Box marginTop="2rem">
                <SecondaryButton
                    onClick={() => setIsAddModalOpen(true)}
                    className={clsx(classes.buttons, classes.addTravellerButton)}
                    disabled={values.length >= parseInt(maxTravellers)}
                >
                    <AddOutlinedIcon className={classes.buttonIcon} />
                    {t('pages.rides.addTraveller')}
                </SecondaryButton>
            </Box>
            {isAddModalOpen && (
                <AddTravellerModal
                    isOpen={isAddModalOpen}
                    onClose={() => setIsAddModalOpen(false)}
                    onSubmit={handleAddTraveller}
                />
            )}
            {isEditModalOpen && (
                <AddTravellerModal
                    isOpen={isEditModalOpen}
                    onClose={() => setIsEditModalOpen(false)}
                    onSubmit={handleEditTraveller}
                    traveller={selectedTraveller.current}
                />
            )}
            {selectedTraveller.current && isRemoveModalOpen && (
                <RemoveTravellerConfirmModal
                    onClose={() => setIsRemoveModalOpen(false)}
                    onConfirm={handleRemoveTraveller}
                    traveller={selectedTraveller.current}
                />
            )}
        </>
    );
};

export default TravellerInformation;
