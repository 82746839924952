import moment, { Moment } from 'moment';

const getDateFormatKey = (date: Moment): string => {
    if (date > moment().subtract(1, 'day')) {
        return 'timeFormat';
    }

    if (date > moment().subtract(1, 'week')) {
        return 'dayFormat';
    }

    return 'dateFormat';
};

export default getDateFormatKey;
