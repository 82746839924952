/* eslint-disable max-statements */
import { useHistory } from 'react-router-dom';
import config from '../config';
import refreshCookie from './refreshCookie';

interface Payload {
    openRide?: {
        rideId?: number;
        inNewTab?: boolean;
    };
    unauthenticated?: boolean;
    unauthorized?: boolean;
    ridesListError?: boolean;
    editBooker?: {
        userId: unknown;
    };
    sendBookingConfirmationEmail?: { rideId?: number };
    changeRoute?: { rideId?: number };
    changeFlight?: { rideId?: number };
    changePassengers?: { rideId?: number };
    changeTrackedFlight?: { rideId?: number };
}

export default function handleLegacyIframeMessage(
    event: MessageEvent,
    history: ReturnType<typeof useHistory>,
    listeners?: {
        onRefreshingCookie: () => void;
        onCookieRefreshed: () => void;
        onUnauthorized: () => void;
        onOpenUser?: (value: string) => void;
    }
): void {
    const { legacyPortalPayload: payload } = event.data as {
        legacyPortalPayload?: Payload;
    };

    if (!payload) {
        return;
    }

    if (payload.unauthenticated) {
        listeners?.onRefreshingCookie?.();
        // eslint-disable-next-line @typescript-eslint/no-floating-promises
        refreshCookie()
            .then(result => {
                switch (result.code) {
                    case 'UNAUTHENTICATED':
                        window.location.pathname = '/log-in';
                        return;
                    case 'SUCCESS':
                        listeners?.onCookieRefreshed?.();
                        return;
                    default:
                        throw new Error('Unhandled result code');
                }
            });

        return;
    }

    if (payload.unauthorized) {
        listeners?.onRefreshingCookie?.();
        // eslint-disable-next-line @typescript-eslint/no-floating-promises
        refreshCookie()
            .then(result => {
                switch (result.code) {
                    case 'UNAUTHENTICATED':
                        window.location.pathname = '/invalid-cookie';
                        return;
                    case 'SUCCESS':
                        listeners?.onUnauthorized?.();
                        return;
                    default:
                        throw new Error('Unhandled result code');
                }
            });

        return;
    }

    if (payload.openRide) {
        const { rideId, inNewTab } = payload.openRide;

        if (!rideId) {
            throw new Error('No ride ID provided');
        }

        if (inNewTab) {
            window.open(`${config.appUrl}/rides/${rideId}`);
        } else {
            history.push(`/rides/${rideId}`);
        }

        return;
    }

    if (payload.editBooker) {
        const { userId } = payload.editBooker;

        if (!userId) {
            throw new Error('No user ID provided');
        }

        if (typeof userId === 'string') {
            listeners?.onOpenUser?.(userId);
        } else {
            throw new Error('User ID is not a string');
        }

        return;
    }

    if (payload.sendBookingConfirmationEmail) {
        const { rideId } = payload.sendBookingConfirmationEmail;

        if (!rideId) {
            throw new Error('No ride ID provided');
        }

        history.push(`/rides/${rideId}/booking-confirmation`);
        return;
    }

    if (payload.changeRoute) {
        handleChangeRoute(payload.changeRoute, 'route', history);
        return;
    }

    if (payload.changeFlight) {
        handleChangeRoute(payload.changeFlight, 'flight', history);
        return;
    }

    if (payload.changePassengers) {
        handleChangeRoute(payload.changePassengers, 'passengers', history);
        return;
    }

    if (payload.changeTrackedFlight) {
        handleChangeRoute(
            payload.changeTrackedFlight,
            'tracked-flight',
            history,
        );
        return;
    }

    throw new Error(
        'No valid action supplied for legacyPortalPayload',
    );
}

const handleChangeRoute = (
    payload: Record<string, number | undefined>,
    routeName: string,
    history: ReturnType<typeof useHistory>,
) => {
    const { rideId } = payload;

    if (!rideId) {
        throw new Error('No ride ID provided');
    }

    history.push(`/rides/${rideId}/change/${routeName}`);
};
