import React, { FunctionComponent } from 'react';
import { useParams } from 'react-router-dom';
import LegacyView from '../../../../components/legacyView/LegacyView';

const Passengers: FunctionComponent = () => {
    const { rideId }: {rideId: string} = useParams();

    return (
        <LegacyView
            path={`/rides/${rideId}/change/pax-info`}
            title={`Ride ${rideId}: Change passenger info`}
        />
    );
};

export default Passengers;
