import { MultipleSelect, TextField as CustomTextField } from '@get-e/react-components';
import SearchIcon from '@mui/icons-material/Search';
import { Box, Grid, InputAdornment, Typography, TextField, useMediaQuery } from '@mui/material';
import {
    DataGridPro,
    GridColumns,
    GridRowParams,
    GridRowSpacingParams,
    GridRowsProp,
    GridValidRowModel,
} from '@mui/x-data-grid-pro';
import { LocalizationProvider } from '@mui/x-date-pickers-pro';
import { AdapterMoment } from '@mui/x-date-pickers-pro/AdapterMoment';
import { DateRangePicker, DateRange } from '@mui/x-date-pickers-pro/DateRangePicker';
import moment, { Moment } from 'moment';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';

import { COLORS } from '../../../constants/colors';
import { DATE_FORMATS } from '../../../constants/dateFormats';
import { getSingleRideRoute } from '../../../constants/urlPaths';
import { FlightStatus, getFlightStatusKeyName } from '../../../helpers/flightStatusUtil';
import {
    getRideStatusKey,
    getRideStatusSupplierMap,
    RideStatus,
    RideStatusSupplierFilter,
} from '../../../helpers/rideStatusUtil';
import useDataGridStyles from '../../../styles/DataGrid';
import theme from '../../../styles/theme';
import FilterButton from '../components/FilterButton';
import CloseRideModal from './components/CloseRideModal';
import ConfirmRideModal, { ConfirmRideData } from './components/ConfirmRideModal';
import FilterRidesModal, { RideFilters } from './components/FilterRidesModal';
import ReferencesAndRemarksModal from './components/ReferencesAndRemarksModal';
import { useRidesColumns } from './hooks/useRidesColumns';

export enum RideAction {
    EDIT_RIDE,
    SEND_CONFIRMATION,
    DOWNLOAD_CONFIRMATION,
}

export enum ChangeStatus {
    CONFIRM = 'buttonName.confirm',
    CLOSE = 'buttonName.close',
    ADD_DRIVER = 'buttonName.addDriver',
    EDIT_DRIVER = 'buttonName.editDriver',
    DELETE_DRIVER = 'buttonName.deleteDriver'
}

const rows: GridRowsProp = [
    {
        id: 1,
        status: getRideStatusKey(RideStatus.TO_CONFIRM),
        flightStatus: getFlightStatusKeyName(FlightStatus.CANCELLED),
        pickup: 'Tue, 13 May 2033 12:00 AM',
        passengers: ['Endrik Lastname', 'Jakub Lastname', 'Virgo crew'],
        passengersCount: 12,
        luggages: 3,
        vehicleType: 'Business Sedan',
        driver: {
            name: 'Dani California',
            rating: '4.80',
            reviewsNumber: '17',
        },
        changeStatus: ChangeStatus.CONFIRM,
        route: {
            from: 'YUL - Montreal-Pierre Elliott Trudeau International Airport',
            to: 'InterContinental Montreal, 360 Rue Saint-Antoine O, Montréal, QC H2Y 3X4, Canada',
            flight: 'Virgo 815',
        },
        reference: 'Cras risus ipsum, faucibus ut, ullamcorper id, varius ac, leo. Quisque malesuada placerat nisl.',
        rideNumber: '111-111-1111',
    },
    {
        id: 2,
        status: getRideStatusKey(RideStatus.CONFIRMED),
        flightStatus: getFlightStatusKeyName(FlightStatus.COMPLETED_ON_TIME),
        pickup: 'Fri, 22 Jun 2044 08:00 PM',
        passengers: ['Endrik Lastname', 'Jakub Lastname', 'Virgo crew', 'Endrik Lastname', 'Jakub Lastname', 'Virgo crew'],
        passengersCount: 6,
        luggages: 1,
        vehicleType: 'Business Sedan',
        driver: null,
        changeStatus: ChangeStatus.ADD_DRIVER,
        route: {
            from: 'MJZ - Mirnyj Airport',
            to: 'AZIMUT Отель Мирный, Ulitsa Lenina, 11А, Mirny, Respublika Sakha',
            flight: 'Virgo 815',
        },
        reference: 'Cras risus ipsum, faucibus ut, ullamcorper id, varius ac, leo. Quisque malesuada placerat nisl.',
        rideNumber: '222-222-2222',
    },
    {
        id: 3,
        status: getRideStatusKey(RideStatus.CANCELLED),
        flightStatus: getFlightStatusKeyName(FlightStatus.LANDED_ON_TIME),
        pickup: 'Mon, 16 Dec 2055 03:00 AM',
        passengers: ['Endrik Lastname', 'Jakub Lastname', 'Virgo crew'],
        passengersCount: 5,
        luggages: 2,
        vehicleType: 'Business Sedan',
        driver: null,
        changeStatus: ChangeStatus.CLOSE,
        route: {
            from: 'MJZ - Mirnyj Airport',
            to: 'AZIMUT Отель Мирный, Ulitsa Lenina, 11А, Mirny, Respublika Sakha',
            flight: 'Virgo 815',
        },
        reference: null,
        rideNumber: '333-333-3333',
    },
];

const Rides = () => {
    const { t } = useTranslation();
    const history = useHistory();
    const dataGridClases = useDataGridStyles();
    const isMobile = useMediaQuery(theme.breakpoints.down('lg'));
    const [searchPhrase, setSearchPhrase] = useState<string>();
    const [statusIds, setStatusIds] = useState<string[]>([]);
    const [value, setValue] = React.useState<DateRange<Moment>>([moment().subtract(6, 'months'), moment()]);
    const [isFilterRidesModalOpen, setIsFilterRidesModalOpen] = useState(false);
    const [isConfirmRideModalOpen, setIsConfirmRideModalOpen] = useState(false);
    const [isCloseRideModalOpen, setIsCloseRideModalOpen] = useState(false);
    const [rideData, setRideData] = useState<unknown>();
    const [isReferencesModalOpen, setIsReferencesModalOpen] = useState(false);

    const getRowSpacing = React.useCallback((params: GridRowSpacingParams) => {
        return {
            top: params.isFirstVisible ? 0 : 10,
            bottom: params.isLastVisible ? 0 : 10,
        };
    }, []);

    const onSetStatusIds = (ids: string[]): void => {
        setStatusIds(ids);
    };

    const handleChangeStatusButtonClick = (changeStatus: ChangeStatus, data: unknown) => {
        switch (changeStatus) {
            case ChangeStatus.CONFIRM:
                setRideData(data);
                setIsConfirmRideModalOpen(true);
                break;
            case ChangeStatus.CLOSE:
                setRideData(data);
                setIsCloseRideModalOpen(true);
                break;
            case ChangeStatus.ADD_DRIVER:
                break;
            case ChangeStatus.EDIT_DRIVER:
                break;
            default:
                throw Error('Unexpected change status');
        }
    };

    const handleEdit = (id: string) => {};

    const handleReferenceAndRemarks = (id: string) => {
        setIsReferencesModalOpen(true);
    };

    const handleDownloadRideOrder = (id: string) => {};

    const [desktopColumns, mobileColumns] = useRidesColumns(
        handleChangeStatusButtonClick,
        handleEdit,
        handleReferenceAndRemarks,
        handleDownloadRideOrder
    );

    const handleRowClick = (params: GridRowParams) => {
        history.push(getSingleRideRoute(params.id.toString()));
    };

    const handleFilterRides = (filters: RideFilters) => {};

    const handleConfirmRide = (data: ConfirmRideData) => {};

    return (
        <>
            <Grid
                container
                alignItems="flex-start"
                sx={{
                    padding: ['1rem', '1rem', '2rem'],
                    paddingBottom: ['4rem', '2rem', '2rem'],
                }}
            >
                <Grid item xs>
                    <Typography
                        sx={{
                            color: COLORS.BLUE,
                            fontSize: '1.5rem',
                            fontWeight: 700,
                        }}
                    >
                        {t('pages.rides.scheduledRides')}
                    </Typography>
                </Grid>
                <Grid container marginTop="2rem">
                    <Grid
                        item
                        xs={12}
                        md={isMobile ? 12 : 4}
                        lg={isMobile ? 12 : 4}
                        xl={4}
                        marginBottom={isMobile ? '2rem' : 'initial'}
                    >
                        <CustomTextField
                            id="searchbyTravellerReferenceRideNumber"
                            autoComplete="off"
                            value={searchPhrase}
                            noHelperTextSpace
                            onChange={event => {
                                setSearchPhrase(event.target.value);
                            }}
                            label={t('pages.rides.searchByLocationNameReference')}
                            name="search"
                            InputProps={{
                                endAdornment: (
                                    <InputAdornment position="end">
                                        <SearchIcon />
                                    </InputAdornment>
                                ),
                            }}
                        />
                    </Grid>
                    <Grid
                        item
                        xs={12}
                        md={4}
                        xl={4}
                        display={isMobile ? 'none' : 'flex'}
                        sx={{ paddingLeft: ['0', '0', '2rem'] }}
                    >
                        <MultipleSelect
                            label="Status"
                            value={statusIds}
                            values={getRideStatusSupplierMap()}
                            onSetIds={onSetStatusIds}
                            defaultSelectedValue={[
                                RideStatusSupplierFilter.TO_CONFIRM,
                                RideStatusSupplierFilter.CONFIRMED,
                                RideStatusSupplierFilter.TO_CLOSE,
                            ]}
                        />
                    </Grid>
                    <Grid
                        item
                        xs={12}
                        md={4}
                        xl={4}
                        display={isMobile ? 'none' : 'flex'}
                        sx={{
                            paddingLeft: ['0', '0', '2rem'],
                            marginTop: ['1rem', '1rem', 0],
                            justifyContent: 'flex-end',
                        }}
                    >
                        <LocalizationProvider
                            dateAdapter={AdapterMoment}
                            localeText={{
                                start: t('pages.rides.startingDate'),
                                end: t('pages.rides.endingDate'),
                            }}
                        >
                            <DateRangePicker
                                value={value}
                                inputFormat={DATE_FORMATS['DD MMM yyyy']}
                                disableMaskedInput
                                onChange={newValue => {
                                    setValue(newValue);
                                }}
                                renderInput={(startProps, endProps) => (
                                    <>
                                        <TextField id="startingDate" autoComplete="off" {...startProps} variant="filled" />
                                        <Box
                                            sx={{
                                                mx: 2,
                                                display: 'flex',
                                                alignSelf: 'center',
                                            }}
                                        >
                                            {t('to')}
                                        </Box>
                                        <TextField id="endingDate" autoComplete="off" {...endProps} variant="filled" />
                                    </>
                                )}
                            />
                        </LocalizationProvider>
                    </Grid>
                </Grid>
                <Grid container position="relative">
                    <Box width="100%">
                        <DataGridPro
                            className={isMobile ? dataGridClases.dataGridMobile : dataGridClases.dataGrid}
                            autoHeight
                            hideFooter
                            disableColumnSelector
                            disableColumnFilter
                            rows={rows}
                            columns={
                                isMobile
                                    ? (mobileColumns as unknown as GridColumns<GridValidRowModel>)
                                    : (desktopColumns as unknown as GridColumns<GridValidRowModel>)
                            }
                            getRowHeight={() => 'auto'}
                            getRowSpacing={getRowSpacing}
                            onRowClick={handleRowClick}
                            headerHeight={isMobile ? 0 : 56}
                        />
                    </Box>
                    {isMobile && <FilterButton onClick={() => setIsFilterRidesModalOpen(true)} />}
                </Grid>
            </Grid>
            {isFilterRidesModalOpen && (
                <FilterRidesModal
                    isModalOpen={isFilterRidesModalOpen}
                    onClose={() => setIsFilterRidesModalOpen(false)}
                    onFilter={handleFilterRides}
                    isSaving={false}
                />
            )}
            {isConfirmRideModalOpen && (
                <ConfirmRideModal
                    isModalOpen={isConfirmRideModalOpen}
                    onClose={() => setIsConfirmRideModalOpen(false)}
                    onConfirm={handleConfirmRide}
                    data={rideData}
                    isSaving={false}
                />
            )}
            {isCloseRideModalOpen && (
                <CloseRideModal
                    isModalOpen={isCloseRideModalOpen}
                    onClose={() => setIsCloseRideModalOpen(false)}
                    data={rideData}
                />
            )}
            {isReferencesModalOpen && (
                <ReferencesAndRemarksModal
                    isModalOpen={isReferencesModalOpen}
                    onClose={() => setIsReferencesModalOpen(false)}
                />
            )}
        </>
    );
};

export default Rides;
