/* eslint-disable no-case-declarations */
import { useMutation } from '@apollo/react-hooks';
import { MessageDialog } from '@get-e/react-components';
import { Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { ApolloError } from 'apollo-client';
import React, { FunctionComponent, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { COLORS } from '../../../../constants/colors';
import { useNotificationContext, Severity } from '../../../../context/NotificationContext';
import { InputError } from '../../../../helpers/inputValidation/InputError';
import {
    ApiValidationError,
    parseInputErrors,
} from '../../../../helpers/inputValidation/parseFormErrors';
import { report } from '../../../../helpers/report';
import typographyStyles from '../../../../styles/Typography';
import { GET_INVITED_USERS, UserInvite } from '../Users.graphql';
import {
    REVOKE_USER_INVITE,
    RevokeUserInviteModalInput,
} from './RevokeUserInviteModal.graphql';

const useStyles = makeStyles({
    confirmButton: {
        width: '100%',
        background: COLORS.RED,
        '&:hover': { background: COLORS.RED_WARNING },
    },
    closeButton: { marginLeft: '.5rem' },
});

enum InviteError {
    NotFound = 'modals.revokeInvite.helperText.notFound',
    UnexpectedError = 'modals.unexpectedError',
    Unauthorized = 'modals.revokeInvite.helperText.unauthorized'
}

interface RevokeUserInviteErrors {
    validationErrors: {
        input?: {
            inviteId?: ApiValidationError[];
        };
    };
}

const RevokeUserInviteModal: FunctionComponent<{
    onClose: () => void;
    user: UserInvite;
}> = ({
    onClose,
    user,
}) => {
    const { t } = useTranslation();
    const typographyClasses = typographyStyles();
    const { showNotification } = useNotificationContext();
    const classes = useStyles();

    const [
        modalError,
        setModalError,
    ] = useState<InviteError|InputError|null>(null);

    const [revokeUserInvite, { error }] = useMutation<RevokeUserInviteModalInput>(REVOKE_USER_INVITE, {
        onCompleted() {
            onClose();
            const { fullName } = user;

            showNotification(t('revokeInviteSuccess', { fullName }), Severity.Info);
        },
        onError(apolloError) {
            const modalErrors = getRevokeUserInviteError(apolloError);

            if (modalErrors === InviteError.UnexpectedError) {
                report(apolloError);
            }

            setModalError(modalErrors);
        },
        awaitRefetchQueries: true,
        refetchQueries: [
            {
                query: GET_INVITED_USERS,
                variables: {
                    after: null,
                    before: null,
                },
            },
        ],
    });

    const containsNotFound = (inviteErrors: ApiValidationError[]|undefined): boolean => {
        if (!inviteErrors || !inviteErrors.length) {
            return false;
        }

        return inviteErrors.some(inviteError => inviteError.code === 'NOT_FOUND');
    };

    const getRevokeUserInviteError = (
        newError: ApolloError
    ): InviteError|InputError => {
        let newModalError: InviteError|InputError|null = null;

        if (newError.graphQLErrors.length) {
            for (const graphqlError of newError.graphQLErrors) {
                switch (graphqlError?.extensions?.code) {
                    case 'UNAUTHORIZED':
                        newModalError = InviteError.Unauthorized;
                        break;
                    case 'BAD_USER_INPUT':
                        const { validationErrors } = graphqlError
                            .extensions as RevokeUserInviteErrors;

                        try {
                            newModalError = containsNotFound(validationErrors
                                .input?.inviteId)
                                ? newModalError = InviteError.NotFound
                                : parseInputErrors(validationErrors
                                    .input?.inviteId);
                        } catch {
                            newModalError = InviteError.UnexpectedError;
                        }

                        break;
                    default:
                        newModalError = InviteError.UnexpectedError;
                        continue;
                }
            }
        }

        if (newModalError === null) {
            newModalError = InviteError.UnexpectedError;
        }

        return newModalError;
    };

    const handleClick = async (): Promise<void> => {
        await revokeUserInvite({ variables: { inviteId: user.id } });
    };

    return (
        <MessageDialog
            onClose={onClose}
            onConfirm={handleClick}
            title={t('modals.revokeInvite.title')}
            isConfirmButtonDisplayed
            closeButtonLabel= {t('buttonName.cancel')}
            confirmButtonLabel={t('buttonName.revokeInvite')}
            closeButtonType="secondary"
            confirmButtonClassName={classes.confirmButton}
            closeButtonClassName={classes.closeButton}
            areButtonsFullWidth
        >
            <Typography
                style={{
                    fontWeight: 'bold',
                    paddingTop: '1rem',
                }}
            >
                {t('modals.revokeInvite.description')}
            </Typography>
            <Typography
                style={{
                    paddingTop: '0.5rem',
                    paddingBottom: '1rem',
                }}
            >
                {t('modals.revokeInvite.descriptionDetailed')}
            </Typography>
            {error
                    && (
                        <div className={typographyClasses.errorContainer}>
                            <p className={typographyClasses.errorTheme}>
                                {modalError === null
                                    ? t(InviteError.UnexpectedError)
                                    : t(modalError)
                                }
                            </p>
                        </div>
                    )}
        </MessageDialog>
    );
};

export default RevokeUserInviteModal;
