import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { Box, Grid, LinearProgress, Typography } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { COLORS } from '../../../constants/colors';

const useStyles = makeStyles({
    mainContainer: {
        width: '100%',
        height: 'calc(100% - 1rem)',
        flexDirection: 'column',
        border: `1px solid ${COLORS.BLACK_12}`,
        borderRadius: '3px',
        marginTop: '1rem',
    },
    label: {
        color: COLORS.BLACK,
        fontSize: '0.75rem',
        fontWeight: '700',
    },
});

interface AverageRatingDistributionCardProps {
    title?: string;
    stars5?: number;
    stars4?: number;
    stars3?: number;
    stars2?: number;
    stars1?: number;
}

const AverageRatingDistributionCard = ({
    title = '',
    stars5 = 0,
    stars4 = 0,
    stars3 = 0,
    stars2 = 0,
    stars1 = 0,
}: AverageRatingDistributionCardProps) => {
    const classes = useStyles();
    const { t } = useTranslation();

    const totalRatings = useMemo(() => stars1 + stars2 + stars3 + stars4 + stars5, [stars1, stars2, stars3, stars4, stars5]);

    const ratingsPerPercent = useMemo(() => 100 / totalRatings, [totalRatings]);

    return (
        <Grid container className={classes.mainContainer}>
            <Grid item padding="1rem" height="100%" display="flex" flexDirection="column" justifyContent="space-between">
                <Box display="flex" justifyContent="space-between" alignItems="center" marginBottom="1.25rem">
                    <Typography fontWeight="700" color={COLORS.SLATE_GREY}>
                        {title}
                    </Typography>
                    <InfoOutlinedIcon style={{ color: COLORS.SLATE_GREY }} />
                </Box>
                <Box display="flex" flexDirection="column" paddingRight="2px">
                    <Box display="flex" justifyContent="space-between" alignItems="center">
                        <Typography className={classes.label}>{t('pages.reports.stars5')}</Typography>
                        <Box display="flex" alignItems="center" flex="1">
                            <Box width="100%" padding="0 0.5rem" color={COLORS.GREEN}>
                                <LinearProgress value={ratingsPerPercent * stars5} color="inherit" variant="determinate" />
                            </Box>
                            <Typography fontSize="0.75rem" fontWeight="700">{`${Math.round(stars5)}`}</Typography>
                        </Box>
                    </Box>
                    <Box display="flex" justifyContent="space-between" alignItems="center">
                        <Typography className={classes.label}>{t('pages.reports.stars4')}</Typography>
                        <Box display="flex" alignItems="center" flex="1">
                            <Box width="100%" padding="0 0.5rem" color={COLORS.ORANGE_WARNING}>
                                <LinearProgress value={ratingsPerPercent * stars4} color="inherit" variant="determinate" />
                            </Box>
                            <Typography fontSize="0.75rem" fontWeight="700">{`${Math.round(stars4)}`}</Typography>
                        </Box>
                    </Box>
                    <Box display="flex" justifyContent="space-between" alignItems="center">
                        <Typography className={classes.label}>{t('pages.reports.stars3')}</Typography>
                        <Box display="flex" alignItems="center" flex="1">
                            <Box width="100%" padding="0 0.5rem" color={COLORS.RED}>
                                <LinearProgress value={ratingsPerPercent * stars3} color="inherit" variant="determinate" />
                            </Box>
                            <Typography fontSize="0.75rem" fontWeight="700">{`${Math.round(stars3)}`}</Typography>
                        </Box>
                    </Box>
                    <Box display="flex" justifyContent="space-between" alignItems="center">
                        <Typography className={classes.label}>{t('pages.reports.stars2')}</Typography>
                        <Box display="flex" alignItems="center" flex="1">
                            <Box width="100%" padding="0 0.5rem" color={COLORS.RED}>
                                <LinearProgress value={ratingsPerPercent * stars2} color="inherit" variant="determinate" />
                            </Box>
                            <Typography fontSize="0.75rem" fontWeight="700">{`${Math.round(stars2)}`}</Typography>
                        </Box>
                    </Box>
                    <Box display="flex" justifyContent="space-between" alignItems="center">
                        <Typography className={classes.label}>{t('pages.reports.stars1')}</Typography>
                        <Box display="flex" alignItems="center" flex="1">
                            <Box width="100%" padding="0 0.5rem" color={COLORS.RED}>
                                <LinearProgress value={ratingsPerPercent * stars1} color="inherit" variant="determinate" />
                            </Box>
                            <Typography fontSize="0.75rem" fontWeight="700">{`${Math.round(stars1)}`}</Typography>
                        </Box>
                    </Box>
                </Box>
            </Grid>
        </Grid>
    );
};

export default AverageRatingDistributionCard;
