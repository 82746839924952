import { useQuery } from 'react-query';

import { USE_USER_PROFILE } from '../../../constants/queryKeys';
import { getUserProfile } from '../../../services/userProfile';

export const useUserProfile = () => useQuery(
    [USE_USER_PROFILE],
    () =>
        getUserProfile(),
    {
        keepPreviousData: true,
        refetchOnWindowFocus: false,
        retry: 1,
        cacheTime: 500,
    },
);
