import { Theme } from '@mui/material/styles';
import { makeStyles, createStyles } from '@mui/styles';

const userGridStyles = makeStyles((theme: Theme) => createStyles(
    {
        root: {
            flexGrow: 1,
            width: '100%',
            button: { backgroundColor: theme.palette.primary.main },
        },
        inviteUserButton: {
            order: 0,
            marginBottom: '1em',
        },
        usersHeader: { marginBottom: '3rem' },
        userTabs: { order: 1 },
        content: { order: 2 },
        [theme.breakpoints.up('sm')]: {
            userTabs: {
                order: 0,
                marginBottom: '1em',
            },
            inviteUserButton: { order: 1 },
        },
    },
));

export default userGridStyles;
