import { Modal, PrimaryButton, TextField, SecondaryButton } from '@get-e/react-components';
import PersonOutlineIcon from '@material-ui/icons/PersonOutline';
import EmailOutlinedIcon from '@mui/icons-material/EmailOutlined';
import { Checkbox, FormControlLabel, InputAdornment, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import ShowPasswordButton from '../../../../components/buttons/showPasswordButton/ShowPasswordButton';
import { COLORS } from '../../../../constants/colors';
import { BUTTON_WIDTH } from '../../../../constants/layout';
import { InputError } from '../../../../helpers/inputValidation/InputError';
import and from '../../../../helpers/inputValidation/validators/and';
import isEmail from '../../../../helpers/inputValidation/validators/isEmail';
import isEqualString from '../../../../helpers/inputValidation/validators/isEqualString';
import isFilledString from '../../../../helpers/inputValidation/validators/isFilledString';
import isPasswordStrong from '../../../../helpers/inputValidation/validators/isPasswordStrong';

const useStyles = makeStyles({
    formButton: {
        width: BUTTON_WIDTH,
        marginRight: '1rem',
    },
    formField: {
        marginBottom: '1rem',
        width: '100%',
        '& .MuiFormHelperText-root.Mui-error': { padding: '0 .75rem' },
    },
    buttonsContainer: { marginTop: '2rem' },
    multipleSelectItem: {
        width: '100% !important',
        padding: '0.5rem !important',
        '& .MuiListItemText-root': { paddingLeft: '0.5rem !important' },
    },
});

interface AddUserFields {
    firstName: string;
    lastName: string;
    email: string;
    password: string;
    confirmPassword: string;
    manageUsers: boolean;
}

interface AddUserErrors {
    firstName: InputError | null;
    lastName: InputError | null;
    email: InputError | null;
    password: InputError | null;
    confirmPassword: InputError | null;
}

interface AddUserModalProps {
    onClose: () => void;
    isOpen: boolean;
}

const AddUserModal = ({ onClose, isOpen }: AddUserModalProps) => {
    const classes = useStyles();
    const { t } = useTranslation();
    const [showingPassword, setShowingPassword] = useState(false);
    const [showingConfirmPassword, setShowingConfirmPassword] = useState(false);

    const [values, setValues] = useState<AddUserFields>({
        firstName: '',
        lastName: '',
        email: '',
        password: '',
        confirmPassword: '',
        manageUsers: false,
    });

    const [formErrors, setFormErrors] = useState<AddUserErrors>({
        firstName: null,
        lastName: null,
        email: null,
        password: null,
        confirmPassword: null,
    });

    const validateFields = (): boolean => {
        const validated = {
            firstName: isFilledString(values.firstName, InputError.Empty),
            lastName: isFilledString(values.lastName, InputError.Empty),
            email: and(isFilledString(values.email, InputError.Empty), () => isEmail(values.email, InputError.InvalidEmail)),
            password: isPasswordStrong(values.password, InputError.NotStrongPassword),
            confirmPassword: isEqualString(values.confirmPassword, values.password, InputError.NoMatch),
        };

        const fieldErrors: AddUserErrors = {
            firstName: validated.firstName.isValid ? null : validated.firstName.error,
            lastName: validated.lastName.isValid ? null : validated.lastName.error,
            email: validated.email.isValid ? null : validated.email.error,
            password: validated.password.isValid ? null : validated.password.error,
            confirmPassword: validated.confirmPassword.isValid ? null : validated.confirmPassword.error,
        };

        const isValid = Object.values(fieldErrors).every(error => error === null);

        !isValid && setFormErrors(fieldErrors);

        return isValid;
    };

    const handleSubmit = (): void => {
        if (!validateFields()) {
            return;
        }

        onClose();
    };

    const handleChange = <T extends keyof AddUserFields>(key: T, newValue: AddUserFields[T] & (string | boolean)): void => {
        setValues({
            ...values,
            [key]: newValue,
        });

        setFormErrors(prevStateForm => ({
            ...prevStateForm,
            [key]: null,
        }));
    };

    return (
        <Modal open={isOpen} onClose={onClose}>
            <Typography
                style={{
                    color: COLORS.BLUE_DARK,
                    fontSize: '1.5rem',
                    fontWeight: 700,
                    marginBottom: '2rem',
                }}
            >
                {t('pages.users.newUser')}
            </Typography>
            <TextField
                className={classes.formField}
                error={formErrors.firstName !== null}
                helperText={formErrors.firstName ? t(formErrors.firstName) : null}
                label={t('pages.users.fields.firstName')}
                name="firstName"
                onChange={event => handleChange('firstName', event.target.value)}
                required
                autoComplete="off"
                value={values.firstName}
                InputProps={{
                    endAdornment: (
                        <InputAdornment position="end">
                            <PersonOutlineIcon />
                        </InputAdornment>
                    ),
                }}
            />
            <TextField
                className={classes.formField}
                error={formErrors.lastName !== null}
                helperText={formErrors.lastName ? t(formErrors.lastName) : null}
                label={t('pages.users.fields.lastName')}
                name="lastName"
                onChange={event => handleChange('lastName', event.target.value)}
                required
                autoComplete="off"
                value={values.lastName}
                InputProps={{
                    endAdornment: (
                        <InputAdornment position="end">
                            <PersonOutlineIcon />
                        </InputAdornment>
                    ),
                }}
            />
            <TextField
                className={classes.formField}
                error={formErrors.email !== null}
                helperText={formErrors.email ? t(formErrors.email) : null}
                label={t('pages.users.fields.email')}
                name="email"
                onChange={event => handleChange('email', event.target.value)}
                required
                autoComplete="off"
                value={values.email}
                InputProps={{
                    endAdornment: (
                        <InputAdornment position="end">
                            <EmailOutlinedIcon />
                        </InputAdornment>
                    ),
                }}
            />
            <TextField
                label="Password"
                type={showingPassword ? 'text' : 'password'}
                value={values.password}
                onChange={event => handleChange('password', event.target.value)}
                InputProps={{
                    endAdornment: (
                        <ShowPasswordButton
                            onShowPassword={password => {
                                setShowingPassword(password);
                            }}
                        />
                    ),
                }}
                error={formErrors.password !== null}
                helperText={formErrors.password ? t(formErrors.password) : null}
                required
                className={classes.formField}
            />

            <TextField
                label="Confirm password"
                type={showingConfirmPassword ? 'text' : 'password'}
                value={values.confirmPassword}
                onChange={event => handleChange('confirmPassword', event.target.value)}
                InputProps={{
                    endAdornment: (
                        <ShowPasswordButton
                            onShowPassword={password => {
                                setShowingConfirmPassword(password);
                            }}
                        />
                    ),
                }}
                error={formErrors.confirmPassword !== null}
                helperText={formErrors.confirmPassword ? t(formErrors.confirmPassword) : null}
                required
                className={classes.formField}
            />
            <Typography
                style={{
                    color: COLORS.BLUE_DARK,
                    fontSize: '1rem',
                    fontWeight: 700,
                    marginBottom: '1rem',
                }}
            >
                {t('pages.users.fields.userPermissions')}
            </Typography>
            <FormControlLabel
                control={
                    <Checkbox
                        checked={values.manageUsers}
                        onChange={event => handleChange('manageUsers', event.target.checked)}
                    />}
                label={t('pages.users.fields.manageUsers')}
                sx={{ width: '100%' }}
            />
            <div className={classes.buttonsContainer}>
                <PrimaryButton className={classes.formButton} onClick={handleSubmit}>
                    {t('buttonName.invite')}
                </PrimaryButton>
                <SecondaryButton className={classes.formButton} onClick={onClose}>
                    {t('buttonName.cancel')}
                </SecondaryButton>
            </div>
        </Modal>
    );
};

export default AddUserModal;
