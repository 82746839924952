import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import IconButton from '@mui/material/IconButton';
import { GridRenderCellParams } from '@mui/x-data-grid-pro';
import React from 'react';

import theme from '../../styles/theme';

export function DetailPanelToggle(props: Pick<GridRenderCellParams, 'id' | 'value'>) {
    const { value: isExpanded } = props;

    return (
        <IconButton size="small" tabIndex={-1} aria-label={isExpanded ? 'Close' : 'Open'}>
            <ExpandMoreIcon
                sx={{
                    transform: `rotateZ(${isExpanded ? 180 : 0}deg)`,
                    transition: () => theme.transitions.create('transform', { duration: theme.transitions.duration.shortest }),
                }}
                fontSize="inherit"
            />
        </IconButton>
    );
}
