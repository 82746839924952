/* eslint-disable id-length */
/* eslint-disable @typescript-eslint/no-unsafe-return */
import { AxiosResponse } from 'axios';

import {
    RideResponse,
    FlightDetailsVariables,
    UpdateRideInformation,
    UpdateTravellerInformation,
    UpdateAvailabilites,
    UpdateVehicleRide,
    SearchLocation,
} from '../pages/ride/customer/api/types';
import { AvailabilitiesResponse, ResponseFlightDetails } from '../pages/rides/customer/api/types';
import apiClient from './api';
import {
    getRideRoute,
    getUpdateTravellerInformation, RIDE_AVAILABILITIES,
    getUpdateVehicleRideRoute,
    getCancelRideRoute,
    getUpdateRideInformation,
    getSendBookingConfirmationEmailRoute,
    getUpdateFlightNumberRoute,
    getFlightNumberDetailsRoute,
    SEARCH_LOCATION,
} from './routes';

export const viewRide = async (id: string): Promise<RideResponse> => {
    const { data } = await apiClient.get<RideResponse>(getRideRoute(id));

    return data;
};

export const updateRideInformation = async (variables: UpdateRideInformation): Promise<AxiosResponse> => {
    const { id, payload } = variables;
    const response = await apiClient.post(getUpdateRideInformation(id), payload);

    return response;
};

export const cancelRide = async (id: string): Promise<AxiosResponse> => {
    const response = await apiClient.post(getCancelRideRoute(id));

    return response;
};

export const updateTravellerInformation = async (variables: UpdateTravellerInformation): Promise<AxiosResponse> => {
    const { id, payload } = variables;
    const response = await apiClient.post(getUpdateTravellerInformation(id), { passengers: payload });

    return response;
};

export const listSearchLocationOptions = async (search: string): Promise<SearchLocation[]> => {
    const { data } = await apiClient.get<SearchLocation[]>(
        SEARCH_LOCATION,
        { params: { q: search } }
    );

    return data;
};

export const updateFlightNumber = async (variables: FlightDetailsVariables): Promise<AxiosResponse> => {
    const { id, payload } = variables;
    const response = await apiClient.post(getUpdateFlightNumberRoute(id), payload);

    return response;
};

export const getAvailabilites = async (variables: UpdateAvailabilites): Promise<AvailabilitiesResponse> => {
    const { data } = await apiClient.get<AvailabilitiesResponse>(RIDE_AVAILABILITIES, { params: variables });

    return data;
};

export const updateRideVehicle = async (variables: UpdateVehicleRide): Promise<AxiosResponse> => {
    const { carId, rideId } = variables;
    const response = await apiClient.post(getUpdateVehicleRideRoute(rideId), { uuid: carId });

    return response;
};

export const getFlightNumberDetails = async ({ payload }: Partial<FlightDetailsVariables>): Promise<ResponseFlightDetails> => {
    if (!payload) {
        throw new Error('Payload is missing.');
    }

    const { flightNumber, departureDate } = payload;

    const { data } = await apiClient.get(getFlightNumberDetailsRoute(flightNumber, departureDate));

    return data;
};

export const sendConfirmationEmail = async ({ id, emailTo }: {id: string; emailTo: string}): Promise<AxiosResponse> => {
    const response = await apiClient.post<AxiosResponse>(getSendBookingConfirmationEmailRoute(id), { emailTo });

    return response;
};
