/* eslint-disable complexity */
/* eslint-disable max-lines-per-function */
/* eslint-disable max-statements */
import {
    DateField,
    DateTimeField,
    PrimaryButton,
    SecondaryButton,
    Select,
    SelectOption,
    TextField,
    TimeField,
} from '@get-e/react-components';
import {
    Box,
    Checkbox,
    FormControlLabel,
    Grid,
    Typography,
    useMediaQuery,
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import clsx from 'clsx';
import { BaseError } from 'make-error-cause';
import moment from 'moment';
import React, {
    FunctionComponent,
    useEffect,
    useMemo,
    useRef,
    useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useParams } from 'react-router-dom';

import { DUMMY_DATE, REPEATS, WEEKDAYS } from '../../../constants';
import { COLORS } from '../../../constants/colors';
import { DATE_FORMATS } from '../../../constants/dateFormats';
import { BUTTON_WIDTH } from '../../../constants/layout';
import { TIME_FORMATS } from '../../../constants/timeFormats';
import { AVAILABILITY } from '../../../constants/urlPaths';
import FormError from '../../../helpers/inputValidation/FormError';
import getFormErrorMessage from '../../../helpers/inputValidation/getFormErrorMessage';
import { InputError } from '../../../helpers/inputValidation/InputError';
import { IS_EDIT_AVAILABILITY_ROUTE } from '../../../helpers/inputValidation/regexRules';
import allValid from '../../../helpers/inputValidation/validators/allValid';
import and from '../../../helpers/inputValidation/validators/and';
import isAfterDate from '../../../helpers/inputValidation/validators/isAfterDate';
import isAfterTime from '../../../helpers/inputValidation/validators/isAfterTime';
import isFilledString from '../../../helpers/inputValidation/validators/isFilledString';
import isNotNull from '../../../helpers/inputValidation/validators/isNotNull';
import isPastDate from '../../../helpers/inputValidation/validators/isPastDate';
import isValidDateFormat from '../../../helpers/inputValidation/validators/isValidDateFormat';
import isValidTimeFormat from '../../../helpers/inputValidation/validators/isValidTimeFormat';
import {
    getWhenMessage,
    resolveApplicableOnDaysValue,
    resolveRepeatsInitialValue,
} from '../../../helpers/repeats';
import theme from '../../../styles/theme';
import {
    createStopSalesRule,
    deleteStopSalesRule,
    editStopSalesRule,
} from '../api';
import { blockedPeriods } from '../dummyData/dummyData';
import ConfirmCreateModal from './components/ConfirmCreateModal';
import ConfirmDeleteModal from './components/ConfirmDeleteModal';

const useStyles = makeStyles({
    checkbox: { height: '2rem' },
    backButton: { marginLeft: '1rem !important' },
    buttonFixedWidth: {
        width: `${BUTTON_WIDTH} !important`,
        height: '44px',
    },
    formFieldMobile: { marginBottom: '1.25rem' },
    dateFieldStart: {
        width: '50%',
        marginRight: '0.5rem',
    },
    dateFieldEnd: {
        width: '50%',
        marginLeft: '0.5rem',
    },
});

const accountId = '2';
const ruleId = '1';

interface UrlParams {
    id: string;
}

const CreateAvailability: FunctionComponent = () => {
    const autoFocusRef = useRef<HTMLInputElement>();
    const { t } = useTranslation();
    const history = useHistory();
    const urlParams = useParams<UrlParams>();
    const isMobile = useMediaQuery(theme.breakpoints.down('lg'));
    const [isSaving, setIsSaving] = useState(false);

    const [isConfirmCreateModalOpen, setIsConfirmCreateModalOpen]
    = useState(false);

    const [isConfirmDeleteModalOpen, setIsConfirmDeleteModalOpen]
    = useState(false);

    const [startDateTime, setStartDateTime] = useState<Date | null>(null);

    const [startDateTimeError, setStartDateTimeError]
    = useState<InputError | null>(null);

    const [endDateTime, setEndDateTime] = useState<Date | null>(null);

    const [endDateTimeError, setEndDateTimeError] = useState<InputError | null>(
        null
    );

    const [startTime, setStartTime] = useState<Date | null>(null);
    const [startTimeError, setStartTimeError] = useState<InputError | null>(null);
    const [endTime, setEndTime] = useState<Date | null>(null);
    const [endTimeError, setEndTimeError] = useState<InputError | null>(null);
    const [description, setDescription] = useState('');

    const [descriptionError, setDescriptionError] = useState<InputError | null>(
        null
    );

    const [repeats, setRepeats] = useState(REPEATS.DO_NOT_REPEAT);

    const [applicableOnDays, setApplicableOnDays] = useState<
    Array<keyof typeof WEEKDAYS>
    >([]);

    const [formError, setFormError] = useState<BaseError | FormError | null>(
        null
    );

    const classes = useStyles();

    const isEditing = useMemo(() => {
        return IS_EDIT_AVAILABILITY_ROUTE.test(history.location.pathname);
    }, [history.location.pathname]);

    const blockedPeriodIndex = useMemo(() => {
        return urlParams.id ? parseInt(urlParams.id) - 1 : 0;
    }, [urlParams]);

    const blockedPeriod = blockedPeriods[blockedPeriodIndex];
    const isfetchingBlockedPeriod = false;

    useEffect(() => {
        if (isEditing && blockedPeriod) {
            const startDateTimeInitial = blockedPeriod.start
                ? moment(blockedPeriod.start).toDate()
                : null;

            const endDateTimeInitial = blockedPeriod.end
                ? moment(blockedPeriod.end).toDate()
                : null;

            const startTimeInitial = blockedPeriod.applicableStartTime
                ? moment(`${DUMMY_DATE} ${blockedPeriod.applicableStartTime}`).toDate()
                : null;

            const endTimeInitial = blockedPeriod.applicableEndTime
                ? moment(`${DUMMY_DATE} ${blockedPeriod.applicableEndTime}`).toDate()
                : null;

            setStartDateTime(startDateTimeInitial);
            setEndDateTime(endDateTimeInitial);
            setStartTime(startTimeInitial);
            setEndTime(endTimeInitial);
            setRepeats(resolveRepeatsInitialValue(blockedPeriod.applicableOnDays));
            setApplicableOnDays(blockedPeriod.applicableOnDays ?? []);
            setDescription(blockedPeriod.description);
        }
    }, [isEditing, blockedPeriod]);

    useEffect(() => {
        autoFocusRef.current?.focus();
    }, [autoFocusRef]);

    const isFieldDisabled = useMemo(
        () => isEditing && isfetchingBlockedPeriod,
        [isEditing, isfetchingBlockedPeriod]
    );

    const isBlockedTimeVisible = useMemo(() => {
        return repeats !== REPEATS.DO_NOT_REPEAT;
    }, [repeats]);

    const isRepeatsOnVisible = useMemo(() => {
        return repeats === REPEATS.CUSTOM;
    }, [repeats]);

    const validateFormFields = (): boolean => {
        const validated = {
            startDateTime: and(isNotNull(startDateTime, InputError.Empty), value =>
                and(isPastDate(startDateTime, InputError.InvalidPastDate), () =>
                    isValidDateFormat(
                        startDateTime,
                        DATE_FORMATS['dd MMM yyyy hh:mm a'],
                        InputError.InvalidDateFormat
                    ))),
            endDateTime: and(isNotNull(endDateTime, InputError.Empty), () =>
                and(
                    isAfterDate(endDateTime, startDateTime, InputError.InvalidAfterDate),
                    () =>
                        isValidDateFormat(
                            endDateTime,
                            DATE_FORMATS['dd MMM yyyy hh:mm a'],
                            InputError.InvalidDateFormat
                        )
                )),
            description: isFilledString(description, InputError.Empty),
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
        } as any;

        if (isBlockedTimeVisible) {
            const startTimeParsed = moment(
                `${DUMMY_DATE} ${moment(startTime).format(TIME_FORMATS['HH:mm:ss'])}`
            ).toDate();

            const endTimeParsed = moment(
                `${DUMMY_DATE} ${moment(endTime).format(TIME_FORMATS['HH:mm:ss'])}`
            ).toDate();

            validated.startTime = and(isNotNull(startTime, InputError.Empty), () =>
                isValidTimeFormat(
                    startTime,
                    TIME_FORMATS['HH:mm'],
                    InputError.InvalidTimeFormat
                ));

            validated.endTime = and(isNotNull(endTime, InputError.Empty), () =>
                and(
                    isAfterTime(
                        endTimeParsed,
                        startTimeParsed,
                        InputError.InvalidAfterTime
                    ),
                    () =>
                        isValidTimeFormat(
                            endTime,
                            TIME_FORMATS['HH:mm'],
                            InputError.InvalidTimeFormat
                        )
                ));
        }

        if (!allValid(validated)) {
            setStartDateTimeError(
                validated.startDateTime.isValid ? null : validated.startDateTime.error
            );

            setEndDateTimeError(
                validated.endDateTime.isValid ? null : validated.endDateTime.error
            );

            setDescriptionError(
                validated.description.isValid ? null : validated.description.error
            );

            if (isBlockedTimeVisible) {
                setStartTimeError(
                    validated.startTime.isValid ? null : validated.startTime.error
                );
                setEndTimeError(
                    validated.endTime.isValid ? null : validated.endTime.error
                );
            }

            return false;
        }

        return true;
    };

    const handleCreate = () => {
        if (validateFormFields()) {
            setIsConfirmCreateModalOpen(true);
        }
    };

    const handleBack = () => {
        history.push(AVAILABILITY);
    };

    const handleDelete = () => {
        setIsConfirmDeleteModalOpen(true);
    };

    const submitDelete = async () => {
        try {
            setFormError(null);
            setIsSaving(true);
            await deleteStopSalesRule(accountId, ruleId);
            setIsSaving(false);
            history.push(AVAILABILITY);
        } catch (error) {
            setIsSaving(false);

            if (error instanceof BaseError) {
                setFormError(new BaseError(error.message));
                return;
            }
        } finally {
            setIsConfirmDeleteModalOpen(false);
        }
    };

    const submitForm = async () => {
        const postData = {
            start: startDateTime
                ? moment(startDateTime).format(DATE_FORMATS['YYYY-MM-DD HH:mm:ss'])
                : null,
            end: endDateTime
                ? moment(endDateTime).format(DATE_FORMATS['YYYY-MM-DD HH:mm:ss'])
                : null,
            description,
            applicableOnDays: applicableOnDays.length ? applicableOnDays : null,
            applicableStartTime:
        startTime && isBlockedTimeVisible
            ? moment(startTime).format(TIME_FORMATS['HH:mm:ss'])
            : null,
            applicableEndTime:
        endTime && isBlockedTimeVisible
            ? moment(endTime).format(TIME_FORMATS['HH:mm:ss'])
            : null,
        };

        try {
            setFormError(null);
            setIsSaving(true);

            if (isEditing) {
                await editStopSalesRule(accountId, ruleId, postData);
            } else {
                await createStopSalesRule(accountId, postData);
            }

            setIsSaving(false);
            history.push(AVAILABILITY);
        } catch (error) {
            setIsSaving(false);

            if (error instanceof BaseError) {
                setFormError(new BaseError(error.message));
                return;
            }
        } finally {
            setIsConfirmCreateModalOpen(false);
        }
    };

    const handleApplicableOnDays = (
        key: keyof typeof WEEKDAYS,
        checked: boolean
    ) => {
        if (checked) {
            !applicableOnDays.includes(key)
        && setApplicableOnDays(prevValue => [...prevValue, key]);
        } else {
            setApplicableOnDays(
                applicableOnDays.filter(element => element !== key)
            );
        }
    };

    const handleStartTime = (newTime: Date | null) => {
        setStartTime(newTime);
        setStartTimeError(null);
    };

    const handleEndTime = (newTime: Date | null) => {
        setEndTime(newTime);
        setEndTimeError(null);
    };

    const handleRepeatsOn = (newValue: REPEATS) => {
        setRepeats(newValue);
        setFormError(null);

        if (newValue !== REPEATS.CUSTOM && newValue !== REPEATS.DO_NOT_REPEAT) {
            setApplicableOnDays([resolveApplicableOnDaysValue(newValue)]);
        } else {
            setApplicableOnDays([]);
        }

        if (newValue !== REPEATS.DO_NOT_REPEAT) {
            if (startDateTime !== null) {
                setStartTime(startDateTime);
            }

            if (endDateTime !== null) {
                setEndTime(endDateTime);
            }
        }
    };

    const resolveFormErrorMessage = (error: FormError | BaseError | null) => {
        if (error instanceof BaseError) {
            return error.message;
        }

        return t(getFormErrorMessage(FormError.UnexpectedError));
    };

    const blockedPeriodMessage = useMemo(() => {
        return getWhenMessage(
            repeats,
            startDateTime,
            endDateTime,
            startTime,
            endTime,
            applicableOnDays
        );
    }, [
        repeats,
        startDateTime,
        endDateTime,
        startTime,
        endTime,
        applicableOnDays,
    ]);

    return (
        <Grid
            container
            alignItems="flex-start"
            sx={{
                padding: {
                    xs: '1rem',
                    md: '2rem',
                    xl: '2rem',
                },
            }}
        >
            <Grid item xs={12} sm={6} md={6} xl={6}>
                <Typography
                    sx={{
                        color: COLORS.BLUE,
                        fontSize: '1.5rem',
                        lineHeight: '1.8rem',
                        fontWeight: 700,
                        marginBottom: isMobile ? '1rem' : '0',
                    }}
                >
                    {isEditing ? t('pages.availability.editBlockPeriod') : t('pages.availability.createBlockPeriod')}
                </Typography>
            </Grid>
            <Grid
                item
                xs={12}
                sm={6}
                md={6}
                xl={6}
                sx={{
                    display: isEditing ? 'flex' : 'none',
                    justifyContent: isMobile ? 'flex-start' : 'flex-end',
                }}
            >
                <SecondaryButton
                    variation="danger"
                    className={classes.buttonFixedWidth}
                    onClick={handleDelete}
                >
                    {t('buttonName.delete')}
                </SecondaryButton>
            </Grid>
            <Grid
                container
                rowSpacing={2}
                sx={{
                    backgroundColor: COLORS.WHITE,
                    paddingTop: '2rem',
                    margin: 0,
                }}
            >
                <Grid item sm={12} xs={12} md={8} xl={8}>
                    <Grid container columnSpacing={4}>
                        <Grid
                            item
                            xs={12}
                            lg={12}
                            sx={{
                                paddingTop: '0',
                                paddingBottom: '1rem',
                            }}
                        >
                            <Typography
                                sx={{
                                    color: COLORS.BLUE_DARK,
                                    fontWeight: '700',
                                }}
                            >
                                {t('pages.availability.rideInformation')}
                            </Typography>
                        </Grid>
                        <Grid item xs={12} lg={12} md={12}>
                            {isBlockedTimeVisible && (
                                <Box
                                    sx={{
                                        display: 'flex',
                                        justifyContent: 'space-between',
                                        flexDirection: isMobile ? 'column' : 'row',
                                    }}
                                >
                                    <DateField
                                        className={isMobile ? classes.formFieldMobile : classes.dateFieldStart}
                                        label={t('form.fields.startDate')}
                                        format={DATE_FORMATS['EEE, dd MMM yyyy']}
                                        value={startDateTime}
                                        onChange={newValue => {
                                            setStartDateTime(newValue);
                                            setStartDateTimeError(null);
                                            setFormError(null);
                                        }}
                                        required
                                        error={startDateTimeError !== null}
                                        helperText={
                                            startDateTimeError ? t(startDateTimeError) : undefined
                                        }
                                    />
                                    {!isMobile && (
                                        <Typography
                                            style={{
                                                width: '1rem',
                                                display: 'flex',
                                                justifyContent: 'center',
                                                paddingTop: '1.25rem',
                                            }}
                                        >
                                            {t('to')}
                                        </Typography>
                                    )}
                                    <DateField
                                        className={!isMobile ? classes.dateFieldEnd : ''}
                                        label={t('form.fields.endDate')}
                                        format={DATE_FORMATS['EEE, dd MMM yyyy']}
                                        value={endDateTime}
                                        onChange={newValue => {
                                            setEndDateTime(newValue);
                                            setEndDateTimeError(null);
                                            setFormError(null);
                                        }}
                                        required
                                        error={endDateTimeError !== null}
                                        helperText={
                                            endDateTimeError ? t(endDateTimeError) : undefined
                                        }
                                    />
                                </Box>
                            )}
                            {!isBlockedTimeVisible && (
                                <Box
                                    sx={{
                                        display: 'flex',
                                        justifyContent: 'space-between',
                                        flexDirection: isMobile ? 'column' : 'row',
                                    }}
                                >
                                    <DateTimeField
                                        className={isMobile ? classes.formFieldMobile : classes.dateFieldStart}
                                        label={t('form.fields.startDate')}
                                        format={DATE_FORMATS['EEE, dd MMM yyyy at HH:mm a']}
                                        value={startDateTime}
                                        onChange={newValue => {
                                            setStartDateTime(newValue);
                                            setStartDateTimeError(null);
                                            setFormError(null);
                                        }}
                                        required
                                        disabled={isSaving || isFieldDisabled}
                                        error={startDateTimeError !== null}
                                        helperText={
                                            startDateTimeError ? t(startDateTimeError) : undefined
                                        }
                                    />
                                    {!isMobile && (
                                        <Typography
                                            style={{
                                                width: '1rem',
                                                display: 'flex',
                                                justifyContent: 'center',
                                                paddingTop: '1.25rem',
                                            }}
                                        >
                                            {t('to')}
                                        </Typography>
                                    )}
                                    <DateTimeField
                                        className={!isMobile ? classes.dateFieldEnd : ''}
                                        label={t('form.fields.endDate')}
                                        format={DATE_FORMATS['EEE, dd MMM yyyy at HH:mm a']}
                                        value={endDateTime}
                                        onChange={newValue => {
                                            setEndDateTime(newValue);
                                            setEndDateTimeError(null);
                                            setFormError(null);
                                        }}
                                        required
                                        disabled={isSaving || isFieldDisabled}
                                        error={endDateTimeError !== null}
                                        helperText={
                                            endDateTimeError ? t(endDateTimeError) : undefined
                                        }
                                    />
                                </Box>
                            )}
                        </Grid>
                    </Grid>
                    <Grid
                        container
                        rowSpacing={2}
                        sx={{
                            backgroundColor: COLORS.WHITE,
                            margin: 0,
                        }}
                    >
                        <Grid item xs={12} lg={12}>
                            <Typography
                                sx={{
                                    color: COLORS.BLUE_DARK,
                                    fontWeight: '700',
                                }}
                            >
                                {t('pages.availability.repetitionRules')}
                            </Typography>
                        </Grid>
                        <Grid item xs={12} sx={{ paddingLeft: '0 !important' }}>
                            <Select
                                label={t('pages.availability.repeats')}
                                value={repeats}
                                required
                                disabled={isSaving || isFieldDisabled}
                                onChangeValue={newValue => handleRepeatsOn(newValue)}
                            >
                                {Object.keys(REPEATS).map(repeat => (
                                    <SelectOption
                                        key={repeat}
                                        value={REPEATS[repeat as keyof typeof REPEATS]}
                                    >
                                        {REPEATS[repeat as keyof typeof REPEATS]}
                                    </SelectOption>
                                ))}
                            </Select>
                        </Grid>
                    </Grid>
                    {isRepeatsOnVisible && (
                        <>
                            <Grid item xs sx={{ padding: '1rem 0' }}>
                                <Typography
                                    sx={{
                                        color: COLORS.BLUE_DARK,
                                        fontWeight: '700',
                                    }}
                                >
                                    {t('pages.availability.repeatsOn')}
                                </Typography>
                            </Grid>
                            <Grid
                                container
                                sx={{
                                    paddingLeft: '0',
                                    flexDirection: 'column',
                                    paddingBottom: '1rem',
                                }}
                                direction="row"
                            >
                                {Object.keys(WEEKDAYS).map(weekday => {
                                    return (
                                        <FormControlLabel
                                            key={weekday}
                                            control={
                                                <Checkbox
                                                    checked={applicableOnDays.includes(
                                                        weekday as keyof typeof WEEKDAYS
                                                    )}
                                                    disabled={isSaving || isFieldDisabled}
                                                    onChange={event =>
                                                        handleApplicableOnDays(
                                                            weekday as keyof typeof WEEKDAYS,
                                                            event.target.checked
                                                        )
                                                    }
                                                />
                                            }
                                            label={WEEKDAYS[weekday as keyof typeof WEEKDAYS]}
                                            className={classes.checkbox}
                                        />
                                    );
                                })}
                            </Grid>
                        </>
                    )}
                    {isBlockedTimeVisible && (
                        <>
                            <Grid
                                item
                                xs={12}
                                lg={12}
                                sx={{
                                    paddingLeft: '0',
                                    paddingTop: '1.25rem',
                                    paddingBottom: '1rem',
                                }}
                            >
                                <Typography
                                    sx={{
                                        color: COLORS.BLUE_DARK,
                                        fontWeight: '700',
                                    }}
                                >
                                    {t('pages.availability.blockedTimeOnSelectedWeekdays')}
                                </Typography>
                            </Grid>
                            <Grid container columnSpacing={4}>
                                <Grid item xs={12} lg={12} md={12}>
                                    <Box
                                        sx={{
                                            display: 'flex',
                                            justifyContent: 'space-between',
                                            flexDirection: isMobile ? 'column' : 'row',
                                        }}
                                    >
                                        <TimeField
                                            className={isMobile ? classes.formFieldMobile : classes.dateFieldStart}
                                            label={t('form.fields.startTime')}
                                            value={startTime}
                                            onChange={newValue => {
                                                handleStartTime(newValue);
                                                setFormError(null);
                                            }}
                                            error={startTimeError !== null}
                                            helperText={
                                                startTimeError ? t(startTimeError) : undefined
                                            }
                                            format={TIME_FORMATS['HH:mm a']}
                                        />
                                        {!isMobile && (
                                            <Typography
                                                style={{
                                                    width: '1rem',
                                                    display: 'flex',
                                                    justifyContent: 'center',
                                                    paddingTop: '1.25rem',
                                                }}
                                            >
                                                {t('to')}
                                            </Typography>
                                        )}
                                        <TimeField
                                            className={!isMobile ? classes.dateFieldEnd : ''}
                                            label={t('form.fields.endTime')}
                                            value={endTime}
                                            onChange={newValue => {
                                                handleEndTime(newValue);
                                                setFormError(null);
                                            }}
                                            error={endTimeError !== null}
                                            helperText={endTimeError ? t(endTimeError) : undefined}
                                            format={TIME_FORMATS['HH:mm a']}
                                        />
                                    </Box>
                                </Grid>
                            </Grid>
                        </>
                    )}
                    <Grid
                        item
                        xs={12}
                        lg={12}
                        sx={{
                            paddingLeft: '0',
                            paddingTop: '1.25rem',
                            paddingBottom: '1rem',
                        }}
                    >
                        <Typography
                            sx={{
                                color: COLORS.BLUE_DARK,
                                fontWeight: '700',
                            }}
                        >
                            {t('pages.availability.description')}
                        </Typography>
                    </Grid>
                    <Grid item xs sx={{ margin: 0 }}>
                        <TextField
                            value={description}
                            onChange={event => {
                                setDescription(event.target.value);
                                setDescriptionError(null);
                                setFormError(null);
                            }}
                            label={t('pages.availability.yourDescription')}
                            type="text"
                            autoFocus
                            name="description"
                            inputRef={autoFocusRef}
                            error={descriptionError !== null}
                            helperText={descriptionError ? t(descriptionError) : undefined}
                            required
                            disabled={isSaving || isFieldDisabled}
                        />
                    </Grid>
                    <Grid
                        item
                        xs={12}
                        lg={12}
                        sx={{
                            paddingLeft: '0 !important',
                            paddingTop: '1.25rem !important',
                        }}
                    >
                        <PrimaryButton
                            onClick={handleCreate}
                            className={classes.buttonFixedWidth}
                            disabled={isSaving || isFieldDisabled}
                        >
                            {isEditing ? t('buttonName.save') : t('buttonName.create')}
                        </PrimaryButton>
                        <SecondaryButton
                            className={clsx(classes.backButton, classes.buttonFixedWidth)}
                            onClick={handleBack}
                        >
                            {t('buttonName.back')}
                        </SecondaryButton>
                    </Grid>
                    {formError && (
                        <Grid
                            item
                            xs={12}
                            lg={12}
                            sx={{
                                paddingLeft: '0 !important',
                                paddingBottom: '1rem',
                                paddingTop: '0.5rem',
                            }}
                        >
                            <Typography
                                sx={{
                                    color: COLORS.RED,
                                    fontSize: '1rem',
                                    marginBottom: '0 !important',
                                }}
                            >
                                {formError ? resolveFormErrorMessage(formError) : null}
                            </Typography>
                        </Grid>
                    )}
                </Grid>
            </Grid>
            {isConfirmDeleteModalOpen && (
                <ConfirmDeleteModal
                    isModalOpen={isConfirmDeleteModalOpen}
                    onClose={() => setIsConfirmDeleteModalOpen(false)}
                    repeats={repeats}
                    blockedPeriodMessage={blockedPeriodMessage}
                    submitDelete={submitDelete}
                    isFieldDisabled={isFieldDisabled}
                    isSaving={isSaving}
                />
            )}
            {isConfirmCreateModalOpen && (
                <ConfirmCreateModal
                    isModalOpen={isConfirmCreateModalOpen}
                    onClose={() => setIsConfirmCreateModalOpen(false)}
                    repeats={repeats}
                    blockedPeriodMessage={blockedPeriodMessage}
                    submitForm={submitForm}
                    isEditing={isEditing}
                    isFieldDisabled={isFieldDisabled}
                    isSaving={isSaving}
                />
            )}
        </Grid>
    );
};

export default CreateAvailability;
