import downloadFile from '../../../../helpers/downloadFile';
import apiClient from '../../../../services/api';
import { getDownloadReceiptRoute } from '../../../../services/routes';
import { DownloadPdfParams } from './types';

const downloadReceipt = async (variables: DownloadPdfParams): Promise<unknown> => {
    const { unid, prettifiedUnid } = variables;

    const result = await apiClient
        .get(getDownloadReceiptRoute(unid), { responseType: 'blob' })
        .then(response => {
            const url = window.URL.createObjectURL(new Blob([response.data]));

            downloadFile(url, `GET-E Receipt ${prettifiedUnid}.pdf`);
        })
        .catch(error => {
            console.log(error);
        });

    return result;
};

export default downloadReceipt;
