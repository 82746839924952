import { CircularProgress, MenuItem } from '@mui/material';
import React, { FunctionComponent, useState } from 'react';
import { useTranslation } from 'react-i18next';

import config from '../../../config';
import { Severity, useNotificationContext } from '../../../context/NotificationContext';
import { report } from '../../../helpers/report';
import { User } from '../../../services/types';
import typographyStyles from '../../../styles/Typography';
import EditUserModalNew from '../../users/customer/components/EditUserModalNew';
import SimpleMenuContainer from './SimpleMenuContainer';
import simpleMenuContainerStyles from './SimpleMenuContainer.styles';
import UserMenuButtonName from './UserMenuButtonName';

const UserMenu: FunctionComponent<{
    currentUser: User;
    isUpdateProfile?: boolean;
    onUserProfileUpdated?: () => Promise<void>;
}> = ({ currentUser, isUpdateProfile, onUserProfileUpdated }) => {
    const [menuOpen, setMenuOpen] = useState(false);
    const { t } = useTranslation();
    const typeClasses = typographyStyles();
    const [openModal, setOpenModal] = useState(false);
    const menuClasses = simpleMenuContainerStyles();
    const { showNotification } = useNotificationContext();
    const [loggingOut, setLoggingOut] = useState(false);

    const logOut = async (): Promise<void> => {
        setLoggingOut(true);
        await fetch(config.deleteCookieUrl, {
            method: 'POST',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
            },
            credentials: 'include',
        })
            .then(response => {
                if (response.status >= 200 && response.status < 300) {
                    window.location.href = config.logOutUrl;
                    return;
                }

                throw new Error(`Unexpected response status: ${response.status}`);
            })
            .catch(error => {
                report(error);
                showNotification(t('alert.logOutError'), Severity.Error);
            });
        setLoggingOut(false);
        setMenuOpen(false);
    };

    const handleModalOpen = (): void => {
        setMenuOpen(false);
        setOpenModal(true);
    };

    return (
        <>
            {openModal
                ? (
                    <EditUserModalNew
                        user={currentUser}
                        onClose={() => setOpenModal(false)}
                        isUpdateProfile={Boolean(isUpdateProfile)}
                        onUserProfileUpdated={onUserProfileUpdated}
                    />
                )
                : null}
            <SimpleMenuContainer
                testId="user"
                buttonName={
                    <UserMenuButtonName
                        userName={currentUser.fullName}
                    />
                }
                open={menuOpen}
                onClose={() => setMenuOpen(false)}
                onOpen={() => setMenuOpen(true)}
                className={menuClasses.userMenu}
            >
                <MenuItem onClick={handleModalOpen} data-testid="my-profile">
                    {t('authentication.myProfile')}
                </MenuItem>
                <MenuItem
                    className={typeClasses.errorTheme}
                    disabled={loggingOut}
                    onClick={logOut}
                    style={{ position: 'relative' }}
                >
                    {t('authentication.logOut')} {loggingOut
                        ? (
                            <CircularProgress
                                size={15}
                                color="inherit"
                                thickness={4}
                                style={{
                                    position: 'absolute',
                                    bottom: '0.85em',
                                    right: '1em',
                                }}
                            />
                        )
                        : null}
                </MenuItem>
            </SimpleMenuContainer>
        </>
    );
};

export default UserMenu;

