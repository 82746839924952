import { Modal } from '@get-e/react-components';
import { Grid, Typography } from '@mui/material';
import moment from 'moment';
import React, { FunctionComponent } from 'react';
import { Trans, useTranslation } from 'react-i18next';

import { COLORS } from '../../../../constants/colors';
import { useCurrentUserContext } from '../../../../context/CurrentUserContext';
import { User } from '../../../../services/types';
import EditUserFormNew from './EditUserFormNew';
import UpdateUserProfileForm from './UpdateUserProfileForm';

const EditUserModalNew: FunctionComponent<{
    user: User;
    onClose: () => void;
    isUpdateProfile?: boolean;
    onUserProfileUpdated?: () => Promise<void>;
}> = ({ user, onClose, isUpdateProfile, onUserProfileUpdated }) => {
    const { t } = useTranslation();
    const date = moment(user.joinedAt).format(t('dateFormat'));
    const { currentUser } = useCurrentUserContext();
    const showMyProfile = currentUser.id.toString() === user.id.toString();

    return (
        <Modal open={true} onClose={onClose}>
            <Grid
                container
                spacing={4}
                justifyContent="center"
                alignItems="center"
                style={{ marginBottom: '2rem' }}
            >
                <Grid item xs>
                    {showMyProfile
                        ? (
                            <Typography
                                variant="h2"
                                component="h2"
                                style={{
                                    marginBottom: '0.5rem',
                                    color: COLORS.BLUE,
                                }}
                            >
                                {user.fullName}
                            </Typography>
                        )
                        : (
                            <Typography
                                variant="h2"
                                component="h2"
                                style={{
                                    margin: 0,
                                    color: COLORS.BLUE_DARK,
                                }}
                            >
                                {t('modals.edit.editUser')}
                            </Typography>
                        )
                    }
                    {user.joinedAt
                        ? <p><Trans i18nKey="joinedAtDate">{{ date }}</Trans></p>
                        : null
                    }
                </Grid>
            </Grid>
            {isUpdateProfile
                ? (
                    <UpdateUserProfileForm
                        user={user}
                        onClose={onClose}
                        onUserProfileUpdated={onUserProfileUpdated}
                    />
                )
                : (
                    <EditUserFormNew
                        user={user}
                        onClose={onClose}
                    />
                )
            }
        </Modal>
    );
};

export default EditUserModalNew;
