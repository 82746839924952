const RIDE_ID_URLS = [
    '^\\/rides\\/[^\\/]+$',
    '^\\/rides\\/[^\\/]+\\/change\\/route$',
    '^\\/rides\\/[^\\/]+\\/change\\/passengers$',
    '^\\/rides\\/[^\\/]+\\/change\\/flight$',
    '^\\/rides\\/[^\\/]+\\/change\\/tracked-flight$',
    '^\\/rides\\/[^\\/]+\\/booking-confirmation$',
];

const IFRAME_URL = [
    '^\\/rides$',
    '^\\/booking-tool$',
    '^\\/pending-changes$',
    ...RIDE_ID_URLS,
];

export const isIframeView = (pathname: string): boolean => {
    const regex = new RegExp(IFRAME_URL.join('|'), 'i');

    return regex.test(pathname);
};

export const isRideIdView = (pathname: string): boolean => {
    const regex = new RegExp(RIDE_ID_URLS.join('|'), 'i');

    return regex.test(pathname);
};

