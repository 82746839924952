import { isValid } from 'date-fns';

import { InputError } from '../InputError';

const isValidDate = (
    value: Date | null,
): InputError | null => {
    return !isValid(value) ? InputError.InvalidDate : null;
};

export default isValidDate;
