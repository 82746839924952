import LocationOffIcon from '@mui/icons-material/LocationOff';
import { Box, Typography } from '@mui/material';
import React, { useMemo } from 'react';

import { useTranslation } from 'react-i18next';
import getDueInTime from '../../../helpers/getDueInTime';
import { getRideStatusColor, getRideStatusTextColor, RideStatus, RideTrackingStatus } from '../../../helpers/rideStatusUtil';

interface StatusBadgeProps {
    status: keyof typeof RideStatus;
    trackingStatus?: keyof typeof RideTrackingStatus;
    pickupDateTimeUtc?: string;
}

const StatusBadge = ({ status, trackingStatus, pickupDateTimeUtc }: StatusBadgeProps) => {
    const { t } = useTranslation();

    const dueIn = useMemo(
        () => (status === 'BOOKED' && pickupDateTimeUtc ? getDueInTime(pickupDateTimeUtc, t('dueIn')) : ''),
        [pickupDateTimeUtc, status, t]
    );

    const untrackingIcon = useMemo(() => {
        const isNotTrackedRideStatus = trackingStatus === 'NOT_TRACKING' && (status === 'ACTIVE' || status === 'COMPLETED');

        return isNotTrackedRideStatus
            ? (
                <LocationOffIcon
                    style={{
                        color: getRideStatusTextColor(status),
                        fontSize: '1rem',
                        marginLeft: '0.25rem',
                    }}
                />
            )
            : null;
    }, [status, trackingStatus]);

    return (
        <Box
            sx={{
                backgroundColor: getRideStatusColor(status),
                width: '154px',
                height: '32px',
                borderRadius: '4px',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
            }}
        >
            <Typography
                sx={{
                    textAlign: 'center',
                    color: getRideStatusTextColor(status),
                    fontSize: '0.75rem',
                    lineHeight: '0.875rem',
                    fontWeight: 400,
                }}
            >
                {dueIn || RideStatus[status]}
            </Typography>
            {untrackingIcon}
        </Box>
    );
};

export default StatusBadge;
