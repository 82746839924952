import { ListItemButton, ListItemIcon, ListItemText } from '@mui/material';
import { makeStyles } from '@mui/styles';
import React, { FunctionComponent } from 'react';
import { NavLink } from 'react-router-dom';

import { COLORS } from '../../constants/colors';
import { isMatchPath } from '../../helpers/isMatchPath';

const useStyles = makeStyles({
    selectedItem: { background: `${COLORS.BLUE_DARK} !important` },
    item: { '&:hover': { background: `${COLORS.BLUE_DARK} !important` } },
});

interface PropTypes {
    icon: JSX.Element;
    label: string;
    href: string;
    onClick?: () => void;
}

export const MenuListItem: FunctionComponent<PropTypes> = (
    { icon, label, href, onClick }
) => {
    const classes = useStyles();

    return (
        <ListItemButton
            selected={isMatchPath(href)}
            component={NavLink}
            to={href}
            className={isMatchPath(href) ? classes.selectedItem : classes.item}
            onClick={onClick}
        >
            <ListItemIcon>{icon}</ListItemIcon>
            <ListItemText>{label}</ListItemText>
        </ListItemButton>
    );
};
