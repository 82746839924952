import { PortalLocale } from '../context/LocaleContext';
import { SupportedLocale } from '../typings/zendesk';

/*
 * Supported locales can be found at
 * https://support.zendesk.com/api/v2/rosetta/locales/public.json
 */

const findZendeskLocale = (value: PortalLocale): SupportedLocale => {
    switch (value) {
        case 'en-GB':
            return 'en-gb';
        default:
            throw new Error(`${value as string} is unsupported`);
    }
};

export default findZendeskLocale;
