import CalendarTodayOutlinedIcon from '@mui/icons-material/CalendarTodayOutlined';
import DirectionsCarFilledOutlinedIcon from '@mui/icons-material/DirectionsCarFilledOutlined';
import ExploreOffOutlinedIcon from '@mui/icons-material/ExploreOffOutlined';
import LocationOnOutlinedIcon from '@mui/icons-material/LocationOnOutlined';
import LuggageOutlinedIcon from '@mui/icons-material/LuggageOutlined';
import PermIdentityOutlinedIcon from '@mui/icons-material/PermIdentityOutlined';
import RouteOutlinedIcon from '@mui/icons-material/RouteOutlined';
import TimerOutlinedIcon from '@mui/icons-material/TimerOutlined';
import { Box, Divider, FormControlLabel, Grid, Switch, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import moment from 'moment';
import React, { useContext, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import IconedData from '../../../components/iconedData/IconedData';
import GoogleMap from '../../../components/navigation/GoogleMap';
import { COLORS } from '../../../constants/colors';
import { DEFAULT_COORDINATES } from '../../../helpers/googleMaps/googleApi';
import { PrimaryTimeZone } from '../../../services/types';
import { RideContext } from '../context/RideContext';

const useStyles = makeStyles({
    heading: {
        color: COLORS.ORANGE,
        fontSize: '1rem',
        fontWeight: 700,
        marginTop: '.5rem',
    },
    verticalDivider: {
        height: '24px',
        width: '1px',
        border: 'none',
        margin: '0 1rem',
        background: COLORS.BLACK_12,
    },
    horizontalDivider: {
        margin: '1.5rem 1rem',
        height: '1px',
        border: 'none',
        background: COLORS.BLACK_12,
    },
});

const showGoogleMap = true;

const RideSummary = () => {
    const classes = useStyles();
    const { t } = useTranslation();

    const {
        summary: {
            distance,
            time,
            pickUpLocation,
            dropOffLocation,
            pickupCoordinates,
            dropOffCoordinates,
            pickUpDateAndTime,
            pickUpDateAndTimeUtc,
            luggages,
            passengers,
            vehicleType,
            primaryTimeZone,
            pickUpPlaceId,
            dropOffPlaceId,
        },
    } = useContext(RideContext);

    const [isUtc, setIsUtc] = useState<boolean>(primaryTimeZone === PrimaryTimeZone.UTC);

    useEffect(() => {
        setIsUtc(primaryTimeZone === PrimaryTimeZone.UTC);
    }, [primaryTimeZone]);

    const points = useMemo(() => {
        return [pickupCoordinates, dropOffCoordinates];
    }, [dropOffCoordinates, pickupCoordinates]);

    const driverPosition = useMemo(() => {
        return {
            timestamp: moment(),
            coordinates: {
                latitude: parseFloat(DEFAULT_COORDINATES.lat.toString()),
                longitude: parseFloat(DEFAULT_COORDINATES.lon.toString()),
            },
            coordinatesAccuracyMeters: 100,
            bearingDegrees: 0,
        };
    }, []);

    return (
        <Grid
            container
            style={{
                width: '100%',
                flexDirection: 'column',
            }}
        >
            <Grid
                item
                style={{
                    height: '200px',
                    background: COLORS.EXTRA_LIGHT_GRAY,
                }}
            >
                {showGoogleMap && (
                    <GoogleMap
                        points={points}
                        driverPosition={driverPosition}
                        placeIds={[pickUpPlaceId ?? '', dropOffPlaceId ?? '']}
                    />
                )}
                {!showGoogleMap && (
                    <Box
                        sx={{
                            height: '100%',
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                        }}
                    >
                        <ExploreOffOutlinedIcon
                            style={{
                                color: COLORS.SLATE_GREY,
                                fontSize: '40px',
                            }}
                        />
                    </Box>
                )}
            </Grid>
            <Grid item padding="1rem">
                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'space-between',
                        marginBottom: '1rem',
                        alignItems: 'center',
                    }}
                >
                    <Typography variant="h3" component="h4" className={classes.heading}>
                        {t('pages.rides.summary')}
                    </Typography>
                    <FormControlLabel
                        control={
                            <Switch
                                checked={isUtc}
                                onChange={event => setIsUtc(event.target.checked)}
                                name="primaryTimeZone"
                            />}
                        label={t('pages.summary.useUTCTime')}
                        labelPlacement="start"
                    />
                </Box>
                <IconedData icon={RouteOutlinedIcon} data={distance} tooltip={t('distance')} />
                <IconedData icon={TimerOutlinedIcon} data={time} tooltip={t('time')} bottomMargin="1.5rem" />
                <IconedData icon={LocationOnOutlinedIcon} data={pickUpLocation} tooltip={t('pages.summary.pickUpLocation')} />
                <IconedData icon={LocationOnOutlinedIcon} data={dropOffLocation} tooltip={t('pages.summary.dropOffLocation')} />
                <IconedData
                    icon={CalendarTodayOutlinedIcon}
                    data={isUtc ? pickUpDateAndTimeUtc : pickUpDateAndTime}
                    tooltip={t('pages.summary.pickUpDateAndTime')}
                />
            </Grid>
            <Grid item padding="0 1rem" display="flex" flexDirection="row" alignItems="center">
                <IconedData
                    icon={PermIdentityOutlinedIcon}
                    data={passengers?.toString()}
                    tooltip={t('pages.summary.travellers')}
                />
                <Divider orientation="vertical" className={classes.verticalDivider} />
                <IconedData icon={LuggageOutlinedIcon} data={luggages?.toString()} tooltip={t('pages.summary.luggages')} />
                <Divider orientation="vertical" className={classes.verticalDivider} />
                <IconedData icon={DirectionsCarFilledOutlinedIcon} data={vehicleType} tooltip={t('pages.summary.carType')} />
            </Grid>
        </Grid>
    );
};

export default RideSummary;
