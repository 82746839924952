import DirectionsCarOutlinedIcon from '@mui/icons-material/DirectionsCarOutlined';
import ModeEditOutlinedIcon from '@mui/icons-material/ModeEditOutlined';
import PersonAddAltIcon from '@mui/icons-material/PersonAddAlt';
import PersonOffOutlinedIcon from '@mui/icons-material/PersonOffOutlined';
import PersonOutlinedIcon from '@mui/icons-material/PersonOutlined';
import { GridActionsCellItem, GridRenderCellParams, GridRowParams } from '@mui/x-data-grid-pro';
import React, { useMemo } from 'react';
import { TFunction } from 'react-i18next';

import { COLORS } from '../../../../constants/colors';
import { User } from '../../../../services/types';
import useDataGridStyles from '../../../../styles/DataGrid';

const COLUMN_WIDTH = 230;

interface ActionClicked {
    onEditClicked?: (user: User) => void;
    onDeactivateClicked?: (user: User) => void;
    onReactivateClick?: (user: User) => void;
}

export const useUserColumns = (t: TFunction<string>, action: ActionClicked, currentUserId: string) => {
    const classes = useDataGridStyles();

    return useMemo(() => {
        const columns = [
            {
                field: 'name',
                headerName: t('pages.users.fields.name'),
                minWidth: COLUMN_WIDTH,
                flex: 1,
            },
            {
                field: 'email',
                headerName: t('pages.users.fields.email'),
                minWidth: COLUMN_WIDTH,
                flex: 1,
            },
            {
                field: 'permissions',
                headerName: t('pages.users.fields.permissions'),
                minWidth: COLUMN_WIDTH,
                flex: 1,
                renderCell: (params: GridRenderCellParams<string>) => (
                    <>
                        {params.row.permissions.manageUsers && (
                            <div className="permissionsCell">
                                <PersonOutlinedIcon fontSize="small" />
                                { t('pages.users.fields.users')}
                            </div>
                        )}
                        {params.row.permissions.manageRidesBookedByOthers && (
                            <div className="permissionsCell">
                                <DirectionsCarOutlinedIcon fontSize="small"/>
                                {t('pages.users.fields.rides')}
                            </div>
                        )}
                    </>
                ),
            },
            {
                field: 'lastSeen',
                headerName: t('pages.users.fields.lastSeen'),
                minWidth: COLUMN_WIDTH,
                flex: 1,
                headerClassName: classes.hideSeparator,
            },
            {
                field: 'actions',
                type: 'actions',
                flex: 1,
                maxWidth: 50,
                headerClassName: classes.hideSeparator,
                getActions: (params: GridRowParams) => {
                    const { onEditClicked, onDeactivateClicked, onReactivateClick } = action;

                    return [
                        onEditClicked && (
                            <GridActionsCellItem
                                label={t('pages.users.menu.edit')}
                                showInMenu
                                onClick={() => {
                                    if (onEditClicked) {
                                        onEditClicked(params.row as User);
                                    }
                                }}
                                onResizeCapture={undefined}
                                onResize={undefined}
                                nonce=""
                                className={classes.menuItem}
                                icon={<ModeEditOutlinedIcon fontSize="small" style={{ color: COLORS.BLACK }}/>}
                            />
                        ),
                        currentUserId !== params.row.id && onDeactivateClicked && (
                            <GridActionsCellItem
                                label= {t('pages.users.menu.deactivate')}
                                showInMenu
                                onClick={() => {
                                    if (onDeactivateClicked) {
                                        onDeactivateClicked(params.row as User);
                                    }
                                }}
                                onResizeCapture={undefined}
                                onResize={undefined}
                                nonce=""
                                className={classes.menuItem}
                                icon={<PersonOffOutlinedIcon fontSize="small" style={{ color: COLORS.BLACK }}/>}
                            />
                        ),
                        onReactivateClick && (
                            <GridActionsCellItem
                                label= {t('pages.users.menu.reactivate')}
                                showInMenu
                                onClick={() => {
                                    if (onReactivateClick) {
                                        onReactivateClick(params.row as User);
                                    }
                                }}
                                onResizeCapture={undefined}
                                onResize={undefined}
                                nonce=""
                                className={classes.menuItem}
                                icon={<PersonAddAltIcon fontSize="small" style={{ color: COLORS.BLACK }}/>}
                            />
                        ),
                    ].filter(Boolean);
                },
            },
        ];

        return columns;
    }, [t, classes.hideSeparator, classes.menuItem, action, currentUserId]);
};

