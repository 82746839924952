import { v4 as uuidv4 } from 'uuid';

import { DriverInformation } from '../hooks/useSupplierDriverInformation';

export const driverInformations = [
    {
        id: uuidv4(),
        name: 'Dalibor Ristic',
        mobile: '+381 65 964 6666',
        rating: '4.7',
        reviewsNumber: '10',
    },
    {
        id: uuidv4(),
        name: 'Noa Ristic',
        mobile: '+31 21 544 544',
        rating: '5.0',
        reviewsNumber: '20',
    },
] as DriverInformation[];
