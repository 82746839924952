import gql from 'graphql-tag';

export interface GetSessionTokenResult {
    createBookingSessionToken: {
        token: string;
    };
}

export const GET_SESSION_TOKEN = gql`
    mutation GET_SESSION_TOKEN {
        createBookingSessionToken {
            token 
        }
    } 
`;
