/* eslint-disable @typescript-eslint/no-explicit-any */
import { BaseError } from 'make-error-cause';
import { useQuery } from 'react-query';

import { USE_PAST_STOP_SALES_RULES, USE_STOP_SALES_RULE, USE_STOP_SALES_RULES } from '../../../constants/queryKeys';
import tryGetAsync from '../../../helpers/tryGetAsync';
import { BlockedPeriod } from './types';

export const useStopSalesRule = (
    accountId: number,
    ruleId: number,
    enabled: boolean
) => {
    const { data, isLoading, isError, isFetching, refetch } = useQuery(
        [USE_STOP_SALES_RULE],
        () => getStopSalesRule(accountId, ruleId),
        {
            keepPreviousData: true,
            refetchOnWindowFocus: false,
            retry: 1,
            enabled,
        }
    );

    return {
        data,
        isLoading,
        isError,
        isFetching,
        refetch,
    };
};

export const useCurrentStopSalesRules = (
    accountId: number,
    enabled: boolean
) => {
    const { data, isLoading, isError, isFetching, refetch } = useQuery(
        [USE_STOP_SALES_RULES],
        () => getCurrentStopSalesRules(accountId),
        {
            keepPreviousData: true,
            refetchOnWindowFocus: false,
            retry: 1,
            enabled,
        }
    );

    return {
        data,
        isLoading,
        isError,
        isFetching,
        refetch,
    };
};

export const usePastStopSalesRules = (accountId: number, enabled: boolean) => {
    const { data, isLoading, isError, isFetching, refetch } = useQuery(
        [USE_PAST_STOP_SALES_RULES],
        () => getPastStopSalesRules(accountId),
        {
            keepPreviousData: true,
            refetchOnWindowFocus: false,
            retry: 1,
            enabled,
        }
    );

    return {
        data,
        isLoading,
        isError,
        isFetching,
        refetch,
    };
};

const getStopSalesRule = async (accountId: number, ruleId: number) => {
    const result = await fetch(
        `/api/internal/accounts/${accountId}/stop-sales-rules/${ruleId}`,
        {
            method: 'GET',
            headers: { Accept: 'application/json' },
        }
    );

    return (await result.json()) as BlockedPeriod;
};

const getCurrentStopSalesRules = async (accountId: number) => {
    const result = await fetch(
        `/api/internal/accounts/${accountId}/stop-sales-rules`,
        {
            method: 'GET',
            headers: { Accept: 'application/json' },
        }
    );

    return (await result.json()) as BlockedPeriod[];
};

const getPastStopSalesRules = async (accountId: number) => {
    const result = await fetch(
        `/api/internal/accounts/${accountId}/stop-sales-rules?withPastPeriods=true`,
        {
            method: 'GET',
            headers: { Accept: 'application/json' },
        }
    );

    return (await result.json()) as BlockedPeriod[];
};

export const editStopSalesRule = async (
    accountId: string,
    ruleId: string,
    payload: any
) => {
    const result = await tryGetAsync(() =>
        fetch(`/api/internal/accounts/${accountId}/stop-sales-rules/${ruleId}`, {
            method: 'PUT',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(payload),
        }));

    if (!result.success || !result.value.ok) {
        throw new BaseError('Failed to edit blocked period');
    }
};

export const createStopSalesRule = async (accountId: string, payload: any) => {
    const result = await tryGetAsync(() =>
        fetch(`/api/internal/accounts/${accountId}/stop-sales-rules`, {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(payload),
        }));

    if (!result.success || !result.value.ok) {
        throw new BaseError('Failed to create blocked period');
    }
};

export const deleteStopSalesRule = async (
    accountId: string,
    ruleId: string
) => {
    const result = await tryGetAsync(() =>
        fetch(`/api/internal/accounts/${accountId}/stop-sales-rules/${ruleId}`, {
            method: 'DELETE',
            headers: { 'Content-Type': 'application/json' },
        }));

    if (!result.success || !result.value.ok) {
        throw new BaseError('Failed to delete blocked period');
    }
};
