import { Modal } from '@get-e/react-components';
import { Typography, CardContent } from '@mui/material';
import { makeStyles } from '@mui/styles';
import React, { FunctionComponent } from 'react';
import { useTranslation } from 'react-i18next';

import { COLORS } from '../../../../constants/colors';
import InvitedUserForm from './InviteUserForm';

const useStyles = makeStyles({
    heading: {
        marginBottom: '1rem',
        color: COLORS.BLUE_DARK,
    },
});

const InviteUserModal: FunctionComponent<{
    open: boolean;
    onClose: () => void;
    onInviteUser: () => void;
}> = ({ open, onClose, onInviteUser }) => {
    const { t } = useTranslation();
    const classes = useStyles();

    return (
        <Modal open={open} onClose={onClose}>
            <CardContent
                sx={{
                    width: '100%',
                    margin: '0 auto',
                    paddingLeft: '0',
                }}
            >
                <Typography variant="h2" component="h2" className={classes.heading}>
                    {t('modals.invite.heading')}
                </Typography>
            </CardContent>
            <InvitedUserForm
                onClose={onClose}
                onInviteUser={onInviteUser}
            />
        </Modal>
    );
};

export default InviteUserModal;
