import { Typography, Box, Grid, Rating, useMediaQuery } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { GridRowParams } from '@mui/x-data-grid-pro';
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import { COLORS } from '../../../../constants/colors';
import theme from '../../../../styles/theme';

const useStyles = makeStyles({
    container: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-start',
        padding: '1rem .5rem .5rem .5rem',
        borderBottom: `1px solid ${COLORS.BLACK_12}`,
        background: COLORS.EXTRA_LIGHT_GRAY,
    },
    title: {
        color: COLORS.SLATE_GREY,
        fontWeight: 700,
    },
    ratingBox: {
        display: 'flex',
        alignItems: 'center',
    },
    serviceBox: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
        paddingBottom: '.5rem',
    },
});

export const useReviewsDetailsContent = () => {
    const classes = useStyles();
    const { t } = useTranslation();
    const isMobile = useMediaQuery(theme.breakpoints.down('md'));

    return useMemo(
        () =>
            ({ row }: GridRowParams) =>
                (
                    <Box className={classes.container}>
                        <Grid container spacing={isMobile ? 1 : 4}>
                            {isMobile && (
                                <>
                                    <Grid item xs={12} display="flex">
                                        <Typography fontWeight="700">{t('pages.reports.rideNumber')}:&nbsp;</Typography>
                                        <Link to={'/'}>
                                            <Typography color={COLORS.BLUE}>{row.rideNumber}</Typography>
                                        </Link>
                                    </Grid>
                                    <Grid item xs={12} display="flex">
                                        <Typography fontWeight="700">{t('pages.reports.driver')}:&nbsp;</Typography>
                                        <Typography>{row.driver}</Typography>
                                    </Grid>
                                </>
                            )}
                            <Grid item lg={5} md={5} xs={12}>
                                <Box className={classes.serviceBox}>
                                    <Typography className={classes.title}>{t('pages.reports.overallService')}</Typography>
                                    <Box className={classes.ratingBox}>
                                        {row.service.overall}
                                        <Rating
                                            name="read-only"
                                            value={row.service.overall}
                                            readOnly
                                            sx={{ paddingLeft: '1rem' }}
                                        />
                                    </Box>
                                </Box>
                                <Box className={classes.serviceBox}>
                                    <Typography className={classes.title}>{t('pages.reports.driver')}</Typography>
                                    <Box className={classes.ratingBox}>
                                        {row.service.driver}
                                        <Rating
                                            name="read-only"
                                            value={row.service.driver}
                                            readOnly
                                            sx={{ paddingLeft: '1rem' }}
                                        />
                                    </Box>
                                </Box>
                                <Box className={classes.serviceBox}>
                                    <Typography className={classes.title}>{t('pages.reports.meetingTheDriver')}</Typography>
                                    <Box className={classes.ratingBox}>
                                        {row.service.meetingDriver}
                                        <Rating
                                            name="read-only"
                                            value={row.service.meetingDriver}
                                            readOnly
                                            sx={{ paddingLeft: '1rem' }}
                                        />
                                    </Box>
                                </Box>
                                <Box className={classes.serviceBox}>
                                    <Typography className={classes.title}>{t('pages.reports.car')}</Typography>
                                    <Box className={classes.ratingBox}>
                                        {row.service.car}
                                        <Rating
                                            name="read-only"
                                            value={row.service.car}
                                            readOnly
                                            sx={{ paddingLeft: '1rem' }}
                                        />
                                    </Box>
                                </Box>
                            </Grid>
                            <Grid item xs={12} md={7} lg={7}>
                                <Typography className={classes.title}>{t('pages.reports.comments')}</Typography>
                                <Box sx={{ padding: '.5rem .5rem 0 0' }}>{row.comment}</Box>
                            </Grid>
                        </Grid>
                    </Box>
                ),
        [classes.container, classes.ratingBox, classes.serviceBox, classes.title, t, isMobile]
    );
};
