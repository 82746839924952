import React, { FunctionComponent } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router';

import ErrorPage from '../../components/errorPage/ErrorPage';
import { carIcon } from '../../public/assets/icons';

const UnexpectedError: FunctionComponent<{
    onRetry?: () => void;
    framed?: boolean;
}> = ({ onRetry, framed }) => {
    const { t } = useTranslation();
    const history = useHistory();

    const onRefresh = () => history.go(0);

    return (
        <ErrorPage
            icon={carIcon}
            header={t('errors.unexpectedError.description')}
            description={t('errors.unexpectedError.nextSteps')}
            buttonTitle={onRetry ? t('errors.retry') : t('errors.refresh') }
            onButtonClick={onRetry ?? onRefresh}
        />
    );
};

export default UnexpectedError;
