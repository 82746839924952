import List from '@material-ui/core/List';
import AccountBalanceIcon from '@material-ui/icons/AccountBalance';
import AddIcon from '@material-ui/icons/Add';
import BugReportIcon from '@material-ui/icons/BugReport';
import ChatBubbleIcon from '@material-ui/icons/ChatBubble';
import DomainIcon from '@material-ui/icons/Domain';
import ImportantDevicesIcon from '@material-ui/icons/ImportantDevices';
import InsertDriveFileIcon from '@material-ui/icons/InsertDriveFile';
import ListIcon from '@material-ui/icons/List';
import LocalTaxiIcon from '@material-ui/icons/LocalTaxi';
import PhoneIcon from '@material-ui/icons/Phone';
import PowerIcon from '@material-ui/icons/Power';
import PublicIcon from '@material-ui/icons/Public';
import SettingsIcon from '@material-ui/icons/Settings';
import TrendingUpIcon from '@material-ui/icons/TrendingUp';
import React, { FunctionComponent, useState } from 'react';

import { MenuListItem } from '../../menuListItem/MenuListItem';
import CollapseMenu from '../CollapseMenu';
import CollapseMenuItem from '../CollapseMenuItem';

enum BackOfficeMenuItem {
    Network,
    Accounting,
    Developer,
    NotifyIT,
    SabreAirCentre,
}

const BackOfficeList: FunctionComponent = () => {
    const [openMenuItem, setOpenMenuItem] = useState<BackOfficeMenuItem|null>(null);

    const handleCollapseMenuClick = (openItem: BackOfficeMenuItem): void => {
        if (openMenuItem === openItem) {
            setOpenMenuItem(null);
        } else {
            setOpenMenuItem(openItem);
        }
    };

    return (
        <List role="menu" data-testid="back-office-navigation">
            <MenuListItem
                icon={<LocalTaxiIcon fontSize="small" />}
                label="Booking tool"
                href="/booking-tool"
                onClick={() => setOpenMenuItem(null)}
            />
            <MenuListItem
                icon={<AddIcon fontSize="small" />}
                label="New ride"
                href="/new-ride"
                onClick={() => setOpenMenuItem(null)}
            />
            <MenuListItem
                icon={<ListIcon fontSize="small" />}
                label="Rides"
                href="/rides"
                onClick={() => setOpenMenuItem(null)}
            />
            <MenuListItem
                icon={<DomainIcon fontSize="small" />}
                label="Accounts"
                href="/accounts"
                onClick={() => setOpenMenuItem(null)}
            />
            <MenuListItem
                icon={<SettingsIcon fontSize="small" />}
                label="In creation"
                href="/in-creation"
                onClick={() => setOpenMenuItem(null)}
            />

            <CollapseMenu
                onClick={() => handleCollapseMenuClick(BackOfficeMenuItem.Network)}
                text="Network"
                isOpen={openMenuItem === BackOfficeMenuItem.Network}
                icon={<PublicIcon fontSize="small" />}
            >
                <CollapseMenuItem url="/iframe" title="Supplier groups" />
                <CollapseMenuItem url="/iframe" title="Drivers" />
                <CollapseMenuItem url="/iframe" title="Places" />
                <CollapseMenuItem url="/iframe" title="Airport lists" />
                <CollapseMenuItem url="/iframe" title="Coverage" />
                <CollapseMenuItem url="/iframe" title="Reseller accounts" />
            </CollapseMenu>

            <CollapseMenu
                onClick={() => handleCollapseMenuClick(BackOfficeMenuItem.Accounting)}
                text="Accounting"
                isOpen={openMenuItem === BackOfficeMenuItem.Accounting}
                icon={<AccountBalanceIcon fontSize="small" />}
            >
                <CollapseMenuItem url="/iframe" title="Customers" />
                <CollapseMenuItem url="/iframe" title="Suppliers" />
                <CollapseMenuItem url="/iframe" title="Sales archive" />
                <CollapseMenuItem url="/iframe" title="Self billing archive" />
                <CollapseMenuItem url="/iframe" title="Payment link" />
                <CollapseMenuItem url="/iframe" title="Bulk update prices" />
            </CollapseMenu>

            <MenuListItem
                icon={<TrendingUpIcon fontSize="small" />}
                label="Statistics"
                href="/statistics"
                onClick={() => setOpenMenuItem(null)}
            />
            <MenuListItem
                icon={<ChatBubbleIcon fontSize="small" />}
                label="Send SMS"
                href="/send-sms"
                onClick={() => setOpenMenuItem(null)}
            />

            <CollapseMenu
                onClick={() => handleCollapseMenuClick(BackOfficeMenuItem.Developer)}
                text="Developer"
                isOpen={openMenuItem === BackOfficeMenuItem.Developer}
                icon={<ImportantDevicesIcon fontSize="small" />}
            >
                <CollapseMenuItem url="/iframe" title="Integrations" />
                <CollapseMenuItem url="/iframe" title="Estimate to trip" />
                <CollapseMenuItem url="/iframe" title="Exact authenticate" />
            </CollapseMenu>

            <CollapseMenu
                onClick={() => handleCollapseMenuClick(BackOfficeMenuItem.NotifyIT)}
                text="Notify IT"
                isOpen={openMenuItem === BackOfficeMenuItem.NotifyIT}
                icon={<BugReportIcon fontSize="small" />}
            >
                <CollapseMenuItem url="/iframe" title="Report a bug" />
                <CollapseMenuItem url="/iframe" title="Request a feature" />
            </CollapseMenu>

            <MenuListItem
                icon={<InsertDriveFileIcon fontSize="small" />}
                label="Documents"
                href="/documents"
                onClick={() => setOpenMenuItem(null)}
            />

            <MenuListItem
                icon={<PhoneIcon fontSize="small" />}
                label="Wake standby"
                href="/wake-standby"
                onClick={() => setOpenMenuItem(null)}
            />

            <CollapseMenu
                onClick={() => handleCollapseMenuClick(BackOfficeMenuItem.SabreAirCentre)}
                text="Sabre AirCentre"
                isOpen={openMenuItem === BackOfficeMenuItem.SabreAirCentre}
                icon={<PowerIcon fontSize="small" />}
            >
                <CollapseMenuItem url="/iframe" title="Transfers" />
                <CollapseMenuItem url="/iframe" title="Pending changes" />
                <CollapseMenuItem url="/iframe" title="Activate airports" />
            </CollapseMenu>
        </List>
    );
};

export default BackOfficeList;
