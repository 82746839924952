import { useQuery } from 'react-query';

import { USE_RIDE } from '../../../../constants/queryKeys';
import { viewRide } from '../../../../services/ride';

export function useRide(id: string) {
    return useQuery([USE_RIDE], () => viewRide(id), {
        keepPreviousData: true,
        refetchOnWindowFocus: false,
        retry: 1,
        cacheTime: 500,
    });
}
