import CreateOutlinedIcon from '@mui/icons-material/CreateOutlined';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import PersonOffOutlinedIcon from '@mui/icons-material/PersonOffOutlined';
import { useMediaQuery } from '@mui/material';
import IconButton from '@mui/material/IconButton';
import {
    GridRenderCellParams,
    GridRowParams,
    GRID_DETAIL_PANEL_TOGGLE_COL_DEF,
    GridActionsCellItem,
    GridRowId,
} from '@mui/x-data-grid-pro';
import React, { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { COLORS } from '../../../constants/colors';
import useDataGridStyles from '../../../styles/DataGrid';
import theme from '../../../styles/theme';
import { ChangeStatus } from '../../rides/supplier/Rides';

export const useDriversColumns = (handleDelete: () => void, handleEdit: () => void) => {
    const { t } = useTranslation();
    const dataGridClases = useDataGridStyles();
    const isMobile = useMediaQuery(theme.breakpoints.down('lg'));

    const onActionItemClick = useCallback((id: number, action: ChangeStatus) => {
        switch (action) {
            case ChangeStatus.EDIT_DRIVER:
                handleEdit();
                break;
            case ChangeStatus.DELETE_DRIVER:
                handleDelete();
                break;
            default:
                throw new Error('Driver action not supported');
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return useMemo(
        () => [
            {
                field: 'name',
                headerName: t('pages.drivers.name'),
                minWidth: 200,
                flex: 1,
            },
            {
                field: 'phoneNumber',
                headerName: t('pages.drivers.phoneNumber'),
                minWidth: 200,
                flex: 1,
            },
            {
                field: 'rideAssigned',
                headerName: t('pages.drivers.rideAssigned'),
                minWidth: 200,
                flex: 1,
                hide: isMobile,
            },
            {
                field: 'actions',
                type: 'actions',
                width: 20,
                hide: isMobile,
                headerClassName: dataGridClases.hideSeparator,
                getActions: (params: GridRowParams) => [
                    <GridActionsCellItem
                        key={ChangeStatus.EDIT_DRIVER}
                        className={dataGridClases.menuItem}
                        icon={<CreateOutlinedIcon style={{ color: COLORS.BLUE_DARK }}/>}
                        label={t('buttonName.edit')}
                        onClick={() =>
                            onActionItemClick(params.row.id, ChangeStatus.EDIT_DRIVER)
                        }
                        showInMenu
                        onResize={undefined}
                        onResizeCapture={undefined}
                        nonce=""
                    />,
                    <GridActionsCellItem
                        key={ChangeStatus.DELETE_DRIVER}
                        className={dataGridClases.menuItem}
                        icon={<PersonOffOutlinedIcon style={{ color: COLORS.BLUE_DARK }}/>}
                        label={t('buttonName.delete')}
                        onClick={() =>
                            onActionItemClick(params.row.id, ChangeStatus.DELETE_DRIVER)
                        }
                        showInMenu
                        onResize={undefined}
                        onResizeCapture={undefined}
                        nonce=""
                    />,

                ],
            },
            {
                ...GRID_DETAIL_PANEL_TOGGLE_COL_DEF,
                hide: !isMobile,
                headerClassName: dataGridClases.hideSeparator,
                renderCell: (params: { id: GridRowId; value: boolean }) => (
                    <MobileViewPanelToggle id={params.id} value={params.value} />
                ),
            },
        ]
        , [t, isMobile, dataGridClases.hideSeparator, dataGridClases.menuItem, onActionItemClick]
    );
};

function MobileViewPanelToggle(props: Pick<GridRenderCellParams, 'id' | 'value'>) {
    const { value: isExpanded } = props;

    return (
        <IconButton
            size="small"
            tabIndex={-1}
            aria-label={isExpanded ? 'Close' : 'Open'}
        >
            <ExpandMoreIcon
                sx={{
                    transform: `rotateZ(${isExpanded ? 180 : 0}deg)`,
                    transition: () =>
                        theme.transitions.create('transform', { duration: theme.transitions.duration.shortest }),
                }}
                fontSize="inherit"
            />
        </IconButton>
    );
}
