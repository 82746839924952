import { InMemoryCache, NormalizedCacheObject } from 'apollo-cache-inmemory';
import ApolloClient from 'apollo-client';
import { ApolloLink } from 'apollo-link';
import { onError } from 'apollo-link-error';
import { createHttpLink } from 'apollo-link-http';

import config from '../config';
import createErrorHandler from './createErrorHandler';

const cache = new InMemoryCache();

function redirectTo(path: string): void {
    window.location.pathname = path;
}

const errorHandler = createErrorHandler(redirectTo);
const errorLink = onError(errorHandler);

const httpLink = createHttpLink({
    uri: config.graphqlUrl,
    credentials: 'include',
});

const link = ApolloLink.from([
    errorLink,
    httpLink,
]);

export const client: ApolloClient<NormalizedCacheObject> = new ApolloClient({
    cache,
    link,
});
