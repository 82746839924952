import React from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router';

import ErrorPage from '../../components/errorPage/ErrorPage';
import { LOG_IN } from '../../constants/urlPaths';
import { lockIcon } from '../../public/assets/icons';

const InvalidAuthCode = () => {
    const { t } = useTranslation();
    const history = useHistory();

    return (
        <ErrorPage
            icon={lockIcon}
            header={t('errors.invalidAuthCode.description')}
            description={t('errors.invalidAuthCode.nextSteps')}
            buttonTitle={t('buttonName.backToLogIn')}
            onButtonClick={() => history.push(LOG_IN)}
        />
    );
};

export default InvalidAuthCode;
