import { Box } from '@mui/material';
import React, { ReactNode } from 'react';

interface CellWrapperProps {
    children: ReactNode;
}

const CellWrapper = ({ children }: CellWrapperProps) => (
    <Box
        sx={{
            padding: '10px 0',
            width: '100%',
            height: 'calc(100% - 20px)',
            display: 'flex',
            flexDirection: 'column',
        }}
    >
        {children}
    </Box>
);

export default CellWrapper;
