import { MessageDialog } from '@get-e/react-components';
import { Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import React from 'react';

import { useTranslation } from 'react-i18next';
import { COLORS } from '../../../constants/colors';
import { Traveller } from './travellerInformation/TravellerInformation';

const useStyles = makeStyles({
    confirmButton: {
        background: COLORS.ORANGE,
        '&:hover': { background: COLORS.RED },
    },
    closeButton: { marginLeft: '.5rem' },
});

const RemoveTravellerConfirmModal = ({
    onClose,
    onConfirm,
    traveller,
}: {
    onClose: () => void;
    onConfirm: (id: string) => void;
    traveller: Traveller;
}) => {
    const { t } = useTranslation();
    const classes = useStyles();

    return (
        <MessageDialog
            onClose={onClose}
            onConfirm={() => onConfirm(traveller.id)}
            title={t('modals.removeTraveller.title')}
            isConfirmButtonDisplayed
            closeButtonLabel={t('buttonName.cancel')}
            confirmButtonLabel={t('buttonName.confirm')}
            closeButtonType="secondary"
            confirmButtonClassName={classes.confirmButton}
            closeButtonClassName={classes.closeButton}
            areButtonsFullWidth
        >
            <Typography paddingTop="1rem">
                {t('modals.removeTraveller.descriptionPart1')}
                <strong> {`${traveller.firstName} ${traveller.lastName}`} </strong>
                {t('modals.removeTraveller.descriptionPart2')}
            </Typography>
            <Typography
                style={{
                    fontWeight: 700,
                    marginBottom: '2rem',
                }}
            >
                {t('doYouWantToProceed')}
            </Typography>
        </MessageDialog>
    );
};

export default RemoveTravellerConfirmModal;
