import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import resources from './public/resources';

// eslint-disable-next-line @typescript-eslint/no-floating-promises
i18n
    .use(initReactI18next)
    .init({
        resources,
        saveMissing: true,
        missingKeyHandler(lngs, _ns, key) {
            throw new Error(`Missing key "${key}" for locales: ${lngs.join(', ')}`);
        },

        // React renderer already escapes text nodes, this prevents double escaping
        interpolation: { escapeValue: false },
        react: { wait: true },
    });

export default i18n;
