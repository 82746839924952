import currentEnvironment from '../currentEnvironment';
import Environment from '../Environment';
import development from './development';
import production from './production';
import test from './test';

const envConfig = (() => {
    switch (currentEnvironment) {
        case Environment.Development:
            return development;
        case Environment.Test:
            return test;
        case Environment.Production:
            return production;
        default:
            throw new Error(`Unhandled environment "${Environment[currentEnvironment]}"`);
    }
})();

export default envConfig;
