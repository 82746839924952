/* eslint-disable react-hooks/rules-of-hooks */
import PersonAddDisabledOutlinedIcon from '@mui/icons-material/PersonAddDisabledOutlined';
import { GridActionsCellItem, GridRowParams } from '@mui/x-data-grid';
import React from 'react';
import { TFunction } from 'react-i18next';

import { COLORS } from '../../../../constants/colors';
import useDataGridStyles from '../../../../styles/DataGrid';
import { UserInvite } from '../Users.graphql';

const COLUMN_WIDTH = 305;

export const getInvitedColumns = (t: TFunction<string>, onRevokeClicked?: (user: UserInvite) => void) => {
    const classes = useDataGridStyles();

    return [
        {
            field: 'fullName',
            headerName: t('pages.users.fields.name'),
            minWidth: COLUMN_WIDTH,
            flex: 1,
        },
        {
            field: 'email',
            headerName: t('pages.users.fields.email'),
            minWidth: COLUMN_WIDTH,
            flex: 1,
        },
        {
            field: 'createdAt',
            headerName: t('pages.users.fields.invitedOn'),
            minWidth: COLUMN_WIDTH,
            flex: 1,
            headerClassName: classes.hideSeparator,
        },
        {
            field: 'actions',
            type: 'actions',
            flex: 1,
            maxWidth: 50,
            headerClassName: classes.hideSeparator,
            getActions: (params: GridRowParams) => [
                onRevokeClicked && (
                    <GridActionsCellItem
                        label= {t('pages.users.menu.revoke')}
                        showInMenu
                        onClick={() => {
                            if (onRevokeClicked) {
                                onRevokeClicked(params.row as UserInvite);
                            }
                        }}
                        onResizeCapture={undefined}
                        onResize={undefined}
                        className={classes.menuItem}
                        icon={<PersonAddDisabledOutlinedIcon fontSize="small" style={{ color: COLORS.BLACK }}/>}
                    />
                ),
            ].filter(Boolean),
        },
    ];
};
