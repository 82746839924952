import CreateOutlinedIcon from '@mui/icons-material/CreateOutlined';
import DirectionsCarOutlinedIcon from '@mui/icons-material/DirectionsCarOutlined';
import PersonOffOutlinedIcon from '@mui/icons-material/PersonOffOutlined';
import PersonOutlinedIcon from '@mui/icons-material/PersonOutlined';
import { useMediaQuery } from '@mui/material';
import {
    GridRenderCellParams,
    GridRowParams,
    GRID_DETAIL_PANEL_TOGGLE_COL_DEF,
    GridActionsCellItem,
    GridRowId,
} from '@mui/x-data-grid-pro';
import React, { useCallback, useMemo } from 'react';

import { useTranslation } from 'react-i18next';
import { DetailPanelToggle } from '../../../../components/detailPanelToggle/DetailPanelToggle';
import { COLORS } from '../../../../constants/colors';
import useDataGridStyles from '../../../../styles/DataGrid';
import theme from '../../../../styles/theme';
import { ChangeStatus } from '../Users';

const COLUMN_WIDTH = 230;

export const useUsersColumns = (handleDelete: () => void, handleEdit: () => void) => {
    const { t } = useTranslation();
    const dataGridClases = useDataGridStyles();
    const isMobile = useMediaQuery(theme.breakpoints.down('md'));

    const onActionItemClick = useCallback((id: number, action: ChangeStatus) => {
        switch (action) {
            case ChangeStatus.EDIT_USER:
                handleEdit();
                break;
            case ChangeStatus.DELETE_USER:
                handleDelete();
                break;
            default:
                throw new Error('Driver action not supported');
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return useMemo(
        () => [
            {
                field: 'name',
                headerName: t('pages.users.fields.name'),
                minWidth: COLUMN_WIDTH,
                flex: 1,
            },
            {
                field: 'email',
                headerName: t('pages.users.fields.email'),
                minWidth: COLUMN_WIDTH,
                flex: 1,
            },
            {
                field: 'permissions',
                headerName: t('pages.users.fields.permissions'),
                minWidth: COLUMN_WIDTH,
                flex: 1,
                hide: isMobile,
                renderCell: (params: GridRenderCellParams<string>) => (
                    <>
                        {params.row.permissions.manageUsers && (
                            <div className="permissionsCell">
                                <PersonOutlinedIcon fontSize="small" />
                                {t('pages.users.fields.users')}
                            </div>
                        )}
                        {params.row.permissions.manageRidesBookedByOthers && (
                            <div className="permissionsCell">
                                <DirectionsCarOutlinedIcon fontSize="small"/>
                                {t('pages.users.fields.rides')}
                            </div>
                        )}
                    </>
                ),
            },
            {
                field: 'lastSeen',
                headerName: t('pages.users.fields.lastSeen'),
                minWidth: COLUMN_WIDTH,
                flex: 1,
                hide: isMobile,
            },
            {
                field: 'actions',
                type: 'actions',
                hide: isMobile,
                flex: 1,
                maxWidth: 50,
                headerClassName: dataGridClases.hideSeparator,
                getActions: (params: GridRowParams) => [
                    <GridActionsCellItem
                        key={ChangeStatus.EDIT_USER}
                        className={dataGridClases.menuItem}
                        icon={<CreateOutlinedIcon style={{ color: COLORS.BLUE_DARK }}/>}
                        label={t('buttonName.edit')}
                        onClick={() =>
                            onActionItemClick(params.row.id, ChangeStatus.EDIT_USER)
                        }
                        showInMenu
                        onResize={undefined}
                        onResizeCapture={undefined}
                        nonce=""
                    />,
                    <GridActionsCellItem
                        key={ChangeStatus.DELETE_USER}
                        className={dataGridClases.menuItem}
                        icon={<PersonOffOutlinedIcon style={{ color: COLORS.BLUE_DARK }}/>}
                        label={t('buttonName.delete')}
                        onClick={() =>
                            onActionItemClick(params.row.id, ChangeStatus.DELETE_USER)
                        }
                        showInMenu
                        onResize={undefined}
                        onResizeCapture={undefined}
                        nonce=""
                    />,

                ],
            },
            {
                ...GRID_DETAIL_PANEL_TOGGLE_COL_DEF,
                hide: !isMobile,
                headerClassName: dataGridClases.hideSeparator,
                renderCell: (params: { id: GridRowId; value: boolean }) => (
                    <DetailPanelToggle id={params.id} value={params.value} />
                ),
            },
        ]
        , [t, isMobile, dataGridClases.hideSeparator, dataGridClases.menuItem, onActionItemClick]
    );
};
