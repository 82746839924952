import React from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';

import ErrorPage from '../../components/errorPage/ErrorPage';
import { notFoundIcon } from '../../public/assets/icons';

const NotFound = () => {
    const { t } = useTranslation();
    const history = useHistory();

    return (
        <ErrorPage
            icon={notFoundIcon}
            header={t('errors.notFound.description')}
            description= {t('errors.notFound.nextSteps')}
            buttonTitle={t('buttonName.backToHome')}
            onButtonClick={() => history.push('/')}
        />
    );
};

export default NotFound;
