import { useMutation } from '@apollo/react-hooks';
import React, { FunctionComponent, useCallback, useEffect, useState } from 'react';

import { causesRedirect } from '../../ApolloClient/createErrorHandler';
import config from '../../config';
import { useCurrentUserContext } from '../../context/CurrentUserContext';
import { useLocaleContext } from '../../context/LocaleContext';
import { report } from '../../helpers/report';
import iframeStyles from '../../styles/Iframe';
import UnexpectedError from '../errorPages/UnexpectedError';
import {
    GET_SESSION_TOKEN,
    GetSessionTokenResult,
} from './BookingTool.graphql';
import BookingToolSkeleton from './components/BookingToolSkeleton';

/*
 * Commit containing select menu work can be found at
 * https://github.com/GET-E/portal/blob/1d12b6ecd271e1a318443582e6f32fa72621a0e9/src/pages/BookingTool.tsx#L1
 */

const bookingToolLocales = ['en', 'es', 'it', 'nl'] as const;

type BookingToolLocale = typeof bookingToolLocales[number];

const BookingTool: FunctionComponent = () => {
    const classes = iframeStyles();
    const { currentUser } = useCurrentUserContext();
    const { bookingToolSubdomain } = currentUser.company.customer;
    const subDomain = bookingToolSubdomain ?? 'self';
    const bookingToolUrl = config.bookingToolUrl.replace('{subDomain}', subDomain);
    const { locale } = useLocaleContext();
    const [sessionToken, setSessionToken] = useState<string|null>(null);

    const bookingToolLocale = ((): BookingToolLocale => {
        switch (locale) {
            case 'en-GB':
                return 'en';
            default:
                throw new Error(`${locale as string} is unsupported`);
        }
    })();

    const [createBookingSessionToken, { loading, error }] = useMutation<GetSessionTokenResult>(GET_SESSION_TOKEN, {
        onError(apolloError) {
            report(apolloError);
        },
    });

    const updateSessionToken = useCallback(async (): Promise<void> => {
        const result = await createBookingSessionToken();

        if (result?.data) {
            setSessionToken(result.data.createBookingSessionToken.token);
        }
    }, [createBookingSessionToken]);

    useEffect(() => {
        // eslint-disable-next-line @typescript-eslint/no-floating-promises
        updateSessionToken();
    }, [updateSessionToken]);

    if (loading) {
        return <BookingToolSkeleton />;
    }

    if (error || !sessionToken) {
        if (error && causesRedirect(error)) {
            return null;
        }

        return <UnexpectedError onRetry={updateSessionToken} framed={true}/>;
    }

    const handleIfrmeLoad = () => {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const iframe = document.getElementById('booking-tool-iframe') as any;
        const iWindow = iframe.contentWindow;
        const iDocument = iWindow.document;

        const chatButton = iDocument.querySelector('[aria-label="Chat"]') as HTMLElement;

        console.log('chatButton ', chatButton);

        if (chatButton) {
            chatButton.style.cssText = 'display:none !important';
        }
    };

    return (
        <iframe
            id="booking-tool-iframe"
            className={classes.iframe}
            src={`${bookingToolUrl}/${bookingToolLocale}?hideLocaleUI=true&session=${sessionToken}`}
            title="New ride"
            data-testid="booking-tool-iframe"
            onLoad={handleIfrmeLoad}
        />
    );
};

export default BookingTool;
