import { PrimaryButton } from '@get-e/react-components';
import AddIcon from '@mui/icons-material/Add';
import { Grid, Typography } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { DataGridPro, GridColumns, GridValidRowModel, GridRowsProp } from '@mui/x-data-grid-pro';
import clsx from 'clsx';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import uuid from 'react-uuid';

import { CustomNoRowsOverlay } from '../../../components/noRowsDataGridOverlay/CustomNoRowsOverlay';
import { COLORS } from '../../../constants/colors';
import { BUTTON_WIDTH } from '../../../constants/layout';
import useDataGridStyles from '../../../styles/DataGrid';
import AddUserModal from './components/AddUserModal';
import DeleteUserModal from './components/DeleteUserModal';
import EditUserModal from './components/EditUserModal';
import { useUsersColumns } from './hooks/useUsersColumns';
import { useUsersColumnsMobile } from './hooks/useUsersColumnsMobile';

const useStyles = makeStyles({ addRideButton: { width: BUTTON_WIDTH } });

const rows: GridRowsProp = [
    {
        id: uuid(),
        name: 'name',
        email: 'name@gmail.com',
        permissions: {
            manageUsers: true,
            manageRidesBookedByOthers: true,
        },
        lastSeen: '1',
    },
    {
        id: uuid(),
        name: 'name',
        permissions: {
            manageUsers: true,
            manageRidesBookedByOthers: false,
        },
        lastSeen: '1',
    },
    {
        id: uuid(),
        name: 'name',
        email: 'name@gmail.com',
        permissions: {
            manageUsers: false,
            manageRidesBookedByOthers: true,
        },
        lastSeen: '1',
    },
    {
        id: uuid(),
        name: 'name',
        email: 'name@gmail.com',
        permissions: {
            manageUsers: true,
            manageRidesBookedByOthers: false,
        },
        lastSeen: '1',
    },
];

export enum ChangeStatus {
    ADD_USER = 'buttonName.addUser',
    EDIT_USER = 'buttonName.editUser',
    DELETE_USER = 'buttonName.deleteUser'
}

const Users = () => {
    const classes = useStyles();
    const dataGridClases = useDataGridStyles();
    const { t } = useTranslation();
    const [isAddModalOpen, setIsAddModalOpen] = useState(false);
    const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
    const [isEditModalOpen, setIsEditModalOpen] = useState(false);

    const handleDelete = () => setIsDeleteModalOpen(true);

    const handleEdit = () => setIsEditModalOpen(true);

    const columns = useUsersColumns(handleDelete, handleEdit);

    return (
        <Grid container alignItems="flex-start" padding="2rem">
            <Grid item xs={12} md={8} xl={8}>
                <Typography
                    sx={{
                        color: COLORS.BLUE,
                        fontSize: '1.5rem',
                        fontWeight: 700,
                    }}
                >
                    {t('pages.users.users')}
                </Typography>
            </Grid>
            <Grid
                item
                xs={12}
                md={4}
                xl={4}
                sx={{
                    display: 'flex',
                    justifyContent: ['flex-start', 'flex-start', 'flex-end'],
                    marginTop: ['1rem', '1rem', '0'],
                }}
            >
                <PrimaryButton className={classes.addRideButton} onClick={() => setIsAddModalOpen(true)} icon={<AddIcon />}>
                    {t('pages.users.newUser')}
                </PrimaryButton>
            </Grid>
            <DataGridPro
                className={clsx({
                    [dataGridClases.dataGrid]: true,
                    [dataGridClases.dataGridNoRows]: Boolean(rows),
                })}
                autoHeight
                hideFooter
                disableColumnSelector
                disableColumnFilter
                rows={rows}
                columns={columns as unknown as GridColumns<GridValidRowModel>}
                components={{ NoRowsOverlay: CustomNoRowsOverlay }}
                componentsProps={{ noRowsOverlay: { noRows: t('noResultsFound') } }}
                getDetailPanelContent={useUsersColumnsMobile(handleDelete, handleEdit)}
                getDetailPanelHeight={() => 'auto'}
            />
            {isAddModalOpen && (
                <AddUserModal
                    isOpen={isAddModalOpen}
                    onClose={() => setIsAddModalOpen(false)}
                />
            )}
            {isEditModalOpen && (
                <EditUserModal
                    isOpen={isEditModalOpen}
                    onClose={() => setIsEditModalOpen(false)}
                />
            )}
            {isDeleteModalOpen && <DeleteUserModal onClose={() => setIsDeleteModalOpen(false)} />}
        </Grid>
    );
};

export default Users;
