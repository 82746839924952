/* eslint-disable no-nested-ternary */
import { DATE_FORMATS } from '../../constants/dateFormats';
import { Ride } from '../../pages/ride/components/RideInformation';
import { Vehicle } from '../../pages/ride/components/VehicleInformationCards';
import { SummaryRideType } from '../../pages/ride/context/RideContext';
import { RideResponse } from '../../pages/ride/customer/api/types';
import { EstimateDetails } from '../../pages/rides/customer/api/types';
import { getDateTimeWithoutOffsetFormatted } from '../getDateTimeWithoutOffset';

function secondsToTimeFormatted(seconds: number) {
    const hours = Math.floor(seconds / 3600);
    const minutes = Math.floor(seconds % 3600 / 60);

    const hDisplay = hours > 0 ? `${hours}H ` : '';
    const mDisplay = minutes > 0 ? `${minutes}min` : '';

    return hDisplay + mDisplay;
}

function metersToKmAndMiFormatted(meters: number) {
    const kilometers = (meters / 1000).toFixed(1);
    const miles = (meters * 0.000621371192).toFixed(1);

    return `${kilometers}km / ${miles} mi`;
}

export const mapRideToSummaryRide = (
    ride: RideResponse,
    rideValues: Ride,
    vehicle: Vehicle | null,
    newEstimateDistanceAndDuration: EstimateDetails | undefined
): SummaryRideType => ({
    status: ride.status,
    vehicleType: vehicle?.vehicleType ?? '-',
    specialRequest: rideValues?.specialRequest,
    price: (Math.round((vehicle?.price ?? 0) * 100) / 100).toFixed(2),
    priceCurrency: vehicle?.priceCurrancy ?? '',
    distance: vehicle ? metersToKmAndMiFormatted(newEstimateDistanceAndDuration?.distanceInMeters ?? ride.distanceInMeters) : '-',
    time: vehicle ? secondsToTimeFormatted(newEstimateDistanceAndDuration?.durationInSeconds ?? ride.durationInSeconds) : '-',
    pickUpLocation: rideValues.pickUpLocation?.name ?? '-',
    pickupCoordinates: rideValues.pickUpLocation?.latitude && rideValues.pickUpLocation?.longitude
        ? {
            lat: rideValues.pickUpLocation?.latitude,
            lon: rideValues.pickUpLocation?.longitude,
        }
        : undefined,
    dropOffLocation: rideValues.dropOffLocation?.name ?? '-',
    dropOffCoordinates: rideValues.dropOffLocation?.latitude && rideValues.dropOffLocation?.longitude
        ? {
            lat: rideValues.dropOffLocation?.latitude,
            lon: rideValues.dropOffLocation?.longitude,
        }
        : undefined,
    pickUpDateAndTime: getDateTimeWithoutOffsetFormatted(
        rideValues.pickUpTime
            ? rideValues.primaryTimeZone === 'UTC'
                ? rideValues.pickUpTime.toUTCString()
                : rideValues.pickUpTime.toString()
            : '',
        DATE_FORMATS['ddd, DD MMM YYYY at HH:mm A']
    ),
    pickUpDateAndTimeUtc: getDateTimeWithoutOffsetFormatted(
        rideValues.pickUpTime
            ? rideValues.primaryTimeZone === 'UTC'
                ? rideValues.pickUpTime.toString()
                : rideValues.pickUpTime.toUTCString()
            : '',
        DATE_FORMATS['ddd, DD MMM YYYY at HH:mm A']
    ),
    luggages: parseInt(rideValues.luggages),
    passengers: parseInt(rideValues.travellers),
    paymentMethod: ride?.paymentMethod,
    booker: ride?.booker,
    primaryTimeZone: rideValues.primaryTimeZone,
    dropOffPlaceId: rideValues.dropOffLocation?.id,
    pickUpPlaceId: rideValues.pickUpLocation?.id,
});

