import { Theme } from '@mui/material/styles';
import { makeStyles, createStyles } from '@mui/styles';

const useAppBarStyles = makeStyles((theme: Theme) => createStyles(
    {
        mobileAppBar: {
            backgroundColor: theme.palette.common.white,
            bottom: 0,
            color: theme.palette.common.black,
            padding: theme.spacing(0),
            top: 'auto',
            '& [class*="MuiButton-root"]': {
                minWidth: '40px',
                padding: '5px 13px',
            },
            '& [class*="MuiToolbar-gutters"]': { paddingRight: 0 },
        },
    }
));

export default useAppBarStyles;
