import moment from 'moment';
import { DATE_FORMATS } from '../constants/dateFormats';

export const getDateTimeWithoutOffsetFormatted = (dateTime: string, dateTimeFormat?: string) => {
    const utcOffset = moment.parseZone(dateTime).utcOffset();

    return moment(dateTime).utcOffset(utcOffset)
        .format(dateTimeFormat);
};

export const getDateTimeWithoutOffset = (dateTime: string) =>
    new Date(getDateTimeWithoutOffsetFormatted(dateTime, DATE_FORMATS['YYYY-MM-DD HH:mm:ss']));
