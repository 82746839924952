import FormError from './FormError';

export default function getFormErrorMessage(error: FormError): string {
    switch (error) {
        case FormError.Unauthorized:
            return 'errors.formWError.unauthorized';
        case FormError.UnexpectedError:
            return 'errors.formWError.unexpectedError';
        default:
            throw new Error('Unhandled form error');
    }
}
