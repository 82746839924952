import { createContext } from 'react';

import { PrimaryTimeZone } from '../../../services/types';
import { Coordinates } from '../../rides/customer/api/types';
import { Booker } from '../customer/api/types';

export interface SummaryRideType {
    status: string;
    specialRequest: string;
    price: string | null;
    priceCurrency: string;
    distance: string;
    time: string;
    pickUpLocation: string;
    dropOffLocation: string;
    pickupCoordinates?: Coordinates;
    dropOffCoordinates?: Coordinates;
    pickUpDateAndTime: string;
    pickUpDateAndTimeUtc: string;
    luggages: number;
    passengers: number;
    mainTraveller?: string;
    otherTraveller?: string;
    vehicleType: string;
    paymentMethod?: string;
    booker?: Booker;
    primaryTimeZone: PrimaryTimeZone | null;
    pickUpPlaceId?: string;
    dropOffPlaceId?: string;
}

export interface RideContextType {
    summary: SummaryRideType;
}

export const RideContext = createContext<RideContextType>({
    summary: {
        status: '',
        vehicleType: '',
        specialRequest: '',
        price: '0',
        priceCurrency: '',
        distance: '',
        time: '',
        pickUpLocation: '',
        dropOffLocation: '',
        pickupCoordinates: undefined,
        dropOffCoordinates: undefined,
        pickUpDateAndTime: '',
        pickUpDateAndTimeUtc: '',
        mainTraveller: '',
        otherTraveller: '',
        luggages: 0,
        passengers: 0,
        primaryTimeZone: PrimaryTimeZone.LOCAL,
    },
});
